import React from 'react';
import PropTypes from 'prop-types';
import { HINT_TIMEOUTS } from 'constants/index';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import './HintsList.scss';

const HintsList = ({ title, hints, closeHintHandler, noTitle, className }) => {
  return (
    <div
      className={cx('hints-list', {
        [className]: className,
      })}
    >
      {!noTitle && (
        <p className="hints-list__title"> {title ? title : 'Возможно будет полезно для вас:'}</p>
      )}

      <div className="hints-list__list">
        {hints.length >= 1 &&
          hints.map(item => (
            <div className="hints-list__item-wrap" key={item.id}>
              {!item.isHelpLink ? (
                <>
                  <button
                    type="button"
                    className="hints-list__item"
                    onClick={item.action}
                    data-testid="hints-list-item"
                    data-cy="hints-list-item"
                  >
                    <span className="hints-list__item-text">{item.title}</span>
                  </button>

                  <CSSTransition
                    in={item.openCondition}
                    timeout={HINT_TIMEOUTS}
                    unmountOnExit
                    classNames="hint-auth"
                  >
                    <HintAuth
                      ref={item.dropEl}
                      title={item.titleForPopup}
                      onCloseBtnClick={closeHintHandler}
                    >
                      {item.popup}
                    </HintAuth>
                  </CSSTransition>
                </>
              ) : (
                <button
                  type="button"
                  className="hints-list__item js-help"
                  data-testid="hints-list-item"
                >
                  <span className="hints-list__item-text">{item.title}</span>
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

HintsList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  closeHintHandler: PropTypes.func,
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      titleForPopup: PropTypes.string,
      action: PropTypes.func,
      openCondition: PropTypes.bool,
      popup: PropTypes.node,
      dropEl: PropTypes.shape({}),
      isHelpLink: PropTypes.bool,
    }),
  ),
  noTitle: PropTypes.bool,
};

HintsList.defaultProps = {
  className: null,
  closeHintHandler: () => {},
  title: null,
  noTitle: false,
  hints: [],
};

export default HintsList;

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

// Styles
import './HintAuth.scss';

const HintAuth = React.forwardRef((props, ref) => {
  const { className, classNameInner, title, children, onCloseBtnClick, isMenuOpen } = props;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  return createPortal(
    <div
      className={cx('hint-auth', {
        [className]: className,
      })}
      ref={ref}
      data-cy="hint-auth"
      data-testid="hint-auth"
    >
      <div
        className={cx('hint-auth__inner', {
          [classNameInner]: classNameInner,
        })}
        data-testid="hint-auth-inner"
      >
        <div className="hint-auth__content">
          {title && (
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={{ enter: 100, exit: 250 }}
              unmountOnExit
            >
              <h2 className="hint-auth__title">{title}</h2>
            </CSSTransition>
          )}

          {children}
        </div>
      </div>

      <button
        className="hint-auth__close"
        type="button"
        onClick={onCloseBtnClick}
        aria-label="Закрыть попап"
        data-cy="hint-auth-close-btn"
      >
        <IconClose className="hint-auth__close-icon" />
      </button>
    </div>,
    document.body,
  );
});

HintAuth.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  title: PropTypes.string,
  onCloseBtnClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isMenuOpen: PropTypes.bool,
};

HintAuth.defaultProps = {
  className: null,
  classNameInner: null,
  title: null,
  onCloseBtnClick: () => {},
  isMenuOpen: false,
};

export default HintAuth;

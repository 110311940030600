import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLOR, SIZE, WEIGHT } from 'tools/constants';
import { Card, LineText, TitleText } from 'ui';

import { IDENTIFICATION_ENUM } from '../../utils';

export const CardsOfGetAccount = ({
  dataCards,
  urlToGetIdentificationOfSelfWorkPage,
  urlToGetIdentificationOfSvyaznoyPage,
  urlToGetIdentificationOfLetterPage,
}) => (
  <CardsWraperStyled>
    {dataCards.map(({ icon, title, text, type, url = null }) => (
      <CardStyled
        key={title}
        hover
        url={
          url ||
          getUrl(
            type,
            urlToGetIdentificationOfSelfWorkPage,
            urlToGetIdentificationOfSvyaznoyPage,
            urlToGetIdentificationOfLetterPage,
          )
        }
      >
        <IconWraperStyled>{icon}</IconWraperStyled>
        <ContentCardWraper>
          <TitleTextMdStyled size={SIZE.lg}>{title}</TitleTextMdStyled>
          <LineTextStyled color={COLOR['grey-blue']} weight={WEIGHT['circe-regular']}>
            {text}
          </LineTextStyled>
        </ContentCardWraper>
      </CardStyled>
    ))}
  </CardsWraperStyled>
);

CardsOfGetAccount.propTypes = {
  dataCards: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ),
  urlToGetIdentificationOfSelfWorkPage: PropTypes.string || null,
  urlToGetIdentificationOfSvyaznoyPage: PropTypes.string || null,
  urlToGetIdentificationOfLetterPage: PropTypes.string || null,
};

CardsOfGetAccount.defaultProps = {
  dataCards: PropTypes.array.isRequired,
  urlToGetIdentificationOfSelfWorkPage: null,
  urlToGetIdentificationOfSvyaznoyPage: null,
  urlToGetIdentificationOfLetterPage: null,
};

const getUrl = (
  type,
  urlToGetIdentificationOfSelfWorkPage,
  urlToGetIdentificationOfSvyaznoyPage,
  urlToGetIdentificationOfLetterPage,
) => {
  switch (type) {
    case IDENTIFICATION_ENUM.inSelfwork:
      return urlToGetIdentificationOfSelfWorkPage;
    case IDENTIFICATION_ENUM.inSvyaznoy:
      return urlToGetIdentificationOfSvyaznoyPage;
    case IDENTIFICATION_ENUM.inLetter:
      return urlToGetIdentificationOfLetterPage;
    default:
      return null;
  }
};

const CardsWraperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconWraperStyled = styled.div`
  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
  width: 85px;
  height: 40px;

  path {
    transition: fill 0.3s;

    ${CardStyled}:hover & {
      fill: #007aff;
    }
  }
`;

const TitleTextMdStyled = styled(TitleText)`
  margin-top: 40px;
`;

const ContentCardWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineTextStyled = styled(LineText)`
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
`;

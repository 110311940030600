import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { CSSTransition } from 'react-transition-group';

import Card from '../Card/Card';
import MoneyInputAndPaymentButton from '../MoneyInputAndPaymentButton/MoneyInputAndPaymentButton';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';
import { ReactComponent as IconMaestroLogo } from 'static/assets/maestro-logo.svg';
import { ReactComponent as IconMasterCardLogo } from 'static/assets/mastercard-logo.svg';
import { ReactComponent as IconMirLogo } from 'static/assets/mir-logo.svg';
import { ReactComponent as IconVisaLogo } from 'static/assets/visa-logo.svg';

import rublesToPenny from '../../helpers/rublesToPenny';
import pennyToRubles from '../../helpers/pennyToRubles';
import isBankCardNumberValid from '../../helpers/isBankCardNumberValid';
import { normalizePhone } from 'helpers';

import {
  setMoneyTransferSum,
  setMoneyTransferCardOrWalletNumber,
  setMoneyTransferNextStep,
  getMoneyTransferBankCardInfo,
} from 'modules/money-transfer/actions';

import {
  getMinSumByTransferMethod,
  getMaxSumByTransferMethod,
  getUserBalance,
  getTotalBalanceByMoneyTransferMethod,
} from 'modules/money-transfer/selectors';

import './BankCard.scss';

const BankCard = ({
  dispatch,
  isMoneyTransferAllowed,
  maxLimitSum,
  minSum,
  maxSum,
  userBalance,
  maxTransferUserBalance,
  sum,
  cardOrWalletNumber,
  bankCardInfo,
  getMoneyTransferBankCardInfoSuccess,
}) => {
  const [isRendered, setIsRendered] = React.useState(false);
  const [cardNumber, setCardNumber] = React.useState('');
  const [localSum, setLocalSum] = React.useState('');
  const [isCardValid, setIsCardValid] = React.useState(false);
  const [isSumValid, setIsSumValid] = React.useState(true);
  const [isErrorSum, setIsErrorSum] = React.useState('');

  const isMirProvider = cardNumber.slice(0, 1) === '2';
  const isMasterCardProvider = cardNumber.slice(0, 1) === '5';
  const isVisaProvider = cardNumber.slice(0, 1) === '4';
  const isMaestroProvider = false;
  const hasBankColor = cardNumber.length >= 6 && getMoneyTransferBankCardInfoSuccess;

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (isBankCardNumberValid(cardNumber)) {
      setIsCardValid(true);
    }

    if (!isBankCardNumberValid(cardNumber) && isCardValid) {
      setIsCardValid(false);
    }

    if (cardNumber.length >= 6) {
      dispatch(getMoneyTransferBankCardInfo(cardNumber.slice(0, 6)));
    }
  }, [cardNumber]);

  React.useEffect(() => {
    if (cardOrWalletNumber) {
      setCardNumber(cardOrWalletNumber);
    }
  }, [cardOrWalletNumber]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (localSum) {
      dispatch(setMoneyTransferSum(rublesToPenny(parseFloat(localSum))));
    } else {
      dispatch(setMoneyTransferSum(0));
    }
  }, [localSum]);

  React.useEffect(() => {
    if (sum !== 0) {
      setLocalSum(sum.toString());
    }
  }, [sum]);

  function onCardNumberChange(event) {
    setCardNumber(normalizePhone(event.target.value));
  }

  function onSumChange(values) {
    const { floatValue, value } = values;
    if (floatValue > maxLimitSum || floatValue > maxSum) {
      setLocalSum(Math.min(maxLimitSum, maxSum));
    } else if (value === '') {
      setLocalSum('');
    } else {
      setLocalSum(value);
    }

    if (floatValue > maxTransferUserBalance) {
      setIsSumValid(false);
      setIsErrorSum(`Недостаточно средств`);
    } else if (minSum > floatValue) {
      setIsSumValid(false);
      setIsErrorSum(`Минимальная сумма - ${minSum} ₽`);
    } else if (maxSum < floatValue) {
      setIsSumValid(false);
      setIsErrorSum(`Максимальная сумма - ${maxSum} ₽`);
    } else {
      setIsSumValid(true);
      setIsErrorSum('');
    }
  }

  function pay() {
    dispatch(setMoneyTransferCardOrWalletNumber(cardNumber));
    dispatch(setMoneyTransferNextStep());
  }

  function transferAll() {
    onSumChange({ floatValue: maxTransferUserBalance, value: maxTransferUserBalance.toString() });
  }

  function clearCardNumber() {
    setCardNumber('');
  }

  return (
    <CSSTransition classNames="animation-fade" in={isRendered} timeout={0} unmountOnExit>
      <div className="money-transfer-bank-card">
        {hasBankColor && (
          <div
            className="money-transfer-bank-card__background"
            style={{ backgroundColor: bankCardInfo.backgroundColors[0] || 'transparent' }}
          />
        )}
        {/* интерфейс карты */}
        <Card
          className="money-transfer-bank-card__card"
          style={{
            backgroundColor: hasBankColor ? bankCardInfo.backgroundColors[1] : 'transparent',
          }}
        >
          {/* если введено больше 5 цифр и есть информация о банковской карте */}
          {/* то показываем лого банка, которому принадлежит карта */}
          {hasBankColor && (
            <img
              className="money-transfer-bank-card__card-bank-logo"
              src={bankCardInfo.logo}
              alt="bank logo"
            />
          )}
          {/* а иначе показываем просто заголовок */}
          {!hasBankColor && (
            <div className="money-transfer-bank-card__card-title">Введите номер карты</div>
          )}

          <div className="money-transfer-bank-card__card-number">
            <div className="money-transfer-bank-card__card-input-wrap">
              <InputMask
                type="tel"
                className="money-transfer-bank-card__card-input"
                placeholder="0000 – 0000 – 0000 – 0000"
                maskChar={null}
                mask="7999 - 9999 - 9999 - 9999"
                value={cardNumber}
                x-autocompletetype="cc-number"
                disabled={!isMoneyTransferAllowed}
                onChange={onCardNumberChange}
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = '0000 – 0000 – 0000 – 0000')}
                formatChars={{
                  9: '[0-9]',
                  a: '[A-Za-z]',
                  '*': '[A-Za-z0-9]',
                  7: '[5,4,2,6]',
                }}
                data-cy="bank-card-number-input"
              />

              {cardNumber.length === 16 && (
                <button
                  className="money-transfer-bank-card__card-clear-button"
                  type="button"
                  aria-label="Очистить поле ввода номера карты"
                  onClick={clearCardNumber}
                  data-cy="remove-bank-card-data-button"
                >
                  <IconClose className="money-transfer-bank-card__card-clear-button-icon" />
                </button>
              )}
            </div>

            {!isMoneyTransferAllowed && (
              <p className="money-transfer-bank-card__card-error">
                Для вашей учетной записи вывод денег недоступен
              </p>
            )}

            {isMirProvider && <IconMirLogo className="money-transfer-bank-card__card-brand-name" />}
            {isMasterCardProvider && (
              <IconMasterCardLogo className="money-transfer-bank-card__card-brand-name" />
            )}
            {isVisaProvider && (
              <IconVisaLogo className="money-transfer-bank-card__card-brand-name" />
            )}
            {isMaestroProvider && (
              <IconMaestroLogo className="money-transfer-bank-card__card-brand-name" />
            )}
          </div>

          {/* список поддерживаемых провайдеров (мобильная версия) */}
          {cardNumber.length === 0 && (
            <div className="money-transfer-bank-card__payment-providers money-transfer-bank-card__payment-providers_mobile">
              <IconVisa className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_visa" />
              <IconMasterCard className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_msc" />
              <IconMir className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_mir" />
              <IconPci className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_pci" />
            </div>
          )}
          {/* /список поддерживаемых провайдеров (мобильная версия) */}
        </Card>
        {/* /интерфейс карты */}

        {/* список поддерживаемых провайдеров */}
        {!isCardValid && (
          <div className="money-transfer-bank-card__payment-providers money-transfer-bank-card__payment-providers_desktop">
            <IconVisa className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_visa" />
            <IconMasterCard className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_msc" />
            <IconMir className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_mir" />
            <IconPci className="money-transfer-bank-card__payment-provider money-transfer-bank-card__payment-provider_pci" />
          </div>
        )}
        {/* /список поддерживаемых провайдеров */}

        {/* навигация */}
        {isCardValid && (
          <div className="money-transfer-bank-card__nav">
            <MoneyInputAndPaymentButton
              sum={localSum.toString()}
              onSumChange={onSumChange}
              isSumValid={isSumValid}
              isErrorSum={isErrorSum}
              onPay={pay}
              transferAll={maxTransferUserBalance >= minSum ? transferAll : null}
            />
          </div>
        )}
        {/* /навигация */}
      </div>
    </CSSTransition>
  );
};

BankCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMoneyTransferAllowed: PropTypes.bool.isRequired,
  maxLimitSum: PropTypes.number.isRequired,
  minSum: PropTypes.number.isRequired,
  maxSum: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
  maxTransferUserBalance: PropTypes.number.isRequired,
  sum: PropTypes.number.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  bankCardInfo: PropTypes.shape({
    backgroundColor: PropTypes.string,
    logo: PropTypes.string,
    bankCardType: PropTypes.string,
  }).isRequired,
  getMoneyTransferBankCardInfoSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMoneyTransferAllowed: state.moneyTransfer.info.services.bankCard.available,
  maxLimitSum: pennyToRubles(state.moneyTransfer.info.maxLimitSum),
  minSum: pennyToRubles(getMinSumByTransferMethod('bankcard')(state)),
  maxSum: pennyToRubles(getMaxSumByTransferMethod('bankcard')(state)),
  userBalance: pennyToRubles(getUserBalance()(state)),
  maxTransferUserBalance: pennyToRubles(getTotalBalanceByMoneyTransferMethod('bankcard')(state)),
  sum: pennyToRubles(state.moneyTransfer.payment.sum),
  cardOrWalletNumber: state.moneyTransfer.payment.cardOrWalletNumber,
  bankCardInfo: state.moneyTransfer.bankCardInfo,
  getMoneyTransferBankCardInfoSuccess: state.moneyTransfer.getMoneyTransferBankCardInfo.success,
});

export default connect(mapStateToProps)(BankCard);

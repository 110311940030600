import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import addDays from 'date-fns/addDays';
import startOfYear from 'date-fns/startOfYear';
import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';
import formatNumber from 'helpers/formatNumber';
import { setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from 'helpers';
import {
  showPopupCalendar,
  showInlineCalendar,
  setPartnerPaymentsCurrentRangeTab,
  setPartnerPaymentsRange,
  hideCheck,
} from '../../actions';

import styles from './PartnerPaymentsMenu.module.scss';

const PartnerPaymentsMenu = ({ dispatch, currentPaymentsRangeTab, totalSum, paymentsNumber }) => {
  const isWeekTabActive = currentPaymentsRangeTab === 1;
  const isMonthTabActive = currentPaymentsRangeTab === 2;
  const isYearTabActive = currentPaymentsRangeTab === 3;
  const isCustomPeriodTabActive = currentPaymentsRangeTab === 4;

  function setTab() {
    dispatch(hideCheck());
  }

  function setWeekTab() {
    setTab();
    dispatch(setPartnerPaymentsCurrentRangeTab(1));
    dispatch(
      setPartnerPaymentsRange({
        startDate: addDays(setHoursAndMinutesToZero(new Date()), -6),
        endDate: setHoursAndMinutesTheEndOfDay(new Date()),
      }),
    );
  }

  function setMonthTab() {
    setTab();
    dispatch(setPartnerPaymentsCurrentRangeTab(2));
    dispatch(
      setPartnerPaymentsRange({
        startDate: new Date(setHoursAndMinutesToZero(addDays(new Date(), -30))),
        endDate: new Date(setHoursAndMinutesTheEndOfDay(new Date())),
      }),
    );
  }

  function setYearTab() {
    setTab();
    dispatch(setPartnerPaymentsCurrentRangeTab(3));
    dispatch(
      setPartnerPaymentsRange({
        startDate: startOfYear(setHoursAndMinutesToZero(new Date())),
        endDate: setHoursAndMinutesTheEndOfDay(new Date()),
      }),
    );
  }

  function setCustomMobileTab() {
    setTab();
    dispatch(showPopupCalendar());
    dispatch(setPartnerPaymentsCurrentRangeTab(4));
  }

  function setCustomDesktopTab() {
    setTab();
    dispatch(showInlineCalendar());
    dispatch(setPartnerPaymentsCurrentRangeTab(4));
  }

  return (
    <div className={styles.partnerPaymentsMenu}>
      <div className={styles.statsSection}>
        <div className={styles.statsSectionBlock}>
          <div>Сумма выплат</div>
          <div>{totalSum} ₽</div>
        </div>
        <div className={styles.statsSectionBlock}>
          <div>Выплат</div>
          <div>{paymentsNumber}</div>
        </div>
      </div>
      <div className={styles.navSection}>
        <nav className={styles.navButtonGroup}>
          <button
            className={cx(styles.navButton, isWeekTabActive && styles.navButtonActive)}
            onClick={setWeekTab}
          >
            Неделя
          </button>
          <button
            className={cx(styles.navButton, isMonthTabActive && styles.navButtonActive)}
            onClick={setMonthTab}
          >
            Месяц
          </button>
          <button
            className={cx(styles.navButton, isYearTabActive && styles.navButtonActive)}
            onClick={setYearTab}
          >
            Год
          </button>
          <button
            className={cx(
              styles.navButton,
              styles.navMobileButton,
              isCustomPeriodTabActive && styles.navButtonActive,
            )}
            onClick={setCustomMobileTab}
          >
            <IconCalendar />
          </button>
          <button
            className={cx(
              styles.navButton,
              styles.navDesktopButton,
              isCustomPeriodTabActive && styles.navButtonActive,
            )}
            onClick={setCustomDesktopTab}
          >
            <IconCalendar />
          </button>
        </nav>
      </div>
    </div>
  );
};

PartnerPaymentsMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentPaymentsRangeTab: PropTypes.number.isRequired,
  paymentsNumber: PropTypes.number.isRequired,
  totalSum: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentPaymentsRangeTab: state.partners.paymentsRangeTab.current,
  paymentsNumber: state.partners.payments.count,
  totalSum: formatNumber(state.partners.payments.sum),
});

export default connect(mapStateToProps)(PartnerPaymentsMenu);

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { PTClassNames } from 'tools/types/propTypes';
import { Link } from 'react-router-dom';

import { LineText } from 'ui/atoms';
import { TitleText } from 'ui/molecules';

export const ButtonRouteLink = ({ children, url, to, title, className, fontFamily, fontWeight }) =>
  title ? (
    <TitleText className={className} fontFamily={fontFamily} fontWeight={fontWeight}>
      <LinkRouteStyled to={url || to} className={className}>
        {children}
      </LinkRouteStyled>
    </TitleText>
  ) : (
    <LineText className={className} fontFamily={fontFamily} fontWeight={fontWeight}>
      <LinkRouteStyled to={url || to} className={className}>
        {children}
      </LinkRouteStyled>
    </LineText>
  );

const LinkRouteStyled = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

ButtonRouteLink.propTypes = {
  children: PropTypes.string.isRequired,
  title: PropTypes.bool,
  url: PropTypes.string,
  to: PropTypes.string,
  className: PTClassNames,
};

ButtonRouteLink.defaultProps = {
  title: false,
  url: null,
  to: null,
  className: '',
};

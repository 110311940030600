import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as IconError } from 'static/assets/icon-information.svg';

import './ProfileErrorMessage.scss';

const ProfileErrorMessage = ({ animationStart, className, content, children }) => {
  return (
    <CSSTransition
      classNames="animation-from-top-to-bottom"
      in={animationStart}
      timeout={{ enter: 100, exit: 100 }}
      unmountOnExit
    >
      <div
        className={cx('profile-error-message', {
          [className]: className,
        })}
      >
        <IconError className="profile-error-message__icon" />

        {content && <div className="profile-error-message__text">{content}</div>}

        {children}
      </div>
    </CSSTransition>
  );
};

ProfileErrorMessage.propTypes = {
  animationStart: PropTypes.bool.isRequired,
  className: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
};

ProfileErrorMessage.defaultProps = {
  className: null,
  content: null,
  children: null,
};

export default ProfileErrorMessage;

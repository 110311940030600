import * as Api from 'api/partners';
import { fetchWrapper } from 'helpers';

import {
  INCOME_REG_FROM_FOREIGN_AGENCY,
  INCOME_REG_FROM_LEGAL_ENTITY,
  INCOME_REG_WORD_MAP,
} from 'constants/index';

import * as types from './action-types';
import { setChangedTaxStatus, setSzStatusFromDb } from '../../authLegasy/actions';

export const sentPassportData =
  ({
    birthDay, //день рождения
    birthPlace, //место рождения
    firstName, //имя
    lastName, //фамилия
    middleName, //очество
    inn, //инн
    issueDate, //дата выпуска
    passportIssuedBy, //паспорт выданный адрес
    passportIssuer, // паспорт выдан подразделение
    number, //номер паспорта
    serie, //серия паспорта
    clientGroup,
  }) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/identification/authorize/simple/start`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'PUT',
        body: JSON.stringify({
          birthDay,
          birthPlace,
          firstName,
          inn,
          issueDate,
          lastName,
          middleName,
          number,
          passportIssuedBy,
          passportIssuer,
          serie,
          clientGroup,
        }),
      })
        .then((data) => {
          dispatch({
            type: types.SENT_PASSPORT_DATA_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: types.SENT_PASSPORT_DATA_FAIL,
          });
          reject(err);
        });
    });
  };

export const checkIfPartialAuthStatusIsInProcess = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/identification/is/running`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((data) => {
        dispatch({
          type: types.PARTIAL_AUTH_STATUS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.PARTIAL_AUTH_STATUS_FAIL,
        });
        reject(err);
      });
  });
};

export const sentPassportPhotoToGetData = (passport) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/identification/authorize/simple/passport/parse`;
  const formData = new FormData();
  formData.append('passport', passport);

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: formData,
      multipart: true,
    })
      .then((data) => {
        dispatch({
          type: types.PARSE_PASSPORT_DATA_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.PARSE_PASSPORT_DATA_FAIL,
        });
        reject(err);
      });
  });
};

export const sentPassportAndSelfie = (passport, selfie) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/identification/authorize`;

  const formData = new FormData();
  formData.append('passport', passport);
  formData.append('selfie', selfie);

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: formData,
      multipart: true,
    })
      .then((data) => {
        dispatch({
          type: types.SENT_PASSPORT_AND_SELFIE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.SENT_PASSPORT_AND_SELFIE_FAIL,
        });
        reject(err);
      });
  });
};

export const getUnconfirmedEmail = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/last/email/change`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((data) => {
        dispatch({
          type: types.GET_UNCONFIRMED_EMAIL_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(() => {
        reject();
      });
  });
};

export const changeEmail = (email) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/email/change`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHANGE_EMAIL_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePhoneRequestCode = (phone, token) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/phone-change`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        phone,
        code: token,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.REQUEST_CODE_CHANGE_PHONE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: types.REQUEST_CODE_CHANGE_PHONE_FAIL,
        });
        reject(error);
      });
  });
};

export const changePhoneConfirm = (actionPinAcceptId, pin, pin2) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/phone-change/confirm`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        pin,
        pin2,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHANGE_PHONE_CONFIRM_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: types.CHANGE_PHONE_CONFIRM_FAIL,
        });
        reject(error);
      });
  });
};

export const savePassportPhoto = (passport) => ({
  type: types.SAVE_PASSPORT_PHOTO,
  payload: {
    passport,
  },
});

export const setPassportErrorMessage = (passportErrorMessage) => ({
  type: types.SET_PASSPORT_ERROR_MESSAGE,
  payload: {
    passportErrorMessage,
  },
});

export const requestCodeCloseWallet = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/close`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then((data) => {
        dispatch({
          type: types.REQUEST_CODE_CLOSE_WALLET_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(() => {
        dispatch({
          type: types.REQUEST_CODE_CLOSE_WALLET_FAIL,
        });
        reject();
      });
  });
};

export const closeWallet = (actionPinAcceptId, pin) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/close/confirm`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        pin,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CLOSE_WALLET_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(() => {
        dispatch({
          type: types.CLOSE_WALLET_FAIL,
        });
        reject();
      });
  });
};

export const setAvatar = (avatar) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/avatar`;

  const formData = new FormData();
  formData.append('avatar', avatar);

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: formData,
      multipart: true,
    })
      .then((data) => {
        dispatch({
          type: types.SET_AVATAR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.SET_AVATAR_FAIL,
        });
        reject(err);
      });
  });
};

export const deleteAvatar = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/avatar`;
  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: {},
    })
      .then((data) => {
        dispatch({
          type: types.SET_AVATAR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.SET_AVATAR_FAIL,
        });
        reject(err);
      });
  });
};

export const setAvatarError = (isAvatarError) => {
  return {
    type: types.SET_AVATAR_ERROR,
    payload: {
      isAvatarError,
    },
  };
};

export const checkAvatar = (filename) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/avatar/${filename}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((data) => {
        dispatch({
          type: types.CHECK_AVATAR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_AVATAR_FAIL,
        });
        reject(err);
      });
  });
};

export const requestCodeToChangePassword = (newPassword, oldPassword, token) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/password-change`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        newPassword,
        oldPassword,
        code: token,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.REQUEST_CODE_TO_CHANGE_PASSWORD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.REQUEST_CODE_TO_CHANGE_PASSWORD_FAIL,
        });
        reject(err);
      });
  });
};

export const changePassword = (actionPinAcceptId, pin) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/settings/password-change/confirm`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        pin,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHANGE_PASSWORD_FAIL,
        });
        reject(err);
      });
  });
};

export const setAuthStep = (authStep) => ({
  type: types.SET_AUTH_STEP,
  payload: {
    authStep,
  },
});

export const setRenderBetweenTabs = (isRenderedBetweenTabs) => ({
  type: types.SET_RENDER_BETWEEN_TABS,
  payload: {
    isRenderedBetweenTabs,
  },
});

export const getLastDateOfOperations = () => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/payment/history/last-date?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_LAST_DATE_OF_OPERATIONS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_LAST_DATE_OF_OPERATIONS_FAIL,
        });
        reject(err);
      });
  });
};

export const getOperations = (month, year, page, pageSize, zoneOffset) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/payment/history?month=${month}&year=${year}&page=${page}&pageSize=${pageSize}&zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_OPERATIONS_SUCCESS,
          payload: {
            res,
            page,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_OPERATIONS_FAIL,
        });
        reject(err);
      });
  });
};

export const clearOperations = () => ({
  type: types.CLEAR_OPERATIONS_SUCCESS,
});

export const getLastDateOfRequests = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/history/last-date?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_LAST_DATE_OF_REQUESTS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_LAST_DATE_OF_REQUESTS_FAIL,
        });
        reject(err);
      });
  });
};

export const getRequests = (month, page, pageSize, year, zoneOffset) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/pay-it/history?month=${month}&page=${page}&pageSize=${pageSize}&year=${year}&zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_REQUESTS_SUCCESS,
          payload: {
            res,
            page,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_REQUESTS_FAIL,
        });
        reject(err);
      });
  });
};

export const clearHistoryRequests = () => ({
  type: types.CLEAR_HISTORY_REQUESTS_SUCCESS,
});

export const getInvoicePDF = (invoiceId) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/payment/check-pdf/${invoiceId}?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      isBlob: true,
    })
      .then((res) => {
        dispatch({
          type: types.GET_INVOICE_PDF_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INVOICE_PDF_FAIL,
        });
        reject(err);
      });
  });
};

export const getIncomeStatement =
  (year = null) =>
  (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/certificate/income-statement`;

    if (year) {
      url = `${url}?year=${year}`;
    }

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        // isBlob: true,
      })
        .then((res) => {
          dispatch({
            type: types.GET_CERTIFICATE_INCOME_STATEMENT_SUCCESS,
            payload: {
              res,
            },
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: types.GET_CERTIFICATE_INCOME_STATEMENT_FAIL,
          });
          reject(err);
        });
    });
  };

export const getCertificateRegistration = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/certificate/registration`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      // isBlob: true,
    })
      .then((res) => {
        dispatch({
          type: types.GET_CERTIFICATE_REGISTRATION_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_CERTIFICATE_REGISTRATION_FAIL,
        });
        reject(err);
      });
  });
};

export const getDeals = (month, offset, pageSize, year, zoneOffset) => (dispatch) => {
  /**
   * где  offset - количество записей, которые пропустить от начала для постраничной разбивки.
   * Т.е. если нужно запросить вторую страницу при условии что на странице 10 записей
   * offset будет 10, если третью - соответственно 20
   * */
  const url = `${
    process.env.REACT_APP_API_URL
  }/client/deals?month=${month}&offset=${offset}&pageSize=${pageSize}&year=${year}&zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        if (res && res.data) {
          /**
           * Берем месяц поледней сделки и сравниваем
           * этот месяц с месяцем других сделок,
           * чтобы в выдаче были только сделки одного месяца
           * */
          const operationTimeMonth =
            res.data &&
            res.data.items &&
            Array.isArray(res.data.items) &&
            res.data.items.length >= 1 &&
            res.data.items[0].operationTime &&
            new Date(res.data.items[0].operationTime).getMonth() + 1;

          dispatch({
            type: types.GET_DEALS_SUCCESS,
            payload: {
              items:
                Array.isArray(res.data.items) &&
                res.data.items.every(
                  (item) => new Date(item.operationTime).getMonth() + 1 === operationTimeMonth,
                )
                  ? res.data.items
                  : [],
              offset,
              pageSize,
            },
          });
          resolve(res);
        }
        throw new Error('Неизвестный формат ответа сервера');
      })
      .catch((err) => {
        dispatch({
          type: types.GET_DEALS_FAIL,
        });
        reject(err);
      });
  });
};

export const getAllMessages = (page, pageSize, zoneOffset) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/messages?page=${page}&pageSize=${pageSize}&zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_ALL_MESSAGES_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ALL_MESSAGES_FAIL,
        });
        reject(err);
      });
  });
};

export const getUnreadMessages = (page, pageSize, zoneOffset) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/messages/unread?page=${page}&pageSize=${pageSize}&zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_UNREAD_MESSAGES_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_UNREAD_MESSAGES_FAIL,
        });
        reject(err);
      });
  });
};

export const markAllMessagesAsRead = () => (dispatch) => {
  dispatch({
    type: types.MARK_ALL_MESSAGES_AS_READ,
  });
  return Promise.resolve();
};

export const clearUnreadMessages = () => ({
  type: types.CLEAR_UNREAD_MESSAGES,
});

export const readAllMessages = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/messages/read/all`;

  return new Promise((_resolve, reject) => {
    /** Оптимистично диспетчим успех */
    dispatch({
      type: types.READ_ALL_MESSAGES_SUCCESS,
    });

    fetchWrapper(url, {
      method: 'POST',
    }).catch(reject);
  });
};

export const readExactMessage = (messageId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/messages/read/${messageId}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
    })
      .then((data) => {
        dispatch({
          type: types.READ_EXACT_MESSAGE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.READ_EXACT_MESSAGE_FAIL,
        });
        reject(err);
      });
  });
};

/** РУЧКИ ЗАПРОСА ДЕНЕГ */
export const getMoneyRequestLimits = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/money/context?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_MONEY_REQUEST_LIMITS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_MONEY_REQUEST_LIMITS_FAIL,
        });
        reject(err);
      });
  });
};

export const createMoneyRequest = (commentary, email, sum) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/money`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        commentary,
        email,
        sum,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.CREATE_MONEY_REQUEST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CREATE_MONEY_REQUEST_FAIL,
        });
        reject(err);
      });
  });
};

export const getMoneyRequestById = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/money/${id}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_MONEY_REQUEST_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_MONEY_REQUEST_FAIL,
        });
        reject(err);
      });
  });
};

export const transferMoneyByMoneyRequest =
  ({ cvc, expiryMonth, expiryYear, pan, uuid } = {}) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/pay-it/payment/money`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'POST',
        body: JSON.stringify({
          cvc,
          expiryMonth,
          expiryYear,
          pan,
          uuid,
        }),
      })
        .then((data) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_MONEY_REQUEST_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_MONEY_REQUEST_FAIL,
          });
          reject(err);
        });
    });
  };

export const getMoneyRequestTransactionOperationStatus = (id) => async (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/money/result/${id}/state`;
  return fetchWrapper(url);
};
/** /РУЧКИ ЗАПРОСА ДЕНЕГ */

/** РУЧКИ ЗАПРОСА ДЕНЕГ ЧЕРЕЗ СЧЕТ */
export const createInvoice = (commentary, date, email, sum) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/invoice`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        commentary,
        dateProvideService: date,
        email,
        sum,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CREATE_INVOICE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CREATE_INVOICE_FAIL,
        });
        reject(err);
      });
  });
};

export const getInvoiceById = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/invoice/${id}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        // TODO: убрать, когда backend будет отдавать ошибку
        if (!res) {
          throw new Error(`invoice with id ${id} is not found`);
        }

        dispatch({
          type: types.GET_INVOICE_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INVOICE_FAIL,
        });
        reject(err);
      });
  });
};

export const getInvoiceContext = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/invoice/context?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_INVOICE_CONTEXT_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INVOICE_CONTEXT_FAIL,
        });
        reject(err);
      });
  });
};

export const transferMoneyByInvoice =
  ({ cvc, email, expiryMonth, expiryYear, pan, uuid } = {}) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/pay-it/payment/invoice`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'POST',
        body: JSON.stringify({
          cvc,
          email,
          expiryMonth,
          expiryYear,
          pan,
          uuid,
        }),
      })
        .then((data) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_INVOICE_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_INVOICE_FAIL,
          });
          reject(err);
        });
    });
  };

export const getInvoiceTransactionOperationStatus = (id) => async (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/invoice/result/${id}/state`;
  return fetchWrapper(url);
};

export const getInvoiceTransactionCheck = (checkId, hash) => {
  const url = `${process.env.REACT_APP_API_URL}/deals/${checkId}/${hash}`;
  return fetchWrapper(url);
};
/** /РУЧКИ ЗАПРОСА ДЕНЕГ ЧЕРЕЗ СЧЕТ */

/* eslint-disable no-unused-vars */
export const sendBillCreationRequest = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 1000);
    // setTimeout(reject, 1000);
  });
};
/* eslint-enable no-unused-vars */

export const registerIncome = ({
  currentSelectedOption,
  purpose,
  sum,
  // startDate,
  // requestTime,
  dateProvideService,
  companyName,
  companyInn,
}) => {
  const url = `${process.env.REACT_APP_API_URL}/client/deal`;

  const body = {
    // requestTime,
    // operationTime: startDate,
    dateProvideService,
    incomeType: INCOME_REG_WORD_MAP[currentSelectedOption],
    serviceName: purpose,
    serviceQuantity: 1,
    totalAmount: parseFloat(sum) * 100,
    serviceAmount: parseFloat(sum) * 100,
  };

  if (currentSelectedOption === INCOME_REG_FROM_LEGAL_ENTITY) {
    body.customerInn = companyInn;
    body.customerOrganization = companyName;
  }

  if (currentSelectedOption === INCOME_REG_FROM_FOREIGN_AGENCY) {
    body.customerOrganization = companyName;
  }

  return fetchWrapper(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const getIncomeCheckById = (dealId, operationTime) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/client/deals/${dealId}?operationTime=${operationTime}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((data) => {
        dispatch({
          type: types.GET_INCOME_CHECK_BY_ID_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INCOME_CHECK_BY_ID_FAIL,
        });
        reject(err);
      });
  });
};

export const deleteCheck = (dealId, messageCode) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/client/deal`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        dealId,
        messageCode,
      }),
    })
      .then(() => {
        dispatch({
          type: types.DELETE_CHECK_SUCCESS,
          payload: {
            dealId,
          },
        });
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: types.DELETE_CHECK_FAIL,
        });
        reject(err);
      });
  });
};

export const checkBankCardBankHolder = (cardNumber) => (dispatch) => {
  const url = `https://pro.selfwork.ru/cardinfo/info?bin=${cardNumber}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
      credentials: 'omit',
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_BANK_CARD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_BANK_CARD_FAIL,
        });
        reject(err);
      });
  });
};

export const setTaxPayingProgress = (taxPayingProgress) => ({
  type: types.SET_TAX_PAYING_PROGRESS,
  payload: {
    taxPayingProgress,
  },
});

export const removeBankCardData = () => ({
  type: types.REMOVE_BANK_CARD_DATA,
});

export const getBusinessCardInfo = (uniqueId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/cardinfo/${uniqueId}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_BUSINESS_CARD_INFO_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_BUSINESS_CARD_INFO_FAIL,
        });
        reject(err);
      });
  });
};

export const transferMoneyByBusinessCard =
  ({ clientUniqueId, message, payToken, sum } = {}) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/receipt/businesscard`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'POST',
        body: JSON.stringify({
          clientUniqueId,
          message,
          payToken,
          sum,
        }),
      })
        .then((data) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_BUSINESS_CARD_SUCCESS,
            payload: {
              data,
            },
          });
          resolve(data);
        })
        .catch((err) => {
          dispatch({
            type: types.TRANSFER_MONEY_BY_BUSINESS_CARD_FAIL,
          });
          reject(err);
        });
    });
  };

export const getTransferPaymentStatus = (type, id, timeout) => {
  const url = `${process.env.REACT_APP_API_URL}/transfer/${type}/${id}/state`;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetchWrapper(url).then(resolve).catch(reject);
    }, timeout);
  });
};

export const toggleBusinessCard = () => ({
  type: types.TOGGLE_BUSINESS_CARD,
});

export const getTransactionOperationStatus = (id) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/receipt/businesscard/result/${id}/state`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_TRANSACTION_OPERATION_STATUS_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_TRANSACTION_OPERATION_STATUS_FAIL,
        });
        reject(err);
      });
  });
};

/** /РУЧКИ ОТЧЕТНОСТИ - НАЛОГИ */
export const getTaxInfo = (zoneOffset) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/client/tax?zoneOffset=${zoneOffset}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_TAX_INFO_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_TAX_INFO_FAIL,
        });
        reject(err);
      });
  });
};

export const getTaxes = (maxMonth, year) => (dispatch) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/client/incomes?maxMonth=${maxMonth}&year=${year}&p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_TAXES_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_TAXES_FAIL,
        });
        reject(err);
      });
  });
};

export const setIncomeCheckData = (data) => {
  return {
    type: types.SET_INCOME_CHECK_DATA,
    payload: {
      incomeCheckData: data,
    },
  };
};

export const setProfileSettingsActiveTab = (profileSettingsActiveTab) => ({
  type: types.SET_PROFILE_SETTINGS_ACTIVE_TAB,
  payload: {
    profileSettingsActiveTab,
  },
});

// ОПЕРАЦИИ С КАРТАМИ В НАСТРОЙКАХ ПРОФИЛЯ

/* eslint-disable no-unused-vars */
export const deleteCard = () => (dispatch) => {
  const randomInt = Math.floor(Math.random() * Math.floor(5));

  if (randomInt < 3) {
    return new Promise((resolve, reject) => {
      setTimeout(reject, 1000);
    });
  }
  return new Promise((resolve) => {
    setTimeout(resolve, 1500);
  });
};
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export const makeCardDefault = () => (dispatch) => {
  const randomInt = Math.floor(Math.random() * Math.floor(5));

  if (randomInt < 3) {
    return new Promise((resolve, reject) => {
      setTimeout(reject, 1000);
    });
  }
  return new Promise((resolve) => {
    setTimeout(resolve, 1500);
  });
};
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export const addCard = () => (dispatch) => {
  const randomInt = Math.floor(Math.random() * Math.floor(5));

  if (randomInt < 3) {
    return new Promise((resolve, reject) => {
      setTimeout(reject, 1000);
    });
  }
  return new Promise((resolve) => {
    setTimeout(resolve, 1500);
  });
};
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export const addCompanyBill = () => (dispatch) => {
  return Promise.resolve();
  // const randomInt = Math.floor(Math.random() * Math.floor(5));
  //
  // if (randomInt < 3) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(reject, 1000);
  //   });
  // }
  // return new Promise(resolve => {
  //   setTimeout(resolve, 1500);
  // });
};
/* eslint-enable no-unused-vars */

export const createApplePaySession = (validationUrl) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/apple-pay/session/create`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        validationUrl,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.CREATE_APPLE_PAY_SESSION_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.CREATE_APPLE_PAY_SESSION_FAIL,
        });
        reject(err);
      });
  });
};

export const removeFnsRights = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/partner/unbind`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
    })
      .then((res) => {
        dispatch({
          type: types.REMOVE_FNS_RIGHTS_SUCCESS,
          payload: {
            res,
          },
        });
        dispatch(setChangedTaxStatus('NO'));
        dispatch(setSzStatusFromDb(''));
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.REMOVE_FNS_RIGHTS_FAIL,
        });
        reject(err);
      });
  });
};

export const getInnList = (inn) => (dispatch) => {
  // const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`;
  const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      credentials: 'omit',
      auth: 'Token d73cd135e5dd036aadfb91be3d75ff560741cff8',
      needCsrfToken: false,
      body: JSON.stringify({
        query: inn,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.GET_INN_LIST_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INN_LIST_FAIL,
        });
        reject(err);
      });
  });
};

export const getPartnerListFetching = () => ({
  type: types.GET_PARTNER_LIST_FETCHING,
});

export const getPartnerListSuccess = (payload) => ({
  type: types.GET_PARTNER_LIST_SUCCESS,
  payload,
});

export const getPartnerListFail = () => ({
  type: types.GET_PARTNER_LIST_FAIL,
});

export const getPartnerList = () => (dispatch) => {
  dispatch(getPartnerListFetching());

  Api.getPartnerList()
    .then((payload) => dispatch(getPartnerListSuccess(payload)))
    .catch(() => dispatch(getPartnerListFail()));
};

export const clearPartnerList = () => ({
  type: types.CLEAR_PARTNER_LIST,
});

export const hidePartnerRequestById = (payload) => ({
  type: types.HIDE_PARTNER_REQUEST_BY_ID,
  payload,
});

export const addPartner = (payload) => ({
  type: types.ADD_PARTNER,
  payload,
});

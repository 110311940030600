import React from 'react';

// Icons
import { ReactComponent as IconLoader } from 'static/assets/icon-loader-small.svg';

import './SmallCircleLoader.scss';

const SmallCircleLoader = () => {
  return <IconLoader />;
};

export default SmallCircleLoader;

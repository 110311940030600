import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AriaModal from 'react-aria-modal';
import { connect } from 'react-redux';

import {
  setChosenPaymentStatusFilters,
  setChosenPaymentWaysFilters,
  setChosenSumFromFilters,
  setChosenSumUntilFilters,
} from 'modules/acquiring/actions';
import MultipleSelect from 'modules/shared/components/MultipleSelect/MultipleSelect';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SumInput from 'modules/shared/components/SumInput/SumInput';

import { ERROR, GREY_BORDER, LIGHT_BLUE, PROCESS, REJECTED, SUCCESS } from 'constants/index';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

import './FiltersSideBar.scss';

const FiltersSideBar = ({
  closeHandler,
  mounted,
  dispatch,
  chosenSumFromFilters,
  chosenSumUntilFilters,
  chosenPaymentWaysFilters,
  chosenPaymentStatusFilters,
}) => {
  const [sumFrom, setSumFrom] = useState('');
  const [sumUntil, setSumUntil] = useState('');
  const [localPaymentWays, setLocalPaymentWays] = useState([]);
  const [localPaymentStatus, setLocalPaymentStatus] = useState([]);

  const paymentWayOptions = [
    { value: 'BANK', label: 'Банковская карта' },
    //{ value: 'KEEPER', label: 'Кошелек Самозанятые.рф' },
  ];
  const paymentStatusOptions = [
    { value: SUCCESS, label: 'Оплачен' },
    { value: PROCESS, label: 'Ожидает подтверждения' },
    { value: ERROR, label: 'Ошибка оплаты' },
    { value: REJECTED, label: 'Возвращëн' },
  ];

  /** Очищаем фильтры */
  const clearFiltersHandler = () => {
    dispatch(setChosenPaymentWaysFilters([]));
    dispatch(setChosenPaymentStatusFilters([]));
    dispatch(setChosenSumFromFilters(''));
    dispatch(setChosenSumUntilFilters(''));

    setSumFrom('');
    setSumUntil('');
    setLocalPaymentWays([]);
    setLocalPaymentStatus([]);
    closeHandler();
  };

  /** Устанавливаем фильтры */
  const acceptFilters = () => {
    dispatch(setChosenPaymentWaysFilters(localPaymentWays));
    dispatch(setChosenPaymentStatusFilters(localPaymentStatus));
    dispatch(setChosenSumFromFilters(sumFrom));
    dispatch(setChosenSumUntilFilters(sumUntil));
    closeHandler();
  };

  /**
   * Если в сторе есть sumFrom, sumUntil,
   * chosenPaymentWaysFilters, chosenPaymentStatusFilters
   * ставим их в локальные значения
   * */
  useEffect(() => {
    if (chosenSumFromFilters) {
      setSumFrom(chosenSumFromFilters);
    }

    if (chosenSumUntilFilters) {
      setSumUntil(chosenSumUntilFilters);
    }
  }, [chosenSumFromFilters, chosenSumUntilFilters]);

  return (
    <AriaModal
      underlayClass="filters-side-bar"
      onExit={closeHandler}
      titleText=" "
      underlayColor="rgba(255, 255, 255, 0.2)"
      mounted={mounted}
    >
      <div className="filters-side-bar__content">
        <h3 className="filters-side-bar__title">Фильтрация</h3>
        <div className="filters-side-bar__main">
          <div className="filters-side-bar__list">
            <MultipleSelect
              defaultValue={chosenPaymentWaysFilters}
              options={paymentWayOptions}
              handleSelectChange={(list) => setLocalPaymentWays(list)}
              className="filters-side-bar__list-item"
              label="Способ оплаты"
              isSomethingSelected={Boolean(localPaymentWays && localPaymentWays.length)}
            />

            <MultipleSelect
              defaultValue={chosenPaymentStatusFilters}
              options={paymentStatusOptions}
              handleSelectChange={(list) => setLocalPaymentStatus(list)}
              className="filters-side-bar__list-item"
              label="Статус платежа"
              isSomethingSelected={Boolean(localPaymentStatus && localPaymentStatus.length)}
            />

            <div className="filters-side-bar__sum-wrap">
              <SumInput
                label="Сумма - от"
                setSum={setSumFrom}
                value={sumFrom}
                className="filters-side-bar__sum-item"
                minSum={1}
                maxSum={999999}
              />
              <SumInput
                label="Сумма - до"
                setSum={setSumUntil}
                value={sumUntil}
                className="filters-side-bar__sum-item"
                minSum={1}
                maxSum={999999}
              />
            </div>
          </div>

          <div className="filters-side-bar__buttons">
            <StandardButton
              className="filters-side-bar__buttons-item"
              title="Сбросить"
              onClick={clearFiltersHandler}
              isLink={false}
              bgType={GREY_BORDER}
            />
            <StandardButton
              className="filters-side-bar__buttons-item"
              title="Применить"
              onClick={acceptFilters}
              isLink={false}
              bgType={LIGHT_BLUE}
            />
          </div>
        </div>

        <button className="filters-side-bar__close-button" onClick={closeHandler} type="button">
          <IconClose className="filters-side-bar__close-button-icon" />
        </button>
      </div>
    </AriaModal>
  );
};

FiltersSideBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  mounted: PropTypes.bool.isRequired,
  chosenSumFromFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chosenSumUntilFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chosenPaymentWaysFilters: PropTypes.arrayOf(PropTypes.shape({})),
  chosenPaymentStatusFilters: PropTypes.arrayOf(PropTypes.shape({})),
};

FiltersSideBar.defaultProps = {
  chosenSumFromFilters: '',
  chosenSumUntilFilters: '',
  chosenPaymentWaysFilters: [],
  chosenPaymentStatusFilters: [],
};

const mapStateToProps = (state) => ({
  chosenSumFromFilters: state.acquiring.chosenSumFromFilters,
  chosenSumUntilFilters: state.acquiring.chosenSumUntilFilters,
  chosenPaymentWaysFilters: state.acquiring.chosenPaymentWaysFilters,
  chosenPaymentStatusFilters: state.acquiring.chosenPaymentStatusFilters,
});

export default connect(mapStateToProps)(FiltersSideBar);

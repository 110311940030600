import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import useWindowSize from '@rehooks/window-size';

// Components
import SEFromLKEnterINN from 'modules/profileLegasy/components/SEFromLKEnterINN/SEFromLKEnterINN';
import SEFromLKWaiting from 'modules/profileLegasy/components/SEFromLKWaiting/SEFromLKWaiting';
import SEFromLKSuccess from 'modules/profileLegasy/components/SEFromLKSuccess/SEFromLKSuccess';
import SEFromLKFailed from 'modules/profileLegasy/components/SEFromLKFailed/SEFromLKFailed';
import SEFromLKAlreadyRegistered from 'modules/profileLegasy/components/SEFromLKAlreadyRegistered/SEFromLKAlreadyRegistered';
import SEFromLKError from 'modules/profileLegasy/components/SEFromLKError/SEFromLKError';
import SEFromLKNoSz from 'modules/profileLegasy/components/SEFromLKNoSz/SEFromLKNoSz';
import SEFromLKNoRights from 'modules/profileLegasy/components/SEFromLKNoRights/SEFromLKNoRights';
import Footer from 'modules/authLegasy/components/Footer/Footer';

// Misc
import { YES, PARTLY_YES } from 'constants/index.js';

// Styles
import './SEFromLKRegistrationContainer.scss';

const SelfEmployedRegistration = ({ userTaxStatus, szStatusFromDb }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES)) {
      setCurrentStep(3);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [szStatusFromDb]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return createPortal(
    <div className="se-from-lk-container">
      {/* ВВОД ИНН */}
      {currentStep === 1 && (
        <SEFromLKEnterINN
          className="se-from-lk_enter-inn"
          onNextStepButtonClick={() => setCurrentStep(currentStep + 1)}
          onSuccessSERegistration={() => setCurrentStep(3)}
          onFailedSERegistration={() => setCurrentStep(4)}
          onAlreadyRegistered={() => setCurrentStep(5)}
          onServerError={() => setCurrentStep(6)}
          onNoSz={() => setCurrentStep(7)}
          onNoRights={() => setCurrentStep(8)}
        />
      )}
      {/* /ВВОД ИНН */}

      {/* ОЖИДАНИЕ, ПРОВЕРКА */}
      {currentStep === 2 && (
        <SEFromLKWaiting
          className="se-from-lk_waiting"
          onSuccessSERegistration={() => setCurrentStep(3)}
          onFailedSERegistration={() => setCurrentStep(4)}
          onAlreadyRegistered={() => setCurrentStep(5)}
          onServerError={() => setCurrentStep(6)}
          onNoSz={() => setCurrentStep(7)}
          onNoRights={() => setCurrentStep(8)}
          cancelRequestOnSzStatus={() => setCurrentStep(1)}
        />
      )}
      {/* /ОЖИДАНИЕ, ПРОВЕРКА */}

      {/* УСПЕХ */}
      {currentStep === 3 && (
        <SEFromLKSuccess onNoRights={() => setCurrentStep(1)} className="se-from-lk_success" />
      )}
      {/* /УСПЕХ */}

      {/* НЕ УДАЛОСЬ ПРОВЕРИТЬ ИНН */}
      {currentStep === 4 && (
        <SEFromLKFailed className="se-from-lk_fail" onChangeINN={() => setCurrentStep(1)} />
      )}
      {/* /НЕ УДАЛОСЬ ПРОВЕРИТЬ ИНН */}

      {/* ТАКОЙ ПОЛЬЗОВАТЕЛЬ УЖЕ ЗАРЕГИСТРИРОВАН */}
      {currentStep === 5 && (
        <SEFromLKAlreadyRegistered
          className="se-from-lk_fail"
          onChangeINN={() => setCurrentStep(1)}
        />
      )}
      {/* /ТАКОЙ ПОЛЬЗОВАТЕЛЬ УЖЕ ЗАРЕГИСТРИРОВАН */}

      {/* ОШИБКА НА СЕРВЕРЕ, ЛИБО В ФНС */}
      {currentStep === 6 && (
        <SEFromLKError className="se-from-lk_fail" onChangeINN={() => setCurrentStep(1)} />
      )}
      {/* /ОШИБКА НА СЕРВЕРЕ, ЛИБО В ФНС */}

      {/* ИНН НЕ ЯВЛЯЕТСЯ САМОЗАНЯТЫМ В ФНС */}
      {currentStep === 7 && (
        <SEFromLKNoSz className="se-from-lk_fail" onChangeINN={() => setCurrentStep(1)} />
      )}
      {/* /ИНН НЕ ЯВЛЯЕТСЯ САМОЗАНЯТЫМ В ФНС */}

      {/* НЕ ДАНЫ ПРАВА ПРИЛОЖЕНИЮ В ФНС */}
      {currentStep === 8 && (
        <SEFromLKNoRights className="se-from-lk_fail" onChangeINN={() => setCurrentStep(1)} />
      )}
      {/* /НЕ ДАНЫ ПРАВА ПРИЛОЖЕНИЮ В ФНС */}

      {/* ФУТЕР */}
      {windowSize.innerWidth > 980 && <Footer />}
      {/* /ФУТЕР */}
    </div>,
    document.body,
  );
};

SelfEmployedRegistration.propTypes = {
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
};

SelfEmployedRegistration.defaultProps = {
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  szStatusFromDb: state.auth.szStatusFromDb,
});

export default connect(mapStateToProps)(SelfEmployedRegistration);

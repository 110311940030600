import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import { CSSTransition } from 'react-transition-group';

import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { setTaxesListToPay } from 'modules/tax/actions';
import MonthTaxCard from 'modules/tax/components/MonthTaxCard/MonthTaxCard';
import NoTaxesMessages from 'modules/tax/components/NoTaxesMessages/NoTaxesMessages';
import TaxList from 'modules/tax/components/TaxList/TaxList';
import TotalTaxToPay from 'modules/tax/components/TotalTaxToPay/TotalTaxToPay';

import { BLUE, CONSISTENT_ANIMATIONS_DELAY, GREY_BORDER, MONTHSRU } from 'constants/index';
import formatNumber from 'helpers/formatNumber';

import './TaxListContainer.scss';

const LOADING = 'LOADING';
const FAIL = 'FAIL';

export const TaxListContainer = ({
  monthList,
  taxList,
  isInnerPage,
  dispatch,
  currentMonth,
  dataLoadingStatus,
}) => {
  const windowSize = useWindowSize();
  const [filteredTaxList, setFilteredTaxList] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [checkedTaxes, setCheckedTaxes] = useState({});
  const [sumToPay, setSumToPay] = useState(0);
  const [currentActiveMonth, setCurrentActiveMonth] = useState(null);

  const filterTaxesByMonth = (month) => {
    setSumToPay(0);
    setFilteredTaxList(taxList.filter((item) => Number(item.dateCreate.slice(5, 7)) === month));
    setCheckedTaxes({});
    setIsFiltered(true);
    setCurrentActiveMonth(month);
  };

  const returnTaxesListToDefault = () => {
    setFilteredTaxList(taxList);
    setIsFiltered(false);
    setCurrentActiveMonth(null);
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const saveSum = (list) => {
    dispatch(setTaxesListToPay(list));
  };

  const addTaxToACheckedList = (documentIndex, amount) => {
    const updatedObj = {};
    updatedObj[`tax-${documentIndex}`] = { documentIndex, amount };

    setCheckedTaxes((prevState) => {
      if (prevState[`tax-${documentIndex}`]) {
        setSumToPay((sumPrevState) => sumPrevState - amount);
        return Object.keys(prevState)
          .filter((key) => key !== `tax-${documentIndex}`)
          .reduce((result, current) => {
            result[current] = prevState[current];
            return result;
          }, {});
      }
      setSumToPay((sumPrevState) => sumPrevState + amount);
      return {
        ...prevState,
        ...updatedObj,
      };
    });
  };

  useEffect(() => {
    if (taxList && taxList.length) {
      setFilteredTaxList(taxList);
    }
  }, [taxList]);

  return (
    <div className="tax-list-container">
      {dataLoadingStatus === LOADING && (
        <div className="tax-list-container__months-loader">
          <CircleLoader />
        </div>
      )}
      {!dataLoadingStatus && taxList && taxList.length >= 1 && (
        <>
          <div
            className={cx('tax-list-container__left-part', {
              'tax-list-container__left-part_notice-message':
                Object.keys(checkedTaxes) &&
                Object.keys(checkedTaxes).length >= 2 &&
                !isInnerPage &&
                windowSize.innerWidth > 980,
            })}
          >
            <ProfileErrorMessage
              animationStart={
                Object.keys(checkedTaxes) &&
                Object.keys(checkedTaxes).length >= 2 &&
                !isInnerPage &&
                windowSize.innerWidth > 980
              }
              content="Для оплаты всех или нескольких налогов одновременно возможно использовать только кошелек самозанятого"
              className="tax-list-container__warning"
            />

            {isInnerPage && filteredTaxList && filteredTaxList.length === 1 && (
              <TotalTaxToPay
                className="tax-list-container__total"
                amount={filteredTaxList[0].amount}
              />
            )}

            {!isInnerPage && (
              <TotalTaxToPay className="tax-list-container__total" amount={sumToPay} />
            )}

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered && windowSize.innerWidth > 980}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              <>
                {!dataLoadingStatus && monthList && monthList.length >= 1 && !isInnerPage && (
                  <div className="tax-list-container__months">
                    <p className="tax-list-container__months-title">По налоговым периодам:</p>

                    <Scrollbar
                      className="tax-list-container__content-scrollbar"
                      /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
                      style={{ height: 320 }}
                      wrapperProps={{
                        renderer: (props) => {
                          const { elementRef, style, ...restProps } = props;
                          return (
                            <span
                              {...restProps}
                              ref={elementRef}
                              className="tax-list-container__months-inner"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                overflow: 'hidden',
                              }}
                            />
                          );
                        },
                      }}
                      trackYProps={{
                        renderer: ({ elementRef, style, ...restProps }) => {
                          return (
                            <span
                              {...restProps}
                              ref={elementRef}
                              className="tax-list-container__track"
                            />
                          );
                        },
                      }}
                      thumbYProps={{
                        renderer: ({ elementRef, style, ...restProps }) => {
                          return (
                            <span
                              {...restProps}
                              ref={elementRef}
                              className="tax-list-container__thumb"
                            />
                          );
                        },
                        /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
                      }}
                    >
                      <div className="tax-list-container__months-list">
                        {isFiltered && (
                          <MonthTaxCard
                            month="Все месяцы"
                            className="tax-list-container__months-item"
                            onClick={returnTaxesListToDefault}
                            isLink={windowSize.innerWidth <= 980}
                          />
                        )}

                        {monthList.map((item) => (
                          <MonthTaxCard
                            month={item.month}
                            sum={item.sum}
                            key={item.id}
                            className="tax-list-container__months-item"
                            onClick={() => filterTaxesByMonth(item.monthNumber)}
                            isLink={windowSize.innerWidth <= 980}
                            isActive={currentActiveMonth === item.monthNumber}
                          />
                        ))}
                      </div>
                    </Scrollbar>
                  </div>
                )}

                {dataLoadingStatus === FAIL && (
                  <div className="tax-list-container__months-error">
                    К сожалению, не удалось загрузить данные
                  </div>
                )}
              </>
            </CSSTransition>

            {windowSize.innerWidth <= 980 && (
              <>
                {monthList && monthList.length >= 1 && !isInnerPage && (
                  <div className="tax-list-container__months">
                    <p className="tax-list-container__months-title">По налоговым периодам:</p>

                    <div className="tax-list-container__months-list">
                      {monthList.map((item) => (
                        <MonthTaxCard
                          month={item.month}
                          sum={item.sum}
                          key={item.id}
                          className="tax-list-container__months-item"
                          onClick={() => filterTaxesByMonth(item.monthNumber)}
                          isLink={windowSize.innerWidth <= 980}
                          monthNumber={item.monthNumber}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {isInnerPage && currentMonth && filteredTaxList && filteredTaxList.length > 1 && (
                  <MonthTaxCard
                    month={currentMonth.month}
                    sum={currentMonth.sum}
                    className="tax-list-container__months-item tax-list-container__months-item_inner-page"
                    isActive
                    monthNumber={currentMonth.monthNumber}
                  />
                )}
              </>
            )}
          </div>

          <div className="tax-list-container__table-wrap">
            {((!isInnerPage && windowSize.innerWidth > 980) ||
              (isInnerPage && windowSize.innerWidth <= 980)) && (
              <TaxList
                className="tax-list-container__table"
                taxList={filteredTaxList}
                addTaxToACheckedList={addTaxToACheckedList}
                checkedTaxes={checkedTaxes}
                saveSumByClickOnOnePayButton={saveSum}
              />
            )}

            {!isInnerPage ? (
              <div className="tax-list-container__table-bottom">
                <div className="tax-list-container__table-bottom-notification">
                  Оплата всех или нескольких налогов возможна только с кошелька самозанятого, а
                  оплата одного налога как картой так и кошельком.
                </div>

                {!currentActiveMonth ? (
                  <StandardButton
                    title={
                      !Object.keys(checkedTaxes).length
                        ? `Оплатить все`
                        : `Оплатить ${formatNumber(
                            Math.floor(sumToPay / 100),
                          )}, ${sumToPay.toString().slice(-2)} ₽`
                    }
                    isLink
                    href="/lk/tax-payment"
                    onClick={() =>
                      saveSum(
                        !Object.keys(checkedTaxes).length
                          ? taxList.map((item) => {
                              return { documentIndex: item.documentIndex, amount: item.amount };
                            })
                          : Object.values(checkedTaxes),
                      )
                    }
                    bgType={BLUE}
                    className="tax-list-container__table-bottom-button"
                  />
                ) : (
                  <StandardButton
                    title={
                      !Object.keys(checkedTaxes).length
                        ? `Оплатить все за ${MONTHSRU[currentActiveMonth - 1].toLowerCase()}`
                        : `Оплатить ${formatNumber(
                            Math.floor(sumToPay / 100),
                          )}, ${sumToPay.toString().slice(-2)} ₽`
                    }
                    isLink
                    href="/lk/tax-payment"
                    onClick={() =>
                      saveSum(
                        !Object.keys(checkedTaxes).length
                          ? taxList
                              .filter(
                                (item) =>
                                  Number(item.dateCreate.slice(5, 7)) === currentActiveMonth,
                              )
                              .map((item) => {
                                return { documentIndex: item.documentIndex, amount: item.amount };
                              })
                          : Object.values(checkedTaxes),
                      )
                    }
                    bgType={BLUE}
                    className="tax-list-container__table-bottom-button"
                  />
                )}
              </div>
            ) : (
              <div className="tax-list-container__button-list">
                {filteredTaxList.length === 1 && (
                  <StandardButton
                    title="Оплатить"
                    isLink
                    href="/lk/tax-payment"
                    onClick={() =>
                      saveSum([{ sum: filteredTaxList[0].sum, id: filteredTaxList[0].id }])
                    }
                    bgType={BLUE}
                    className="tax-list-container__button"
                  />
                )}

                {Object.keys(checkedTaxes).length >= 1 && (
                  <div className="tax-list-container__table-bottom">
                    {Object.keys(checkedTaxes).length > 1 && (
                      <div className="tax-list-container__table-bottom-notification">
                        Оплата всех или нескольких налогов возможна только с кошелька самозанятого,
                        а оплата одного налога как картой так и кошельком.
                      </div>
                    )}

                    <StandardButton
                      title={`Оплатить ${formatNumber(
                        Math.floor(sumToPay / 100),
                      )}, ${sumToPay.toString().slice(-2)} ₽`}
                      isLink
                      href="/lk/tax-payment"
                      onClick={() => saveSum(Object.values(checkedTaxes))}
                      bgType={BLUE}
                      className="tax-list-container__table-bottom-button"
                    />
                  </div>
                )}

                <StandardButton
                  title="Вернуться"
                  isLink
                  href="/lk/tax-list"
                  bgType={GREY_BORDER}
                  className="tax-list-container__button"
                />
              </div>
            )}
          </div>
        </>
      )}

      {!dataLoadingStatus && !taxList.length && (
        <div className="tax-list-container__no-taxes">
          <NoTaxesMessages />

          {windowSize.innerWidth <= 980 && (
            <StandardButton
              title="Вернуться"
              isLink
              href="/lk/accounting"
              bgType={GREY_BORDER}
              className="tax-list-container__no-taxes-return-button"
            />
          )}
        </div>
      )}
    </div>
  );
};

TaxListContainer.propTypes = {
  monthList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      month: PropTypes.string,
      sum: PropTypes.number,
    }),
  ),
  taxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
  isInnerPage: PropTypes.bool,
  currentMonth: PropTypes.shape({
    sum: PropTypes.number,
    month: PropTypes.string,
    monthNumber: PropTypes.number,
  }),
  dataLoadingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

TaxListContainer.defaultProps = {
  monthList: [],
  taxList: [],
  isInnerPage: false,
  currentMonth: null,
  dataLoadingStatus: null,
};

export default connect()(TaxListContainer);

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

import './CompanyNameInputWithDropdown.scss';

const CompanyNameInputWithDropdown = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    dropdownOptions,
    className,
    dropdownItemClick,
    disabled,
    isNoAnimationStyles,
    placeholder,
    label,
    isDropOpen,
  } = props;

  return (
    <div
      className={cx('company-name-input-with-dropdown', {
        [className]: className,
        'company-name-input-with-dropdown_std-styles': isNoAnimationStyles,
        'company-name-input-with-dropdown_focused': value !== '' || isNoAnimationStyles,
        'company-name-input-with-dropdown_dropdown-open': isDropOpen,
      })}
    >
      <div className="company-name-input-with-dropdown__inner">
        <input
          type="text"
          className="company-name-input-with-dropdown__input"
          onChange={({ target }) => onChange(target.value)}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        />

        {isDropOpen && (
          <Scrollbar
            className="company-name-input-with-dropdown__content-scrollbar"
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            style={{ height: 250 }}
            wrapperProps={{
              renderer: (scrollBarProps) => {
                const { elementRef, style, ...restProps } = scrollBarProps;
                return (
                  <span
                    {...restProps}
                    ref={elementRef}
                    className="company-name-input-with-dropdown__content-scrollbar-inner"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      overflow: 'hidden',
                    }}
                  />
                );
              },
            }}
            trackYProps={{
              renderer: ({ elementRef, style, ...restProps }) => {
                return (
                  <span
                    {...restProps}
                    ref={elementRef}
                    className="company-name-input-with-dropdown__track"
                  />
                );
              },
            }}
            thumbYProps={{
              renderer: ({ elementRef, style, ...restProps }) => {
                return (
                  <span
                    {...restProps}
                    ref={elementRef}
                    className="company-name-input-with-dropdown__thumb"
                  />
                );
              },
              /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
            }}
          >
            <div className="company-name-input-with-dropdown__dropdown" ref={ref}>
              {dropdownOptions &&
                dropdownOptions.length >= 1 &&
                dropdownOptions.map((item) => (
                  <button
                    className="company-name-input-with-dropdown__dropdown-item"
                    onClick={() => dropdownItemClick(item.data.inn, item.value)}
                    type="button"
                    key={item.data.hid}
                  >
                    <span className="company-name-input-with-dropdown__dropdown-item-inn">
                      {item.data.inn}
                    </span>
                    <span className="company-name-input-with-dropdown__dropdown-item-company-name">
                      {item.value}
                    </span>
                  </button>
                ))}
            </div>
          </Scrollbar>
        )}
      </div>

      {value && <IconCheck className="inn-input-with-dropdown__check" />}

      <p className="company-name-input-with-dropdown__label">{label}</p>
    </div>
  );
});

CompanyNameInputWithDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dropdownItemClick: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        inn: PropTypes.string,
        hid: PropTypes.string,
      }),
      value: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  isNoAnimationStyles: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  isDropOpen: PropTypes.bool.isRequired,
};

CompanyNameInputWithDropdown.defaultProps = {
  className: null,
  dropdownOptions: [],
  disabled: false,
  isNoAnimationStyles: false,
  placeholder: null,
};

export default CompanyNameInputWithDropdown;

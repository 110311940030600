import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { CONSISTENT_ANIMATIONS_DELAY, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import iconSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

export const SelfEmployedAlreadyRegistered = ({ className, userINN, onChangeINN, isMenuOpen }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  return (
    <>
      <div
        className={cx('se-register', {
          [className]: className,
        })}
        data-testid="se-register"
        data-cy="se-register-alien"
      >
        <div className="se-register__container container">
          <div className="se-register__content">
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              <h1 className="se-register__title" data-cy="se-register-alien-title">
                Такой пользователь уже зарегистрирован
              </h1>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="se-register__desc-wrap animation-from-bottom-to-top">
                <div className="se-register__desc">
                  Войдите под своей учетной записью или напишите письмо в поддержку клиентов
                </div>
              </div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <div className="se-register__form animation-from-bottom-to-top">
                <form className="form form_register">
                  <div className="form__block">
                    <div className="form__input-holder">
                      <InputMask
                        type="text"
                        mask="9999-9999-9999"
                        maskChar={null}
                        required
                        className="form__input form__input_fail"
                        defaultValue={userINN}
                        disabled
                        readOnly
                        data-testid="se-inn-input"
                      />

                      <button
                        className="form__input-edit"
                        type="button"
                        onClick={onChangeINN}
                        data-testid="change-inn-button"
                      >
                        <IconEdit />
                      </button>
                    </div>

                    {windowSize.innerWidth > 700 && (
                      <div className="form__loader-wrap">
                        <CircleProgress
                          value={0}
                          color="var(--reddish-pink)"
                          className="form__loader"
                          radius={28}
                        />
                        <IconLockClosed className="form__password-secure-lock form__password-secure-lock_red" />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered && windowSize.innerWidth > 700}
              timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
              unmountOnExit
            >
              <div className="se-register__btn">
                <StandardButton
                  isLink
                  title="Продолжить как физ.лицо"
                  className="js-have-to-handle"
                  href="/lk"
                  bgType={GREY_BORDER}
                />
              </div>
            </CSSTransition>
          </div>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
            unmountOnExit
          >
            <div className="se-register__hint-block">
              <div className="se-register__hint">
                <p className="se-register__hint-text">
                  Нельзя создавать несколько учетных записей для одного пользователя
                  <img
                    src={iconSad}
                    alt=""
                    className="se-register__hint-icon se-register__hint-icon_sad"
                  />
                </p>

                <IconTriangle className="se-register__hint-triangle" />
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered && windowSize.innerWidth <= 700}
            timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
            unmountOnExit
          >
            <div className="se-register__btn">
              <StandardButton
                isLink
                title="Продолжить как физ.лицо"
                className="js-have-to-handle"
                href="/lk"
                bgType={GREY_BORDER}
              />
            </div>
          </CSSTransition>
        </div>
      </div>
    </>
  );
};

SelfEmployedAlreadyRegistered.propTypes = {
  className: PropTypes.string.isRequired,
  userINN: PropTypes.string.isRequired,
  onChangeINN: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  userINN: state.auth.INN,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedAlreadyRegistered);

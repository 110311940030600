import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { INVOICE, MONEY_REQUEST } from 'constants/index';
import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconBill } from 'static/assets/icon-bill.svg';
import { ReactComponent as IconCoins } from 'static/assets/icon-coins.svg';

import './HistoryRequestsItem.scss';

const HistoryRequestsItem = ({ historyRequestsItemData, currentOpenOperationId, itemClick }) => {
  const windowSize = useWindowSize();

  /** Храним скопированный url */
  const [copiedUrl, setCopiedUrl] = useState('');

  /** Флаг, переключаем если кликнули на url */
  const [isCopied, setIsCopied] = useState(false);

  /** Клик по элементу списка запросов */
  const setDataForCopy = (uid, moneyRequest) => {
    /** Сбрасываем флаг */
    setIsCopied(false);

    /** Записываем в стейт url */
    if (moneyRequest === MONEY_REQUEST) {
      setCopiedUrl(`${window.location.origin}/pay-it/${uid}`);
    } else {
      setCopiedUrl(`${window.location.origin}/invoice/${uid}`);
    }
  };

  return (
    <button
      type="button"
      className={cx('history-requests-item ', {
        'history-requests-item_open': currentOpenOperationId === historyRequestsItemData.uid,
        'history-requests-item_payed': historyRequestsItemData.paid === true,
      })}
      onClick={() => {
        setDataForCopy(historyRequestsItemData.uid, historyRequestsItemData.type);
        itemClick(historyRequestsItemData.uid);
      }}
      data-testid="history-requests-item"
    >
      {windowSize.innerWidth > 980 ? (
        <div className="history-requests-item__top">
          <div className="history-requests-item__title-wrap">
            {historyRequestsItemData.type === MONEY_REQUEST && (
              <IconCoins className="history-requests-item__icon history-requests-item__icon_coins" />
            )}

            {historyRequestsItemData.type === INVOICE && (
              <IconBill className="history-requests-item__icon history-requests-item__icon_bill" />
            )}

            <p className="history-requests-item__title">{historyRequestsItemData.commentary}</p>
          </div>

          <div className="history-requests-item__payment-status">
            {historyRequestsItemData.paid ? 'оплачен' : 'не оплачен'}
          </div>

          <p className="history-requests-item__date">{historyRequestsItemData.dateTimeAdd}</p>

          <p className="history-requests-item__sum">
            {historyRequestsItemData.paid && '+ '}
            {formatNumber(historyRequestsItemData.sum / 100)} ₽
          </p>
        </div>
      ) : (
        <div className="history-requests-item__top">
          <div className="history-requests-item__title-wrap">
            {historyRequestsItemData.type === MONEY_REQUEST && (
              <IconCoins className="history-requests-item__icon history-requests-item__icon_coins" />
            )}

            {historyRequestsItemData.type === INVOICE && (
              <IconBill className="history-requests-item__icon history-requests-item__icon_bill" />
            )}

            <p className="history-requests-item__title">{historyRequestsItemData.commentary}</p>

            <p className="history-requests-item__date">{historyRequestsItemData.dateTimeAdd}</p>
          </div>

          <div className="history-requests-item__right-part">
            <p className="history-requests-item__sum">
              {historyRequestsItemData.paid && '+ '}
              {formatNumber(historyRequestsItemData.sum / 100)} ₽
            </p>

            <div className="history-requests-item__payment-status">
              {historyRequestsItemData.paid ? 'оплачен' : 'не оплачен'}
            </div>
          </div>
        </div>
      )}

      <div className="history-requests-item__bottom">
        <div className="history-requests-item__additional-info">
          <div className="history-requests-item__message-wrap">
            <p className="history-requests-item__message-title">Способ отправки:</p>
            <p className="history-requests-item__message-text">
              {historyRequestsItemData.email && historyRequestsItemData.email !== ''
                ? 'По почте'
                : 'Ссылкой'}
            </p>
          </div>

          {historyRequestsItemData.uid &&
            (!historyRequestsItemData.email || historyRequestsItemData.email === '') &&
            !historyRequestsItemData.paid && (
              <div className="history-requests-item__message-wrap">
                <p className="history-requests-item__message-title">Ссылка:</p>

                <CopyToClipboard
                  text={copiedUrl}
                  onCopy={() => setIsCopied(true)}
                  className="history-requests-item__message-text history-requests-item__message-text_url"
                  data-testid="history-requests-item-link-to-copy"
                >
                  <a
                    href={
                      historyRequestsItemData.type === MONEY_REQUEST
                        ? `${window.location.origin}/pay-it/${historyRequestsItemData.uid}`
                        : historyRequestsItemData.type === INVOICE
                        ? `${window.location.origin}/invoice/${historyRequestsItemData.uid}`
                        : '/'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {historyRequestsItemData.type === MONEY_REQUEST &&
                      `${window.location.origin}/pay-it/${historyRequestsItemData.uid}`}

                    {historyRequestsItemData.type === INVOICE &&
                      `${window.location.origin}/invoice/${historyRequestsItemData.uid}`}
                  </a>
                </CopyToClipboard>

                {isCopied && copiedUrl && copiedUrl.indexOf(historyRequestsItemData.uid) > -1 && (
                  <span className="history-requests-item__copy-success">Скопировано</span>
                )}
              </div>
            )}

          {/* ДАТА И ВРЕМЯ */}
          <div className="history-requests-item__message-wrap">
            <p className="history-requests-item__message-title">Дата и время оплаты:</p>

            {/* ЕСЛИ НА СЕРВЕРЕ ЕСТЬ НУЖНЫЙ ФОРМАТ ДАТЫ, БЕРЕМ ЕГО */}
            {historyRequestsItemData.dateTimePayment && (
              <p className="history-requests-item__message-text">
                {historyRequestsItemData.dateTimePayment}
              </p>
            )}
            {/* /ЕСЛИ НА СЕРВЕРЕ ЕСТЬ НУЖНЫЙ ФОРМАТ ДАТЫ, БЕРЕМ ЕГО */}

            {/* ЕСЛИ НА СЕРВЕРЕ НЕТ НУЖНОГО ФОРМАТА ДАТЫ, БЕРЕМ ДРУГОЙ ФОРМАТ */}
            {!historyRequestsItemData.dateTimePayment && historyRequestsItemData.dateTimeAdd && (
              <p className="history-requests-item__message-text">Пока не оплачен</p>
            )}
            {/* /ЕСЛИ НА СЕРВЕРЕ НЕТ НУЖНОГО ФОРМАТА ДАТЫ, БЕРЕМ ДРУГОЙ ФОРМАТ */}
          </div>
          {/* ЕСЛИ НА СЕРВЕРЕ ЕСТЬ НУЖНЫЙ ФОРМАТ ДАТЫ, БЕРЕМ ЕГО */}
        </div>
        {/* /ДАТА И ВРЕМЯ */}
      </div>
    </button>
  );
};

HistoryRequestsItem.propTypes = {
  historyRequestsItemData: PropTypes.shape({
    type: PropTypes.string,
    commentary: PropTypes.string,
    paid: PropTypes.bool,
    dateTimeAdd: PropTypes.string,
    dateTimePayment: PropTypes.string,
    sum: PropTypes.number,
    writeOff: PropTypes.bool,
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
  currentOpenOperationId: PropTypes.string,
  itemClick: PropTypes.func.isRequired,
};

HistoryRequestsItem.defaultProps = {
  currentOpenOperationId: null,
};

export default HistoryRequestsItem;

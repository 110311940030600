import React, { useState } from 'react';

import CopyItemButton from 'modules/shared/components/CopyItemButton/CopyItemButton';

import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';

const HintBankCardDetails = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(null);

  /** Клик на копирование ссылки */
  const copyClick = (urlText) => {
    setCopiedUrl(urlText);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="hint__text-wrap" data-testid="bank-card-details-popup">
      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">Индекс документа</p>
        <div className="hint__bank-card-details-info-wrap">
          <p className="hint__bank-card-details-info">53857204892938485927495204385</p>

          {isCopied && copiedUrl === '53857204892938485927495204385' ? (
            <div className="hint__copied">
              <div className="hint__copied-icon-wrap">
                <IconCopy className="hint__copied-icon" />
              </div>
              <p className="hint__copied-text">Cкопировано</p>
            </div>
          ) : (
            <CopyItemButton
              text="53857204892938485927495204385"
              copyClick={() => copyClick('53857204892938485927495204385')}
              textWithoutMainRoute
            />
          )}
        </div>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">Информация о сроке зачисления платежа</p>
        <p className="hint__bank-card-details-info">
          Платеж учитывается ФНС в течении 10 рабочих дней (пени за данный период не начисляются)
        </p>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">ИНН получателя</p>
        <div className="hint__bank-card-details-info-wrap">
          <p className="hint__bank-card-details-info">5402195246</p>

          {isCopied && copiedUrl === '5402195246' ? (
            <div className="hint__copied">
              <div className="hint__copied-icon-wrap">
                <IconCopy className="hint__copied-icon" />
              </div>
              <p className="hint__copied-text">Cкопировано</p>
            </div>
          ) : (
            <CopyItemButton
              text="5402195246"
              copyClick={() => copyClick('5402195246')}
              textWithoutMainRoute
            />
          )}
        </div>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">Полное название организации</p>
        <p className="hint__bank-card-details-info">
          Управление федерального казначейства по г.Москве (инспекция федеральной налоговой службы
          по заельцовскому району г. Новосибирска)
        </p>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">КПП</p>
        <div className="hint__bank-card-details-info-wrap">
          <p className="hint__bank-card-details-info">540201001</p>

          {isCopied && copiedUrl === '540201001' ? (
            <div className="hint__copied">
              <div className="hint__copied-icon-wrap">
                <IconCopy className="hint__copied-icon" />
              </div>
              <p className="hint__copied-text">Cкопировано</p>
            </div>
          ) : (
            <CopyItemButton
              text="540201001"
              copyClick={() => copyClick('540201001')}
              textWithoutMainRoute
            />
          )}
        </div>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">БИК</p>

        <div className="hint__bank-card-details-info-wrap">
          <p className="hint__bank-card-details-info">044525000</p>

          {isCopied && copiedUrl === '044525000' ? (
            <div className="hint__copied">
              <div className="hint__copied-icon-wrap">
                <IconCopy className="hint__copied-icon" />
              </div>
              <p className="hint__copied-text">Cкопировано</p>
            </div>
          ) : (
            <CopyItemButton
              text="044525000"
              copyClick={() => copyClick('044525000')}
              textWithoutMainRoute
            />
          )}
        </div>
      </div>

      <div className="hint__bank-card-details">
        <p className="hint__bank-card-details-title">Номер счета</p>

        <div className="hint__bank-card-details-info-wrap">
          <p className="hint__bank-card-details-info">40101810045250010041</p>

          {isCopied && copiedUrl === '40101810045250010041' ? (
            <div className="hint__copied">
              <div className="hint__copied-icon-wrap">
                <IconCopy className="hint__copied-icon" />
              </div>
              <p className="hint__copied-text">Cкопировано</p>
            </div>
          ) : (
            <CopyItemButton
              text="40101810045250010041"
              copyClick={() => copyClick('40101810045250010041')}
              textWithoutMainRoute
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HintBankCardDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Card from '../Card/Card';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';

import formatNumber from 'helpers/formatNumber';
import pennyToRubles from '../../helpers/pennyToRubles';

import CardHeaderSz from '../CardHeaderSz/CardHeaderSz';
import CardHeaderQiwi from '../CardHeaderQiwi/CardHeaderQiwi';
import CardHeaderUMoney from '../CardHeaderUMoney/CardHeaderUMoney';
import CardHeaderWebMoney from '../CardHeaderWebMoney/CardHeaderWebMoney';

import { requestMoneyTransfer, setMoneyTransferPreviousStep } from 'modules/money-transfer/actions';

import {
  getCommissionByMoneyTransferMethod,
  getTotalSumByMoneyTransferMethod,
} from 'modules/money-transfer/selectors';

import './ReceiptCard.scss';
import CardHeaderSBP from '../CardHeaderSBP/CardHeaderSBP';

const ReceiptCard = ({ dispatch, message, cardOrWalletNumber, sbpBank, sum }) => {
  const { paymentMethod } = useParams();
  // используются хуки зместо connect, т.к. с помощью connect нет доступа к параметру paymentMethod
  const totalSum = pennyToRubles(useSelector(getTotalSumByMoneyTransferMethod(paymentMethod)));
  const commissionFee = pennyToRubles(
    useSelector(getCommissionByMoneyTransferMethod(paymentMethod)),
  );

  const summaryFields = [
    {
      source: sum,
      title: 'Сумма',
      value: `${formatNumber(Number(sum).toFixed(2))} ₽`,
    },
  ];

  if (paymentMethod !== 'sz') {
    summaryFields.push({
      source: commissionFee,
      title: 'Комиссия',
      value: `${formatNumber(Number(commissionFee).toFixed(2))} ₽`,
    });

    summaryFields.push({
      source: totalSum,
      title: 'Всего к оплате',
      value: `${formatNumber(Number(totalSum).toFixed(2))} ₽`,
    });
  }

  if (paymentMethod === 'sbp') {
    summaryFields.push({
      source: sbpBank,
      title: 'Выбранный банк',
      value: sbpBank.name,
    });
  }

  summaryFields.push({
    source: cardOrWalletNumber,
    title:
      paymentMethod === 'bankcard'
        ? 'Номер карты получателя'
        : paymentMethod === 'sbp'
        ? 'Номер телефона'
        : paymentMethod === 'umoney'
        ? 'Номер кошелька ЮMoney'
        : paymentMethod === 'webmoney'
        ? 'Номер кошелька Webmoney'
        : paymentMethod === 'qiwi'
        ? 'Номер кошелька QIWI'
        : 'Номер кошелька',
    value:
      paymentMethod === 'bankcard'
        ? `${cardOrWalletNumber.slice(0, 4)} ${cardOrWalletNumber.slice(
            4,
            6,
          )}** **** ${cardOrWalletNumber.slice(12, 16)}`
        : cardOrWalletNumber,
  });

  summaryFields.push({
    source: true,
    title: 'Способ оплаты',
    value: 'Кошелек',
  });

  if (paymentMethod === 'sz') {
    summaryFields.push({
      source: message,
      title: 'Сообщение',
      value: message,
    });
  }

  function goBack() {
    dispatch(setMoneyTransferPreviousStep());
  }

  function pay() {
    dispatch(requestMoneyTransfer(paymentMethod));
  }

  return (
    <div className="money-transfer-receipt-card">
      <Card className="money-transfer-receipt-card__card">
        <header className="money-transfer-receipt-card__header">
          {paymentMethod === 'bankcard' && <CardHeaderSz />}
          {paymentMethod === 'sbp' && <CardHeaderSBP />}
          {paymentMethod === 'sz' && <CardHeaderSz />}
          {paymentMethod === 'webmoney' && <CardHeaderWebMoney />}
          {paymentMethod === 'qiwi' && <CardHeaderQiwi />}
          {paymentMethod === 'umoney' && <CardHeaderUMoney />}
        </header>
        <div className="money-transfer-receipt-card__content">
          <PaymentSummary summaryFields={summaryFields} />
        </div>
      </Card>
      <nav className="money-transfer-receipt-card__nav">
        <button className="money-transfer-receipt-card__nav_back-button" onClick={goBack}>
          Вернуться назад
        </button>
        <button className="money-transfer-receipt-card__nav_pay-button" onClick={pay}>
          Оплатить {formatNumber(totalSum.toFixed(2))} ₽
        </button>
      </nav>
    </div>
  );
};

ReceiptCard.propTypess = {
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  sbpBank: PropTypes.object.isRequired,
  sum: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.moneyTransfer.payment.message,
  cardOrWalletNumber: state.moneyTransfer.payment.cardOrWalletNumber,
  sbpBank: state.moneyTransfer.payment.sbpBank,
  sum: pennyToRubles(state.moneyTransfer.payment.sum),
});

export default connect(mapStateToProps)(ReceiptCard);

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

import './ProfileHint.scss';

const Hint = React.forwardRef((props, ref) => {
  const { className, classNameInner, title, children, onCloseBtnClick, isMenuOpen } = props;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  return createPortal(
    <div
      className={cx('hint', {
        [className]: className,
      })}
      ref={ref}
    >
      <div
        className={cx('hint__inner', {
          [classNameInner]: classNameInner,
        })}
      >
        {title && (
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={{ enter: 100, exit: 250 }}
            unmountOnExit
          >
            <h2 className="hint__title">{title}</h2>
          </CSSTransition>
        )}

        {children}
      </div>

      <button className="hint__close" type="button" onClick={onCloseBtnClick}>
        <IconClose className="hint__close-icon" />
      </button>
    </div>,
    document.body,
  );
});

Hint.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  title: PropTypes.string,
  onCloseBtnClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isMenuOpen: PropTypes.bool,
};

Hint.defaultProps = {
  className: null,
  classNameInner: null,
  title: null,
  onCloseBtnClick: () => {},
  isMenuOpen: false,
};

export default Hint;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';
import TaxInfoCard from 'modules/tax/components/TaxInfoCard/TaxInfoCard';
import TaxPaymentCard from 'modules/tax/components/TaxPaymentCard/TaxPaymentCard';
import TaxPaymentSz from 'modules/tax/components/TaxPaymentSz/TaxPaymentSz';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import { ReactComponent as IconPayCard } from 'static/assets/icon-pay-card.svg';
import { ReactComponent as IconWallet } from 'static/assets/icon-payment-wallet.svg';

// Styles
import './TaxPayment.scss';

const TaxPayment = ({
  taxPayingProgress,
  isTaxInfoLoading,
  sumToPay,
  isMultipleTaxes,
  taxesToPay,
}) => {
  const [currentActiveTabId, setCurrentActiveTabId] = useState(1);
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();
  const [oneTaxChangedSum, setOneTaxChangedSum] = useState(0);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    setOneTaxChangedSum(sumToPay / 100);
  }, [sumToPay]);

  useEffect(() => {
    if (isMultipleTaxes) {
      setCurrentActiveTabId(2);
    } else {
      setCurrentActiveTabId(1);
    }
  }, [isMultipleTaxes]);

  const clickOnBankCardTransfer = () => {
    setCurrentActiveTabId(1);
  };

  const clickOnSzTransfer = () => {
    setCurrentActiveTabId(2);
  };

  return (
    <div className="tax-payment" data-testid="tax-payment">
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <ResponsiveHeader isCloseButtonLink href="/lk/tax-list" className="tax-payment__header" />
      </CSSTransition>
      {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
        unmountOnExit
      >
        <div className="tax-payment__top">
          <h1 className="tax-payment__title">Оплата налога</h1>

          {windowSize.innerWidth <= 980 && (
            <p className="tax-payment__sub-title">Выберите способ</p>
          )}

          <div className="tax-payment__top-progress">
            <div
              className="tax-payment__top-progress-bar"
              style={{ width: `${taxPayingProgress}%` }}
            />
          </div>
        </div>
      </CSSTransition>

      <div className="tax-payment__content">
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <div className="tax-payment__tabs-wrap">
            <ProfileErrorMessage
              animationStart={isMultipleTaxes}
              content="Для оплаты всех или нескольких налогов одновременно возможно использовать только кошелек самозанятого"
              className="tax-payment__warning"
            />

            <TaxInfoCard
              isTaxInfoLoading={isTaxInfoLoading}
              sumToPay={sumToPay}
              isMultipleTaxes={isMultipleTaxes}
              setOneTaxChangedSum={setOneTaxChangedSum}
              oneTaxChangedSum={Number(oneTaxChangedSum)}
            />

            <div className="tax-payment__tabs">
              <button
                className={cx('tax-payment__tabs-item', {
                  'tax-payment__tabs-item_active': currentActiveTabId === 1,
                })}
                type="button"
                data-testid="tax-payment-tab-button"
                onClick={clickOnBankCardTransfer}
                disabled={isMultipleTaxes}
              >
                <span className="tax-payment__tabs-item-title">По номеру карты</span>

                <IconPayCard className="tax-payment__tabs-item-icon tax-payment__tabs-item-icon_card" />
              </button>

              <button
                className={cx('tax-payment__tabs-item', {
                  'tax-payment__tabs-item_active': currentActiveTabId === 2,
                })}
                type="button"
                data-testid="tax-payment-tab-button"
                onClick={clickOnSzTransfer}
              >
                <span className="tax-payment__tabs-item-title">Кошелек самозанятых</span>

                <IconWallet className="tax-payment__tabs-item-icon tax-payment__tabs-item-icon_wallet" />
              </button>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <div className="tax-payment__tabs-wrap">
            <TaxInfoCard
              isTaxInfoLoading={isTaxInfoLoading}
              sumToPay={sumToPay}
              isMultipleTaxes={isMultipleTaxes}
              setOneTaxChangedSum={setOneTaxChangedSum}
              oneTaxChangedSum={Number(oneTaxChangedSum)}
            />

            <div className="tax-payment__tabs">
              {isMultipleTaxes ? (
                <button
                  className="tax-payment__tabs-item"
                  data-testid="tax-payment-tabs-item-link"
                  type="button"
                  disabled={isMultipleTaxes}
                >
                  <span className="tax-payment__tabs-item-title">По номеру карты</span>

                  <IconPayCard className="tax-payment__tabs-item-icon tax-payment__tabs-item-icon_card" />
                </button>
              ) : (
                <Link
                  to="/lk/tax-payment/bank-card"
                  className="tax-payment__tabs-item"
                  data-testid="tax-payment-tabs-item-link"
                >
                  <span className="tax-payment__tabs-item-title">По номеру карты</span>

                  <IconPayCard className="tax-payment__tabs-item-icon tax-payment__tabs-item-icon_card" />
                </Link>
              )}

              <Link
                to="/lk/tax-payment/sz"
                className="tax-payment__tabs-item"
                data-testid="tax-payment-tabs-item-link"
              >
                <span className="tax-payment__tabs-item-title">Кошелек самозанятых</span>

                <IconWallet className="tax-payment__tabs-item-icon tax-payment__tabs-item-icon_wallet" />
              </Link>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={Boolean(isRendered && windowSize.innerWidth > 980)}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <div className="tax-payment__card-wrap">
            {currentActiveTabId === 1 && (
              <TaxPaymentCard
                sumToPay={sumToPay}
                taxesToPay={taxesToPay}
                oneTaxChangedSum={Number(oneTaxChangedSum)}
              />
            )}

            {currentActiveTabId === 2 && (
              <TaxPaymentSz
                oneTaxChangedSum={Number(oneTaxChangedSum)}
                isMultipleTaxes={isMultipleTaxes}
              />
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

TaxPayment.propTypes = {
  taxPayingProgress: PropTypes.number,
  isTaxInfoLoading: PropTypes.bool,
  sumToPay: PropTypes.number.isRequired,
  isMultipleTaxes: PropTypes.bool,
  taxesToPay: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      documentIndex: PropTypes.string,
    }),
  ).isRequired,
};

TaxPayment.defaultProps = {
  taxPayingProgress: 0,
  isTaxInfoLoading: false,
  isMultipleTaxes: false,
};

const mapStateToProps = (state) => ({
  taxPayingProgress: state.profile.taxPayingProgress,
  taxesToPay: state.tax.taxesToPay,
});

export default connect(mapStateToProps)(TaxPayment);

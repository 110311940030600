import useWindowSize from '@rehooks/window-size';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';
import { AnimatedUI, ButtonUI, InputUI, LineText, TitleText } from 'ui';
import { logOut } from '../../../../authLegasy/actions';

import {
  loginSmsConfirmAPIThunk,
  useLoginFailSelector,
  useLoginIdSelector,
  useLoginSmsConfirmFetchingSelector,
  useResendTimeSelector,
} from '../../../models/login';
import {
  reSendSmsAPIThunk,
  useRequestErrorSelector,
  useRequestFulfilledSelector,
} from '../../../models/reSendSms';
import { ReSendInfo } from '../../ui';

export const AuthConfirmContainer = ({ onComeBack }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const resendTime = useResendTimeSelector();
  const isMenuOpen = useSelector((store) => store.auth.isMenuOpen);
  const phone = useSelector((store) => store.auth.phone);
  const id = useLoginIdSelector();
  const { error: loadingLoadError } = useLoginFailSelector();
  const reSendLoginFetching = useRequestFulfilledSelector();
  const reSendLoginError = useRequestErrorSelector();
  const loginSmsConfirmFetching = useLoginSmsConfirmFetchingSelector();

  const [isInTimer, setIsInTimer] = useState(true);

  const errorMessage = (loadingLoadError ?? '') + (reSendLoginError ? ' ' + reSendLoginError : '');

  const handleSubmit = (pin) => {
    dispatch(loginSmsConfirmAPIThunk({ id, pin }));
  };

  const handleReSendSms = () => {
    dispatch(reSendSmsAPIThunk(id));
    setIsInTimer(false);
  };

  useEffect(() => {
    if (reSendLoginFetching) setIsInTimer(true);
  }, [reSendLoginFetching]);

  return (
    <WrapperStyled>
      <FormStyled>
        <AnimatedTitleStyled id={0} finishId={5} isVisible={!isMenuOpen}>
          <TitleTextStyled weight={WEIGHT['circe-extra-bold']}>Код авторизации</TitleTextStyled>
        </AnimatedTitleStyled>
        <AnimatedInfoStyled id={1} finishId={5} isVisible={!isMenuOpen}>
          <LineTextStyled>
            {'Код отправлен на номер '}
            {windowSize.innerWidth <= 435 && <br />}
            +7 ({phone.slice(1, 4)}) {phone.slice(4, 7)}-{phone.slice(7, 9)}-{phone.slice(9, 11)}
          </LineTextStyled>
        </AnimatedInfoStyled>
        <AnimatedPasswordStyled id={2} finishId={5} isVisible={!isMenuOpen}>
          <InputUI.Code
            onFinish={handleSubmit}
            focusFirstInputOnFirstRender
            isError={!!errorMessage}
            cleanOfError
            disabled={loginSmsConfirmFetching}
          />
        </AnimatedPasswordStyled>
        <AnimatedInfoTwoStyled id={3} finishId={5} isVisible={!isMenuOpen}>
          {!!errorMessage && <ErrorTextStyled>{errorMessage}</ErrorTextStyled>}
        </AnimatedInfoTwoStyled>
        <AnimatedInfoTwoStyled id={4} finishId={5} isVisible={!isMenuOpen}>
          <ReSendInfo time={resendTime} isOnTimer={isInTimer} onResendSMS={handleReSendSms} />
        </AnimatedInfoTwoStyled>
        <AnimatedInfoTwoStyled id={5} finishId={5} isVisible={!isMenuOpen}>
          <ButtonUI.Link
            onClick={() => {
              dispatch(logOut());
              onComeBack();
            }}
          >
            <LinkStyled>Вернуться</LinkStyled>
          </ButtonUI.Link>
        </AnimatedInfoTwoStyled>
      </FormStyled>
    </WrapperStyled>
  );
};

const WrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const FormStyled = styled.form`
  max-width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  height: min-content;
  width: inherit;
`;

const AnimatedTitleStyled = styled(AnimatedUI)`
  margin-bottom: 8px;
`;

const TitleTextStyled = styled(TitleText)`
  display: inline-block;
  width: 100%;
  font-weight: 800;
  font-size: 36px;
  line-height: 53px;
  text-align: center;
  color: var(--dark-slate-blue);
`;

const AnimatedInfoStyled = styled(AnimatedUI)`
  margin-bottom: 50px;
`;

const LineTextStyled = styled(LineText)`
  ${getFont(WEIGHT['rubik-regular'])}
  color: var(--bluey-grey);
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

const AnimatedPasswordStyled = styled(AnimatedUI)`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;
const AnimatedInfoTwoStyled = styled(AnimatedInfoStyled)`
  margin-bottom: 6px;
`;
const InfoTextStyled = styled(LineTextStyled)`
  font-size: 16px;
`;

const LinkStyled = styled(InfoTextStyled)`
  color: var(--dusk-blue);
`;

const ErrorTextStyled = styled(InfoTextStyled)`
  color: var(--reddish-pink);
`;

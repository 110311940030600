import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { changeLookOfPhone } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ShowCasePreviewForm from 'modules/cashbox/components/ShowCasePreviewForm/ShowCasePreviewForm';
import Check from 'modules/profileLegasy/components/Check/Check';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';
import BlockedUserText from 'modules/shared/components/BlockedUserText/BlockedUserText';
import CircleWithEmoji from 'modules/shared/components/CircleWithEmoji/CircleWithEmoji';
import PaymentProcessCard from 'modules/shared/components/PaymentProcessCard/PaymentProcessCard';
import PaymentSuccessHeader from 'modules/shared/components/PaymentSuccessHeader/PaymentSuccessHeader';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

import {
  BLUE,
  BLUE_BORDER,
  PAYMENT_TRANSFER_FAIL,
  PAYMENT_TRANSFER_LOADING,
  PAYMENT_TRANSFER_OK,
} from 'constants/index';
import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconBurger } from 'static/assets/icon-burger.svg';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';
import { ReactComponent as IconLogo } from 'static/assets/logo-white.svg';
import profileBg from 'static/assets/profile-bg.png';

// Styles
import './ShowCasePreview.scss';

const ShowCasePreview = ({
  className,
  name,
  sum,
  maxLimitSum,
  isNameVisible,
  isPhoneVisible,
  isEmailVisible,
  isPreview,
  userFirstName,
  userLastName,
  userMiddleName,
  fio,
  userLogin,
  userEmail,
  sumHint,
  isUserLoggedIn,
  showCaseId,
  isMoneyTransferOk,
  isMoneyTransferOkNoCheck,
  isMoneyTransferFail,
  isSending,
  urlForRepeatPayment,
  amountFromLocalStorage,
  isCheckLoading,
  checkData,
  isCheckError,
  handleDownloadInvoice,
  isPayerNameNeeded,
  isPayerPhoneNeeded,
  isPayerEmailNeeded,
  isUserBlocked,
  isReplenishmentBlocked,
  isNotAvailable,
}) => {
  const INITIAL_STATUS =
    !isSending &&
    !isMoneyTransferOk &&
    !isMoneyTransferFail &&
    !isMoneyTransferOkNoCheck &&
    !isUserBlocked &&
    !isReplenishmentBlocked;
  const windowSize = useWindowSize();
  const [errorMessage, setErrorMessage] = useState('');

  const [showedIframe, setShowedIframe] = useState(false);
  const [resetPay, setResetPay] = useState(0);

  useEffect(() => {
    if (window && window.sessionStorage) {
      const localErrorMessage = window.sessionStorage.getItem('transactionStateMessage');
      if (localErrorMessage) {
        setErrorMessage(localErrorMessage);
        window.sessionStorage.removeItem('transactionStateMessage');
      }
    }
  }, []);

  const summaryFields = [
    {
      source: fio,
      title: 'Получатель',
      value: `${fio}`,
    },
    {
      source: name,
      title: 'Цель',
      value: name,
    },
    {
      source: amountFromLocalStorage,
      title: 'Сумма',
      value: `${formatNumber(amountFromLocalStorage)} ₽`,
    },
  ];

  const reset = () => {
    setResetPay((trigger) => trigger + 1);
  };

  return (
    <div
      className={cx('show-case-preview', {
        'show-case-preview_logged-in': isUserLoggedIn,
        [className]: className,
      })}
      data-testid="show-case-preview"
    >
      {/* ХЭДЕР */}
      <div className="show-case-preview__top">
        {isPreview && (
          <>
            <IconLogo className="show-case-preview__top-logo" />

            <IconBurger className="show-case-preview__top-burger" />
          </>
        )}

        <div
          className={cx('show-case-preview__top-container ', {
            'container container_small': !isPreview,
          })}
          data-testid="show-case-preview-top-container"
        >
          <div className="show-case-preview__back-button-wrapper">
            {showedIframe && (
              <div className="show-case-preview__back-button" onClick={reset}>
                <IconArrow className={cx('show-case-preview__back-button-icon')} />
                <span className="show-case-preview__back-button-text">Назад</span>
              </div>
            )}
          </div>

          {name && (
            <div className="show-case-preview__title-wrap">
              <p className="show-case-preview__pre-title">Наименование услуги/товара</p>

              <h1 className="show-case-preview__title">{name}</h1>
            </div>
          )}

          <div className="show-case-preview__person-info">
            {isNameVisible && (
              <div className="show-case-preview__person-info-block">
                <p className="show-case-preview__person-info-block-title">Самозанятый</p>

                <p className="show-case-preview__person-info-block-text show-case-preview__person-info-block-text_name">
                  {isPreview ? (
                    <>
                      {userLastName} {userFirstName} {userMiddleName}
                    </>
                  ) : (
                    <> {fio} </>
                  )}
                </p>
              </div>
            )}

            {isPhoneVisible && (
              <div className="show-case-preview__person-info-block">
                <p className="show-case-preview__person-info-block-title">Номер телефона</p>

                <p className="show-case-preview__person-info-block-text">
                  {userLogin && changeLookOfPhone(userLogin)}
                </p>
              </div>
            )}

            {isEmailVisible && (
              <div className="show-case-preview__person-info-block">
                <p className="show-case-preview__person-info-block-title">E-mail</p>

                <p className="show-case-preview__person-info-block-text">{userEmail}</p>
              </div>
            )}
          </div>
        </div>

        <img src={profileBg} alt="Profile bg" className="show-case-preview__top-bg" />
      </div>
      {/* /ХЭДЕР */}

      {/* КОНТЕНТ */}
      <div className="show-case-preview__content">
        <div
          className={cx('show-case-preview__content-container', {
            'container container_small': !isPreview,
            'show-case-preview__content-container_success-payment': !isPreview && isMoneyTransferOk,
          })}
          data-testid="show-case-preview-content-container"
        >
          {INITIAL_STATUS ? (
            <>
              {/* ФОРМА */}
              <div className="show-case-preview__form">
                <ShowCasePreviewForm
                  isPreview={isPreview}
                  sum={sum}
                  maxLimitSum={maxLimitSum}
                  sumHint={sumHint}
                  showCaseId={showCaseId}
                  isPayerNameNeeded={isPayerNameNeeded}
                  isPayerPhoneNeeded={isPayerPhoneNeeded}
                  isPayerEmailNeeded={isPayerEmailNeeded}
                  showedIframe={(e) => setShowedIframe(e)}
                  resetPay={resetPay}
                  isNotAvailable={isNotAvailable}
                />
              </div>
              {/* /ФОРМА */}

              {/* БАЛЛУН */}
              <div className="show-case-preview__balloon">
                <p className="show-case-preview__balloon-text">
                  Обратите внимание: Вы производите оплату услуги или товара пользователю, имеющему
                  официальный статус самозанятого (НПД) в ФНС. После оплаты, вы автоматически
                  получите чек от пользователя.
                </p>

                <IconTriangle className="show-case-preview__balloon-triangle" />
              </div>
              {/* /БАЛЛУН */}
            </>
          ) : (
            <>
              {isUserBlocked || isReplenishmentBlocked ? (
                <>
                  <div className="show-case-preview__blocked-user">
                    <CircleWithEmoji
                      emoji={emojiSad}
                      className="show-case-preview__blocked-user-img"
                    />
                    {isUserBlocked && (
                      <BlockedUserText className="show-case-preview__blocked-user-text" />
                    )}
                    {isReplenishmentBlocked && (
                      <BlockedUserText
                        title={'Пополнение кошелька заблокировано'}
                        desc={'Пополнение кошелька заблокировано и нельзя совершить платеж.'}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={cx('show-case-preview__payment', {
                      'show-case-preview__payment_with-check': isMoneyTransferOk,
                    })}
                    data-testid="show-case-preview-payment"
                  >
                    {isMoneyTransferOk || isMoneyTransferOkNoCheck ? (
                      <div className="show-case-preview__payment-card show-case-preview__payment-card_success">
                        <PaymentSuccessHeader paymentStatus={PAYMENT_TRANSFER_OK} />

                        <PaymentSummary
                          className="web-money-card__payment-summary"
                          summaryFields={summaryFields.filter((item) => item.source)}
                        />
                      </div>
                    ) : (
                      <PaymentProcessCard
                        className="show-case-preview__payment-card"
                        paymentStatus={isSending ? PAYMENT_TRANSFER_LOADING : PAYMENT_TRANSFER_FAIL}
                      />
                    )}

                    {(isMoneyTransferOkNoCheck || isCheckError) && (
                      <div className="show-case-preview__payment-no-check-note">
                        <IconInfo className="show-case-preview__payment-no-check-note-icon" />
                        <p className="show-case-preview__payment-no-check-note-text">
                          К сожалению, не удалось создать чек для операции
                        </p>
                      </div>
                    )}

                    {/* ПРИЧИНА ОШИБКИ ПЕРЕВОДА */}
                    {isMoneyTransferFail && (
                      <div className="show-case-preview__payment-fail-reason">
                        <span className="show-case-preview__payment-fail-reason-key">
                          Возможная причина:
                        </span>
                        <span className="show-case-preview__payment-fail-reason-value">
                          {errorMessage || 'Ведутся работы на сервере'}
                        </span>
                      </div>
                    )}
                    {/* /ПРИЧИНА ОШИБКИ ПЕРЕВОДА */}

                    <div className="show-case-preview__payment-button-list">
                      {/* КНОПКА ПОВТОРИТЬ ПЛАТЕЖ */}
                      {!isSending && (isMoneyTransferOk || isMoneyTransferOkNoCheck) && (
                        <StandardButton
                          title={urlForRepeatPayment ? 'Повторить платеж' : 'Вернуться в кассу'}
                          bgType={BLUE_BORDER}
                          isLink
                          href={urlForRepeatPayment || '/lk/kassa'}
                          className="show-case-preview__payment-button show-case-preview__payment-button_wide"
                        />
                      )}
                      {/* /КНОПКА ПОВТОРИТЬ ПЛАТЕЖ */}

                      {/* КНОПКА ПОВТОРИТЬ ПЛАТЕЖ */}
                      {!isSending && isMoneyTransferFail && (
                        <StandardButton
                          title={urlForRepeatPayment ? 'Попробовать снова' : 'Вернуться в кассу'}
                          bgType={BLUE_BORDER}
                          isLink
                          href={urlForRepeatPayment || '/lk/kassa'}
                          className="show-case-preview__payment-button show-case-preview__payment-button_wide"
                        />
                      )}
                      {/* /КНОПКА ПОВТОРИТЬ ПЛАТЕЖ */}

                      {isMoneyTransferOk && !isMoneyTransferOkNoCheck && !isCheckError && (
                        <StandardButton
                          title={'Скачать чек'}
                          bgType={BLUE}
                          isLink
                          isOutAppLink
                          onClick={handleDownloadInvoice}
                          disabled={isCheckLoading}
                          className="show-case-preview__payment-button show-case-preview__payment-button_wide"
                        />
                      )}
                    </div>
                  </div>

                  {/* ЛОАДЕР ДЛЯ ЧЕКА */}
                  {isCheckLoading && !isMoneyTransferOkNoCheck && !isCheckError && (
                    <div className="transfer-result__text">
                      {'Пожалуйста подождите, получаем чек...'}
                    </div>
                  )}
                  {/* /ЛОАДЕР ДЛЯ ЧЕКА */}

                  {isMoneyTransferOk &&
                    windowSize.innerWidth > 800 &&
                    checkData &&
                    !isCheckLoading &&
                    !isCheckError && (
                      <Check className="show-case-preview__check" checkData={checkData} />
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* /КОНТЕНТ */}
    </div>
  );
};

ShowCasePreview.propTypes = {
  name: PropTypes.string,
  sum: PropTypes.number,
  maxLimitSum: PropTypes.number,
  sumHint: PropTypes.number,
  isNameVisible: PropTypes.bool,
  isPhoneVisible: PropTypes.bool,
  isEmailVisible: PropTypes.bool,
  className: PropTypes.string,
  isPreview: PropTypes.bool.isRequired,
  fio: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userMiddleName: PropTypes.string,
  userLogin: PropTypes.string,
  userEmail: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
  showCaseId: PropTypes.string,
  isMoneyTransferOk: PropTypes.bool,
  isMoneyTransferOkNoCheck: PropTypes.bool,
  isMoneyTransferFail: PropTypes.bool,
  isSending: PropTypes.bool,
  urlForRepeatPayment: PropTypes.string,
  amountFromLocalStorage: PropTypes.number,
  isCheckLoading: PropTypes.bool,
  checkData: PropTypes.shape({}),
  isCheckError: PropTypes.bool,
  handleDownloadInvoice: PropTypes.func,
  isPayerNameNeeded: PropTypes.bool,
  isPayerPhoneNeeded: PropTypes.bool,
  isPayerEmailNeeded: PropTypes.bool,
  isUserBlocked: PropTypes.bool,
  isReplenishmentBlocked: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
};

ShowCasePreview.defaultProps = {
  name: null,
  sum: null,
  maxLimitSum: null,
  sumHint: null,
  className: null,
  fio: null,
  userFirstName: null,
  userLastName: null,
  userMiddleName: null,
  isNameVisible: false,
  isPhoneVisible: false,
  isEmailVisible: false,
  userLogin: null,
  userEmail: null,
  isUserLoggedIn: false,
  showCaseId: null,
  isMoneyTransferOk: false,
  isMoneyTransferOkNoCheck: false,
  isMoneyTransferFail: false,
  isSending: false,
  urlForRepeatPayment: null,
  amountFromLocalStorage: 0,
  isCheckLoading: false,
  checkData: null,
  isCheckError: false,
  handleDownloadInvoice: () => {},
  isUserBlocked: false,
  isReplenishmentBlocked: false,
  isPayerNameNeeded: false,
  isPayerPhoneNeeded: false,
  isPayerEmailNeeded: false,
};

export default ShowCasePreview;

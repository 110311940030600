import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ApplePayButton from 'modules/shared/components/ApplePayButton/ApplePayButton';
import ApplePaySetupButton from 'modules/shared/components/ApplePaySetupButton/ApplePaySetupButton';

import './ApplePayBox.scss';

const ApplePayBox = ({ amount, disableApplePayButton, payByApplePay }) => {
  const [isPaymentsAvailable /*, setIsPaymentsAvailable*/] = useState(false);
  const [isSetUpAvailable /*, setIsSetUpAvailable*/] = useState(false);

  useEffect(() => {
    // if (window && window.ApplePaySession &&  window.ApplePaySession.canMakePayments) {
    // if (window && window.ApplePaySession) {
    //   window.ApplePaySession.canMakePaymentsWithActiveCard('merchant.ru.selfwork')
    //     .then(res => {
    //       if (res) {
    //         setIsPaymentsAvailable(true);
    //       } else {
    //         setIsSetUpAvailable(true);
    //       }
    //     })
    //     .catch(err => {
    //       /* eslint-disable no-console */
    //       console.log(err);
    //       /* eslint-enable no-console */
    //     });
    // }
  }, []);

  return (
    <div className="apple-pay-box">
      {isPaymentsAvailable && (
        <ApplePayButton
          amount={amount}
          disableApplePayButton={disableApplePayButton}
          payByApplePay={payByApplePay}
        />
      )}
      {isSetUpAvailable && <ApplePaySetupButton disableApplePayButton={disableApplePayButton} />}
    </div>
  );
};

ApplePayBox.propTypes = {
  amount: PropTypes.number.isRequired,
  disableApplePayButton: PropTypes.bool,
  payByApplePay: PropTypes.func,
};

ApplePayBox.defaultProps = {
  disableApplePayButton: false,
  payByApplePay: () => {},
};

export default ApplePayBox;

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../Dialog';
import IconEmojiSad from 'static/assets/icon-emoji-sad.png';
import styles from './DialogError.module.scss';

const DialogError = ({ className, onClose }) => {
  return (
    <Dialog onClose={onClose} className={className}>
      <h1 className={styles.dialogErrorTitle}>Ой! Ошибка!</h1>
      <div className={styles.dialogErrorIcon}>
        <img src={IconEmojiSad} alt="icon sad emoji" />
      </div>
      <p className={styles.dialogErrorText}>
        Что-то пошло не так. Попробуйте повторить попытку позже или напишите в тех.поддержку.
      </p>
      <nav className={styles.dialogErrorNav}>
        <button onClick={onClose}>Вернуться</button>
      </nav>
    </Dialog>
  );
};

DialogError.propTypes = {
  className: PropTypes.string,
};

export default DialogError;

import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PopupPageWrap from 'modules/profileLegasy/components/PopupPageWrap/PopupPageWrap';
import { getTaxesToPay } from 'modules/tax/actions';
import TaxListWrap from 'modules/tax/components/TaxListWrap/TaxListWrap';

import { MONTHSRU } from 'constants/index';

import './TaxListPage.scss';

const LOADING = 'LOADING';
const FAIL = 'FAIL';

const TaxListPage = ({ history, dispatch }) => {
  const windowSize = useWindowSize();
  const [monthList, setMonthList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [dataLoadingStatus, setDataLoadingStatus] = useState(null);

  useEffect(() => {
    dispatch(getTaxesToPay())
      .then((res) => {
        if (res && res.data && res.data.taxes) {
          setTaxList(
            (prevState) => [...prevState].concat(res.data.taxes),
            /*          .concat([{
          amount: 116500,
          dateCreate: "2020-10-20",
          dateDue: "2020-10-20",
          documentIndex: "18209997200000004992",
          inn: "665896616305",
          recipient: "Управление Федерального казначейства по г. Москве",
          recipientBankAccountNumber: "00000000000000000000",
          recipientBankBik: "044525000",
          recipientBankName: "ГУ БАНКА РОССИИ ПО ЦФО",
          recipientInn: "6658040003",
          recipientKpp: "665801001",
          type: "TAX",
        }]) */
          );
        }
      })
      .catch(() => {
        setDataLoadingStatus(FAIL);
      });
  }, [dispatch]);

  useEffect(() => {
    setDataLoadingStatus(LOADING);
    if (taxList && taxList.length >= 1) {
      taxList.forEach((item) => {
        setMonthList((prevState) => {
          if (
            prevState.find(
              (prevStateItem) =>
                prevStateItem.monthNumber === new Date(item.dateCreate).getMonth() + 1,
            )
          ) {
            /* eslint-disable array-callback-return, consistent-return */
            return [...prevState].map((prevStateItem) => {
              if (prevStateItem.monthNumber === new Date(item.dateCreate).getMonth() + 1) {
                return {
                  ...prevStateItem,
                  sum: prevStateItem.sum + item.amount,
                };
              }
            });
            /* eslint-enable array-callback-return, consistent-return */
          }
          return [...prevState].concat([
            {
              id: item.documentIndex,
              month: MONTHSRU[new Date(item.dateCreate).getMonth()],
              monthNumber: new Date(item.dateCreate).getMonth() + 1,
              sum: item.amount,
            },
          ]);
        });
        setDataLoadingStatus(null);
      });
    } else {
      setDataLoadingStatus(null);
    }
  }, [taxList]);

  return (
    <PopupPageWrap
      isStandardTitle={windowSize.innerWidth > 980}
      textTitle={windowSize.innerWidth > 980 ? 'Оплата налога' : null}
      title={
        windowSize.innerWidth <= 980 ? (
          <div className="tax-list-page__title-wrap">
            <h1 className="tax-list-page__title">Оплата налога</h1>
            <p className="tax-list-page__sub-title">Выберите период</p>
          </div>
        ) : null
      }
      mainContent={
        <TaxListWrap
          history={history}
          monthList={monthList}
          taxList={taxList}
          dataLoadingStatus={dataLoadingStatus}
        />
      }
      needFooter
      isMainContentFullWidth
      className="tax-list-page"
      redirectPage={
        window.location.href.indexOf('/lk/tax-list/month-') > -1 ? '/lk/tax-list' : '/lk/accounting'
      }
    />
  );
};

TaxListPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(TaxListPage);

import React, { useEffect, useState, useMemo, createRef } from 'react';
import cx from 'classnames';
import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';

// Styles
import './TabsWithScrollableActiveState.scss';

const TabsWithScrollableActiveState = ({
  isRendered,
  currentTabId,
  tabArray,
  className,
  breakPoint,
}) => {
  const windowSize = useWindowSize();

  /**
   * Создаем массив из рефов для каждого из айтемов массива,
   * пришедшего из пропсов
   * */
  const itemsRefArr = useMemo(() => tabArray.map(() => createRef()), [tabArray]);

  const [tabsBarActiveStyle, setTabsBarActiveStyle] = useState(null);

  /** Устанавливаем стили для активного таба */
  useEffect(() => {
    if (isRendered && itemsRefArr) {
      if (windowSize.innerWidth > breakPoint) {
        setTabsBarActiveStyle({
          width: itemsRefArr[currentTabId].current.clientWidth,
          left: itemsRefArr[currentTabId].current.offsetLeft,
        });
      } else {
        setTabsBarActiveStyle({
          width: `${100 / itemsRefArr.length}%`,
          left: currentTabId
            ? `${100 / itemsRefArr.length}%`
            : itemsRefArr[currentTabId].current.offsetLeft,
        });
      }
    }
  }, [currentTabId, windowSize.innerWidth, isRendered, itemsRefArr, breakPoint]);

  return (
    <nav
      className={cx('tabs-with-scrollable-active-state', {
        [className]: className,
      })}
      data-testid="tabs-with-scrollable-active-state"
    >
      {tabArray.map((item, index) => (
        <button
          className={cx('tabs-with-scrollable-active-state__nav-item', {
            'tabs-with-scrollable-active-state__nav-item_active': currentTabId === index,
          })}
          ref={itemsRefArr[index]}
          key={item.title}
          type="button"
          onClick={item.click}
          style={{
            width: windowSize.innerWidth > breakPoint ? 'auto' : `${100 / itemsRefArr.length}%`,
          }}
          data-testid="tabs-with-scrollable-active-state-button"
        >
          {item.title}
        </button>
      ))}

      <div className="tabs-with-scrollable-active-state__tabs-bar">
        <div
          className="tabs-with-scrollable-active-state__tabs-bar-active"
          data-testid="tabs-with-scrollable-active-state-bar"
          style={tabsBarActiveStyle}
        />
      </div>
    </nav>
  );
};

TabsWithScrollableActiveState.propTypes = {
  className: PropTypes.string,
  isRendered: PropTypes.bool,
  currentTabId: PropTypes.number.isRequired,
  tabArray: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      click: PropTypes.func,
    }),
  ).isRequired,
  breakPoint: PropTypes.number,
};

TabsWithScrollableActiveState.defaultProps = {
  isRendered: false,
  className: null,
  breakPoint: 600,
};

export default TabsWithScrollableActiveState;

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import PaymentBox from 'modules/shared/components/PaymentBox/PaymentBox';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SumInput from 'modules/shared/components/SumInput/SumInput';
import TextareaWithLabelAnimation from 'modules/shared/components/TextareaWithLabelAnimation/TextareaWithLabelAnimation';

import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';

import { disableSpaceEnter, disableRussianLetters, validateEmail, normalizePhone } from 'helpers';

// Styles
import './ShowCasePreviewForm.scss';
import { Redirect, useLocation } from 'react-router-dom';
import { getShowCaseTransactionOperationStatus } from '../../actions';
import SBPButton from '../../../shared/components/SBPButton/SBPButton';
import { WindowUI } from '../../../../ui';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const hideSBP = false; //process.env.REACT_APP_IS_PROD_BUILD === 'Y';

export const ShowCasePreviewForm = ({
  sum,
  maxLimitSum,
  sumHint,
  isPreview,
  showCaseId,
  user,
  isPayerNameNeeded,
  isPayerPhoneNeeded,
  isPayerEmailNeeded,
  showedIframe,
  resetPay,
  isNotAvailable,
}) => {
  const urlRef = useRef(new URL(window.location.href));
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [paymentSum, setPaymentSum] = useState('');
  const [commentary, setCommentary] = useState('');
  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [isOpenPopupIsNotAvailable, setOpenPopupIsNotAvailable] = useState(false);

  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeSBPLoading, setIsIframeSBPLoading] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showIframeSBP, setShowIframeSBP] = useState(false);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState(null);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);
  const [redirectToSuccessPageNoCheck, setRedirectToSuccessPageNoCheck] = useState(null);

  const amount = paymentSum || (sum > 0 && sum / 100);

  const getIframeUrl = (sbp) => {
    let url = `${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/${
      sbp ? 'pay-it-cash-box-sbp' : 'pay-it-cash-box'
    }?payItUID=${showCaseId}&account=${user ? user.login : 'anonymous'}&amount=${amount * 100}`;

    if (commentary) {
      url += `&comment=${commentary}`;
    }

    if (fio) {
      url += `&fio=${fio}`;
    }

    if (phone) {
      url += `&phone=${normalizePhone(phone)}`;
    }

    if (email) {
      url += `&email=${email}`;
    }

    return url;
  };

  const continueButtonClick = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    window.sessionStorage.setItem('cashBoxTransferAmount', amount);
    setStep(1);
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const continueButtonClickSBP = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    window.sessionStorage.setItem('cashBoxTransferAmount', amount);
    setStep(1);
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  useEffect(() => {
    if (resetPay) {
      setStep(0);
      setShowIframe(false);
      setIsIframeLoading(false);
      setShowIframeSBP(false);
      setIsIframeSBPLoading(false);
    }
  }, [resetPay]);

  useEffect(() => {
    if (urlRef.current) {
      const localAmount = urlRef.current.searchParams.get('amount');
      const localComment = urlRef.current.searchParams.get('comment');
      const localFio = urlRef.current.searchParams.get('fio');
      const localPhone = urlRef.current.searchParams.get('phone');
      const localEmail = urlRef.current.searchParams.get('email');

      if (localComment) {
        setCommentary(localComment);
      }

      if (localAmount) {
        setPaymentSum((+localAmount / 100).toFixed(2));
      }

      if (localFio) {
        setFio(localFio);
      }

      if (localPhone) {
        setPhone(localPhone);
      }

      if (localEmail) {
        setEmail(localEmail);
      }
    }
  }, []);

  useEffect(() => {
    showedIframe(showIframe || showIframeSBP);
  }, [showIframe, showIframeSBP, showedIframe]);

  const updateTimeout = useRef(null);
  const updateOperationStatus = useCallback(
    (operationId) => {
      if (!operationId) {
        clearTimeout(updateTimeout.current);
        return;
      }

      getShowCaseTransactionOperationStatus(operationId)
        .then((res) => {
          if (res && res.data) {
            if (res.data.state === STATUS_WAIT) {
              updateTimeout.current = setTimeout(() => updateOperationStatus(operationId), 1000);
            } else if (res.data.state === STATUS_ERROR) {
              setRedirectToErrorPage(`/pay-it/cashbox/${operationId}/error/${res.data.uuid}`);
            } else if (res.data.state === STATUS_SUCCESS) {
              if (res.data.url) {
                setRedirectToSuccessPage(`${res.data.url}/${res.data.uuid}`);
                // setRedirectToSuccessPage(`/pay-it/cashbox/${operationId}/success/${res.data.uuid}`);
              } else {
                setRedirectToSuccessPageNoCheck(
                  `/pay-it/cashbox/${operationId}/not-register-deal/${res.data.uuid}`,
                );
              }
            }

            if (res.data.transactionStateMessage) {
              window.localStorage.setItem(
                'transactionStateMessage',
                res.data.transactionStateMessage,
              );
            }
          }
        })
        .catch(() => {
          const uuid = location.pathname.split('/').reverse()[0];
          setRedirectToErrorPage(`/pay-it/cashbox/${operationId}/error${uuid ? '/' + uuid : ''}`);
        });
    },
    [location.pathname],
  );

  useEffect(() => {
    const stateListener = (e) => {
      if (!process.env.REACT_APP_PCI_DSS_IFRAME.includes(e.origin)) return;
      if (e.data.operationId) {
        clearTimeout(updateTimeout?.current);
        updateOperationStatus(e.data.operationId);
      } else if (e.data.linkUrl) {
        window.open(e.data.linkUrl, e.data.target || '_blank');
      }
    };
    if (showIframeSBP) {
      window.addEventListener('message', stateListener);
    }
    return () => {
      window.removeEventListener('message', stateListener);
      clearTimeout(updateTimeout?.current);
    };
  }, [showIframeSBP, updateOperationStatus]);

  if (redirectToErrorPage) {
    return <Redirect to={redirectToErrorPage} />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  if (redirectToSuccessPageNoCheck) {
    return <Redirect to={redirectToSuccessPageNoCheck} />;
  }

  return (
    <div className="show-case-preview-form">
      {isOpenPopupIsNotAvailable && isNotAvailable && (
        <WindowUI.WindowUniversalError
          header={'Ошибка оплаты'}
          text={'Оплата невозможна из-за превышения лимитов у получателя.'}
          buttonText={'Хорошо'}
          onClose={() => {
            setOpenPopupIsNotAvailable(false);
          }}
        />
      )}

      {step === 0 && (
        <>
          {/* ВВОД СУММЫ */}
          <div className="show-case-preview-form__block">
            <SumInput
              label="Сумма"
              setSum={setPaymentSum}
              minSum={1}
              maxSum={maxLimitSum / 100}
              value={!isPreview ? amount || '' : sum || ''}
              placeholder={!isPreview ? `${sumHint / 100} ₽` : ''}
              readOnly={sum}
              disabled={Boolean(sum)}
            />
          </div>
          {/* /ВВОД СУММЫ */}

          {isPayerNameNeeded && (
            <div className="show-case-preview-form__block">
              <InputWithLabelAnimation
                label="ФИО"
                value={fio}
                onChange={(event) => setFio(event.target.value)}
              />
            </div>
          )}

          {isPayerPhoneNeeded && (
            <div className="show-case-preview-form__block">
              <InputWithLabelAnimation
                label="Номер телефона"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                isPhoneInput
              />
            </div>
          )}

          {isPayerEmailNeeded && (
            <div className="show-case-preview-form__block">
              <InputWithLabelAnimation
                label="E-mail"
                value={email}
                onChange={(event) =>
                  setEmail(disableRussianLetters(disableSpaceEnter(event.target.value)))
                }
              />
            </div>
          )}

          {/* КОММЕНТАРИЙ */}
          <div className="show-case-preview-form__block">
            <TextareaWithLabelAnimation
              value={commentary}
              label="Комментарий"
              onChange={(event) => setCommentary(event.target.value)}
            />
          </div>
          {/* /КОММЕНТАРИЙ */}

          {/* КНОПКА ОПЛАТИТЬ */}
          <div className="show-case-preview-form__button-wrap">
            {isPreview ? (
              <div className="show-case-preview-form__button-wrap-preview">
                <StandardButton
                  title="Оплатить картой"
                  isLink={false}
                  disabled
                  className="show-case-preview-form__button"
                />
                <SBPButton className="show-case-preview-form__button" disablePayButton />
              </div>
            ) : (
              <PaymentBox
                isOneColumn
                amount={+amount || 0}
                isDisabled={
                  !showCaseId ||
                  (!amount && !paymentSum) ||
                  (isPayerNameNeeded && !fio) ||
                  (isPayerEmailNeeded && (!email || !validateEmail(email))) ||
                  (isPayerPhoneNeeded && (!phone || (phone && normalizePhone(phone).length !== 11)))
                }
                onPayByCard={continueButtonClick}
                onPayBySBP={continueButtonClickSBP}
                disableApplePayButton
                disableSamsungPayButton
                hideSBP={hideSBP}
              />
            )}
          </div>
          {/* /КНОПКА ОПЛАТИТЬ */}
        </>
      )}

      {step === 1 && (
        <>
          {showIframe && (
            <div className="show-case-preview-form__iframe-wrap">
              {isIframeLoading && (
                <div className="show-case-preview-form__iframe-loader">
                  <CircleLoader />
                </div>
              )}
              <iframe
                title="ShowCasePreviewForm"
                className="show-case-preview-form__iframe"
                src={getIframeUrl(false)}
                frameBorder="0"
                onLoad={() => {
                  setIsIframeLoading(false);
                }}
              />
            </div>
          )}
          {showIframeSBP && (
            <div className="show-case-preview-form__iframe-wrap-sbp">
              {isIframeSBPLoading && (
                <div className="show-case-preview-form__iframe-loader">
                  <CircleLoader />
                </div>
              )}
              <iframe
                title="ShowCasePreviewForm"
                className="show-case-preview-form__iframe"
                src={getIframeUrl(true)}
                frameBorder="0"
                onLoad={() => {
                  setIsIframeSBPLoading(false);
                }}
              />
            </div>
          )}
        </>
      )}

      {!isPreview && (
        <p className="show-case-preview-form__privacy-policy">
          Совершая платеж, вы соглашаетесь с{' '}
          <a
            href="https://самозанятые.рф/legal/walletoffer.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="show-case-preview-form__privacy-policy-link"
          >
            публичной офертой
          </a>
          ,{' '}
          <a
            href="https://самозанятые.рф/legal/walletterms.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="show-case-preview-form__privacy-policy-link"
          >
            пользовательским соглашением
          </a>
          <span> и </span>
          <a
            href={`${process.env.REACT_APP_URL}privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
            className="show-case-preview-form__privacy-policy-link"
          >
            политикой конфиденциальности
          </a>
        </p>
      )}

      <div className="show-case-preview-form__payment-ways">
        <IconVisa className="show-case-preview-form__payment-ways-item show-case-preview-form__payment-ways-item_visa" />
        <IconMasterCard className="show-case-preview-form__payment-ways-item show-case-preview-form__payment-ways-item_msc" />
        <IconMir className="show-case-preview-form__payment-ways-item show-case-preview-form__payment-ways-item_mir" />
        <IconPci className="show-case-preview-form__payment-ways-item show-case-preview-form__payment-ways-item_pci" />
      </div>
    </div>
  );
};

ShowCasePreviewForm.propTypes = {
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  sum: PropTypes.number,
  maxLimitSum: PropTypes.number,
  sumHint: PropTypes.number,
  isPreview: PropTypes.bool,
  showCaseId: PropTypes.string,
  isPayerNameNeeded: PropTypes.bool,
  isPayerPhoneNeeded: PropTypes.bool,
  isPayerEmailNeeded: PropTypes.bool,
  showedIframe: PropTypes.func,
};

ShowCasePreviewForm.defaultProps = {
  user: null,
  sum: null,
  sumHint: null,
  maxLimitSum: null,
  isPreview: false,
  showCaseId: null,
  isPayerNameNeeded: false,
  isPayerPhoneNeeded: false,
  isPayerEmailNeeded: false,
  showedIframe: () => {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ShowCasePreviewForm);

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import React, { useCallback, useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import downloadFile from 'helpers/downloadFile';

import circleBackground from 'static/assets/qr_circle_without_qr.png';
import squareBackground from 'static/assets/qr_square_without_qr.jpg';

// Version >= 2.4.0
import 'swiper/css/swiper.css';
// Style
import './QrCodeSlider.scss';

const QrCodeSlider = ({ url }) => {
  const windowSize = useWindowSize();

  // Swiper instance
  const [swiper, updateSwiper] = useState(null);
  // Slides current index
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  // Manipulate swiper from outside
  const goTo = currentSlideCount => {
    if (swiper !== null) {
      swiper.slideTo(currentSlideCount);
      updateCurrentIndex(currentSlideCount);
    }
  };

  const generateQrCode = (background, qrCodeWidth, qrCodeX, qrCodeY) => {
    QRCode.toDataURL(url, { width: qrCodeWidth, margin: 2 }).then(data => {
      const qrCode = new Image();
      qrCode.src = data;
      qrCode.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = background ? background.width : qrCode.width;
        canvas.height = background ? background.height : qrCode.height;

        const ctx = canvas.getContext('2d');

        if (background) {
          ctx.drawImage(background, 0, 0);
        }

        ctx.drawImage(qrCode, qrCodeX, qrCodeY);
        canvas.toBlob(blob => {
          downloadFile(blob, 'samozanyatye-qr-code.png');
          setIsGenerating(false);
        });
      };
    });
  };

  const loadQrCode = () => {
    setIsGenerating(true);

    const parameters = [
      {
        backgroundSrc: `${process.env.PUBLIC_URL}/qr_circle_without_qr_big.png`,
        qrCodeWidth: 536,
        qrCodeX: 436,
        qrCodeY: 398,
      },
      { backgroundSrc: ``, qrCodeWidth: 536, qrCodeX: 0, qrCodeY: 0 },
      {
        backgroundSrc: `${process.env.PUBLIC_URL}/qr_square_without_qr_big.jpg`,
        qrCodeWidth: 1000,
        qrCodeX: 400,
        qrCodeY: 592,
      },
    ];

    const param = parameters[currentIndex];
    let background = null;
    if (param.backgroundSrc) {
      background = new Image();
      background.src = param.backgroundSrc;
      background.onload = () =>
        generateQrCode(background, param.qrCodeWidth, param.qrCodeX, param.qrCodeY);
    } else {
      generateQrCode(background, param.qrCodeWidth, param.qrCodeX, param.qrCodeY);
    }
  };

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [swiper]);

  useEffect(() => {
    QRCode.toDataURL(url, { width: 134, margin: 2 }).then(data => setQrCodeData(data));
  }, [url]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);

  const navArray = [
    {
      id: 1,
      title: 'Круглая картинка',
    },
    {
      id: 2,
      title: 'QR-код',
    },
    {
      id: 3,
      title: 'Прямоугольная картинка',
    },
  ];

  return (
    <div className="qr-code-slider">
      <div className="qr-code-slider__main">
        <Swiper
          containerClass="swiper-container qr-code-slider__slider-container"
          spaceBetween={30}
          slidesPerView={1}
          getSwiper={updateSwiper}
        >
          <div className="qr-code-slider__item">
            <div className="qr-code-slider__item-content-circle">
              <img src={circleBackground} alt="Qr code circle background" />
              {qrCodeData && (
                <img
                  className="qr-code-slider__item-qr-code-circle"
                  src={qrCodeData}
                  alt="Qr code"
                />
              )}
            </div>
          </div>
          <div className="qr-code-slider__item">
            {qrCodeData && (
              <img className="qr-code-slider__item-qr-code" src={qrCodeData} alt="Qr code" />
            )}
          </div>
          <div className="qr-code-slider__item">
            <div className="qr-code-slider__item-content-square">
              <img
                src={squareBackground}
                className="qr-code-slider__item-img-square"
                alt="Qr code square background"
              />
              {qrCodeData && (
                <img
                  className="qr-code-slider__item-qr-code-square"
                  src={qrCodeData}
                  alt="Qr code"
                />
              )}
            </div>
          </div>
        </Swiper>
      </div>

      <div className="qr-code-slider__nav">
        {navArray &&
          Array.isArray(navArray) &&
          navArray.length &&
          navArray.length >= 1 &&
          navArray.map(item => (
            <button
              key={item.id}
              type="button"
              className={cx('qr-code-slider__nav-item', {
                'qr-code-slider__nav-item_active': currentIndex === item.id - 1,
              })}
              onClick={() => goTo(item.id - 1)}
            >
              {windowSize.innerWidth > 980 && item.title}
            </button>
          ))}
      </div>

      <div className="qr-code-slider__download-wrap">
        {isGenerating ? (
          <div>
            <div className="qr-code-slider__generating">
              <CircleLoader
                width={16}
                height={16}
                spinnerColor="#1D2E69"
                borderWidth={2}
                mainColor="#B0B5C4"
              />
              <span className="qr-code-slider__generating-text">Документ создается</span>
            </div>
            <div className="qr-code-slider__generating-desc">
              Загрузка начнется сразу после того как документ будет создан
            </div>
          </div>
        ) : (
          <button className="qr-code-slider__download" type="button" onClick={() => loadQrCode()}>
            Загрузить для печати
          </button>
        )}
      </div>
    </div>
  );
};

QrCodeSlider.propTypes = {
  url: PropTypes.string.isRequired,
};

export default QrCodeSlider;

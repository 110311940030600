import React from 'react';

const HintSelfEmployed = () => (
  <ul className="hint-auth__list">
    <li className="hint-auth__list-item">1. Возможность вести коммерческую деятельность</li>
    <li className="hint-auth__list-item">
      2. Работа с компаниями через сервис
      <a
        href="https://самозанятые.рф/business?utm_source=pro.wallet"
        target="_blank"
        rel="noopener noreferrer"
        className="hint-auth__list-item-link"
      >
        «Бизнес»
      </a>
    </li>
    <li className="hint-auth__list-item">
      3. Возможность выполнять заказы в
      <a
        href="https://самозанятые.рф/selfworkers?utm_source=pro.wallet"
        target="_blank"
        rel="noopener noreferrer"
        className="hint-auth__list-item-link"
      >
        «Маркетплейсе»
      </a>
    </li>
  </ul>
);

export default HintSelfEmployed;

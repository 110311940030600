import cx from 'classnames';
import { normalizeSum, normalizeSumForMonth } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

import formatNumber from 'helpers/formatNumber';

import './AccountLimits.scss';

const AccountLimits = ({ limits, className }) => {
  const accountLimitsItems = [
    {
      id: 1,
      title: 'Можно хранить на балансе',
      limit: formatNumber(normalizeSum(limits && limits.balanceLimit)),
    },
    {
      id: 2,
      title: 'Лимит на одну операцию',
      limit: formatNumber(normalizeSum(limits && limits.maxSum)),
    },
    {
      id: 3,
      title: 'Лимит на вывод из кошелька',
      limit: formatNumber(normalizeSumForMonth(limits && limits.monthLimit)),
    },
  ];

  return (
    <div
      className={cx('account-limits', {
        [className]: className,
      })}
    >
      {accountLimitsItems.map((item) => (
        <div className="account-limits__item" key={item.id}>
          <div className="account-limits__item-bar">
            <div className="account-limits__item-bar-progress" />
          </div>

          <p className="account-limits__item-title">{item.title}</p>
          <p className="account-limits__item-desc">{item.limit}</p>
        </div>
      ))}
    </div>
  );
};

AccountLimits.propTypes = {
  className: PropTypes.string,
  limits: PropTypes.shape({
    groupCode: PropTypes.string,
    balanceLimit: PropTypes.number,
    monthLimit: PropTypes.number,
    maxSum: PropTypes.number,
  }),
};

AccountLimits.defaultProps = {
  className: null,
  limits: null,
};

export default AccountLimits;

import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

export const CodeInput = ({
  codeLength,
  onChange,
  onFinish,
  disabled,
  isError,
  cleanOfError,
  focusFirstInputOnFirstRender,
  className,
}) => {
  const codeInput1 = useRef(null);
  const codeInput2 = useRef(null);
  const codeInput3 = useRef(null);
  const codeInput4 = useRef(null);
  const codeInput5 = useRef(null);
  const codeInput6 = useRef(null);
  const codeInput7 = useRef(null);
  const codeInput8 = useRef(null);
  const codeAllInputs = [
    codeInput1,
    codeInput2,
    codeInput3,
    codeInput4,
    codeInput5,
    codeInput6,
    codeInput7,
    codeInput8,
  ];
  const codeInputs = codeAllInputs.slice(0, codeLength);

  const [currentValue, setCurrentValue] = useState([]);
  const POSITION_IN_INPUT = { left: 'left', right: 'right', selection: 'selection' };
  const [currentFocus, setCurrentFocus] = useState(0);
  const [positionInInput, setPositionInInput] = useState(POSITION_IN_INPUT.right);

  const isValueHasLeftEmpty = (event, newValueArr) =>
    currentFocus + event.target.value.length + 1 > newValueArr.length;
  const isAddValueHasRightValue = (event) =>
    codeInputs[currentFocus + event.target.value.length - 1]?.current?.value;

  //RUN возврат в родитель данных строкой при их каждом обновлении
  //RUN возврат в родитель данных строкой при полном наполнении
  useEffect(() => {
    onChange(currentValue.join(''));
    if (currentValue.length === codeLength) {
      onFinish(currentValue.join(''));
    } /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentValue, codeLength]);

  //RUN очищаем поля при появлении ошибки
  useEffect(() => {
    if (cleanOfError && isError) setCurrentValue([]);
    focusFirstInputOnFirstRender && codeInput1.current.focus();
  }, [cleanOfError, isError, focusFirstInputOnFirstRender]);

  //RUN заполнение набора импутов или их очистка + смещение фокуса в право + валидация  на число
  const onChangeInput = (event, id) => {
    //сработало изменение имупута
    if (!Number.isInteger(Number(event.target.value))) return; //валидация на число

    // если при изменении импут заполняется, то
    if (event.target.value) {
      //определение нового value
      const targetValueArr = event.target.value?.split('');
      const newCurrentValue = [
        ...currentValue.slice(0, id),
        ...targetValueArr,
        ...currentValue.slice(id + 1, codeInputs.length),
      ];
      setCurrentValue(() => newCurrentValue.slice(0, codeLength)); // сэтим новое значение сдвигая в права (копирование из смс)
      //определение нового фокуса
      if (
        targetValueArr.length === 1 &&
        isValueHasLeftEmpty(event, newCurrentValue) &&
        codeInputs[newCurrentValue.length]
      )
        // то сдвигаем фокус + 1 от текущего размера велью
        setCurrentFocus(newCurrentValue.length);
      else if (isAddValueHasRightValue(event)) {
        if (targetValueArr.length === 1) setCurrentFocus(currentFocus + targetValueArr.length);
        else if (targetValueArr.length === 2)
          setCurrentFocus(currentFocus + targetValueArr.length - 1);
      } else if (!isAddValueHasRightValue(event)) {
        if (
          targetValueArr.length === 1 ||
          (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 2)
        )
          setCurrentFocus(currentFocus + targetValueArr.length);
        else if (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 1)
          setCurrentFocus(currentFocus + targetValueArr.length - 1);
      }

      //определение положения каретки
      if (codeInputs[currentFocus + targetValueArr.length - 1]?.current.value) {
        if (
          targetValueArr.length === 1 &&
          codeInputs[id].current.selectionStart === 0 &&
          codeInputs[id].current.selectionEnd === 1
        )
          setPositionInInput(POSITION_IN_INPUT.selection);
        else if (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 1)
          setPositionInInput(POSITION_IN_INPUT.left);
        else if (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 2)
          setPositionInInput(POSITION_IN_INPUT.right);
      } else {
        if (targetValueArr.length === 1) setPositionInInput(POSITION_IN_INPUT.right);
        else if (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 1)
          setPositionInInput(POSITION_IN_INPUT.left);
        else if (targetValueArr.length === 2 && codeInputs[id].current.selectionStart === 2)
          setPositionInInput(POSITION_IN_INPUT.right);
      }
    } else {
      // если при изменении импут пустой
      setCurrentValue((currentValue) => [
        ...currentValue.slice(0, id),
        ...(id + 1 ? currentValue.slice(id + 1, currentValue.length) : []),
      ]); //сетим новое значение, удаляя символ из велью
    }
  };

  //RUN смещение фокуса в лево при нажатии backSpace button и выделение value импута
  const onBackSpacePress = (event, id) => {
    if (event.keyCode === 8) {
      setCurrentFocus((currentId) => (currentId > 0 ? currentId - 1 : currentId));
      setPositionInInput(POSITION_IN_INPUT.selection);
    }
  };

  //RUN смещение фокуса в лево при нажатии left button и выделение value импута
  const onLeftPress = (event, id) => {
    if (event.keyCode === 37 && codeInputs[id - 1]) {
      setCurrentFocus((currentId) => (currentId - 1 >= 0 ? currentId - 1 : currentId));
      setPositionInInput(POSITION_IN_INPUT.selection);
    }
  };

  //RUN смещение фокуса в право при нажатии right button и выделение value импута
  const onRightPress = (event, id) => {
    if (event.keyCode === 39 && codeInputs[id + 1]) {
      setCurrentFocus((currentId) => (currentId + 1 <= codeLength - 1 ? currentId + 1 : currentId));
      setPositionInInput(POSITION_IN_INPUT.selection);
    }
  };

  useEffect(() => {
    if (!codeInputs[currentFocus]) return;
    codeInputs[currentFocus].current.focus();
    if (positionInInput === POSITION_IN_INPUT.right) {
      const inputValueLength = codeInputs[currentFocus].current.value.length;
      codeInputs[currentFocus].current.selectionStart = inputValueLength;
      codeInputs[currentFocus].current.selectionEnd = inputValueLength;
    } else if (positionInInput === POSITION_IN_INPUT.left) {
      codeInputs[currentFocus].current.selectionStart = 0;
      codeInputs[currentFocus].current.selectionEnd = 0;
    } else if (positionInInput === POSITION_IN_INPUT.selection) {
      codeInputs[currentFocus].current.selectionStart = 0;
      codeInputs[currentFocus].current.selectionEnd = 1;
    }
  }, [positionInInput, currentFocus, codeInputs]);

  //RUN фокус на первый инпут при первом рендере
  useEffect(() => focusFirstInputOnFirstRender && codeInput1.current.focus(), []);

  return (
    <WrapperStyled className={className}>
      {codeInputs.map((inputRef, id) => (
        <ItemWrapperStyled key={id}>
          <InputStyled
            type="text"
            inputMode="numeric"
            disabled={disabled}
            ref={inputRef}
            onChange={(event) => onChangeInput(event, id)}
            value={currentValue[id] || ''}
            onKeyDown={(event) => {
              onBackSpacePress(event, id);
              onLeftPress(event, id);
              onRightPress(event, id);
            }}
            onClick={() => {
              setCurrentFocus(id);
              setPositionInInput(POSITION_IN_INPUT.selection);
            }}
            isError={!!isError}
            isValue={!!currentValue[id]}
          />
        </ItemWrapperStyled>
      ))}
    </WrapperStyled>
  );
};

CodeInput.propTypes = {
  //Длина кода ожидаемого кода (макс=8)
  codeLength: PropTypes.number,
  //Функция выполняемая по достижению ожидаемой длины кода
  onFinish: PropTypes.func,
  //Функция выполняемая на каждое изменение импута
  onChange: PropTypes.func,
  //Сделать первый импут в фокусе при первом рендере
  focusFirstInputOnFirstRender: PropTypes.bool,
  //Применение стилистики ошибки
  isError: PropTypes.bool,
  //Отключить ввод символов
  disabled: PropTypes.bool,
  //Класс, для расширения стилей
  className: PTClassNames,
};

CodeInput.defaultProps = {
  codeLength: 4,
  focusFirstInputOnFirstRender: false,
  isError: false,
  disabled: false,
  className: '',
  onChange: (f) => f,
  onFinish: (f) => f,
};

const WrapperStyled = styled.div`
  width: fit-content;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
`;

const ItemWrapperStyled = styled.div`
  position: relative;
`;

const InputStyled = memo(styled.input`
  ${getFont(WEIGHT['circe-normal'])}
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-slate-blue);
  width: 48px;
  height: 56px;
  background: none;
  -webkit-appearance: none;
  box-shadow: 0px 4px 27px #e8ecf2;
  border-radius: 9px;
  border: none;
  text-align: center;
  cursor: pointer;
  ${({ isError }) =>
    isError &&
    css`
      border: solid 1px var(--reddish-pink);
    `}
  ${({ isValue }) =>
    isValue &&
    css`
      border: solid 1px var(--dark-slate-blue);
      box-shadow: none;
    `}
  &:focus {
    border: solid 1px var(--dark-slate-blue);
    box-shadow: none;
  }
`);

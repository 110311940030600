import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';
import './Balloon.scss';

const Balloon = ({ className, children, text }) => {
  return (
    <div
      className={cx('balloon', {
        [className]: className,
      })}
      data-testid="balloon"
    >
      {children}

      {text && <div className="balloon__text">{text}</div>}

      <IconTriangle className="balloon__triangle" />
    </div>
  );
};

Balloon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
};

Balloon.defaultProps = {
  className: null,
  children: null,
  text: null,
};

export default Balloon;

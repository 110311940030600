import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import formatNumber from 'helpers/formatNumber';

import './ShowCaseItemSum.scss';

const ShowCaseItemSum = ({ sum, sumHint, className }) => {
  return (
    <div
      className={cx('show-case-item-sum', {
        [className]: className,
      })}
    >
      <p className="show-case-item-sum__label">{sum ? 'Сумма' : 'Сумма подсказки'}</p>

      {sum ? (
        <p className="show-case-item-sum__sum">
          {(sum / 100).toString().indexOf('.') > -1
            ? formatNumber(sum / 100).replace('.', ',')
            : `${formatNumber(sum / 100)},00`}
          &nbsp; ₽
        </p>
      ) : (
        <>
          {sumHint ? (
            <p className="show-case-item-sum__sum">
              {(sumHint / 100).toString().indexOf('.') > -1
                ? formatNumber(sumHint / 100).replace('.', ',')
                : `${formatNumber(sumHint / 100)},00`}
              &nbsp; ₽
            </p>
          ) : (
            <p className="show-case-item-sum__sum">—</p>
          )}
        </>
      )}
    </div>
  );
};

ShowCaseItemSum.propTypes = {
  sum: PropTypes.number,
  sumHint: PropTypes.number,
  className: PropTypes.string,
};

ShowCaseItemSum.defaultProps = {
  sum: 0,
  sumHint: 0,
  className: null,
};

export default ShowCaseItemSum;

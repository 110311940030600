import { createSlice } from '@reduxjs/toolkit';

import { reSendSmsAPIThunk } from './thunk';

const initialState = {
  reSendSmsData: null,
  reSendSmsRequest: {
    state: null,
    pending: false,
    fulfilled: false,
    rejected: false,
  },
};

const pendingAction = (state) => {
  state.reSendSmsRequest = {
    pending: true,
    fulfilled: false,
    rejected: false,
    error: null,
  };
};

const fulfilledAction = (state) => {
  state.reSendSmsRequest.pending = false;
  state.reSendSmsRequest.fulfilled = true;
};

const rejectedAction = (state, action) => {
  state.reSendSmsRequest.pending = false;
  state.reSendSmsRequest.rejected = true;
  state.reSendSmsRequest.error = action.payload;
};

const reSendSmsSlice = createSlice({
  name: 'reSendSms',
  initialState,
  reducers: {
    clearAction: () => initialState,
  },
  extraReducers: {
    [reSendSmsAPIThunk.pending]: pendingAction,
    [reSendSmsAPIThunk.fulfilled]: fulfilledAction,
    [reSendSmsAPIThunk.rejected]: rejectedAction,
  },
});

export const { clearAction } = reSendSmsSlice.actions;
const { reducer } = reSendSmsSlice;
export { reducer as reSendSmsReducer };

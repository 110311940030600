import { createAsyncThunk } from '@reduxjs/toolkit';

import { documentListGetAPI } from './api';

export const documentListGetRequestAPIThunk = createAsyncThunk(
  'documentList/documentListGetRequestAPIThunk',
  async (props, { rejectWithValue, getState }) => {
    try {
      const store = getState();
      const { page } = store.documentList.documentsData;
      const { companyName, state, partnerId, platformName } = props || {};
      return await documentListGetAPI({
        page: page + 1,
        companyName,
        state: state !== 'ANY' ? state : null,
        partnerId: partnerId && Number(partnerId),
        platformName,
      });
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

import React from 'react';
import { ReactComponent as WebMoneyLogo } from 'static/assets/web-money-logo.svg';
import './CardHeaderWebMoney.scss';

const CardHeaderWebMoney = () => (
  <div className="money-transfer-card-header-webmoney">
    <WebMoneyLogo className="money-transfer-card-header-webmoney__logo" />
  </div>
);

export default CardHeaderWebMoney;

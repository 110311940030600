import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { PTChildrenElement, PTClassNames } from 'tools/types/propTypes';

export const CARD_MODE = {
  disabled: 'disabled',
  inProgress: 'inProgress',
  actived: 'actived',
  availabled: 'availabled',
};

export const PTCCard = PropTypes.oneOf(Object.keys(CARD_MODE));

export const Card = ({ type, children, hover, className, url }) => {
  return url && hover ? (
    <CardLinkWrapper to={url}>
      <CardStyled className={className} type={type} hover={hover}>
        {children}
      </CardStyled>
    </CardLinkWrapper>
  ) : (
    <CardStyled className={className} type={type} hover={hover}>
      {children}
    </CardStyled>
  );
};

Card.propTypes = {
  type: PTCCard,
  children: PTChildrenElement.isRequired,
  hover: PropTypes.bool,
  className: PTClassNames,
  url: PropTypes.string,
};

Card.defaultProps = {
  type: CARD_MODE.availabled,
  hover: false,
  className: '',
  url: null,
};

const CardStyled = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--white);
  ${({ type }) =>
    type === CARD_MODE.disabled &&
    css`
      & > * {
        opacity: 0.4;
      }
      & > *:last-of-type {
        opacity: 1;
      }
    `}
  display: flex;
  flex-direction: column;
  ${({ type }) =>
    type === CARD_MODE.availabled
      ? css`
          box-shadow: 0px 2px 6px rgba(0, 22, 98, 0.12);
        `
      : CARD_MODE.disabled || type === CARD_MODE.actived
      ? css`
          box-shadow: 0px 0px 1px #999dab;
        `
      : css`
          box-shadow: none;
        `}
  transition: box-shadow 0.3s;
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        box-shadow: 0px 4px 20px rgba(0, 22, 98, 0.08);
      }
    `}
`;
const CardLinkWrapper = styled(NavLink)`
  display: block;
  text-decoration: unset;
`;

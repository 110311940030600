import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { createRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import { getUser } from 'modules/authLegasy/actions';
import { setAvatar, setAvatarError, deleteAvatar } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import stdAvatar from 'static/assets/std-avatar.png';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import { ReactComponent as IconTrash } from 'static/assets/icon-trash.svg';

import './AvatarUpload.scss';

export const AvatarUpload = ({ userAvatar, dispatch, className, smallAvatar }) => {
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/${userAvatar}`;
  const [avatarImg, setAvatarImg] = useState(userAvatar !== null ? avatarUrl : stdAvatar);

  const [
    isAvatarDropdownOpened,
    avatarDropdownDropEl,
    avatarDropdownToggleDrop,
    avatarDropdownCloseExplicitly,
  ] = useToggleAndOutClick();

  const dropzoneRef = createRef();

  const openUploadDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /** Устанавливаем новый автаар */
  const handleAvatarChange = (file) => {
    const fileSizeMb = file.size / 1024 / 1024;
    avatarDropdownCloseExplicitly();
    if (fileSizeMb > 8) {
      dispatch(setAvatarError(true));
    } else {
      setIsAvatarLoading(true);
      dispatch(setAvatarError(false));

      dispatch(setAvatar(file))
        .then(() => dispatch(getUser()))
        .then((res) => {
          if (res && res.data && res.data.avatar) {
            setAvatarImg(`${process.env.REACT_APP_API_URL}/settings/avatar/${res.data.avatar}`);
          } else {
            throw new Error('Ответ от сервера не содержит нужных полей');
          }
        })
        .catch(() => {
          /** Показываем сообщение об ошибке загрузки аватара на 10сек */
          dispatch(setAvatarError(true));

          /** После чего убираем его */
          setTimeout(() => {
            dispatch(setAvatarError(false));
          }, 10000);
        })
        .then(() => {
          setIsAvatarLoading(false);
        });
    }
  };

  const handleDropEvent = (acceptedFiles) => {
    handleAvatarChange(acceptedFiles[0]);
  };

  const unsetAvatar = () => {
    avatarDropdownCloseExplicitly();
    setIsAvatarLoading(true);
    dispatch(setAvatarError(false));

    dispatch(deleteAvatar())
      .then(() => dispatch(getUser()))
      .then((res) => {
        setAvatarImg(stdAvatar);
      })
      .catch(() => {
        /** Показываем сообщение об ошибке загрузки аватара на 10сек */
        dispatch(setAvatarError(true));

        /** После чего убираем его */
        setTimeout(() => {
          dispatch(setAvatarError(false));
        }, 10000);
      })
      .then(() => {
        setIsAvatarLoading(false);
      });
  };

  return (
    <div
      className={cx('avatar-upload', {
        [className]: className,
        'avatar-upload__avatar-small': smallAvatar,
        'avatar-upload__avatar-large': !smallAvatar,
      })}
      data-testid="avatar-upload"
    >
      <CSSTransition
        in={isAvatarDropdownOpened && !isAvatarLoading}
        timeout={{
          enter: 600,
          exit: 200,
        }}
        unmountOnExit
        classNames="animation-from-top-to-bottom-normal"
      >
        <div className="avatar-upload__dropdown" ref={avatarDropdownDropEl}>
          <div className="avatar-upload__dropdown-item" onClick={() => openUploadDialog()}>
            <IconEdit className="avatar-upload__dropdown-item-icon" />
            <div className="avatar-upload__dropdown-item-name">Изменить фото</div>
          </div>
          {userAvatar && (
            <div className="avatar-upload__dropdown-item" onClick={() => unsetAvatar()}>
              <IconTrash className="avatar-upload__dropdown-item-icon" />
              <div className="avatar-upload__dropdown-item-name">Удалить фото</div>
            </div>
          )}
        </div>
      </CSSTransition>

      <div className="avatar-upload__dropzone-wrap" onClick={avatarDropdownToggleDrop}>
        {!isAvatarLoading && (
          <img
            src={avatarImg}
            alt=""
            className="avatar-upload__avatar"
            data-testid="profile-settings-avatar"
          />
        )}

        {/* eslint-disable react/prop-types, react/jsx-props-no-spreading */}
        <Dropzone
          maxFiles={1}
          multiple={false}
          ref={dropzoneRef}
          noClick
          noKeyboard
          accept={'image/*'}
          onDrop={(acceptedFiles) => handleDropEvent(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            </section>
          )}
        </Dropzone>
        {/* eslint-enable react/prop-types, react/jsx-props-no-spreading */}

        {isAvatarLoading && (
          <div className="avatar-upload__avatar-loading">
            <CircleLoader className="avatar-upload__avatar-loading-icon" mainColor="#ffffff" />
          </div>
        )}
      </div>
    </div>
  );
};

AvatarUpload.propTypes = {
  className: PropTypes.string,
  userAvatar: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  smallAvatar: PropTypes.bool,
};

AvatarUpload.defaultProps = {
  userAvatar: null,
  className: null,
  smallAvatar: true,
};

const mapStateToProps = (state) => ({
  userAvatar: state.auth.user && state.auth.user.avatar ? state.auth.user.avatar : null,
});

export default connect(mapStateToProps)(AvatarUpload);

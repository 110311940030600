import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { CSSTransition } from 'react-transition-group';

import HintBankCardDetails from 'modules/profileLegasy/components/Hint/HintBankCardDetails';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';

import { HINT_TIMEOUTS } from 'constants/index';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

// Styles
import './TaxInfoCard.scss';

const TaxInfoCard = ({
  sumToPay,
  isTaxInfoLoading,
  isMultipleTaxes,
  setOneTaxChangedSum,
  oneTaxChangedSum,
}) => {
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [inputWidth, setInputWidth] = useState(0);

  const handleSumChange = (values) => {
    const { floatValue, value } = values;

    if (floatValue < 1) {
      setOneTaxChangedSum(1);
    } else if (floatValue > 15000) {
      setOneTaxChangedSum(15000);
    } else if (value === '') {
      setOneTaxChangedSum('');
    } else {
      setOneTaxChangedSum(value);
    }
  };

  return (
    <div className="tax-info-card" data-testid="tax-info-card">
      <p className="tax-info-card__title">Итого к оплате:</p>

      {isMultipleTaxes ? (
        <TaxesAmount
          className="tax-info-card__sum"
          amount={sumToPay}
          isLoading={isTaxInfoLoading}
        />
      ) : (
        <div className="tax-info-card__sum-wrap">
          <NumberFormat
            className="tax-info-card__sum-input"
            value={oneTaxChangedSum}
            thousandSeparator=" "
            allowNegative={false}
            allowEmptyFormatting={false}
            decimalScale={2}
            isNumericString
            allowedDecimalSeparators={[',', '.']}
            type="tel"
            getInputRef={(el) => {
              if (el) {
                setInputWidth(el.value.length * 19 + 16);
              }
            }}
            suffix=" ₽"
            style={{ width: inputWidth }}
            onValueChange={(values) => handleSumChange(values)}
            isAllowed={(values) => {
              const { value } = values;

              if (value.charAt(0) === '0') {
                return value.charAt(1) === '.';
              }

              return true;
            }}
            data-testid="tax-info-card-sum-input"
          />

          <IconEdit className="tax-info-card__sum-icon" />
        </div>
      )}

      <button type="button" className="tax-info-card__bank-details-btn" onClick={toggleDrop}>
        Реквизиты ФНС
      </button>

      <CSSTransition in={isDropOpen} timeout={HINT_TIMEOUTS} unmountOnExit classNames="hint-auth">
        <HintAuth ref={dropEl} onCloseBtnClick={closeExplicitly} title="Реквизиты ФНС">
          <HintBankCardDetails />
        </HintAuth>
      </CSSTransition>
    </div>
  );
};

TaxInfoCard.propTypes = {
  sumToPay: PropTypes.number,
  isTaxInfoLoading: PropTypes.bool,
  isMultipleTaxes: PropTypes.bool,
  setOneTaxChangedSum: PropTypes.func,
  oneTaxChangedSum: PropTypes.number,
};

TaxInfoCard.defaultProps = {
  sumToPay: 0,
  isTaxInfoLoading: false,
  isMultipleTaxes: false,
  setOneTaxChangedSum: () => {},
  oneTaxChangedSum: 0,
};

export default TaxInfoCard;

import { createSelector } from '@reduxjs/toolkit';
import useWindowSize from '@rehooks/window-size';
import addDays from 'date-fns/addDays';
import { formatDateHuman, setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from 'helpers';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

import {
  clearPartnerPaymentList,
  getPartnerPaymentList,
  hideCheck,
  showCheck,
  showPopupCheck,
} from '../../actions';
import StubPayments from '../StubPayments';
import Tip from '../Tip';
import styles from './PartnerPaymentsTable.module.scss';

const PartnerPaymentsTable = () => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const { partnershipId } = useParams();

  const paymentList = useSelector(({ partners }) => partners.payments.items);
  const hasMorePayments = useSelector(({ partners }) => partners.payments.hasMore, shallowEqual);
  const isGetPartnerPaymentListFetching = useSelector(
    ({ partners }) => partners.getPartnerPaymentList.fetching,
    shallowEqual,
  );
  const { startDate, endDate } = useSelector(
    createSelector(
      (store) => store.partners.paymentsRange,
      // eslint-disable-next-line
      ({ startDate, endDate }) => ({
        startDate: Number(startDate || addDays(setHoursAndMinutesToZero(new Date()), -6)),
        endDate: Number(endDate || setHoursAndMinutesTheEndOfDay(new Date())),
      }),
    ),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(clearPartnerPaymentList());
    dispatch(getPartnerPaymentList(partnershipId));
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <div className={styles.partnerPaymentsTable}>
      <header className={styles.partnerPaymentsTableHeader}>
        <div>Дата</div>
        <div>Услуга</div>
        <div>Налог</div>
        <div>Сумма</div>
      </header>
      <div className={styles.partnerPaymentsTableContent}>
        {!isGetPartnerPaymentListFetching && paymentList.length === 0 && <StubPayments />}
        {paymentList.length > 0 && (
          <InfiniteScroll
            hasMore={!isGetPartnerPaymentListFetching && hasMorePayments}
            pageStart={1}
            getScrollParent={() => document.getElementById('partner-scrollable-area')}
            loadMore={(page) => dispatch(getPartnerPaymentList(partnershipId, page))}
            useWindow={false}
          >
            {paymentList.map((payment) => (
              <div
                key={payment.id}
                className={styles.partnerPaymentsTableRow}
                onClick={() => {
                  if (!payment.checkAvailability) {
                    return;
                  }

                  if (windowSize.innerWidth <= 1199) {
                    dispatch(hideCheck());
                    dispatch(showPopupCheck(payment.id));
                  } else {
                    dispatch(showCheck(payment.id));
                  }
                }}
              >
                <div className={styles.partnerPaymentsTableRowDate}>
                  {formatDateHuman(new Date(payment.operationTime))}
                </div>
                <div className={styles.partnerPaymentsTableRowService}>{payment.serviceName}</div>
                <div className={styles.partnerPaymentsTableRowTax}>
                  <div className={styles.partnerPaymentsTableRowTaxDesktop}>
                    {payment.paidByPartner && (
                      <div className={styles.partnerPaymentsTableRowTaxDesktopIcon}>
                        <IconCheck />
                        <Tip className={styles.partnerPaymentsTableRowTaxDesktopIconTip} isToTop>
                          Оплату вашего НПД взял на себя
                          <br /> партнер, вам платить не нужно.
                        </Tip>
                      </div>
                    )}
                    {!payment.paidByPartner && (
                      <div className={styles.partnerPaymentsTableRowTaxDesktopSum}>
                        {formatNumber(payment.tax)} ₽
                      </div>
                    )}
                  </div>
                  <div className={styles.partnerPaymentsTableRowTaxMobile}>
                    <div className={styles.partnerPaymentsTableRowTaxMobileSum}>
                      <span>НПД</span>
                      <span>
                        {payment.paidByPartner
                          ? 'Оплатил партнер'
                          : `${formatNumber(payment.tax)}₽`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.partnerPaymentsTableRowSum}>
                  {formatNumber(payment.totalAmount)} ₽
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
        {isGetPartnerPaymentListFetching && (
          <CircleLoader className={styles.partnerPaymentsTableLoader} />
        )}
      </div>
    </div>
  );
};

export default PartnerPaymentsTable;

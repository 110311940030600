import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer from 'store/rootReducer';

/** Создаем логгер для девелопмента */
const loggerMiddleware = createLogger({
  duration: true,
  diff: true,
  collapsed: true,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    /** Создаем список с мидлвар по умолчанию от Redux Toolkit */
    const middlewares = [...getDefaultMiddleware()];
    /**  Добавляем в список логгер для режима разработки */
    if (process.env.NODE_ENV === 'development') middlewares.push(loggerMiddleware);
    return middlewares;
  },
});

export default store;

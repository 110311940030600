import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';
import PopupHeader from './PopupHeader';
import PopupFooter from './PopupFooter';
import styles from './Popup.module.scss';

const modalRootElement = document.body;

const Popup = ({ className, children, onClose, redirectHref, isMobileOnly, overlay: Overlay }) => {
  const [isRendered, setIsRendered] = React.useState(false);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  return createPortal(
    <div className={cx(styles.popup, className)}>
      <div className={styles.popupContainer}>
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <React.Fragment>
            <div className={styles.popupWrapper}>
              <PopupHeader
                onClose={onClose}
                href={redirectHref}
                className={isMobileOnly && styles.popupMobileHeader}
              />
              <div className={styles.popupContent}>{children}</div>
            </div>

            <PopupFooter />
          </React.Fragment>
        </CSSTransition>
      </div>

      {Overlay && <Overlay />}
    </div>,
    modalRootElement,
  );
};

Popup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  redirectHref: PropTypes.string,
  isMobileOnly: PropTypes.bool,
  overlay: PropTypes.elementType,
};

export default Popup;

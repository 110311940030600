import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import phone7 from 'static/assets/phone-7.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepWebVersion = ({
  returnBack,
  setStepGoToWebVersionLk,
  setStepGoToWebVersionGosuslugi,
}) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">
            Регистрация <br /> через веб-версию
          </h1>

          {windowSize.innerWidth > 760 && (
            <>
              <img src={phone7} alt="" className="manual-page__phone manual-page__phone_7" />

              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
              <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_3" />
            </>
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <div className="manual-page__text">
            Перейдите в {'  '}
            <a
              href="https://lknpd.nalog.ru/auth/login"
              rel="noopener noreferrer"
              target="_blank"
              className="manual-page__text-link"
            >
              кабинет налогоплательщика НПД
            </a>
            {'  '} на сайте ФНС. Это можно сделать с телефона, или с компьютера. Пройти регистрацию
            можно либо с помощью личного кабинета налогоплательщика (вам понадобится ввести ИНН и
            пароль от вашего ЛК), либо по учетной записи Госуслуг при ее наличии.
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Через ЛК налогоплательщика"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWebVersionLk}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Через портал Госуслуг"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWebVersionGosuslugi}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepWebVersion.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToWebVersionLk: PropTypes.func.isRequired,
  setStepGoToWebVersionGosuslugi: PropTypes.func.isRequired,
};

export default ManualPageStepWebVersion;

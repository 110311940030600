export const GET_WALLET_CLOSING_SMS_CODE_FETCHING = 'GET_WALLET_CLOSING_SMS_CODE_FETCHING';
export const GET_WALLET_CLOSING_SMS_CODE_SUCCESS = 'GET_WALLET_CLOSING_SMS_CODE_SUCCESS';
export const GET_WALLET_CLOSING_SMS_CODE_FAIL = 'GET_WALLET_CLOSING_SMS_CODE_FAIL';

export const RESEND_WALLET_CLOSING_SMS_CODE_FETCHING = 'RESEND_WALLET_CLOSING_SMS_CODE_FETCHING';
export const RESEND_WALLET_CLOSING_SMS_CODE_SUCCESS = 'RESEND_WALLET_CLOSING_SMS_CODE_SUCCESS';
export const RESEND_WALLET_CLOSING_SMS_CODE_FAIL = 'RESEND_WALLET_CLOSING_SMS_CODE_FAIL';

export const CLOSE_WALLET_FETCHING = 'CLOSE_WALLET_FETCHING';
export const CLOSE_WALLET_SUCCESS = 'CLOSE_WALLET_SUCCESS';
export const CLOSE_WALLET_FAIL = 'CLOSE_WALLET_FAIL';

export const SHOW_WALLET_CLOSING_BALANCE_POPUP = 'SHOW_WALLET_CLOSING_BALANCE_POPUP';
export const HIDE_WALLET_CLOSING_BALANCE_POPUP = 'HIDE_WALLET_CLOSING_BALANCE_POPUP';

export const SHOW_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP =
  'SHOW_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP';
export const HIDE_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP =
  'HIDE_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP';

export const SHOW_WALLET_CLOSING_CONFIRMATION_POPUP = 'SHOW_WALLET_CLOSING_CONFIRMATION_POPUP';
export const HIDE_WALLET_CLOSING_CONFIRMATION_POPUP = 'HIDE_WALLET_CLOSING_CONFIRMATION_POPUP';

export const SHOW_WALLET_CLOSING_SUCCESS_POPUP = 'SHOW_WALLET_CLOSING_SUCCESS_POPUP';
export const HIDE_WALLET_CLOSING_SUCCESS_POPUP = 'HIDE_WALLET_CLOSING_SUCCESS_POPUP';

export const SHOW_WALLET_CLOSING_ERROR_POPUP = 'SHOW_WALLET_CLOSING_ERROR_POPUP';
export const HIDE_WALLET_CLOSING_ERROR_POPUP = 'HIDE_WALLET_CLOSING_ERROR_POPUP';

export const RESET_WALLET_CLOSING_STATE = 'RESET_WALLET_CLOSING_STATE';

import PropTypes from 'prop-types';
import React from 'react';

import { LinkOfBlog } from '../molecules';

export const SupportLinkOfBlog = ({ text }) => <LinkOfBlog className="js-help">{text}</LinkOfBlog>;

SupportLinkOfBlog.propTypes = {
  text: PropTypes.string,
};

SupportLinkOfBlog.defaultProps = {
  text: 'Служба поддержки',
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MoneyRequestContainer from 'modules/profileLegasy/components/MoneyRequestContainer/MoneyRequestContainer';

/**
 * ШАБЛОН: ОПЛАТА ЗАПРОСА ДЕНЕГ
 */
export const PayItTemplate = () => (
  <Switch>
    <Route
      exact
      path={[
        '/pay-it/:requestId', // ССЫЛКА ОПЛАТЫ ЗАПРОСА ДЕНЕГ
        '/pay-it/money/:operationId/wait', // ПРОВЕРКА СТАТУСА ПЕРЕВОДА
        '/pay-it/money/:operationId/success', // ЭКРАН УСПЕШНОГО ПЕРЕВОДА
        '/pay-it/money/error', // ЭКРАН ОШИБКИ ПЕРЕВОДА
        '/pay-it/money/:requestId/error', // ЭКРАН ОШИБКИ ПЕРЕВОДА
      ]}
    >
      <MoneyRequestContainer />
    </Route>
  </Switch>
);

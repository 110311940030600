import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import CodeInput from 'modules/shared/components/CodeInput/CodeInput';
import Popup from 'modules/shared/components/Popup';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import { changeLookOfPhone } from 'helpers/index';
import maskPhoneNumber from '../../helpers/maskPhoneNumber';
import { getWalletClosingSmsCode, closeWallet, resendWalletClosingSmsCode } from '../../actions';
import styles from './PopupConfirmation.module.scss';
import { InputUI } from '../../../../ui';

const PopupConfirmation = ({
  dispatch,
  isGetWalletClosingSmsCodeFetching,
  isGetWalletClosingSmsCodeSuccess,
  isResendWalletClosingSmsCodeFetching,
  isResendWalletClosingSmsCodeSuccess,
  isCloseWalletFetching,
  isWalletClosingWrongCode,
  isWalletClosingSmsLimit,
  isWalletClosingCodeExpiration,
  smsRequest,
  phoneNumber,
}) => {
  React.useEffect(() => {
    dispatch(getWalletClosingSmsCode());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [timer, setTimer] = React.useState(0);
  const isTimerVisible = timer > 0;

  /** Кол-во попыток ввода. */
  const [attempts, setAttempts] = React.useState(0);
  /** Достигнут максимум попыток ввода. */
  const hasAttemptsLimit = attempts <= 0;

  /** Уменьшаем таймер на единицу каждую секунду, пока значение не будет равно нулю. */
  React.useEffect(() => {
    if (!isTimerVisible) {
      return;
    }

    /** Если при первой загрузке кол-во попыток === 0, то не запускать таймер. */
    if (hasAttemptsLimit) {
      return;
    }

    const timerId = setTimeout(() => {
      setTimer((value) => value - 1);
    }, 1000);

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, attempts]);

  /** Является ли код валидным. */
  const [isCodeValid, setIsCodeValid] = React.useState(true);
  /** Можно ли вводить код. */
  const [isCodeDisabled, setIsCodeDisabled] = React.useState(false);
  /** Видна ли кнопка для повторной отправки кода. */
  const [isResendCodeButtonVisible, setIsResendCodeButtonVisible] = React.useState(false);
  /** Видна ли кнопка для отправки нового кода. */
  // const [isGetCodeButtonVisible, setIsGetCodeButtonVisible] = React.useState(false);

  /** Сообщение о том, что код неправильный. */
  const [isMessageInvalidCodeVisible, setIsMessageInvalidCodeVisible] = React.useState(false);
  /** Сообщение о том, что код просрочен. */
  const [isMessageExpiredCodeVisible, setIsMessageExpiredCodeVisible] = React.useState(false);
  /** Сообщение о том, что достигнут лимит на кол-во отправляемых смс-сообщений. */
  const [isMessageCodeLimitVisible, setIsMessageCodeLimitVisible] = React.useState(false);

  React.useEffect(() => {
    if (isGetWalletClosingSmsCodeSuccess) {
      setTimer(smsRequest.resendTime);
      setAttempts(smsRequest.attempts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWalletClosingSmsCodeSuccess]);

  React.useEffect(() => {
    if (isResendWalletClosingSmsCodeSuccess) {
      setTimer(smsRequest.resendTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendWalletClosingSmsCodeSuccess]);

  React.useEffect(() => {
    /** Если отправленный код оказался неверным. */
    if (isWalletClosingWrongCode) {
      /** Уменьшаем кол-во доступных попыток на единицу. */
      setAttempts((value) => value - 1);
      /** Показываем сообщение о том, что код неправильный. */
      setIsMessageInvalidCodeVisible(true);
    }

    /** Если отправленный код оказался просрочен. */
    if (isWalletClosingCodeExpiration) {
      /** Показываем сообщение о том, что код просрочен.*/
      setIsMessageExpiredCodeVisible(true);
      /** Скрываем кнопку для повторной отправки кода */
      setIsResendCodeButtonVisible(false);
      /** Запрещаем вводить код. */
      setIsCodeDisabled(true);
    }

    /** Если достигнут лимит на кол-во отправляемых сообщений. */
    if (isWalletClosingSmsLimit) {
      /** Показываем сообщение о том, что достигнут лимит на кол-во отправляемых смс-сообщений. */
      setIsMessageCodeLimitVisible(true);
      /** Запрещаем вводить код. */
      setIsCodeDisabled(true);
    }

    if (isWalletClosingWrongCode || isWalletClosingCodeExpiration || isWalletClosingSmsLimit) {
      /** Зачищаем код. */
      resetCode();
      /** Помечаем код как невалидный. */
      setIsCodeValid(false);
    }
  }, [isWalletClosingWrongCode, isWalletClosingCodeExpiration, isWalletClosingSmsLimit]);

  /** Скрыть/показать кнопку для повторной отправки кода. */
  React.useEffect(() => {
    if (hasAttemptsLimit) {
      setIsResendCodeButtonVisible(false);
      setIsCodeDisabled(true);
      return;
    }

    if (!isTimerVisible) {
      setIsResendCodeButtonVisible(true);
    }

    setIsCodeDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempts, timer]);

  const [code, setCode] = React.useState('');

  function onCodeChange(code) {
    if (!isCodeValid && code.length > 0) {
      /** Делаем код валидным. */
      setIsCodeValid(true);
      /** Скрываем сообщение о том, что код неправильный. */
      setIsMessageInvalidCodeVisible(false);
    }

    setCode(code);
  }

  function onCodeEnter(code) {
    dispatch(closeWallet(code));
  }

  function resetCode() {
    setCode('');
  }

  function onSmsCodeSend() {
    if (!isCodeValid) {
      setIsCodeValid(true);
    }

    /** Скрываем кнопку после нажатия на нее. */
    if (isResendCodeButtonVisible) {
      setIsResendCodeButtonVisible(false);
    }

    /** Скрываем сообщение о том, что код неправильный.  */
    if (isMessageInvalidCodeVisible) {
      setIsMessageInvalidCodeVisible(false);
    }

    dispatch(resendWalletClosingSmsCode());
  }

  return (
    <Popup
      redirectHref="/lk/profile-settings"
      overlay={() => (
        <React.Fragment>
          {(isGetWalletClosingSmsCodeFetching ||
            isCloseWalletFetching ||
            isResendWalletClosingSmsCodeFetching) && <Loader inverted />}
        </React.Fragment>
      )}
    >
      <h1 className={styles.popupConfirmationTitle}>Подтвердите удаление Кошелька</h1>
      <div className={styles.popupConfirmationContent}>
        <p className={styles.popupConfirmationMessage1}>
          Код подтверждения отправлен на номер
          <br />
          {phoneNumber}
        </p>
        {/* <CodeInput */}
        {/*   className={styles.popupConfirmationCode} */}
        {/*   length={smsRequest.length} */}
        {/*   isValid={isCodeValid} */}
        {/*   isDisabled={isCodeDisabled} */}
        {/*   value={code} */}
        {/*   onChange={onCodeChange} */}
        {/*   onCodeEnter={onCodeEnter} */}
        {/* /> */}

        <InputUI.Code
          codeLength={smsRequest.length || 4}
          onChange={onCodeChange}
          onFinish={onCodeEnter}
          disabled={isCodeDisabled}
          isError={!isCodeValid}
          value={code}
          cleanOfError
          focusFirstInputOnFirstRender
          className={styles.popupConfirmationCode}
        />
        <div className={styles.popupConfirmationMessages}>
          {/* Сообщение о том, что код неправильный. */}
          {isMessageInvalidCodeVisible && !hasAttemptsLimit && (
            <p className={styles.popupConfirmationMessage3}>Неверный код</p>
          )}
          {/* /Сообщение о том, что код неправильный. */}

          {/* Сообщение о том, что код просрочен. */}
          {isMessageExpiredCodeVisible && (
            <p className={styles.popupConfirmationMessage3}>Код просрочен. Попробуйте позже.</p>
          )}
          {/* /Сообщение о том, что код просрочен. */}

          {/* Сообщение о том, что достигнут лимит на кол-во отправляемых смс-сообщений. */}
          {isMessageCodeLimitVisible && (
            <p className={styles.popupConfirmationMessage3}>
              Достигнут лимит на количество отправляемых сообщений. Попробуйте позже.
            </p>
          )}
          {/* /Сообщение о том, что достигнут лимит на кол-во отправляемых смс-сообщений. */}

          {/* Обратный таймер. */}
          {isTimerVisible &&
            !isMessageExpiredCodeVisible &&
            !isMessageCodeLimitVisible &&
            !hasAttemptsLimit && (
              <p className={styles.popupConfirmationMessage2}>
                Получить новый можно через {timer} сек
              </p>
            )}
          {/* /Обратный таймер. */}

          {/* Кнопка для повторной отправки кода. */}
          {!isTimerVisible && isResendCodeButtonVisible && (
            <button className={styles.popupConfirmationSendSmsButton} onClick={onSmsCodeSend}>
              Отправить еще раз
            </button>
          )}
          {/* /Кнопка для повторной отправки кода. */}

          {/* Сообщение о превышеннии кол-ва попыток ввода кода */}
          {isGetWalletClosingSmsCodeSuccess && hasAttemptsLimit && (
            <React.Fragment>
              <p className={styles.popupConfirmationMessage3}>
                Превышено количество попыток ввода кода.
                <br />
                Попробуйте позже
              </p>
            </React.Fragment>
          )}
          {/* /Сообщение о превышеннии кол-ва попыток ввода кода */}
        </div>
      </div>
    </Popup>
  );
};

PopupConfirmation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isGetWalletClosingSmsCodeFetching: PropTypes.bool.isRequired,
  isGetWalletClosingSmsCodeSuccess: PropTypes.bool.isRequired,
  isResendWalletClosingSmsCodeFetching: PropTypes.bool.isRequired,
  isResendWalletClosingSmsCodeSuccess: PropTypes.bool.isRequired,
  isResendWalletClosingSmsCodeFail: PropTypes.bool.isRequired,
  isCloseWalletFetching: PropTypes.bool.isRequired,
  isCloseWalletSuccess: PropTypes.bool.isRequired,
  isCloseWalletFail: PropTypes.bool.isRequired,
  isWalletClosingWrongCode: PropTypes.bool.isRequired,
  isWalletClosingAttemptsLimit: PropTypes.bool.isRequired,
  isWalletClosingSmsLimit: PropTypes.bool.isRequired,
  isWalletClosingCodeExpiration: PropTypes.bool.isRequired,
  smsRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    resendTime: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    attempts: PropTypes.number.isRequired,
  }).isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isGetWalletClosingSmsCodeFetching: state.walletClosing.getWalletClosingSmsCode.fetching,
  isGetWalletClosingSmsCodeSuccess: state.walletClosing.getWalletClosingSmsCode.success,
  isResendWalletClosingSmsCodeFetching: state.walletClosing.resendWalletClosingSmsCode.fetching,
  isResendWalletClosingSmsCodeSuccess: state.walletClosing.resendWalletClosingSmsCode.success,
  isResendWalletClosingSmsCodeFail: state.walletClosing.resendWalletClosingSmsCode.fail,
  isCloseWalletFetching: state.walletClosing.closeWallet.fetching,
  isCloseWalletSuccess: state.walletClosing.closeWallet.success,
  isCloseWalletFail: state.walletClosing.closeWallet.fail,
  isWalletClosingWrongCode: state.walletClosing.isWalletClosingWrongCode,
  isWalletClosingAttemptsLimit: state.walletClosing.isWalletClosingAttemptsLimit,
  isWalletClosingSmsLimit: state.walletClosing.isWalletClosingSmsLimit,
  isWalletClosingCodeExpiration: state.walletClosing.isWalletClosingCodeExpiration,
  smsRequest: state.walletClosing.smsRequest,
  phoneNumber: changeLookOfPhone(maskPhoneNumber(state.auth.user.login)),
});

export default connect(mapStateToProps)(PopupConfirmation);

import React, { useEffect, useState } from 'react';
import useWindowSize from '@rehooks/window-size';
import { useSpring, animated } from 'react-spring';

// Styles
import './CardsFloatingDetails.scss';

// Icons
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3.png';
import shape4 from 'static/assets/shape-4.png';

const position = {
  ruble: {
    top: 33,
    left: 79,
  },
  rubleSmall: {
    top: 87,
    left: 11,
  },
  sphere1: {
    top: 8,
    left: 10,
  },
  sphere2: {
    top: 110,
    left: 38,
  },
  sphere3: {
    top: 62,
    left: 86,
  },
};

const CardsFloatingDetails = () => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    const onMouseMove = ({ clientX: x, clientY: y }) => {
      setRuble({ xy: calc(x, y) });
      setRubleSmall({ xy: calc(x, y) });
      setSphere1({ xy: calc(x, y) });
      setSphere2({ xy: calc(x, y) });
      setSphere3({ xy: calc(x, y) });
    };

    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    setRuble({ left: 150, deg: 0 });
    setRubleSmall({ left: 150 });
    setSphere1({ left: -20 });
    setSphere2({ left: 150 });
    setSphere3({ left: 150 });

    if (isRendered) {
      setRubleSmall({ left: position.rubleSmall.left, top: position.rubleSmall.top });
      setSphere1({ left: position.sphere1.left, top: position.sphere1.top });
      setSphere2({ left: position.sphere2.left, top: position.sphere2.top });
      setSphere3({ left: position.sphere3.left, top: position.sphere3.top });
      setRuble({ left: position.ruble.left, top: position.ruble.top, deg: 0 });
    }

    /* eslint-disable */
  }, [isRendered]);
  /* eslint-enable */

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans1 = (x, y) => `translate3d(${x / 10}px, ${y / 10}px, 0)`;
  const trans2 = (x, y) => `translate3d(${x / 8 + 35}px, ${y / 8 - 230}px, 0)`;
  const trans3 = (x, y) => `translate3d(${x / 6 - 250}px, ${y / 6 - 200}px, 0)`;
  const trans4 = (x, y) => `translate3d(${x / 3.5}px, ${y / 3.5}px, 0)`;
  const trans5 = (x, y) => `translate3d(${x / 10}px, ${y / 10}px, 0)`;
  const percent = (x) => `${x}%`;
  const rotate = (deg) => `rotate(${deg}deg)`;

  /** Ruble small */
  const [springPropsRubleSmall, setRubleSmall] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      left: 150, // position.rubleSmall.left, // position.rubleSmall.right,
      top: position.rubleSmall.top, // position.rubleSmall.top,
    },
    to: { xy: [0, 0], opacity: 1 },
    config: { mass: 10, tension: 180, friction: 140 },
  }));

  /** Sphere 1 */
  const [springPropsSphere1, setSphere1] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      left: 150, // position.sphere1.left,
      top: position.sphere1.top,
    },
    to: { xy: [0, 0], opacity: 1 },
    config: { mass: 10, tension: 180, friction: 140 },
  }));

  /** Sphere 2 */
  const [springPropsSphere2, setSphere2] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      left: 100, // position.sphere2.left,
      top: position.sphere2.top,
    },
    to: { xy: [0, 0], opacity: 1 },
    config: { mass: 10, tension: 200, friction: 140 },
  }));

  /** Sphere 3 */
  const [springPropsSphere3, setSphere3] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      left: 150, // position.sphere3.left,
      top: position.sphere3.top,
    },
    to: { xy: [0, 0], opacity: 1 },
    config: { mass: 10, tension: 200, friction: 140 },
  }));

  /** Ruble */
  const [springPropsRuble, setRuble] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      left: 150, // position.ruble.left,
      top: position.ruble.top,
      deg: 0,
    },
    to: { xy: [0, 0], opacity: 1 },
    config: { mass: 10, tension: 200, friction: 140 },
  }));

  return (
    <div className="cards-floating-details">
      <animated.img
        className="cards-floating-details__shape cards-floating-details__shape_1"
        src={shape1}
        style={{
          transform: springPropsSphere1.xy.interpolate(trans1),
          left: springPropsSphere1.left.interpolate(percent),
          top: springPropsSphere1.top.interpolate(percent),
          opacity: springPropsSphere1.opacity,
        }}
      />

      {windowSize.innerWidth > 1194 && (
        <animated.img
          className="cards-floating-details__shape cards-floating-details__shape_2"
          src={shape4}
          style={{
            transform: springPropsRubleSmall.xy.interpolate(trans2),
            left: springPropsRubleSmall.left.interpolate(percent),
            top: springPropsRubleSmall.top.interpolate(percent),
            opacity: springPropsRubleSmall.opacity,
          }}
        />
      )}

      {windowSize.innerWidth > 1194 && (
        <animated.img
          className="cards-floating-details__shape cards-floating-details__shape_3"
          src={shape1}
          style={{
            transform: springPropsSphere2.xy.interpolate(trans3),
            left: springPropsSphere2.left.interpolate(percent),
            top: springPropsSphere2.top.interpolate(percent),
            opacity: springPropsSphere2.opacity,
          }}
        />
      )}

      {windowSize.innerWidth > 875 && (
        <animated.img
          className="cards-floating-details__shape cards-floating-details__shape_4"
          src={shape1}
          style={{
            transform: springPropsSphere3.xy.interpolate(trans4),
            left: springPropsSphere3.left.interpolate(percent),
            top: springPropsSphere3.top.interpolate(percent),
            opacity: springPropsSphere3.opacity,
          }}
        />
      )}

      {windowSize.innerWidth > 875 && (
        <animated.div
          className="cards-floating-details__shape cards-floating-details__shape_5"
          style={{
            transform: springPropsRuble.xy.interpolate(trans5),
            top: springPropsRuble.top.interpolate(percent),
            left: springPropsRuble.left.interpolate(percent),
            opacity: springPropsRuble.opacity,
          }}
        >
          <animated.img
            className="cards-floating-details__shape cards-floating-details__shape_5"
            src={shape3}
            style={{
              transform: springPropsRuble.deg.interpolate(rotate),
              opacity: springPropsRuble.opacity,
            }}
          />
        </animated.div>
      )}
    </div>
  );
};

export default CardsFloatingDetails;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { ru } from 'date-fns/esm/locale';
import { validateEmail } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  EmailShareButton,
  //TODO?: функционал был выключен в рамках задачи SWPS-192
  //TODO?: функционал был выключен в в связи блокировкой на территории РФ
  // FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import useClipboard from 'react-use-clipboard';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import { getInvoiceContext } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import DatePickerCustomInput from 'modules/shared/components/DatePickerCustomInput/DatePickerCustomInput';

import headerBg from 'static/assets/card-header-bg.jpg';
import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';
import { ReactComponent as IconEnvelope } from 'static/assets/icon-envelope.svg';
//TODO?: функционал был выключен в рамках задачи SWPS-192
//TODO?: функционал был выключен в в связи блокировкой на территории РФ
// import { ReactComponent as IconFbShare } from 'static/assets/icon-fb-share.svg';
import { ReactComponent as IconLock } from 'static/assets/icon-lock-closed.svg';

import { ReactComponent as IconShare } from 'static/assets/icon-share.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

import { ReactComponent as IconShareVk } from 'static/assets/icon-share-vk.svg';
import { ReactComponent as IconShareWa } from 'static/assets/icon-share-wa.svg';
import { ReactComponent as IconShareOk } from 'static/assets/icon-share-ok.svg';
import { ReactComponent as IconShareTg } from 'static/assets/icon-share-tg.svg';

import './BillCreationCard.scss';
import { MONTHSRU, WEEK_DAYS_FIRST_SUNDAY_RU } from '../../../../constants';
import { getUserLimits } from '../../selectors';
import { calculateCommission, findMinSum } from '../../../../tools/utils/commission-calculation';
import { maxSumDefault, minSumDefault } from '../../../../tools/constants';

const LINK_TAB = 'LINK_TAB';
const EMAIL_TAB = 'EMAIL_TAB';

const BillCreationCard = ({
  className,
  handleSubmitClick,
  isSucceed,
  isFail,
  isLoading,
  link,
  dispatch,
  isFailedOverLimit,
  currentTabId,
  linkTabCLick,
  emailTabCLick,
  userLimits,
}) => {
  const windowSize = useWindowSize();

  const [date, setDate] = useState(null);
  const [isDateValid, setIsDateValid] = useState(false);

  const [minSum, setMinSum] = useState(minSumDefault);
  const [maxSum, setMaxSum] = useState(maxSumDefault);
  // eslint-disable-next-line no-unused-vars
  const [commission, setCommission] = useState(null);

  const [sum, setSum] = useState('');
  const [isSumValid, setIsSumValid] = useState(false);

  const [commentary, setCommentary] = useState('');
  const [isCommentaryValid, setIsCommentaryValid] = useState(false);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isCopied, setCopied] = useClipboard(link);

  /** Получение контекста запроса, диапазон суммы для выставления счета */
  useEffect(() => {
    dispatch(getInvoiceContext())
      .then((res) => {
        if (res && res.data) {
          setCommission(res.data.commission || null);
          if (res.data.hasOwnProperty('minSum') && res.data.hasOwnProperty('maxSum')) {
            setMinSum(findMinSum([res.data.minSum, res.data.maxSum]));
            setMaxSum(
              calculateCommission({
                sum: findMinSum([userLimits.balanceLimit, res.data.maxSum]),
                commission: res.data.commission || null,
                inverted: true,
              }),
            );
          }
        }
      })
      .catch(() => {});
  }, [userLimits, dispatch]);

  /** States for datePicker */
  registerLocale('ru', {
    ...ru,
    localize: {
      ...ru.localize,
      month: (n) => MONTHSRU[n],
      day: (n) => WEEK_DAYS_FIRST_SUNDAY_RU[n],
    },
  });

  const circleLoaderValue = 0;

  /** Check if sum input is valid */
  useEffect(() => {
    setIsSumValid(false);
    if (!minSum && !maxSum && !Number(sum)) {
      setIsSumValid(false);
    } else if (sum && +sum >= minSum / 100 && +sum <= maxSum / 100) {
      setIsSumValid(true);
    }
  }, [sum, maxSum, minSum]);

  const handlePurposeFieldChange = (event) => {
    setCommentary(event.target.value);
  };

  /** Check if purpose input is valid */
  useEffect(() => {
    setIsCommentaryValid(false);

    if (commentary && commentary.length <= 100 && commentary.length >= 1) {
      setIsCommentaryValid(true);
    }
  }, [commentary]);

  const handleEmailFieldChange = (event) => {
    setEmail(event.target.value);
  };

  /** Check if email input is valid */
  useEffect(() => {
    setIsEmailValid(false);

    if (email && validateEmail(email)) {
      setIsEmailValid(true);
    }
  }, [email, currentTabId]);

  /** Check if date is selected */
  useEffect(() => {
    setIsDateValid(Boolean(date));
  }, [date]);

  const isFieldsCompleted = () => {
    if (currentTabId === LINK_TAB && isCommentaryValid && isSumValid && isDateValid) {
      return true;
    }

    if (
      currentTabId === EMAIL_TAB &&
      isCommentaryValid &&
      isSumValid &&
      isEmailValid &&
      isDateValid
    ) {
      return true;
    }

    return false;
  };

  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Истории со страницы запроса денег, чтобы открыть там нужную вкладку
   * */
  const handleRequestsHistoryLink = () => {
    window.localStorage.setItem('historyTab', '1');
  };

  return (
    <div
      className={cx('bill-creation-card', {
        [className]: className,
      })}
    >
      {windowSize.innerWidth > 980 && (
        <div className="bill-creation-card__header" style={{ backgroundImage: `url(${headerBg})` }}>
          {!isFail && (
            <>
              <div className="bill-creation-card__header-icon-wrap">
                <IconBag className="bill-creation-card__header-icon" />
              </div>

              {!isSucceed && (
                <div className="bill-creation-card__header-text">
                  После оплаты покупатель получит чек, а ваш доход будет зарегистрирован в ФНС и
                  начислен в разделе
                  {'   '}
                  <Link to="/lk/accounting" className="bill-creation-card__header-text-link">
                    &quot;Отчетность&quot;
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {!isSucceed && !isFail && (
        <div className="bill-creation-card__content">
          <div className="bill-creation-card__left-part">
            <div className="bill-creation-card__input-holder">
              <p className="bill-creation-card__input-label">ТОВАР ИЛИ УСЛУГА</p>
              <input
                type="text"
                className="bill-creation-card__input bill-creation-card__input_service-name"
                placeholder="Например, уборка квартиры"
                onChange={(event) => handlePurposeFieldChange(event)}
                value={commentary}
                readOnly={isLoading}
                maxLength={256}
              />
              {isCommentaryValid && <IconCheck className="bill-creation-card__input-check" />}
            </div>

            <div className="bill-creation-card__input-holder">
              <p className="bill-creation-card__input-label">СУММА</p>
              <NumberFormat
                className="bill-creation-card__input"
                placeholder="Введите сумму"
                value={sum}
                thousandSeparator=" "
                readOnly={isLoading}
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                isNumericString
                allowedDecimalSeparators={[',', '.']}
                type="tel"
                onValueChange={(values) => {
                  const { floatValue, value } = values;

                  if (floatValue < minSum / 100) {
                    setSum(minSum / 100);
                  } else if (floatValue > maxSum / 100) {
                    setSum(maxSum / 100);
                  } else if (value === '') {
                    setSum('');
                  } else {
                    setSum(value);
                  }
                }}
                isAllowed={(values) => {
                  const { value } = values;

                  if (value.charAt(0) === '0') {
                    return value.charAt(1) === '.';
                  }

                  return true;
                }}
              />
              {isSumValid && <IconCheck className="bill-creation-card__input-check" />}
            </div>

            <div className="income-registration-card__input-holder">
              <p className="income-registration-card__input-label">ДАТА ОКАЗАНИЯ УСЛУГИ</p>
              <DatePicker
                locale="ru"
                dateFormat="dd.MM.yyyy"
                placeholderText="__/__/____"
                showYearDropdown
                selected={date}
                className="income-registration-card__input"
                onChange={(dateLocal) => setDate(dateLocal)}
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                customInput={<DatePickerCustomInput />}
                maxDate={new Date()}
                minDate={new Date().setFullYear(new Date().getFullYear() - 5)}
              />
              {!isDateValid && <IconCalendar className="income-registration-card__calendar-icon" />}
              {isDateValid && <IconCheck className="income-registration-card__input-check" />}
            </div>

            {currentTabId === EMAIL_TAB && (
              <div className="bill-creation-card__input-holder">
                <p className="bill-creation-card__input-label">ВВЕДИТЕ E-MAIL</p>
                <input
                  type="text"
                  className="bill-creation-card__input"
                  placeholder="Введите e-mail"
                  onChange={(event) => handleEmailFieldChange(event)}
                  value={email}
                  readOnly={isLoading}
                />
                {isEmailValid && <IconCheck className="bill-creation-card__input-check" />}
              </div>
            )}

            {windowSize.innerWidth <= 980 && (
              <div className="bill-creation-card__button-wrap">
                <button
                  className="bill-creation-card__button"
                  type="button"
                  disabled={!isFieldsCompleted() || isLoading}
                  onClick={() => {
                    handleSubmitClick(commentary, date, '', sum);
                  }}
                >
                  {isLoading ? (
                    <CircleLoader className="bill-creation-card__button-loader" />
                  ) : (
                    'Выставить счет'
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="bill-creation-card__right-part">
            <div className="bill-creation-card__right-part-top">
              <div className="bill-creation-card__tabs">
                <button
                  className={cx('bill-creation-card__tabs-item', {
                    'bill-creation-card__tabs-item_active': currentTabId === LINK_TAB,
                  })}
                  type="button"
                  onClick={linkTabCLick}
                >
                  Ссылкой
                </button>
                <button
                  className={cx('bill-creation-card__tabs-item', {
                    'bill-creation-card__tabs-item_active': currentTabId === EMAIL_TAB,
                  })}
                  type="button"
                  onClick={emailTabCLick}
                >
                  На почту
                </button>
              </div>

              {currentTabId === LINK_TAB && (
                <p className="bill-creation-card__tip">
                  На следующем шаге мы создадим для вас ссылку
                </p>
              )}

              {currentTabId === EMAIL_TAB && (
                <p className="bill-creation-card__tip">
                  Мы отправим письмо с ссылкой на форму оплаты на указанный e-mail
                </p>
              )}
            </div>

            {windowSize.innerWidth > 980 && (
              <div className="bill-creation-card__button-wrap">
                <button
                  className="bill-creation-card__button"
                  type="button"
                  disabled={!isFieldsCompleted() || isLoading}
                  onClick={() => {
                    handleSubmitClick(commentary, date, email, sum);
                  }}
                >
                  {isLoading ? (
                    <CircleLoader className="bill-creation-card__button-loader" />
                  ) : (
                    'Выставить счет'
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {isSucceed && !isFail && (
        <div className="bill-creation-card__content">
          <div className="bill-creation-card__info">
            <div className="bill-creation-card__info-block">
              <p className="bill-creation-card__info-block-label">Способ отправки</p>
              {currentTabId === LINK_TAB && (
                <p className="bill-creation-card__info-block-value">По ссылке</p>
              )}

              {currentTabId === EMAIL_TAB && (
                <p className="bill-creation-card__info-block-value">На e-mail</p>
              )}
            </div>

            <div className="bill-creation-card__info-block">
              <p className="bill-creation-card__info-block-label">Назначение</p>
              <p className="bill-creation-card__info-block-value">{commentary}</p>
            </div>

            <div className="bill-creation-card__info-block">
              <p className="bill-creation-card__info-block-label">Сумма</p>
              <p className="bill-creation-card__info-block-value"> {sum} ₽</p>
            </div>

            {currentTabId === EMAIL_TAB && (
              <div className="bill-creation-card__info-block">
                <p className="bill-creation-card__info-block-label">E-mail</p>
                <p className="bill-creation-card__info-block-value"> {email}</p>
              </div>
            )}

            {windowSize.innerWidth <= 980 && (
              <>
                <p className="bill-creation-card__tip">
                  Уведомление об оплате появится у вас в личном кабинете
                </p>

                <div className="bill-creation-card__requests-history-link-wrap">
                  <Link
                    to="/lk/history"
                    className="bill-creation-card__requests-history-link"
                    onClick={handleRequestsHistoryLink}
                  >
                    История запросов
                  </Link>
                </div>
              </>
            )}
          </div>

          <div className="bill-creation-card__main">
            <div className="bill-creation-card__link-block">
              <button className="bill-creation-card__link-wrap" type="button" onClick={setCopied}>
                <IconCopyContent className="bill-creation-card__link-icon" />
                <p className="bill-creation-card__link">{link}</p>
              </button>

              <div
                className={cx('bill-creation-card__link-share-block', {
                  'bill-creation-card__link-share-block_with-copy': isCopied,
                })}
              >
                {isCopied && <div className="bill-creation-card__copy-success">скопировано</div>}
                <div className="bill-creation-card__link-share-list">
                  <button
                    className="bill-creation-card__link-share-item"
                    onClick={setCopied}
                    type="button"
                  >
                    <IconShare className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_share" />
                  </button>

                  <EmailShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconEnvelope className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_envelope" />
                  </EmailShareButton>

                  <VKShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconShareVk className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_vk" />
                  </VKShareButton>

                  <TelegramShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconShareTg className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_tg" />
                  </TelegramShareButton>

                  <OKShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconShareOk className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_ok" />
                  </OKShareButton>

                  <WhatsappShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconShareWa className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_wa" />
                  </WhatsappShareButton>

                  {
                    //TODO?: функционал был выключен в рамках задачи SWPS-192
                    //TODO?: функционал был выключен в в связи блокировкой на территории РФ
                  }
                  {/* <FacebookShareButton className="bill-creation-card__link-share-item" url={link}>
                    <IconFbShare className="bill-creation-card__link-share-item-icon bill-creation-card__link-share-item-icon_fb" />
                  </FacebookShareButton> */}
                </div>
              </div>
            </div>

            {windowSize.innerWidth > 980 && (
              <>
                <p className="bill-creation-card__tip">
                  Уведомление об оплате появится у вас в личном кабинете
                </p>

                <div className="bill-creation-card__requests-history-link-wrap">
                  <Link
                    to="/lk/history"
                    className="bill-creation-card__requests-history-link"
                    onClick={handleRequestsHistoryLink}
                  >
                    История запросов
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isFail && !isSucceed && (
        <div className="bill-creation-card__content bill-creation-card__content_error">
          <div className="bill-creation-card__error-icon-wrap">
            <CircleProgress
              className="bill-creation-card__error-progress"
              value={circleLoaderValue}
              color="var(--reddish-pink)"
              radius={28}
            />
            <IconLock className="bill-creation-card__error-icon" />
          </div>

          {/* Если превышено количество лимта на создание счета выводим этот текст */}
          {isFailedOverLimit && (
            <div className="bill-creation-card__error-message">
              Запрос не был отправлен. Превышен лимит на создание запросов в сутки. Попробуйте
              создать запрос через 24 часа
              {windowSize.innerWidth < 980 && (
                <IconTriangle className="bill-creation-card__error-message-triangle" />
              )}
            </div>
          )}

          {/* Если происходит какая-то другая ошибка кроме превышения лимита, выводим этот текст */}
          {!isFailedOverLimit && (
            <div className="bill-creation-card__error-message">
              Упс! Что-то пошло не так и запрос не был отправлен. Попробуйте создать запрос позже.
              Если проблема не решится,{' '}
              <span className="bill-creation-card__error-message-link js-help">напишите нам</span>
              {windowSize.innerWidth < 980 && (
                <IconTriangle className="bill-creation-card__error-message-triangle" />
              )}
            </div>
          )}

          <div className="bill-creation-card__error-link-wrap">
            <Link to="/lk" className="bill-creation-card__error-link">
              В кошелек
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

BillCreationCard.propTypes = {
  className: PropTypes.string,
  handleSubmitClick: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSucceed: PropTypes.bool,
  isFail: PropTypes.bool,
  link: PropTypes.string.isRequired,
  isFailedOverLimit: PropTypes.bool.isRequired,
  currentTabId: PropTypes.string,
  linkTabCLick: PropTypes.func.isRequired,
  emailTabCLick: PropTypes.func.isRequired,
  userLimits: PropTypes.shape({
    maxSum: PropTypes.number.isRequired,
    minSum: PropTypes.number.isRequired,
  }).isRequired,
};

BillCreationCard.defaultProps = {
  className: null,
  isSucceed: false,
  isFail: false,
  currentTabId: LINK_TAB,
};

const mapStateToProps = (state) => ({
  userLimits: getUserLimits()(state),
});

export default connect(mapStateToProps)(BillCreationCard);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import Card from '../Card/Card';
import MoneyInputAndPaymentButton from '../MoneyInputAndPaymentButton/MoneyInputAndPaymentButton';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import rublesToPenny from '../../helpers/rublesToPenny';
import pennyToRubles from '../../helpers/pennyToRubles';

import {
  setMoneyTransferSum,
  setMoneyTransferCardOrWalletNumber,
  setMoneyTransferSBPBank,
  setMoneyTransferNextStep,
} from 'modules/money-transfer/actions';

import {
  getMinSumByTransferMethod,
  getMaxSumByTransferMethod,
  getUserBalance,
  getTotalBalanceByMoneyTransferMethod,
} from 'modules/money-transfer/selectors';

import './SBPCard.scss';
import CardHeaderSBP from '../CardHeaderSBP/CardHeaderSBP';
import { InputUI } from '../../../../ui';
import { getSBPBankList } from '../../../../api/money-transfer';
import FormSelect from '../../../shared/components/FormSelect/FormSelect';
import { isSBPDataValid, isSBPWalletValid } from '../../helpers/isSBPDataValid';
import { checkSBPEnabled } from '../../../../tools/utils/sbp';

const SBPCard = ({
  dispatch,
  isMoneyTransferAllowed,
  sum,
  cardOrWalletNumber,
  sbpBank,
  maxLimitSum,
  minSum,
  maxSum,
  userBalance,
  maxTransferUserBalance,
}) => {
  const [isRendered, setIsRendered] = React.useState(false);
  const [walletNumber, setWalletNumber] = React.useState('');
  const [bank, setBank] = React.useState({ id: '', name: '' });
  const [localSum, setLocalSum] = React.useState('');
  const [isSBPValid, setIsSBPValid] = React.useState(false);
  const [isSumValid, setIsSumValid] = React.useState(true);
  const [isErrorSum, setIsErrorSum] = React.useState('');
  const [isBankList, setBankList] = React.useState(null);

  React.useEffect(() => {
    setIsRendered(true);
    getSBPBankList()
      .then((banks) => {
        setBankList(
          banks
            .sort(function (a, b) {
              if (
                [
                  'альфа банк',
                  'сбербанк',
                  'тинькофф банк',
                  'втб',
                  'райффайзенбанк',
                  'газпромбанк',
                ].includes(a.ru_name.toLowerCase())
              ) {
                if (
                  [
                    'альфа банк',
                    'сбербанк',
                    'тинькофф банк',
                    'втб',
                    'райффайзенбанк',
                    'газпромбанк',
                  ].includes(b.ru_name.toLowerCase())
                ) {
                  return a.ru_name < b.ru_name ? -1 : 1;
                } else {
                  return -1;
                }
              } else {
                return 1;
              }
            })
            .map((bank) => {
              return { value: bank.id, label: bank.ru_name };
            }),
        );
      })
      .catch(() => {
        setBankList([]);
      });
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (isSBPDataValid(walletNumber, bank.id)) {
      setIsSBPValid(true);
    } else {
      setIsSBPValid(false);
    }
  }, [walletNumber, bank]);

  React.useEffect(() => {
    if (cardOrWalletNumber) {
      setWalletNumber(cardOrWalletNumber);
    }
  }, [cardOrWalletNumber]);

  React.useEffect(() => {
    if (sbpBank) {
      setBank(sbpBank);
    }
  }, [sbpBank]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (localSum) {
      dispatch(setMoneyTransferSum(rublesToPenny(parseFloat(localSum))));
    } else {
      dispatch(setMoneyTransferSum(0));
    }
  }, [localSum]);

  React.useEffect(() => {
    if (sum !== 0) {
      setLocalSum(sum.toString());
    }
  }, [sum]);

  function onWalletNumberChange(event) {
    setWalletNumber(event);
  }

  function onSumChange(values) {
    const { floatValue, value } = values;

    if (floatValue > maxLimitSum || floatValue > maxSum) {
      setLocalSum(Math.min(maxLimitSum, maxSum));
    } else if (value === '') {
      setLocalSum('');
    } else {
      setLocalSum(value);
    }

    if (floatValue > maxTransferUserBalance) {
      setIsSumValid(false);
      setIsErrorSum(`Недостаточно средств`);
    } else if (minSum > floatValue) {
      setIsSumValid(false);
      setIsErrorSum(`Минимальная сумма - ${minSum} ₽`);
    } else if (maxSum < floatValue) {
      setIsSumValid(false);
      setIsErrorSum(`Максимальная сумма - ${maxSum} ₽`);
    } else {
      setIsSumValid(true);
      setIsErrorSum('');
    }
  }

  function onPay() {
    dispatch(setMoneyTransferCardOrWalletNumber(walletNumber));
    dispatch(setMoneyTransferSBPBank({ id: bank.id, name: bank.name }));
    dispatch(setMoneyTransferNextStep());
  }

  function transferAll() {
    onSumChange({ floatValue: maxTransferUserBalance, value: maxTransferUserBalance.toString() });
  }

  function findIndexBankSBP() {
    if (bank) {
      const bankIndex = isBankList.findIndex((x) => x.value === bank.id);
      if (bankIndex >= 0) {
        return bankIndex + 1;
      }
      return null;
    } else {
      return null;
    }
  }

  return (
    <CSSTransition classNames="animation-fade" in={isRendered} timeout={0} unmountOnExit>
      <div className="money-transfer-sbp">
        <Card className="money-transfer-sbp__card">
          <header className="money-transfer-sbp__header">
            <CardHeaderSBP />
          </header>
          <div className="money-transfer-sbp__content">
            <div className="money-transfer-sbp__wallet-number">
              <InputUI.Phone
                className="money-transfer-sbp__wallet-number-input"
                value={walletNumber}
                readOnly
                onChange={onWalletNumberChange}
                placeholder="Введите номер телефона"
                disabled={!isMoneyTransferAllowed || !checkSBPEnabled()}
              />
              {isSBPWalletValid(walletNumber) && (
                <IconCheck className="money-transfer-sbp__wallet-number-icon" />
              )}
            </div>
            {isBankList && isBankList.length && (
              <FormSelect
                className=""
                options={isBankList}
                placeholder={'Введите название банка'}
                showDefault={true}
                isSearchable={true}
                currentSelectedOption={findIndexBankSBP()}
                handleSelectChange={(event) => setBank({ id: event.value, name: event.label })}
                disabled={!isMoneyTransferAllowed || !checkSBPEnabled()}
              />
            )}
            {(!isMoneyTransferAllowed || !checkSBPEnabled()) && (
              <p className="money-transfer-sbp__error">
                Для вашей учетной записи вывод денег недоступен
              </p>
            )}
          </div>
        </Card>
        {isSBPValid && (
          <div className="money-transfer-sbp__nav">
            <MoneyInputAndPaymentButton
              sum={localSum.toString()}
              onSumChange={onSumChange}
              isSumValid={isSumValid}
              isErrorSum={isErrorSum}
              onPay={onPay}
              transferAll={maxTransferUserBalance >= minSum ? transferAll : null}
            />
          </div>
        )}
        {!isSBPValid && <div className="money-transfer-sbp__nav-empty"></div>}
      </div>
    </CSSTransition>
  );
};

SBPCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMoneyTransferAllowed: PropTypes.bool.isRequired,
  sum: PropTypes.number.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  sbpBank: PropTypes.object,
  maxLimitSum: PropTypes.number.isRequired,
  minSum: PropTypes.number.isRequired,
  maxSum: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
  maxTransferUserBalance: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isMoneyTransferAllowed: state.moneyTransfer.info.services.sbp.available,
  sum: pennyToRubles(state.moneyTransfer.payment.sum),
  cardOrWalletNumber: state.auth.user.login, //state.moneyTransfer.payment.cardOrWalletNumber,
  sbpBank: state.moneyTransfer.payment.sbpBank,
  maxLimitSum: pennyToRubles(state.moneyTransfer.info.maxLimitSum),
  minSum: pennyToRubles(getMinSumByTransferMethod('sbp')(state)),
  maxSum: pennyToRubles(getMaxSumByTransferMethod('sbp')(state)),
  userBalance: pennyToRubles(getUserBalance()(state)),
  maxTransferUserBalance: pennyToRubles(getTotalBalanceByMoneyTransferMethod('sbp')(state)),
});

export default connect(mapStateToProps)(SBPCard);

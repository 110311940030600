// Проверить, является ли номер банковской карты валидным
// number: string
export default function isBankCardNumberValid(number) {
  if (!number.length) {
    return false;
  }

  // проверить, что номер состоит из цифр
  if (!number.split('').every((char) => /\d/.test(char))) {
    return false;
  }

  const arr = [];
  const cardNumberString = number.toString();
  for (let i = 0; i < cardNumberString.length; i += 1) {
    if (i % 2 === 0) {
      const m = parseInt(cardNumberString[i], 10) * 2;
      if (m > 9) {
        arr.push(m - 9);
      } else {
        arr.push(m);
      }
    } else {
      const n = parseInt(cardNumberString[i], 10);
      arr.push(n);
    }
  }

  const sum = arr.reduce((a, b) => a + b, 0);
  return Boolean(!(sum % 10));
}

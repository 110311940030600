import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './PaymentSummary.scss';

const PaymentSummary = ({ className, summaryFields }) => {
  /* eslint-disable react/no-array-index-key */
  return (
    <div className={cx('payment-summary', className)} data-cy="payment-summary">
      {summaryFields &&
        Array.isArray(summaryFields) &&
        summaryFields.map((item, index) => (
          <div className="payment-summary__item" key={index}>
            <p className="payment-summary__item-title"> {item.title}:</p>
            <p className="payment-summary__item-value">{item.value}</p>
          </div>
        ))}
    </div>
  );
  /* eslint-enable react/no-array-index-key */
};

PaymentSummary.propTypes = {
  className: PropTypes.string,
  summaryFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PaymentSummary.defaultProps = {
  className: null,
};

export default PaymentSummary;

import Footer from 'modules/authLegasy/components/Footer/Footer';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FooterCertificateWidget } from '../../modules/documents';

/* футер находится в своем транзишене так как его надо
 * скрывать на время транзишена
 * иначе он лезет вверху страницы
 * (так как контейнер роутов не экспандится на
 * высоту содержимого, когда оно абсолютом)
 */
export const FooterTemplate = () => {
  const location = useLocation();
  return (
    <CSSTransition timeout={{ enter: 300, exit: 300 }} key={`${location.key}_1`}>
      <Switch location={location}>
        {process.env.REACT_APP_IS_PROD_BUILD === 'N' && ( //! Не для продакшена
          <Route path="/certificate">
            <FooterCertificateWidget /> {/* ПОДПИСАННЫЙ ДОКУМЕНТ */}
          </Route>
        )}
        <Route
          render={({ location: locationInner }) => {
            if (
              locationInner.pathname.indexOf('kopim') <= -1 &&
              locationInner.pathname.indexOf('/pay-it/moneybox/') <= -1 &&
              locationInner.pathname.indexOf('/moneybox/') <= -1
            ) {
              return (
                <Footer
                  isOnRegistration={
                    locationInner.pathname.indexOf('login') > -1 ||
                    locationInner.pathname.indexOf('register') > -1
                  }
                  isInProfile={locationInner.pathname === '/profile'}
                  isDisabled={location.pathname === '/manual'}
                />
              );
            }
          }}
        />
      </Switch>
    </CSSTransition>
  );
};

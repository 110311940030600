import React, { useState } from 'react';
import cx from 'classnames';

const HintHowToGiveRights = () => {
  const [currentTabId, setCurrentTabId] = useState(null);

  return (
    <>
      <div className="hint-auth__list">
        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 0,
            })}
            type="button"
            onClick={() => setCurrentTabId(0)}
          >
            <span className="hint-auth__tab-item-text">Через мобильное приложение «Мой налог»</span>
          </button>

          {currentTabId === 0 && (
            <ul className="hint-auth__tab-content">
              <li className="hint-auth__list-item">
                1. Запустите приложение
                <a
                  href="https://npd.nalog.ru/app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hint-auth__list-item-link"
                >
                  «Мой Налог»
                </a>
              </li>
              <li className="hint-auth__list-item">2. Зайдите в раздел «Настройки»</li>
              <li className="hint-auth__list-item">3. Найдите блок «Программы партнеры»</li>
              <li className="hint-auth__list-item">
                4. Выберите из них платформу «Самозанятые.рф»
              </li>
              <li className="hint-auth__list-item">
                5. Отметьте все разрешения для платформы-партнера
              </li>
              <li className="hint-auth__list-item">6. Нажмите кнопку «Подключить»</li>
            </ul>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 1,
            })}
            type="button"
            onClick={() => setCurrentTabId(1)}
          >
            <span className="hint-auth__tab-item-text">Через веб-версию сайта</span>
          </button>

          {currentTabId === 1 && (
            <ul className="hint-auth__tab-content">
              <li className="hint-auth__list-item">
                1. Войдите в
                <a
                  href="https://lknpd.nalog.ru/auth/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hint-auth__list-item-link"
                >
                  «Мой Налог» личный кабинет
                </a>
                с помощью телефона или связки ИНН и пароль
              </li>
              <li className="hint-auth__list-item">2. Зайдите в раздел «Настройки»</li>
              <li className="hint-auth__list-item">3. Найдите раздел «Партнеры»</li>
              <li className="hint-auth__list-item">
                4. Выберите из них платформу «Самозанятые.рф»
              </li>
              <li className="hint-auth__list-item">
                5. Отметьте все разрешения для платформы-партнера
              </li>
              <li className="hint-auth__list-item">6. Нажмите кнопку «Сохранить»</li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default HintHowToGiveRights;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AnimatedUI, InputUI } from 'ui';

const VALUE_MACK = '9  9  9  9';

export const InputFormOfChangeStatus = ({ onFinishInput, isError, isLoading }) => {
  return (
    <DivAnimatedStyled>
      <InputStyled
        // onChange={(value) => setSmsPassword(value)}/**/
        onFinish={(value) => onFinishInput(value)}
        isError={isError}
        isLoading={isLoading}
        placeholder="Введите код из SMS"
        mask={VALUE_MACK}
        required
      />
    </DivAnimatedStyled>
  );
};

InputFormOfChangeStatus.propTypes = {
  onFinishInput: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

InputFormOfChangeStatus.defaultProps = {
  isError: false,
  isLoading: false,
};

const InputStyled = styled(InputUI.Mask)`
  margin-bottom: 20px;
`;

const DivAnimatedStyled = styled(AnimatedUI)`
  display: flex;
  flex-direction: column;
`;

import wait from '../helpers/wait';
import * as Api from 'api/money-transfer';
import * as types from './action-types';
import {
  MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD,
  MONEY_TRANSFER_STATUS_DELAY,
} from 'constants/index';
import { getTotalSumByMoneyTransferMethod } from '../selectors';

//
// получить информацию о максимльной сумме, комиссиях и прочей информации
//
export const getMoneyTransferInfoFetching = () => ({
  type: types.GET_MONEY_TRANSFER_INFO_FETCHING,
});

export const getMoneyTransferInfoSuccess = (payload) => ({
  type: types.GET_MONEY_TRANSFER_INFO_SUCCESS,
  payload,
});

export const getMoneyTransferInfoFail = (err) => ({
  type: types.GET_MONEY_TRANSFER_INFO_FAIL,
  payload: err,
});

export const getMoneyTransferInfo = () => (dispatch) => {
  dispatch(getMoneyTransferInfoFetching());

  return Api.getMoneyTransferInfo()
    .then((payload) => dispatch(getMoneyTransferInfoSuccess(payload)))
    .catch((err) => dispatch(getMoneyTransferInfoFail(err)));
};

//
// запросить перевод
//
export const requestMoneyTransferFetching = () => ({
  type: types.REQUEST_MONEY_TRANSFER_FETCHING,
});

export const requestMoneyTransferSuccess = (payload) => ({
  type: types.REQUEST_MONEY_TRANSFER_SUCCESS,
  payload,
});

export const requestMoneyTransferFail = (err) => ({
  type: types.REQUEST_MONEY_TRANSFER_FAIL,
  payload: err,
});

export const requestMoneyTransferFailDuplicate = (err) => ({
  type: types.REQUEST_MONEY_TRANSFER_FAIL_DUPLICATE,
  payload: err,
});

export const confirmMoneyTransferDuplicate = (payload) => ({
  type: types.CONFIRM_MONEY_TRANSFER_DUPLICATE,
  payload: payload,
});

export const requestMoneyTransfer = (transferMethod) => (dispatch, getState) => {
  const state = getState();
  const { sum, cardOrWalletNumber, message, sbpBank, duplicate } = state.moneyTransfer.payment;
  const totalSum = getTotalSumByMoneyTransferMethod(transferMethod)(state);

  if (!duplicate) {
    dispatch(requestMoneyTransferFetching());
  }

  return Api.requestMoneyTransfer(transferMethod, {
    number: cardOrWalletNumber,
    sumIn: totalSum,
    sumOut: sum,
    bankId: sbpBank?.id,
    duplicate,
    message,
  })
    .then((payload) => {
      dispatch(requestMoneyTransferSuccess(payload));
      dispatch(setMoneyTransferNextStep());
    })
    .catch((err) => {
      if (err.data === 'operation.duplicate') {
        dispatch(requestMoneyTransferFailDuplicate(err));
      } else {
        dispatch(requestMoneyTransferFail(err));
      }
    });
};

//
// проверить успешность подтверждения с помощью push-уведомления
//
export const checkMoneyTransferConfirmationViaPushFetching = () => ({
  type: types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FETCHING,
});

export const checkMoneyTransferConfirmationViaPushSuccess = (payload) => ({
  type: types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_SUCCESS,
  payload,
});

export const checkMoneyTransferConfirmationViaPushFail = (err) => ({
  type: types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FAIL,
  payload: err,
});

// бесконечный цикл, который шлет запросы для получения статуса до тех пор, пока не будет достигнуто условия остановки
//
// условия остановки:
// - сменился метод подтверждения (confirmationMethod)
// - получен ожидаемый статус от сервера
// - получена ошибка
export const checkMoneyTransferConfirmationViaPush =
  (paymentMethod) => async (dispatch, getState) => {
    let confirmed = false;
    dispatch(checkMoneyTransferConfirmationViaPushFetching());

    while (true) {
      const { confirmationMethod, id: paymentId } = getState().moneyTransfer.payment;

      if (confirmationMethod !== MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD) {
        break;
      }

      let response;

      try {
        response = await Api.checkMoneyTransferStatus(paymentMethod, paymentId);
      } catch (err) {
        dispatch(checkMoneyTransferConfirmationViaPushFail(err));
        return;
      }

      // если состояние "в ожидании", значит стадия подтверждения прошла
      if (response.state !== 'need_confirm') {
        confirmed = true;
        break;
      }

      if (response.state === 'error') {
        return dispatch(checkMoneyTransferConfirmationViaPushFail());
      }

      await wait(MONEY_TRANSFER_STATUS_DELAY);
    }

    if (confirmed) {
      dispatch(checkMoneyTransferConfirmationViaPushSuccess());
      dispatch(setMoneyTransferNextStep());
    }
  };

// пересоздать подтверждение (в случае переключения с push на смс)
export const recreateMoneyTransferConfirmationViaSmsFetching = () => ({
  type: types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FETCHING,
});

export const recreateMoneyTransferConfirmationViaSmsSuccess = (payload) => ({
  type: types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_SUCCESS,
  payload,
});

export const recreateMoneyTransferConfirmationViaSmsFail = (err) => ({
  type: types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FAIL,
  payload: err,
});

export const recreateMoneyTransferConfirmationViaSms = () => (dispatch, getState) => {
  dispatch(recreateMoneyTransferConfirmationViaSmsFetching());

  const { pinMessageId } = getState().moneyTransfer.payment;

  return Api.recreateMoneyTransferConfirmationViaSms(pinMessageId)
    .then((payload) => dispatch(recreateMoneyTransferConfirmationViaSmsSuccess(payload)))
    .catch((err) => dispatch(recreateMoneyTransferConfirmationViaSmsFail(err)));
};

//
// подтвердить перевод с помощью смс
//
export const confirmMoneyTransferViaSmsFetching = () => ({
  type: types.CONFIRM_MONEY_TRANSFER_VIA_SMS_FETCHING,
});

export const confirmMoneyTransferViaSmsSuccess = (payload) => ({
  type: types.CONFIRM_MONEY_TRANSFER_VIA_SMS_SUCCESS,
  payload,
});

export const confirmMoneyTransferViaSmsFail = (err) => ({
  type: types.CONFIRM_MONEY_TRANSFER_VIA_SMS_FAIL,
  payload: err,
});

export const confirmMoneyTransferViaSms = (pin) => (dispatch, getState) => {
  dispatch(confirmMoneyTransferViaSmsFetching());

  const paymentId = getState().moneyTransfer.payment.id;

  return Api.confirmMoneyTransferViaSms(paymentId, pin)
    .then(() => {
      dispatch(confirmMoneyTransferViaSmsSuccess());
      dispatch(setMoneyTransferNextStep());
    })
    .catch((err) => {
      dispatch(confirmMoneyTransferViaSmsFail(err));
    });
};

//
// отправить новый смс-код
//
export const sendMoneyTransferSmsCodeFetching = () => ({
  type: types.SEND_MONEY_TRANSFER_SMS_CODE_FETCHING,
});

export const sendMoneyTransferSmsCodeSuccess = (payload) => ({
  type: types.SEND_MONEY_TRANSFER_SMS_CODE_SUCCESS,
  payload,
});

export const sendMoneyTransferSmsCodeFail = (err) => ({
  type: types.SEND_MONEY_TRANSFER_SMS_CODE_FAIL,
  payload: err,
});

export const sendMoneyTransferSmsCode = () => (dispatch, getState) => {
  dispatch(sendMoneyTransferSmsCodeFetching());

  const { pinMessageId } = getState().moneyTransfer.payment;

  return Api.sendMoneyTransferSmsCode(pinMessageId)
    .then(() => dispatch(sendMoneyTransferSmsCodeSuccess()))
    .catch(() => dispatch(sendMoneyTransferSmsCodeFail()));
};

//
// отследить состояние перевода
//
export const checkMoneyTransferStatusFetching = () => ({
  type: types.CHECK_MONEY_TRANSFER_STATUS_FETCHING,
});

export const checkMoneyTransferStatusSuccess = (state) => ({
  type: types.CHECK_MONEY_TRANSFER_STATUS_SUCCESS,
  payload: {
    state,
  },
});

export const checkMoneyTransferStatusFail = (err) => ({
  type: types.CHECK_MONEY_TRANSFER_STATUS_FAIL,
  payload: err,
});

export const checkMoneyTransferStatus = (paymentMethod) => async (dispatch, getState) => {
  // TODO: удалить, когда появится статус о том, что было передано в банк
  const requestStart = Date.now();
  dispatch(checkMoneyTransferStatusFetching());

  while (true) {
    // TODO: удалить, когда появится статус о том, что было передано в банк
    const currentTime = Date.now();

    if (currentTime - requestStart >= 30 * 1000) {
      dispatch(checkMoneyTransferStatusSuccess('success'));
      dispatch(setMoneyTransferNextStep());
      break;
    }

    const { id: paymentId } = getState().moneyTransfer.payment;

    let response;

    try {
      if (paymentId) {
        response = await Api.checkMoneyTransferStatus(paymentMethod, paymentId);
      }
    } catch (err) {
      dispatch(checkMoneyTransferStatusFail(err));
      return;
    }

    if (!response?.state && !paymentId) {
      return;
    }

    if (response.state === 'error') {
      return dispatch(checkMoneyTransferStatusFail());
    }

    // если состояние "в ожидании", значит стадия подтверждения прошла
    if (response.state !== 'wait') {
      dispatch(checkMoneyTransferStatusSuccess(response.state));
      dispatch(setMoneyTransferNextStep());
      break;
    }

    await wait(MONEY_TRANSFER_STATUS_DELAY);
  }
};

// изменить метод проверки перевода
export const changeMoneyTransferConfirmationMethod = (method) => ({
  type: types.CHANGE_MONEY_TRANSFER_CONFIRMATION_METHOD,
  payload: {
    method,
  },
});

// установить сумму
export const setMoneyTransferSum = (sum) => ({
  type: types.SET_MONEY_TRANSFER_SUM,
  payload: {
    sum,
  },
});

// установить сообщение
export const setMoneyTransferMessage = (message) => ({
  type: types.SET_MONEY_TRANSFER_MESSAGE,
  payload: {
    message,
  },
});

// установить номер карты или кошелька
export const setMoneyTransferCardOrWalletNumber = (number) => ({
  type: types.SET_MONEY_TRANSFER_CARD_OR_WALLET_NUMBER,
  payload: {
    number,
  },
});

export const setMoneyTransferSBPBank = ({ id, name }) => ({
  type: types.SET_MONEY_TRANSFER_SBP_BANK,
  payload: {
    id,
    name,
  },
});

// сбросить перевод денег
export const resetMoneyTransfer = () => ({
  type: types.RESET_MONEY_TRANSFER,
});

// сбросить шаги и перейти к первому
export const resetMoneyTransferSteps = () => ({
  type: types.RESET_MONEY_TRANSFER_STEPS,
});

// перейти к следующему шагу
export const setMoneyTransferNextStep = () => ({
  type: types.SET_MONEY_TRANSFER_NEXT_STEP,
});

// перейти к предыдущему шагу
export const setMoneyTransferPreviousStep = () => ({
  type: types.SET_MONEY_TRANSFER_PREVIOUS_STEP,
});

//
// получить информацию о банковской карте
//

export const getMoneyTransferBankCardInfoFetching = () => ({
  type: types.GET_MONEY_TRANSFER_BANK_CARD_INFO_FETCHING,
});

export const getMoneyTransferBankCardInfoSuccess = (payload) => ({
  type: types.GET_MONEY_TRANSFER_BANK_CARD_INFO_SUCCESS,
  payload,
});

export const getMoneyTransferBankCardInfoFail = () => ({
  type: types.GET_MONEY_TRANSFER_BANK_CARD_INFO_FAIL,
});

let bankCardCache = {
  bin: null,
  payload: null,
};

// bin: string - первые 6 цифр номера карты
export const getMoneyTransferBankCardInfo = (bin) => (dispatch) => {
  dispatch(getMoneyTransferBankCardInfoFetching());

  if (bankCardCache.bin === bin) {
    dispatch(getMoneyTransferBankCardInfoSuccess(bankCardCache.payload));
    return;
  }

  return Api.getBankCardInfo(bin)
    .then((payload) => {
      dispatch(getMoneyTransferBankCardInfoSuccess(payload));
      bankCardCache.bin = bin;
      bankCardCache.payload = payload;
    })
    .catch(() => dispatch(getMoneyTransferBankCardInfoFail()));
};

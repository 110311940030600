/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { PropTypes } from 'prop-types';
import React from 'react';

import { AccountsTypeContainer } from './containers';

export const AccountsTypeWidget = ({
  urlToEDOAccountDetailsPage,
  urlToAuthorizationAccountDetailsPage,
  urlToIdentificationAccountDetailsPage,
}) => (
  <AccountsTypeContainer
    urlToEDOAccountDetailsPage={urlToEDOAccountDetailsPage}
    urlToAuthorizationAccountDetailsPage={urlToAuthorizationAccountDetailsPage}
    urlToIdentificationAccountDetailsPage={urlToIdentificationAccountDetailsPage}
  />
);

AccountsTypeWidget.propTypes = {
  urlToAuthorizationAccountDetailsPage: PropTypes.string,
  urlToIdentificationAccountDetailsPage: PropTypes.string,
};

AccountsTypeWidget.defaultProps = {
  urlToAuthorizationAccountDetailsPage: null,
  urlToIdentificationAccountDetailsPage: null,
};

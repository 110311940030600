import cx from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

export const PasswordRecoveryCompleted = ({ className, onNextStepButtonClick }) => {
  const isMenuOpen = useSelector((store) => store.auth?.isMenuOpen);
  const [isRendered, setIsRendered] = useState(false);

  useLayoutEffect(() => setIsRendered(true), []);

  return (
    <div
      className={cx('password-recovery', {
        [className]: className,
      })}
      data-testid="password-recovery-enter-phone"
    >
      <div className="password-recovery__container container">
        <div className="password-recovery__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="password-recovery__title">Пароль обновлен</h1>
          </CSSTransition>
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <p className="password-recovery__info-text">
              Вы успешно обновили пароль. Вы теперь сможете войти с помощью нового пароля
            </p>
          </CSSTransition>
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="password-recovery__btn-wrap">
              <StandardButton
                title="Войти в Кошелек"
                isLink={false}
                onClick={() => onNextStepButtonClick()}
                className="password-recovery__button"
              />
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styles from './PartnerNav.module.scss';

const PartnerNav = () => {
  const { params } = useRouteMatch('/lk/partner/:platformName/:partnershipId');
  const { partnershipId, platformName } = params;

  return (
    <nav className={styles.partnerNav}>
      <NavLink
        to={`/lk/partner/${platformName}/${partnershipId}`}
        exact
        className={styles.partnerNavLink}
        activeClassName={styles.partnerNavLinkActive}
      >
        О партнере
      </NavLink>
      <NavLink
        to={`/lk/partner/${platformName}/${partnershipId}/documents`}
        exact
        className={styles.partnerNavLink}
        activeClassName={styles.partnerNavLinkActive}
      >
        Документы
      </NavLink>
      <NavLink
        to={`/lk/partner/${platformName}/${partnershipId}/payments`}
        exact
        className={styles.partnerNavLink}
        activeClassName={styles.partnerNavLinkActive}
      >
        Выплаты
      </NavLink>
    </nav>
  );
};

export default PartnerNav;

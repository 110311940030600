import cx from 'classnames';

/* eslint-disable import/prefer-default-export */
export const multiSelectStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  container: (provided) => ({
    ...provided,
    minHeight: 60,
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid var(--dark-slate-blue)',
    boxShadow: 'none',
    borderRadius: 8,
    minHeight: 56,
  }),
  placeholder: (provided) => ({
    ...provided,
    opacity: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '26px 8px 10px 14px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--dark-slate-blue)',
    fontSize: 18,
    fontFamily: `'Circe-Regular', sans-serif`,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--dark-slate-blue)',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 18,
    color: cx({
      white: state.isSelected && !state.isDisabled,
      black: !state.isSelected && !state.isDisabled,
      '#cccccc': state.isDisabled,
    }),
    fontFamily: `'Circe-Regular', sans-serif`,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#F6F8FB',
      borderRadius: 4,
      margin: 4,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'var(--dark-slate-blue)',
    paddingLeft: 4,
    padding: 4,
    fontFamily: `'Rubik', sans-serif`,
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: '#F6F8FB',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: '#616A88',
    transition: 'all .3s ease',
    ':hover': {
      cursor: 'pointer',
      // backgroundColor: data.color,
      color: '#1D2E69',
    },
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#616A88',
    transition: 'all .3s ease',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: data.color,
      color: '#1D2E69',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 240,
  }),
};
// export const selectStylesCondensed = {
//   ...selectStyles,
//   dropdownIndicator: provided => ({
//     ...provided,
//     color: '#4A90E2',
//     padding: '0 4px 0 0',
//   }),
// };

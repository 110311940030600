import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as IconPost } from 'static/assets/icon-post.svg';
import { ReactComponent as Close } from 'static/assets/icon-close.svg';

// Styles
import './style.scss';

export const EmailConfirmSuccessTemplate = () => {
  return (
    <div className="email-confirm">
      <div className="email-confirm__card">
        <h2 className="email-confirm__title">Отлично!</h2>
        <div className="email-confirm__icon-wrap">
          <IconPost className="email-confirm__icon" />
        </div>
        <div className="email-confirm__desc">
          Ваш e-mail успешно подтвержден. Теперь вы можете полноценно пользоваться сервисом
        </div>
        <Link to="/lk" className="email-confirm__close">
          <Close />
        </Link>
      </div>
    </div>
  );
};

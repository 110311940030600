import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import WalletClosingOverlay from '../WalletClosingOverlay';
import Popup from 'modules/shared/components/Popup';
import {
  showWalletClosingBalancePopup,
  showWalletClosingConfirmationPopup,
  hideWalletClosingBalancePopup,
  hideWalletClosingConfirmationPopup,
  hideWalletClosingUnprocessingOperationPopup,
  hideWalletClosingSuccessPopup,
  hideWalletClosingErrorPopup,
  resetState,
} from '../../actions';
import styles from './WalletClosing.module.scss';

const WalletClosing = ({ dispatch, userBalance }) => {
  const isEmptyBalance = userBalance === 0;

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      dispatch(hideWalletClosingBalancePopup());
      dispatch(hideWalletClosingConfirmationPopup());
      dispatch(hideWalletClosingUnprocessingOperationPopup());
      dispatch(hideWalletClosingSuccessPopup());
      dispatch(hideWalletClosingErrorPopup());
      dispatch(resetState());

      document.body.style.overflow = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onRemoveWalletButtonClick() {
    if (isEmptyBalance) {
      return dispatch(showWalletClosingConfirmationPopup());
    }

    dispatch(showWalletClosingBalancePopup());
  }

  return (
    <Popup redirectHref="/lk/profile-settings" overlay={WalletClosingOverlay}>
      <h1 className={styles.walletClosingTitle}>Мы уже скучаем</h1>
      <div className={styles.walletClosingContent}>
        <p>
          Один клик и вы навсегда удалите историю выплат, информацию по партнерам, документооборот и
          отчетность. Удаленные данные не подлежат восстановлению.
        </p>
        <p>Уверены, что хотите удалить Кошелек?</p>
      </div>
      <StandardButton
        className={styles.walletClosingButton}
        isLink={false}
        onClick={onRemoveWalletButtonClick}
        title="Удалить кошелек"
      />
    </Popup>
  );
};

WalletClosing.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userBalance: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  userBalance: state.auth.user.clientAccounts[0]?.balance || 0,
});

export default connect(mapStateToProps)(WalletClosing);

import { getScript } from './utils';
import { useEffect, useState } from 'react';

export const useInitScriptElement = (connectId, methodNameArr) => {
  const [scriptEl, setScriptEl] = useState(null);

  useEffect(() => {
    setScriptEl(() => {
      const scriptEl = createScriptElement(connectId, methodNameArr);
      document.head.appendChild(scriptEl);
      return scriptEl;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { scriptEl };
};

const createScriptElement = (connectId, methodNameArr) => {
  const script = getScript(connectId, methodNameArr);

  const scriptEl = document.createElement('script');
  scriptEl.innerHTML = script;

  return scriptEl;
};

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { saveEmail } from 'modules/authLegasy/actions';

import SmallCircleLoader from 'modules/authLegasy/components/SmallCircleLoader/SmallCircleLoader';

import { ReactComponent as IconError } from 'static/assets/icon-information.svg';

import './UnconfirmedEmailMessage.scss';

const UnconfirmedEmailMessage = ({ dispatch, animationStart, unconfirmedEmail, className }) => {
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [resendError, setResendError] = useState(false);

  /** Запрос на повторную отправку письма с подтверждением e-mail */
  const resendConfirmationEmail = () => {
    setIsResendLoading(true);

    dispatch(saveEmail(unconfirmedEmail))
      .then(() => {
        setIsResendLoading(false);
      })
      .catch((result) => {
        setResendError(
          (result && result.result && result.result.message) ||
            'Что-то пошло не так, попробуйте подтвердить email позже',
        );
        setIsResendLoading(false);
      });
  };

  return (
    <CSSTransition
      classNames="animation-from-top-to-bottom"
      in={animationStart}
      timeout={{ enter: 100, exit: 100 }}
      unmountOnExit
    >
      <div
        className={cx('unconfirmed-email-message', {
          [className]: className,
        })}
      >
        <IconError className="unconfirmed-email-message__icon" />

        <div className="unconfirmed-email-message__text">
          Вы не подтвердили e-mail. Пожалуйста, проверьте почту и перейдите по ссылке в письме для
          дальнейшего использования сервиса. {'  '}
          <button
            type="button"
            className="unconfirmed-email-message__button"
            onClick={resendConfirmationEmail}
          >
            Отправить повторно e-mail.
          </button>
          {'  '}
          {isResendLoading && <SmallCircleLoader />}
          {Boolean(resendError) && (
            <div className="unconfirmed-email-message__error">{resendError}</div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

UnconfirmedEmailMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  animationStart: PropTypes.bool.isRequired,
  unconfirmedEmail: PropTypes.string,
  className: PropTypes.string,
};

UnconfirmedEmailMessage.defaultProps = {
  unconfirmedEmail: null,
  className: null,
};

export default connect()(UnconfirmedEmailMessage);

import React from 'react';

const HintDontHaveRussianPassport = () => (
  <div className="hint__text-wrap" data-cy="hint-dont-have-russian-passport">
    <div className="hint__text">
      К сожалению сейчас с кошельком могут работать только граждане РФ. В ближайшее время мы
      увеличим лимиты для работы с кошельком и откроем его для граждан других стран.
    </div>
  </div>
);

export default HintDontHaveRussianPassport;

import { css } from 'styled-components';
import { WEIGHT } from 'tools/constants';
import { getFont } from '../../../tools';

export const InputMixin = css`
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  width: 100%;
  background: white;
  border: 1px solid white;
  -webkit-appearance: none;
  color: var(--dark-slate-blue);
  font-size: 16px;
  line-height: 24px;
  padding: 0 24px;
  text-align: center;
  ${getFont(WEIGHT['circe-normal'])};
  font-weight: bold;
  cursor: pointer;
  &::placeholder,
  &:-webkit-autofill::placeholder {
    ${getFont(WEIGHT['circe-normal'])}
    font-weight: normal;
    color: #9da6ba;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.7;
  }

  &:focus {
    border-color: var(--dark-blue);
    box-shadow: none;
  }

  &:disabled {
    background: rgba(228, 232, 238, 0.2);
    border: solid 1px rgba(157, 166, 186, 0.2);
    color: #b0b5c4;
    box-shadow: none;
  }

  ${({ isFail }) =>
    isFail &&
    css`
      border: solid 1px var(--reddish-pink);
      box-shadow: none;
    `}

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      border: solid 1px var(--turquoise-blue);
      color: var(--turquoise-blue);
    `}
`;

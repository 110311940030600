import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './ProgressBar.scss';

const ProgressBar = ({ className, progress }) => {
  return (
    <div
      className={cx('progress-bar', {
        [className]: className,
      })}
    >
      <div className="progress-bar__progress">
        <div
          className="progress-bar__progress-bar"
          style={{ width: `${progress}%` }}
          data-testid="progress-bar"
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  className: null,
};

export default ProgressBar;

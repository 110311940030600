import React from 'react';
import FooterAppLinks from 'modules/authLegasy/components/FooterAppLinks/FooterAppLinks';
// TODO: PRO-384
// import FooterSupportButton from '../FooterSupportButton';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <FooterAppLinks />
      {/* TODO: PRO-384 + удалить компонент FooterSupportButton */}
      {/* <div className={styles.footerDesktop}>
        <FooterSupportButton />
      </div> */}
    </footer>
  );
};

export default Footer;

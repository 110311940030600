import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getLimits, getUser } from './modules/authLegasy/actions';
import { BodyTemplate, FooterTemplate, HeaderTemplate } from './templates';
import { useWebSocketEffect } from './tools/hooks';
import { useConfiguredCarrotQuest } from './modules/carrotQuest';

export const AppContainer = () => {
  const dispatch = useDispatch();
  useConfiguredCarrotQuest();

  /** Берем юзера и лимиты по учеткам */
  useEffect(() => {
    dispatch(getUser()).then(() => dispatch(getLimits()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useSelector((store) => store.auth.user, shallowEqual);
  const isMenuOpenWithTransition = useSelector(
    (store) => store.auth.isMenuOpenWithTransition,
    shallowEqual,
  );
  useWebSocketEffect(user);

  return (
    <AppContainerStyled isMenuOpenWithTransition={isMenuOpenWithTransition}>
      <Router basename={process.env.PUBLIC_URL}>
        <HeaderTemplate />
        <BodyTemplateStyled />
        <FooterTemplate />
      </Router>
    </AppContainerStyled>
  );
};

const AppContainerStyled = styled.div`
  height: 100vh;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-height: 600px) {
    height: auto;
  }
  @media screen and (max-width: 800px) {
    height: auto;
  }
  ${({ isMenuOpenWithTransition }) =>
    isMenuOpenWithTransition &&
    css`
      height: 100vh;
      max-height: 100vh;
    `}
`;

const BodyTemplateStyled = styled(BodyTemplate)`
  flex-grow: 100;
`;

import { fetchWrapper } from 'helpers';

export const getCashBoxContext = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox/context`;
  return fetchWrapper(url);
};

export const getShowCase = (id) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox/${id}`;
  return fetchWrapper(url);
};

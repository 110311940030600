import { createSelector } from '@reduxjs/toolkit';
import addDays from 'date-fns/addDays';

import CalendarRangePicker from 'modules/shared/components/CalendarRangePicker/CalendarRangePicker';
import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from '../../../../helpers';
import {
  hideInlineCalendar,
  restorePartnerPaymentsCurrentRangeTab,
  setPartnerPaymentsRange,
} from '../../actions';
import styles from './PartnerPaymentsCalendar.module.scss';

const PartnerPaymentsCalendar = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const paymentsRange = useSelector(
    createSelector(
      (store) => store.partners.paymentsRange,
      ({ startDate, endDate }) => ({
        startDate: startDate || addDays(setHoursAndMinutesToZero(new Date()), -6),
        endDate: endDate || setHoursAndMinutesTheEndOfDay(new Date()),
      }),
    ),
    shallowEqual,
  );

  useEffect(() => {
    function onClickOut(event) {
      const { target } = event;

      if (!ref.current.contains(target)) {
        onCloseCalendar();
      }
    }

    document.addEventListener('click', onClickOut);
    return () => document.removeEventListener('click', onClickOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dates, setDates] = useState(() => [
    {
      startDate: paymentsRange.startDate,
      endDate: paymentsRange.endDate,
      key: 'selection',
    },
  ]);

  function onApplyRange() {
    dispatch(
      setPartnerPaymentsRange({
        startDate: dates[0].startDate,
        endDate: dates[0].endDate,
      }),
    );
    dispatch(hideInlineCalendar());
  }

  function onCloseCalendar() {
    dispatch(restorePartnerPaymentsCurrentRangeTab());
    dispatch(hideInlineCalendar());
  }

  return (
    <div ref={ref} className={styles.partnerPaymentsCalendar}>
      <CalendarRangePicker
        range={dates}
        onChange={setDates}
        onAccept={onApplyRange}
        onCancel={onCloseCalendar}
      />
    </div>
  );
};

export default PartnerPaymentsCalendar;

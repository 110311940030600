import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AUTHORIZED_PROFILE_TAB, SZ_PROFILE_TAB } from 'constants/index';

import { setProfileSettingsActiveTab } from 'modules/profileLegasy/actions';

import './PersonalDataNoticeText.scss';

export const PersonalDataNoticeText = ({ dispatch }) => {
  return (
    <div className="personal-data-notice-text">
      <div className="personal-data-notice-text__massage">
        Раздел заполняетя автоматически, по мере получения &nbsp;
        <button
          onClick={() => {
            dispatch(setProfileSettingsActiveTab(SZ_PROFILE_TAB));
          }}
          className="personal-data-notice-text__massage-link"
          type="button"
        >
          статуса
        </button>
        &nbsp; и подтверждения &nbsp;
        <button
          onClick={() => {
            dispatch(setProfileSettingsActiveTab(AUTHORIZED_PROFILE_TAB));
          }}
          className="personal-data-notice-text__massage-link"
          type="button"
        >
          учетной записи
        </button>
      </div>
    </div>
  );
};

PersonalDataNoticeText.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PersonalDataNoticeText);

/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { LineText, WindowUI } from 'ui';

//import { ReactComponent as DoubtSmile } from '../../assets/doubt-smile.svg';

export const DuplicateConfirmAlert = ({ onDecline, onApprove, onClose }) => (
  <WindowUI.Confirm
    header="Продолжаем?"
    //emoji={<DoubtSmile />}
    approveButtonText="Продолжить"
    rejektButtonText="Отмена"
    onClose={onClose}
    onApprove={onApprove}
    onRejekt={onDecline}
  >
    <AlertContentWraperStyled>
      <LineTextStyled>
        Вы только что перевели такую же сумму. <br /> Если вы хотите отправить деньги повторно,
        нажмите «Продолжить»
      </LineTextStyled>
    </AlertContentWraperStyled>
  </WindowUI.Confirm>
);

DuplicateConfirmAlert.propTypes = {
  onDecline: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DuplicateConfirmAlert.defaultProps = {};

const AlertContentWraperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LineTextStyled = styled(LineText)`
  text-align: center;
`;

import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { acceptPrivacyPolicy, logOut, getUser } from 'modules/authLegasy/actions';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import iconSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconLogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as IconLogo } from 'static/assets/logo.svg';

import './style.scss';

export const AcceptNewAgreement = ({ dispatch, user }) => {
  const windowSize = useWindowSize();
  const [acceptStatus, setAcceptStatus] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const acceptNewAgreement = () => {
    setAcceptStatus('LOADING');

    dispatch(acceptPrivacyPolicy())
      .then(() => {
        setAcceptStatus('SUCCESS');
        dispatch(getUser())
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {
        setAcceptStatus('FAIL');
      });
  };

  useEffect(() => {
    if (user) {
      if (
        user.ofertaAccepted === null ||
        !Object.prototype.hasOwnProperty.call(user, 'ofertaAccepted')
      ) {
        setRedirect(true);
      } else {
        if (user.ofertaAccepted.toString() === 'false') {
          setRedirect(false);
        }

        if (user.ofertaAccepted.toString() === 'true') {
          setRedirect(true);
        }
      }
    }
  }, [user]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="accept-new-agreement" data-testid="accept-new-agreement">
      <div className="accept-new-agreement__header">
        <Link
          to="/"
          className="accept-new-agreement__logo"
          onClick={() => dispatch(logOut())}
          data-testid="logo-link"
        >
          {windowSize.innerWidth > 620 ? (
            <IconLogo className="accept-new-agreement__logo-img" />
          ) : (
            <IconLogoResponsive className="accept-new-agreement__logo-img" />
          )}
        </Link>

        {windowSize.innerWidth <= 620 && (
          <Link
            to="/"
            className="accept-new-agreement__close-button"
            onClick={() => dispatch(logOut())}
            data-testid="responsive-close-button"
          >
            <IconClose className="accept-new-agreement__close-button-icon" />
          </Link>
        )}
      </div>

      <div className="accept-new-agreement__main">
        <div className="accept-new-agreement__main-content">
          <h1 className="accept-new-agreement__main-title">Мы становимся лучше</h1>

          <div className="accept-new-agreement__main-text">
            У нас есть для вас новое {'  '}
            <a
              href="https://самозанятые.рф/legal/walletterms.pdf"
              className="accept-new-agreement__main-text-link"
              data-testid="agreement-link"
            >
              Пользовательское соглашение
            </a>
            . Мы бы очень хотели, чтобы вы ознакомились, согласились с его условиями и продолжили
            пользоваться сервисом.
          </div>

          <div className="accept-new-agreement__main-buttons-list">
            <StandardButton
              isLink={false}
              title="Принять"
              bgType={BLUE}
              className="accept-new-agreement__main-buttons-item"
              onClick={acceptNewAgreement}
              isLoading={acceptStatus === 'LOADING'}
            />
            <StandardButton
              isLink
              href="/"
              title="Отклонить"
              bgType={GREY_BORDER}
              onClick={() => dispatch(logOut())}
              className="accept-new-agreement__main-buttons-item"
            />
          </div>
          {windowSize.innerWidth > 620 && (
            <Link
              to="/"
              className="accept-new-agreement__close-button"
              type="button"
              onClick={() => dispatch(logOut())}
              data-testid="close-button"
            >
              <IconClose className="accept-new-agreement__close-button-icon" />
            </Link>
          )}
        </div>
      </div>

      <SmallPopupWithEmoji
        className="small-popup-with-emoji"
        closePopup={() => setAcceptStatus(null)}
        emoji={iconSad}
        mounted={acceptStatus === 'FAIL'}
        buttonArray={[
          {
            id: 1,
            title: 'Попробовать еще раз',
            action: () => setAcceptStatus(null),
            colorType: 'blue',
          },
          {
            id: 2,
            title: 'Вернуться на главную',
            action: () => {
              setRedirect(true);
              dispatch(logOut());
            },
            colorType: 'grey-border',
          },
        ]}
        title="Упс!"
      >
        <p>
          К сожалению, что-то пошло не так и нам не удалось отправить запрос на сервер. Попробуйте
          повторить позже
        </p>
      </SmallPopupWithEmoji>
    </div>
  );
};

AcceptNewAgreement.propTypes = {
  user: PropTypes.shape({
    ofertaAccepted: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
};

AcceptNewAgreement.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export const AcceptNewAgreementTemplate = connect(mapStateToProps)(AcceptNewAgreement);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import emodjiCool from 'static/assets/icon-emoji-cool.png';
import emodjiGlad from 'static/assets/icon-emoji-glad.png';
import emodjiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

// Styles
import './style.scss';

export const ErrorTemplate = () => {
  const [isRendered, setIsRendered] = useState(false);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="error-page">
      <div className="error-page__container">
        <div className="error-page__text">
          <CSSTransition
            classNames="animation-from-top-to-bottom"
            in={isRendered}
            timeout={{ enter: 300, exit: 300 }}
            unmountOnExit
          >
            <h1 className="error-page__title">Ой, ошибочка вышла!</h1>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-top-to-bottom"
            in={isRendered}
            timeout={{ enter: 350, exit: 300 }}
            unmountOnExit
          >
            <div className="error-page__desc">
              Без паники, но такой страницы не существует. Возможно, она была перенесена или
              удалена.
              {'   '}
              <Link to="/" className="error-page__desc-link">
                Вернуться на главную
              </Link>
              .
            </div>
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-top-to-bottom"
          in={isRendered}
          timeout={{ enter: 400, exit: 300 }}
          unmountOnExit
        >
          <div className="error-page__hints">
            <div className="error-page__hints-item">
              <p className="error-page__hints-item-text">
                <a
                  href="https://самозанятые.рф/find-selfworkers?utm_source=pro.wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error-page__hints-item-link"
                >
                  Создайте
                </a>{' '}
                {'   '}
                или {'   '}
                <a
                  href="https://самозанятые.рф/selfworkers?utm_source=pro.wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error-page__hints-item-link"
                >
                  найдите
                </a>{' '}
                {'   '}
                новые задания в нашем маркетплейсе
                <img src={emodjiGlad} alt="" className="error-page__icon" />
              </p>

              <IconTriangle className="error-page__hints-item-triangle" />
            </div>

            <div className="error-page__hints-item">
              <p className="error-page__hints-item-text">
                <a
                  href="https://самозанятые.рф/business?utm_source=pro.wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error-page__hints-item-link"
                >
                  Зарегистрируйте своё юрлицо
                </a>{' '}
                {'   '}, автоматизируйте работу с самозанятыми
                <img src={emodjiPuzzled} alt="" className="error-page__icon" />
              </p>

              <IconTriangle className="error-page__hints-item-triangle" />
            </div>

            <div className="error-page__hints-item">
              <p className="error-page__hints-item-text">
                Идеи для бизнеса, видео истории, лайфхаки для самозанятых и все это в нашем {'   '}
                <a
                  href="https://самозанятые.рф/blog?utm_source=pro.wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="error-page__hints-item-link"
                >
                  блоге
                </a>
                <img src={emodjiCool} alt="" className="error-page__icon" />
              </p>

              <IconTriangle className="error-page__hints-item-triangle" />
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

import cx from 'classnames';
import { normalizePhone } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useRecaptcha } from 'react-recaptcha-hook';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { setUserPhone, startRecoveryPassword } from 'modules/authLegasy/actions';
import StandardHoverButton from 'modules/shared/components/StandardHoverButton/StandardHoverButton';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

export const PasswordRecoveryEnterPhone = ({
  onNextStepButtonClick,
  className,
  phone,
  dispatch,
  isMenuOpen,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRendered, setIsRendered] = useState(false);

  /** Инициализация капчи */
  const captchaExecute = useRecaptcha({
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    hideDefaultBadge: false,
  });

  /** Удаляем скрипты и элемент с капчей если уходим с этого щага регистрации */
  useEffect(() => {
    return () => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scripts = document.querySelectorAll("script[src*='recaptcha");
      if (scripts) {
        for (let i = 0; i < scripts.length; i += 1) {
          scripts[i].remove();
        }
      }
    };
  }, []);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Проверяем телефон */
  useEffect(() => {
    if (phone && phone.length === 11) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [phone]);

  const handleFieldChange = ({ target }) => {
    const normPhone = normalizePhone(target.value);
    dispatch(setUserPhone(normPhone));
  };

  const handleClick = () => {
    setIsLoading(true);
    setErrorMessage(null);

    captchaExecute('PasswordRecoveryEnterPhone')
      .then((token) => dispatch(startRecoveryPassword(phone, token)))
      .then(() => {
        setIsLoading(false);
        onNextStepButtonClick();
      })
      .catch((result) => {
        setErrorMessage((result && result.result && result.result.message) || 'Ошибка');
        setIsLoading(false);
      });
  };

  const onKeyUp = (event) => {
    if (event.key === 'Enter' && isValid) {
      handleClick();
    }
  };

  return (
    <div
      className={cx('password-recovery', {
        [className]: className,
      })}
      data-testid="password-recovery-enter-phone"
    >
      <div className="password-recovery__container container">
        <div className="password-recovery__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="password-recovery__title">Восстановить пароль</h1>
          </CSSTransition>

          <div className="password-recovery__form">
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="password-recovery__input-holder">
                <InputMask
                  type="tel"
                  value={phone}
                  onChange={handleFieldChange}
                  maskChar={null}
                  className="password-recovery__input"
                  mask="+7\ (999) 999-99-99"
                  placeholder="+ 7 (999) 999-99-99"
                  required
                  onKeyUp={onKeyUp}
                />
              </div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && Boolean(errorMessage)}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div
                className="password-recovery__error-wrap"
                data-testid="password-recovery-error-wrap"
              >
                <div className="password-recovery__error-message">{errorMessage}</div>
              </div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <div className="password-recovery__btn-wrap">
                <StandardHoverButton
                  title="Отправить код"
                  isLink={false}
                  isLoading={isLoading}
                  disabled={!isValid || isLoading}
                  onClick={handleClick}
                  className="password-recovery__button"
                />
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordRecoveryEnterPhone.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  phone: state.auth.phone,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(PasswordRecoveryEnterPhone);

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FnsErrorMessage from 'modules/profileLegasy/components/FnsErrorMessage/FnsErrorMessage';
import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';
import TaxesProgressBar from 'modules/tax/components/TaxesProgressBar/TaxesProgressBar';
import TaxesToPayCardBrief from 'modules/tax/components/TaxesToPayCardBrief/TaxesToPayCardBrief';
import TaxesToPayTooltip from 'modules/tax/components/TaxesToPayTooltip/TaxesToPayTooltip';

import { MONTHSRU } from 'constants/index';

import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

import './TaxesToPayCard.scss';

const TaxesToPayCard = ({
  className,
  taxInfo,
  isTaxInfoLoading,
  isTaxInfoError,
  taxInfoErrorMessage,
  prevMonthTax,
  isPrevMonthTaxLoading,
}) => {
  const windowSize = useWindowSize();
  const [isTooltipOpen, tooltipDropEl, tooltipToggleDrop] = useToggleAndOutClick();
  const [bonusLimit, setBonusLimit] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (taxInfo && (taxInfo.bonusAmount < 1000000 || taxInfo.bonusAmount === 1000000)) {
      setBonusLimit(1000000);
    }

    if (taxInfo && taxInfo.bonusAmount > 1000000) {
      setBonusLimit(2213000);
    }
  }, [taxInfo]);

  /** Считаем остаток налогового бонуса */
  useEffect(() => {
    if (taxInfo && taxInfo.bonusAmount) {
      /** Считаем прогресс полученного налогового бонуса к максимано возможному */
      setProgress(Math.floor((taxInfo.bonusAmount / bonusLimit) * 100));
    }
  }, [bonusLimit, taxInfo]);

  return (
    <div
      className={cx('taxes-to-pay-card', {
        [className]: className,
      })}
    >
      {isTaxInfoError ? (
        <FnsErrorMessage className="taxes-to-pay-card__fns-error" text={taxInfoErrorMessage} />
      ) : (
        <>
          <div className="taxes-to-pay-card__top">
            <p className="taxes-to-pay-card__title">Сумма налога:</p>

            <TaxesAmount
              className="taxes-to-pay-card__sum"
              isLoading={isTaxInfoLoading}
              amount={taxInfo && taxInfo.unpaidAmount}
            />

            {/* Если сумма налога к оплате (taxInfo.unpaidAmount) = 0 */}
            {taxInfo && !taxInfo.payUntil && (
              <div className="taxes-to-pay-card__message taxes-to-pay-card__message_early-to-pay">
                Платить пока рано
              </div>
            )}

            {/* Если сумма налога к оплате (taxInfo.unpaidAmount)
        не равна 0 и сегодняшнее число в периоде от 12 до 25 */}
            {taxInfo && taxInfo.payUntil && (
              <div className="taxes-to-pay-card__actions-wrap">
                <div className="taxes-to-pay-card__actions-link">
                  <div className="taxes-to-pay-card__message taxes-to-pay-card__message_time-to-pay">
                    Нужно оплатить налог
                  </div>
                </div>

                {/* <div className="taxes-to-pay-card__actions-link"> */}
                {/*  <div className="taxes-to-pay-card__get-receipt">Сформировать квитанцию</div> */}
                {/* </div> */}
              </div>
            )}
          </div>

          <div className="taxes-to-pay-card__main">
            {/* НАЛОГОВЫЙ БОНУС */}
            <div className="taxes-to-pay-card__bonus">
              <div className="taxes-to-pay-card__bonus-list">
                {/* ОСТАТОК НАЛОГОВОГО БОНУСА */}
                <div className="taxes-to-pay-card__item">
                  <p className="taxes-to-pay-card__item-title">Остаток</p>

                  <TaxesAmount
                    isLoading={isTaxInfoLoading}
                    amount={taxInfo && taxInfo.bonusAmount}
                    className="taxes-to-pay-card__item-sum"
                  />
                </div>
                {/* /ОСТАТОК НАЛОГОВОГО БОНУСА */}

                {/* НАЛОГОВЫЙ БОНУС */}
                <div className="taxes-to-pay-card__item">
                  <p className="taxes-to-pay-card__item-title">Налоговый бонус</p>

                  <TaxesAmount
                    isLoading={false}
                    amount={bonusLimit}
                    className="taxes-to-pay-card__item-sum"
                  />
                </div>
                {/* /НАЛОГОВЫЙ БОНУС */}
              </div>
              <TaxesProgressBar progress={progress} />
            </div>
            {/* /НАЛОГОВЫЙ БОНУС */}

            {/* ВЫРУЧКА ЗА ТЕКУЩИЙ МЕСЯЦ И ЗАДОЛЖЕННОСТЬ ПО ОПЛАТЕ НАЛОГОВ В ПРЕДЫДУЩИЕ МЕСЯЦЫ */}
            <div className="taxes-to-pay-card__brief">
              {/* НАЛОГ ЗА ПРЕДЫДУЩИЙ МЕСЯЦ */}
              <TaxesToPayCardBrief
                title={`Налог за ${MONTHSRU[
                  new Date().getMonth() !== 0 ? new Date().getMonth() - 1 : 11
                ].toLowerCase()}`}
                isLoading={isPrevMonthTaxLoading}
                amount={prevMonthTax}
              />
              {/* /НАЛОГ ЗА ПРЕДЫДУЩИЙ МЕСЯЦ */}

              {/* ПЕРЕПЛАТА */}
              <TaxesToPayCardBrief
                title="Переплата"
                isLoading={isTaxInfoLoading}
                amount={taxInfo && taxInfo.overpaymentAmount}
              />
              {/* /ПЕРЕПЛАТА */}

              {/* ЗАДОЛЖЕННОСТЬ ПО ОПЛАТЕ НАЛОГОВ В ПРЕДЫДУЩИЕ МЕСЯЦЫ */}
              <TaxesToPayCardBrief
                title="Задолженность"
                isLoading={isTaxInfoLoading}
                amount={taxInfo && taxInfo.debtAmount}
              />
              {/* /ЗАДОЛЖЕННОСТЬ ПО ОПЛАТЕ НАЛОГОВ В ПРЕДЫДУЩИЕ МЕСЯЦЫ */}

              {/* ПЕНИ */}
              <TaxesToPayCardBrief
                title="Пеня"
                isLoading={isTaxInfoLoading}
                amount={taxInfo && taxInfo.penaltyAmount}
              />
              {/* /ПЕНИ */}
            </div>
            {/* /ВЫРУЧКА ЗА ТЕКУЩИЙ МЕСЯЦ И ЗАДОЛЖЕННОСТЬ ПО ОПЛАТЕ НАЛОГОВ В ПРЕДЫДУЩИЕ МЕСЯЦЫ */}

            {/* КНОПКА ОПЛАТИТЬ НАЛОГ */}
            {/* <div className="taxes-to-pay-card__button-wrap"> */}
            {/*  <StandardButton */}
            {/*    className="taxes-to-pay-card__button" */}
            {/*    title=" Оплатить налог" */}
            {/*    isLink={false} */}
            {/*    onClick={() => setRedirectTaxPay(true)} */}
            {/*    disabled={(taxInfo && !taxInfo.payUntil) || isTaxInfoLoading || isTaxInfoError} */}
            {/*    bgType={BLUE_BORDER} */}
            {/*  /> */}
            {/* </div> */}
            {/* /КНОПКА ОПЛАТИТЬ НАЛОГ */}
          </div>

          {/* КНОПКА ОТКРЫТИЯ ТУЛТИПА */}
          <div
            className={cx('taxes-to-pay-card__tooltip-wrap', {
              'taxes-to-pay-card__tooltip-wrap_tooltip-open': isTooltipOpen,
            })}
          >
            <button
              className="taxes-to-pay-card__tooltip-btn"
              type="button"
              onClick={tooltipToggleDrop}
            >
              <IconInfo className="taxes-to-pay-card__tooltip-btn-icon" />
            </button>

            {/* ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА > 1194 */}
            {windowSize.innerWidth > 1194 && (
              <TaxesToPayTooltip isTooltipOpen={isTooltipOpen} ref={tooltipDropEl} />
            )}
            {/* /ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА > 1194 */}
          </div>
          {/* /КНОПКА ОТКРЫТИЯ ТУЛТИПА */}

          {/* ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА <= 1194 */}
          {windowSize.innerWidth <= 1194 && (
            <TaxesToPayTooltip isTooltipOpen={isTooltipOpen} ref={tooltipDropEl} />
          )}
          {/* /ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА <= 1194 */}
        </>
      )}
    </div>
  );
};

TaxesToPayCard.propTypes = {
  className: PropTypes.string,
  taxInfo: PropTypes.shape({
    currency: PropTypes.string,
    debtAmount: PropTypes.number,
    payUntil: PropTypes.string,
    totalAmount: PropTypes.number,
    unpaidAmount: PropTypes.number,
    bonusAmount: PropTypes.number,
    penaltyAmount: PropTypes.number,
    overpaymentAmount: PropTypes.number,
  }),
  isTaxInfoLoading: PropTypes.bool.isRequired,
  isTaxInfoError: PropTypes.bool,
  taxInfoErrorMessage: PropTypes.string,
  prevMonthTax: PropTypes.number,
  isPrevMonthTaxLoading: PropTypes.bool,
};

TaxesToPayCard.defaultProps = {
  className: null,
  taxInfo: {},
  isTaxInfoError: false,
  taxInfoErrorMessage: null,
  prevMonthTax: null,
  isPrevMonthTaxLoading: false,
};

export default TaxesToPayCard;

import React from 'react';

const HintHowToChangeData = () => (
  <>
    <div className="hint__text-wrap">
      <div className="hint__text">
        Если данные изменились, то для внесения изменений необходимо написать письмо в нашу
        поддержку клиентов по адресу:
        <a href="mailto:support@selfwork.ru" className="hint__text-link">
          support@selfwork.ru
        </a>
      </div>
    </div>
  </>
);

export default HintHowToChangeData;

import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen6 from 'static/assets/manual-screen-6.png';
import phone13 from 'static/assets/phone-13.png';

const ManualPageStepSzStatus3 = ({ returnBack, setStepCooperation }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <div className="manual-page__text-list">
            <div className="manual-page__text">
              После подтверждения запроса у вас будет подтвержден статус самозанятого в кошельке.
              Можете пользоваться всем функционалом сервиса.
            </div>

            <div className="manual-page__text">
              Отвязать права можно в любой момент, для этого просто отключите Самозанятые.рф в
              списке подключенных партнеров в приложении “Мой налог”. Либо в самом кошельке в
              настройках ЛК.
            </div>
          </div>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone13} alt="" className="manual-page__phone manual-page__phone_13" />
              <img src={screen6} alt="" className="manual-page__screen manual-page__screen_6" />
            </div>
          )}

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              onClick={setStepCooperation}
              className="manual-page__button-item"
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone13} alt="" className="manual-page__phone manual-page__phone_13" />
          <img src={screen6} alt="" className="manual-page__screen manual-page__screen_6" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepSzStatus3.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepCooperation: PropTypes.func.isRequired,
};

export default ManualPageStepSzStatus3;

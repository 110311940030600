/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { changeStatusDocAction } from '../documentList';
import {
  declineDocumentAPI,
  resignOfConfirmDocumentAPI,
  signConfirmDocumentAPI,
  signOfConfirmDocumentAPI,
} from './api';

/**
 * [Thunk] Запрос на отправку СМС-кода для авторизованного подтверждения документа
 * @return {data<obj>}
 */
export const signOfConfirmDocumentAPIThunk = createAsyncThunk(
  'confirmDocument/signOfConfirmAPIThunk',
  async ({ docPlatform, docId }, { rejectWithValue }) => {
    try {
      return await signOfConfirmDocumentAPI(docPlatform, docId);
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

/**
 * [Thunk] Запрос на повторную отправку СМС-кода для авторизованного подтверждения документа
 * @return {data<obj>}
 */
export const resignOfConfirmDocumentAPIThunk = createAsyncThunk(
  'confirmDocument/resignOfConfirmAPIThunk',
  async (_, { rejectWithValue, getState }) => {
    try {
      const id = getState().confirmDocument.confirmDocumentData?.id;
      return await resignOfConfirmDocumentAPI(id);
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

/**
 * [Thunk] Запрос для автоизованного подтверждения документа после получения СМС
 * @param {number} actionPinAcceptId - id операции из запроса getWalletClosingSmsCode
 * @param {string} pin - пинкод из смс
 * @return {data<obj>}
 */
export const signConfirmDocumentAPIThunk = createAsyncThunk(
  'confirmDocument/signConfirmAPIThunk',
  async ({ docId, actionPinAcceptId, pin }, { rejectWithValue, dispatch }) => {
    try {
      await signConfirmDocumentAPI(actionPinAcceptId, pin);
      dispatch(changeStatusDocAction({ id: docId, newState: 'SIGNED' }));
      return docId;
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

/**
 * [Thunk] Запрос на отклонение подтверждения документа
 * @param {'PERSONAL'|'BUSINESS'} docPlatform - платформа
 * @param {number} docId - ID документа
 * @return {data<obj>}
 */
export const declineDocumentAPIThunk = createAsyncThunk(
  'confirmDocument/declineAPIThunk',
  async ({ docPlatform, docId }, { rejectWithValue, dispatch }) => {
    try {
      await declineDocumentAPI(docPlatform, docId);
      dispatch(changeStatusDocAction({ id: docId, newState: 'REJECTED' }));
      return docId;
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PartnerBankCard from '../PartnerBankCard';
import { confirmPartnerRequest, hidePopupAddPartner } from '../../actions';
import { getAddPartnerRequest } from '../../selectors';

const PopupAddPartner = ({ dispatch, request }) => {
  const description = `Привязка банковской карты необходима для подтверждения запроса от ${request.partner.fullName} и получения выплат за выполненную работу.`;
  const notice =
    'Добавление новой карты не повлияет на другие настройки - вы можете привязывать разные карты к разным партнерам.';

  function onAddCard(cardNumber) {
    dispatch(confirmPartnerRequest(cardNumber, request.partner.platform, request.id));
  }

  function onClose() {
    dispatch(hidePopupAddPartner());
  }

  return createPortal(
    <PartnerBankCard
      title="Добавьте банковскую карту"
      description={description}
      notice={notice}
      buttonText="Готово"
      onFinish={onAddCard}
      onClose={onClose}
    />,
    document.body,
  );
};

PopupAddPartner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    partner: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
  }),
};

const mapStateToProps = (state) => ({
  request: getAddPartnerRequest()(state),
});

export default connect(mapStateToProps)(PopupAddPartner);

import { fetchWrapper } from 'helpers';

export const PLATFORM_NAME = new Map([
  ['B', 'BUSINESS'],
  ['P', 'PERSONAL'],
  ['T', 'TESTING'],
]);

/**
 * [API] Запрос для получении информации по документу
 * @param {string} id - id документа
 * @return {{
 *  id: string,
 *  name: string,
 *  status: 'SIGNED' | 'SEND_TO_SIGN' | 'REJECTED' | 'TERMINATED',
 *  time: number,
 *  autoSign: boolean,
 *  performer: string,
 *  customer: string,
 *  shA256Fingerprint: string,
 *  login: string,
 *  dateSms: string,
 * }}
 */
export const getDocumentsCertificateAPI = async (id, platform) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partnerships/${PLATFORM_NAME.get(
      platform,
    )}/documents/uuid/${id}`;
    const response = await fetchWrapper(url);

    if (response.result.code !== 200) throw new Error(response.result.message);
    return {
      id: response.data?.uuid,
      name: response.data?.documentName,
      status: response.data?.state,
      autoSign: response.data?.autoSign,
      performer: response.data?.fio,
      customer: response.data?.companyName,
      shA256Fingerprint: response.data?.checkSum,
      login: response.data?.login,
      dateSms: response.data?.dateSms,
      //Код был отправлен по SMS на номер +7******8888 19 ноября 2021 в 23:44
    };
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};

// /**
//  * [Mock] Заглушка сервиса для получении информации по документу
//  * @param {string} id - id документа: '0'  - SEND_TO_SIGN, '1' - SIGNED, '2' - REJECTED
//  * @return {{
//  *  id: '3289sdi3ksi3-1sd923kd-23kd-2dwfwd93',
//  *  name: 'Акт №3217 от 10.11.2021',
//  *  performer: 'Новиков Роман Викторович',
//  *  customer: 'ООО «Персональные решения»',
//  *  status: 'SIGNED' | 'SEND_TO_SIGN' | 'REJECTED',
//  *  time: 1637674908367,
//  *  signingMethod: 'Код был отправлен по SMS на номер +7******8888 19 ноября 2021 в 23:44',
//  *  shA256Fingerprint: '49cbe57e9503aa0c6d0cf1f88ec2b8e1dfb870c346fb9c82bc0489915108d75a',
//  * }}
//  */
// export const getDocumentsCertificateAPI = async (id) => {
//   await new Promise((resolve) => setTimeout(resolve, 1000));
//   return id === '0'
//     ? {
//         id: '3289sdi3ksi3-1sd923kd-23kd-2dwfwd93',
//         name: 'Акт №3217 от 10.11.2021',
//         performer: 'Новиков Роман Викторович',
//         customer: 'ООО «Персональные решения»',
//         status: 'SEND_TO_SIGN',
//       }
//     : id === '1'
//     ? {
//         id: '3289sdi3ksi3-1sd923kd-23kd-2dwfwd93',
//         name: 'Акт №3217 от 10.11.2021',
//         performer: 'Новиков Роман Викторович',
//         customer: 'ООО «Персональные решения»',
//         status: 'SIGNED',
//         signingMethod: 'Код был отправлен по SMS на номер +7******8888 19 ноября 2021 в 23:44',
//         shA256Fingerprint: '49cbe57e9503aa0c6d0cf1f88ec2b8e1dfb870c346fb9c82bc0489915108d75a',
//         time: 1637674908367,
//       }
//     : {
//         id: '3289sdi3ksi3-1sd923kd-23kd-2dwfwd93',
//         name: 'Акт №3217 от 10.11.2021',
//         performer: 'Новиков Роман Викторович',
//         customer: 'ООО «Персональные решения»',
//         status: 'REJECTED',
//       };
// };

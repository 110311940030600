import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
// Icons
import { ReactComponent as IconsResponsiveLogo } from 'static/assets/logo-responsive.svg';

// Styles
import './ResponsiveHeader.scss';

const ResponsiveHeader = ({ closePopupHandler, className, isCloseButtonLink, href }) => {
  return (
    <div
      className={cx('responsive-header', {
        [className]: className,
      })}
    >
      <Link to="/lk" className="responsive-header__logo-wrap">
        <IconsResponsiveLogo className="responsive-header__logo" />
      </Link>

      {isCloseButtonLink ? (
        <Link
          to={href || '/lk'}
          className="responsive-header__close"
          aria-label="Закрыть"
          onClick={closePopupHandler}
        >
          <IconClose className="responsive-header__close-icon" />
        </Link>
      ) : (
        <button
          type="button"
          className="responsive-header__close"
          aria-label="Закрыть"
          onClick={closePopupHandler}
        >
          <IconClose className="responsive-header__close-icon" />
        </button>
      )}
    </div>
  );
};

ResponsiveHeader.propTypes = {
  closePopupHandler: PropTypes.func,
  className: PropTypes.string,
  isCloseButtonLink: PropTypes.bool,
  href: PropTypes.string,
};

ResponsiveHeader.defaultProps = {
  closePopupHandler: () => {},
  className: null,
  isCloseButtonLink: false,
  href: null,
};

export default ResponsiveHeader;

import React from 'react';

const HintWhatIsMoneybox = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      <p>
        Копилка позволяет организовать сбор денег на любые нужные Вам цели. Например, это может быть
        подарок общему другу или совместная поездка. Этот сервис доступен и для физлиц, и для
        самозанятых.
      </p>

      <p>
        Вы создаете платежную форму, определяете срок сбора и отправляете ссылку или QR-код на эту
        форму всем участникам. Получателям ссылки или кода нужно просто указать платежные реквизиты
        и сумму, которую они готовы перечислить, и нажать “Оплатить.”{' '}
      </p>

      <p>
        Перечисленные участниками сбора деньги зачисляются на баланс Вашего кошелька. Отслеживать
        баланс и срок до окончания сбора по каждой копилке можно в разделе “Копим.”
      </p>

      <p>Всего можно создать до 10 копилок. Полученные деньги не регистрируются в ФНС как доход.</p>
    </div>
  </div>
);

export default HintWhatIsMoneybox;

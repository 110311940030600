import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PartnerPaymentsMenu from '../PartnerPaymentsMenu';
import PartnerPaymentsContainer from '../PartnerPaymentsContainer';
import PartnerPaymentsCheck from '../PartnerPaymentsCheck';
import PartnerPaymentsCalendar from '../PartnerPaymentsCalendar';
import PopupCheck from '../PopupCheck';
import PopupCalendar from '../PopupCalendar';

import { hideInlineCalendar, hidePopupCheck, hidePopupCalendar } from '../../actions';

import styles from './PartnerPaymentsTab.module.scss';
import { Redirect, useParams } from 'react-router-dom';
import { usePartnerIdSelector } from '../../../profileLegasy/selectors';

const PartnerPaymentsTab = ({
  dispatch,
  popupCheckVisible,
  popupCalendarVisible,
  inlineCalendarVisible,
  isCheckVisible,
  paymentListLength,
}) => {
  const { platformName, partnershipId } = useParams();
  const partnerId = usePartnerIdSelector(platformName, Number(partnershipId));
  React.useEffect(() => {
    return () => {
      dispatch(hideInlineCalendar());
      dispatch(hidePopupCheck());
      dispatch(hidePopupCalendar());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (popupCheckVisible) {
    return <PopupCheck />;
  }

  if (popupCalendarVisible) {
    return <PopupCalendar />;
  }

  if (!partnerId) {
    return <Redirect to="/lk/profile-settings/partners" />;
  }

  return (
    <div className={styles.partnerPaymentsTab}>
      <div className={styles.partnerPaymentsTabContent}>
        <PartnerPaymentsMenu />
        <PartnerPaymentsContainer />
        {inlineCalendarVisible && <PartnerPaymentsCalendar />}
      </div>
      <div className={styles.partnerPaymentsTabCheck}>
        {isCheckVisible && <PartnerPaymentsCheck />}
      </div>
    </div>
  );
};

PartnerPaymentsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  popupCheckVisible: PropTypes.bool.isRequired,
  popupCalendarVisible: PropTypes.bool.isRequired,
  inlineCalendarVisible: PropTypes.bool.isRequired,
  isCheckVisible: PropTypes.bool.isRequired,
  paymentListLength: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  popupCheckVisible: state.partners.layers.popupCheckVisible,
  popupCalendarVisible: state.partners.layers.popupCalendarVisible,
  inlineCalendarVisible: state.partners.layers.inlineCalendarVisible,
  isCheckVisible: state.partners.layers.checkVisible,
  paymentListLength: state.partners.payments.items.length,
});

export default connect(mapStateToProps)(PartnerPaymentsTab);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useInterval from 'react-useinterval';
import { useRecaptcha } from 'react-recaptcha-hook';

// Actions
import { requestCodeToChangePassword, changePassword } from 'modules/profileLegasy/actions';

// Components
import ChangePassword from 'modules/profileLegasy/components/ChangePassword/ChangePassword';
import ChangePasswordSuccess from 'modules/profileLegasy/components/ChangePasswordSuccess/ChangePasswordSuccess';
import OldPassword from 'modules/profileLegasy/components/OldPassword/OldPassword';
import CodeBlock from 'modules/shared/components/CodeBlock/CodeBlock';

// Styles
import './SettingNewPassword.scss';

const SettingNewPassword = (props) => {
  const { user, dispatch, smsVerification, password } = props;

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRequestOk, setIsRequestOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isCodeChecking, setIsCodeChecking] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [isTimeEnded, setIsTimeEnded] = useState(false);
  const [interval, setInterval] = useState(1000);

  const [oldPassword, setOldPassword] = useState('');
  const [isOldNewPasswordsNotEqual, setIsOldNewPasswordsNotEqual] = useState(false);

  /** Timer for resending sms code */
  const startTimer = () => {
    if (seconds > 0) {
      setSeconds((prevState) => prevState - 1);
    } else {
      setIsTimeEnded(true);
      setInterval(null);
    }
  };

  useInterval(startTimer, interval);

  /** Инициализация капчи */
  const captchaExecute = useRecaptcha({
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    hideDefaultBadge: false,
  });

  /** Удаляем скрипты и элемент с капчей если уходим с этого щага регистрации */
  useEffect(() => {
    return () => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scripts = document.querySelectorAll("script[src*='recaptcha");
      if (scripts) {
        for (let i = 0; i < scripts.length; i += 1) {
          scripts[i].remove();
        }
      }
    };
  }, []);

  /** Request to change password */
  const onChangePasswordRequest = () => {
    setIsError(false);
    setIsLoading(true);

    captchaExecute('SettingNewPassword')
      .then((token) => dispatch(requestCodeToChangePassword(password, oldPassword, token)))
      .then(() => {
        setIsLoading(false);
        setIsRequestOk(true);
      })
      .catch((result) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage((result && result.result && result.result.message) || 'Ошбика');
      });
  };

  /** Send sms code to change password */
  const onSendCode = (pin) => {
    setIsError(false);
    setIsCodeChecking(true);

    dispatch(changePassword(smsVerification.id, pin.join('')))
      .then(() => {
        setIsCodeChecking(false);
        setIsCodeValid(true);
      })
      .catch((result) => {
        setIsCodeChecking(false);
        setIsError(true);
        setErrorMessage(
          (result && result.result && result.result.message) || 'Вы ввели неверный код',
        );

        /** Блокировка пользователя при 410 */
        if (result && result.result && result.result.code === 410) {
          setIsUserBlocked(true);
        }
      });
  };

  /** Request new sms */
  const onRequestNewSms = () => {
    setIsError(false);
    setIsLoading(true);

    captchaExecute('SettingNewPassword')
      .then((token) => dispatch(requestCodeToChangePassword(password, oldPassword, token)))
      .then(() => {
        setIsLoading(false);
        setInterval(1000);
        setIsTimeEnded(false);
        setSeconds((smsVerification && smsVerification.resendTime) || 60);
        setIsRequestOk(true);
      })
      .catch((result) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage((result && result.result && result.result.message) || 'Ошибка');
      });
  };

  /** Get resendTime from server */
  useEffect(() => {
    setSeconds((smsVerification && smsVerification.resendTime) || 60);
  }, [smsVerification, dispatch]);

  /** Проверка на схожесть нового и текущего пароля */
  useEffect(() => {
    if (password !== '' && oldPassword !== '' && oldPassword === password) {
      setIsOldNewPasswordsNotEqual(true);
    } else {
      setIsOldNewPasswordsNotEqual(false);
    }
  }, [password, oldPassword]);

  return (
    <div className="setting-new-password">
      {!isRequestOk && !isCodeValid && (
        <>
          <OldPassword
            title="Введите старый пароль"
            onChangeOldPassword={setOldPassword}
            value={oldPassword}
          />

          <ChangePassword
            isLoading={isLoading}
            title="Установите новый пароль"
            login={user ? user.login : ''}
            onNextButtonClick={onChangePasswordRequest}
            errorMessage={
              isOldNewPasswordsNotEqual
                ? 'Новый пароль должен отличаться от текущего'
                : errorMessage
            }
            isOnSettingNewPasswordPage
            isOldNewPasswordsNotEqual={isOldNewPasswordsNotEqual}
          />
        </>
      )}

      {isRequestOk && !isCodeValid && (
        <CodeBlock
          className="setting-new-password__code-block"
          isInSidebar
          smsVerification={smsVerification}
          title="Введите код из СМС"
          onSendCode={onSendCode}
          onRequestNewSms={onRequestNewSms}
          isError={isError}
          errorMessage={errorMessage}
          isCodeValid={isCodeValid}
          isCodeChecking={isCodeChecking}
          isUserBlocked={isUserBlocked}
          isTimeEnded={isTimeEnded}
          seconds={seconds}
        />
      )}

      {isCodeValid && <ChangePasswordSuccess />}
    </div>
  );
};

SettingNewPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    login: PropTypes.string,
  }).isRequired,
  password: PropTypes.string,
  smsVerification: PropTypes.shape({
    id: PropTypes.number,
    resendTime: PropTypes.number,
    attempts: PropTypes.number,
  }),
};

SettingNewPassword.defaultProps = {
  password: null,
  smsVerification: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  password: state.auth.password,
  smsVerification: state.profile.smsVerification,
});

export default connect(mapStateToProps)(SettingNewPassword);

import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import Title from '../Title/Title';
import ProgressLine from '../ProgressLine/ProgressLine';
import Sidebar from '../Sidebar/Sidebar';
import Container from '../Container/Container';

import './Content.scss';

const Content = () => {
  const [isRendered, setIsRendered] = React.useState(false);
  const [isRedirected, setIsRedirected] = React.useState(false);
  const { paymentMethod } = useParams();

  React.useEffect(() => {
    if (!paymentMethod) {
      setIsRedirected(false);
      return;
    }

    switch (paymentMethod) {
      case 'bankcard':
      case 'sbp':
      case 'sz':
      case 'umoney':
      case 'qiwi':
      case 'webmoney':
        setIsRedirected(false);
        return;
      default:
        setIsRedirected(true);
    }
  }, [paymentMethod]);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  if (isRedirected) {
    return <Redirect to="/lk/money-transfer" />;
  }

  return (
    <CSSTransition
      classNames="animation-from-bottom-to-top"
      in={isRendered}
      timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
      unmountOnExit
    >
      <div className="money-transfer__content">
        <div className="money-transfer__content-header">
          <React.Fragment>
            <Title />
            <ProgressLine />
          </React.Fragment>
        </div>

        <div className="money-transfer__content-body">
          {!paymentMethod && (
            <React.Fragment>
              <div className="money-transfer__content-mobile">
                <Sidebar />
              </div>
              <div className="money-transfer__content-not-mobile">
                <Container />
              </div>
            </React.Fragment>
          )}
          {paymentMethod && (
            <React.Fragment>
              <div className="money-transfer__content-not-mobile">
                <Sidebar />
              </div>
              <div className="money-transfer__content-mobile">
                <Container />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default Content;

import cx from 'classnames';
import { changeLookOfPhone } from 'helpers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { toggleBusinessCard } from 'modules/profileLegasy/actions';
import { useDispatch } from 'react-redux';
import { AUTHORIZED, PARTLY_YES, REGISTERED, EDO, YES, IDENTIFIED } from 'constants/index';
import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';
import { ReactComponent as IconArrow } from 'static/assets/icon-dropdown-arrow.svg';
import { ReactComponent as IconShield } from 'static/assets/icon-shield.svg';
import { ReactComponent as IconUser } from 'static/assets/icon-user.svg';

// Styles
import './ProfileDropdown.scss';
import { checkPaymentEnabled } from '../../../../tools/utils/payments';
import { checkPaymentOnlyAuthorizedIdentifyEnabled } from '../../../../tools/utils/only-authorized';

const ProfileDropdown = React.forwardRef((props, ref) => {
  const {
    user,
    closeDrop,
    closeProfileDropFromSzLink,
    onLogOutButtonClick,
    avatar,
    className,
    szStatusFromDb,
    userTaxStatus,
    resetProfileActiveTab,
  } = props;

  const dispatch = useDispatch();

  /** Флаг, переключаем если кликнули на скопировать ссылку-визитку */
  const copiedUrl = `${window.location.origin}${process.env.PUBLIC_URL}/to/${
    user && user.randomUniqueId
  }`;

  const [isCopied, setIsCopied] = useState(false);
  const handleClickCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <>
      <div
        className={cx('profile-dropdown', {
          'profile-dropdown_self-employed': userTaxStatus === YES,
          [className]: className,
        })}
        ref={ref}
        data-cy="profile-dropdown"
        data-testid="profile-dropdown"
      >
        <div className="profile-dropdown__top">
          <Link
            to="/lk/profile-settings"
            className="profile-dropdown__user js-have-to-handle"
            onClick={closeDrop}
            title="Перейти в профиль"
          >
            <div className="profile-dropdown__user-avatar-wrap">
              <img
                src={avatar}
                alt="Изображение пользователя"
                className="profile-dropdown__user-avatar"
              />
            </div>
            <p className="profile-dropdown__user-phone">{changeLookOfPhone(user.login)}</p>
          </Link>

          <button type="button" className="profile-dropdown__close" onClick={closeDrop}>
            <IconArrow className="profile-dropdown__icon-close" />
          </button>
        </div>

        <div className="profile-dropdown__info">
          {user && user.group && user.group === REGISTERED && (
            <p className="profile-dropdown__name">Аноним</p>
          )}
          {user && user.group && user.group === EDO && (
            <p className="profile-dropdown__name">Есть доступ к ЭДО</p>
          )}

          {user &&
            user.group &&
            user.personal &&
            user.personal.firstName &&
            user.personal.lastName &&
            (user.group === AUTHORIZED || user.group === IDENTIFIED) && (
              <p className="profile-dropdown__name">
                {user.personal.firstName} {user.personal.lastName.slice(0, 1)}.
              </p>
            )}

          <div className="profile-dropdown__account-status-wrap">
            <Link
              to="/lk/profile-settings/account-type"
              onClick={() => {
                resetProfileActiveTab();
                closeDrop();
              }}
              className="profile-dropdown__account-status"
            >
              {user && user.group && user.personal && user.group === AUTHORIZED && (
                <React.Fragment>
                  <IconShield className="profile-dropdown__account-status-icon profile-dropdown__account-status-icon_auth-status" />
                  <p className="profile-dropdown__account-status-text">Авторизованная</p>
                </React.Fragment>
              )}
              {user && user.group && user.personal && user.group === REGISTERED && (
                <React.Fragment>
                  <IconUser className="profile-dropdown__account-status-icon profile-dropdown__account-status-icon_test" />
                  <p className="profile-dropdown__account-status-text">Неавторизованная</p>
                </React.Fragment>
              )}

              {user && user.group && user.personal && user.group === IDENTIFIED && (
                <React.Fragment>
                  <p className="profile-dropdown__account-status-text">Идентифицированная</p>
                </React.Fragment>
              )}
            </Link>
          </div>
        </div>

        <div className="profile-dropdown__balance-block">
          {user.clientAccounts && Array.isArray(user.clientAccounts) && user.clientAccounts[0] && (
            <div className="profile-dropdown__balance">
              {user.clientAccounts[0].balance !== 0 && (
                <>
                  <span className="profile-dropdown__balance-rubles">
                    {formatNumber(Math.floor(user.clientAccounts[0].balance / 100))}
                  </span>
                  ,
                  <span className="profile-dropdown__balance-penny">
                    {user.clientAccounts[0].balance.toString().slice(-2)} {'    '}
                  </span>
                </>
              )}
              {user.clientAccounts[0].balance === 0 && (
                <>
                  <span className="profile-dropdown__balance-rubles">
                    {user.clientAccounts[0].balance}
                  </span>
                  ,<span className="profile-dropdown__balance-penny">00 </span>
                </>
              )}
              ₽
            </div>
          )}

          <button
            className="profile-dropdown__balance-withdraw-link js-have-to-handle button"
            onClick={() => {
              closeDrop();
              dispatch(toggleBusinessCard());
            }}
          >
            Пополнить
          </button>
        </div>

        {(userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES)) && (
          <Link
            to="/lk/profile-settings/status"
            className="profile-dropdown__status js-have-to-handle"
            onClick={closeProfileDropFromSzLink}
          >
            Самозанятый
          </Link>
        )}

        {(!userTaxStatus ||
          (userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES))) && (
          <Link
            to="/self-employment-from-lk"
            onClick={closeDrop}
            className="profile-dropdown__status js-have-to-handle"
            title="Получить статус самозанятого"
          >
            Физ лицо
          </Link>
        )}

        {checkPaymentEnabled() && checkPaymentOnlyAuthorizedIdentifyEnabled(user) && (
          <div className="profile-dropdown__user-business-link-block">
            <CopyToClipboard text={copiedUrl} onCopy={handleClickCopy} data-testid="copy-button">
              <button type="button" className="profile-dropdown__user-business-link">
                <div className="profile-dropdown__user-business-link-inner">
                  <IconCopyContent className="profile-dropdown__icon-copy" />
                  Скопировать ссылку-визитку
                </div>
              </button>
            </CopyToClipboard>
            {isCopied && <span className="profile-dropdown__label-copy">скопировано</span>}
            <p className="profile-dropdown__user-business-link-descr">
              Любой, у кого эта ссылка, может перевести вам деньги
            </p>
          </div>
        )}

        <div className="profile-dropdown__nav-block">
          <nav className="profile-dropdown__nav">
            <div className="profile-dropdown__nav-item">
              <Link
                to="/lk/profile-settings"
                className="profile-dropdown__nav-item-link js-have-to-handle"
                onClick={closeDrop}
                data-cy="profile-dropdown-link-to-profile"
              >
                Профиль
              </Link>
            </div>
            <div className="profile-dropdown__nav-item">
              <Link
                to="/lk/profile-settings/partners"
                className="profile-dropdown__nav-item-link js-have-to-handle"
                onClick={closeDrop}
                data-cy="profile-dropdown-link-to-profile"
              >
                Партнеры
              </Link>
            </div>
            <div className="profile-dropdown__nav-item">
              <Link
                to="/lk/profile-settings/documents"
                className="profile-dropdown__nav-item-link js-have-to-handle"
                onClick={closeDrop}
                data-cy="profile-dropdown-link-to-profile"
              >
                Документы
              </Link>
            </div>
            <div className="profile-dropdown__nav-item">
              <button
                className="profile-dropdown__nav-item-link"
                type="button"
                onClick={onLogOutButtonClick}
              >
                Выйти
              </button>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
});

ProfileDropdown.propTypes = {
  user: PropTypes.shape({
    login: PropTypes.string,
    personal: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
    group: PropTypes.string.isRequired,
    randomUniqueId: PropTypes.string,
  }),
  closeDrop: PropTypes.func.isRequired,
  closeProfileDropFromSzLink: PropTypes.func,
  onLogOutButtonClick: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
  className: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  userTaxStatus: PropTypes.string,
  resetProfileActiveTab: PropTypes.func,
};

ProfileDropdown.defaultProps = {
  user: null,
  className: null,
  szStatusFromDb: '',
  userTaxStatus: null,
};

export default ProfileDropdown;

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { removeRedirect } from 'modules/authLegasy/actions';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import { PTChildrenElement } from 'tools/types/propTypes';

export const AccessRouteContainer = ({
  children: Children,
  isPrivate,
  locationDetector,
  needToAcceptNewPolicy,
}) => {
  const location = useLocation();
  const isUserAuth = useSelector((store) => store.auth.isUserAuth);
  const isUserGot = useSelector((store) => store.auth.isUserGot);
  const redirectTo = useSelector((store) => store.auth.redirectTo);

  const dispatch = useDispatch();

  if (!isUserGot) return <Loader inverted className="loader_main" />;
  if ((isUserAuth && isPrivate && !needToAcceptNewPolicy) || (!isUserAuth && !isPrivate)) {
    const componentProps = {};

    if (locationDetector)
      componentProps[locationDetector.propName] =
        location.pathname.indexOf(locationDetector.pattern) > -1;
    return Children;
  }
  if (isUserAuth && isPrivate && needToAcceptNewPolicy) return <Redirect to="/new-policy" />;
  if ((isPrivate && !isUserAuth) || needToAcceptNewPolicy) return <Redirect to="/" />;
  if (isPrivate && !needToAcceptNewPolicy && isUserAuth) return <Redirect to="/lk/accounting" />;
  if (!isPrivate && isUserAuth) {
    const localRedirectTo = redirectTo;
    if (localRedirectTo) dispatch(removeRedirect());
    return <Redirect to={localRedirectTo || '/lk'} />;
  }
  return null;
};

AccessRouteContainer.propTypes = {
  children: PTChildrenElement.isRequired,
  isPrivate: PropTypes.bool,
  locationDetector: PropTypes.shape({
    pattern: PropTypes.string,
    propName: PropTypes.string,
  }),
  needToAcceptNewPolicy: PropTypes.bool,
};

AccessRouteContainer.defaultProps = {
  locationDetector: null,
  needToAcceptNewPolicy: false,
  isPrivate: false,
};

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { createMoneyRequest, getMoneyRequestLimits } from 'modules/profileLegasy/actions';
import HintCommissionAndLimits from 'modules/profileLegasy/components/Hint/HintCommissionAndLimits';
import HintHowDoesItWork from 'modules/profileLegasy/components/Hint/HintHowDoesItWork';
import HintHowToKnowIfRequestPayed from 'modules/profileLegasy/components/Hint/HintHowToKnowIfRequestPayed';
import HintWhereAreMyRequests from 'modules/profileLegasy/components/Hint/HintWhereAreMyRequests';
import MoneyRequestCard from 'modules/profileLegasy/components/MoneyRequestCard/MoneyRequestCard';
import { getUserLimits } from 'modules/profileLegasy/selectors';
import HintsList from 'modules/shared/components/HintsList/HintsList';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import { ReactComponent as IconCoins } from 'static/assets/icon-coins.svg';
import profileBg from 'static/assets/profile-bg.png';

// Styles
import './MoneyRequest.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import { calculateCommission, findMinSum } from '../../../../tools/utils/commission-calculation';
import { maxSumDefault, minSumDefault } from '../../../../tools/constants';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

const LINK_TAB = 'LINK_TAB';
const EMAIL_TAB = 'EMAIL_TAB';

const MoneyRequest = ({ dispatch, userLimits, user }) => {
  const windowSize = useWindowSize();
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [currentOpenPopupId, setCurrentOpenPopupId] = useState(null);
  const [minSum, setMinSum] = useState(minSumDefault);
  const [maxSum, setMaxSum] = useState(maxSumDefault);
  // eslint-disable-next-line no-unused-vars
  const [commission, setCommission] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isFailedOverLimit, setIsFailedOverLimit] = useState(false);

  const [currentTabId, setCurrentTabId] = useState(LINK_TAB);

  const [link, setLink] = useState('');

  /** Берем лимиты для запроса на деньги */
  useEffect(() => {
    dispatch(getMoneyRequestLimits())
      .then((res) => {
        if (res && res.data) {
          setCommission(res.data.commission || null);
          if (res.data.hasOwnProperty('minSum') && res.data.hasOwnProperty('maxSum')) {
            setMinSum(findMinSum([res.data.minSum, res.data.maxSum]));
            setMaxSum(
              calculateCommission({
                sum: findMinSum([userLimits.balanceLimit, res.data.maxSum]),
                commission: res.data.commission || null,
                inverted: true,
              }),
            );
          }
        }
      })
      .catch(() => {});
  }, [dispatch, userLimits]);

  /** Хэндлер открытия попапа - как это работает */
  const handleClickHowDoesItWorkButton = () => {
    toggleDrop();
    setCurrentOpenPopupId(1);
  };

  /** Хэндлер открытия попапа - где хранятся мои запросы */
  const handleClickWhereAreMyRequestsButton = () => {
    toggleDrop();
    setCurrentOpenPopupId(2);
  };

  /** Хэндлер открытия попапа - как узнать, что мой запрос оплачен */
  const handleClickHowToKnowIfRequestPayedButton = () => {
    toggleDrop();
    setCurrentOpenPopupId(3);
  };

  /** Хэндлер открытия попапа - комиссия и лимиты */
  const handleClickCommissionAndLimitsButton = () => {
    toggleDrop();
    setCurrentOpenPopupId(4);
  };

  /** Закрытие любого попапа */
  const closeHintHandler = () => {
    closeExplicitly();
    setCurrentOpenPopupId(null);
  };

  /** Хэндлер отправки запроса на деньги */
  const handleSendRequestClick = (commentary, email, sum) => {
    setIsLoading(true);
    setLink('');

    dispatch(
      createMoneyRequest(commentary, currentTabId === EMAIL_TAB ? email : '', Number(+sum * 100)),
    )
      .then((res) => {
        setIsLoading(false);
        setIsSucceed(true);
        if (res && res.data) {
          setLink(`${window.location.origin}${process.env.PUBLIC_URL}/pay-it/${res.data}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err && err.data === 'pay-it.over-daily-limit') {
          setIsFailedOverLimit(true);
        }
        setIsFailed(true);
      });
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const hints = [
    {
      id: 1,
      title: 'Как это работает?',
      titleForPopup: 'Как это работает?',
      action: handleClickHowDoesItWorkButton,
      openCondition: currentOpenPopupId === 1 && isDropOpen,
      popup: <HintHowDoesItWork />,
      dropEl,
    },
    {
      id: 2,
      title: 'Где хранятся мои запросы?',
      titleForPopup: 'Где хранятся мои запросы?',
      action: handleClickWhereAreMyRequestsButton,
      openCondition: currentOpenPopupId === 2 && isDropOpen,
      popup: <HintWhereAreMyRequests />,
      dropEl,
    },
    {
      id: 3,
      title: 'Как узнать, что мой запрос оплачен?',
      titleForPopup: 'Как узнать, что мой запрос оплачен?',
      action: handleClickHowToKnowIfRequestPayedButton,
      openCondition: currentOpenPopupId === 3 && isDropOpen,
      popup: <HintHowToKnowIfRequestPayed />,
      dropEl,
    },
    {
      id: 4,
      title: 'Комиссия и лимиты',
      titleForPopup: 'Комиссия и лимиты',
      action: handleClickCommissionAndLimitsButton,
      openCondition: currentOpenPopupId === 4 && isDropOpen,
      popup: (
        <HintCommissionAndLimits
          additionalText={'Также действует лимит на создание запроса денег — 20 раз в сутки.'}
        />
      ),
      dropEl,
    },
  ];

  return (
    <>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <div className="money-request">
        {/* ЗАГОЛОВОК ПРИ ШИРИНЕ ЭКРАНА > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="money-request__top">
            {!isSucceed && !isFailed && <h1 className="money-request__title">Новый запрос</h1>}

            {isSucceed && <h1 className="money-request__title">Запрос отправлен</h1>}

            {isFailed && <h1 className="money-request__title">Запрос не отправлен</h1>}
          </div>
        </CSSTransition>
        {/* /ЗАГОЛОВОК ПРИ ШИРИНЕ ЭКРАНА > 980 */}

        {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА < 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div
            className={cx('money-request__header', {
              'money-request__header_fail': isFailed,
            })}
          >
            {!isFailed && (
              <div className="money-request__header-icon-wrap">
                <IconCoins className="money-request__header-icon" />
              </div>
            )}

            {!isSucceed && !isFailed && (
              <h1 className="money-request__header-title">Новый запрос</h1>
            )}

            {isSucceed && <h1 className="money-request__header-title">Запрос отправлен</h1>}

            {isFailed && <h1 className="money-request__header-title">Запрос не отправлен</h1>}

            <img src={profileBg} alt="Фоновая картинка" className="money-request__header__bg" />
          </div>
        </CSSTransition>
        {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА < 980 */}

        <div className="money-request__content">
          {/* КАРТОЧКА ЗАПРОСА ДЕНЕГ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="money-request__card-wrap">
              <MoneyRequestCard
                className="money-request__card"
                handleSubmitClick={handleSendRequestClick}
                isSucceed={isSucceed}
                isFailed={isFailed}
                isLoading={isLoading}
                link={link}
                minSum={Number(minSum)}
                maxSum={Number(maxSum)}
                isFailedOverLimit={isFailedOverLimit}
                linkTabCLick={() => setCurrentTabId(LINK_TAB)}
                emailTabCLick={() => setCurrentTabId(EMAIL_TAB)}
                currentTabId={currentTabId}
              />

              {isSucceed && (
                <div className="money-request__card-link-wrap">
                  <Link to="/lk" className="money-request__card-link">
                    Перейти в кошелек
                  </Link>
                </div>
              )}
            </div>
          </CSSTransition>
          {/* /КАРТОЧКА ЗАПРОСА ДЕНЕГ */}

          {/* ПОЛЕЗНЫЕ ССЫЛКИ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="money-request__useful-links">
              <HintsList hints={hints} closeHintHandler={closeHintHandler} />
            </div>
          </CSSTransition>
          {/* /ПОЛЕЗНЫЕ ССЫЛКИ */}
        </div>

        {/* ПРЕЛОАДЕР */}
        {/* {isLoading && <Loader inverted className="loader_main" />} */}
        {/* /ПРЕЛОАДЕР */}
      </div>
    </>
  );
};

MoneyRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userLimits: PropTypes.shape({
    minSum: PropTypes.number.isRequired,
    balanceLimit: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userLimits: getUserLimits()(state),
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoneyRequest);

import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import phone2 from 'static/assets/phone-2.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepMyTax2 = ({
  returnBack,
  setStepGoToMyTaxPassport,
  setStepGoToMyTaxLk,
  setStepGoToMyTaxGosuslugi,
}) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">Запустите приложение и выберите способ регистрации</h1>

          {windowSize.innerWidth > 760 && (
            <>
              <img src={phone2} alt="" className="manual-page__phone manual-page__phone_2" />

              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
              <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_3" />
            </>
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <div className="manual-page__text-list">
            <div className="manual-page__text">
              По паспорту: это удобно, если нет доступа в личный кабинет налогоплательщика-физлица.
              Потребуется работающая камера на телефоне, так как вам нужно будет сфотогорафировать
              основные страницы паспорта, и сделать селфи;
            </div>

            <div className="manual-page__text">
              С помощью личного кабинета налогоплательщика: это самый простой вариант. Камера на
              устройстве и паспорт не нужен. Вам понадобится ввести ИНН и пароль от вашего ЛК;
            </div>

            <div className="manual-page__text">
              Через портал Госуслуг: паспорт и фотография не понадобятся. Нужны будут телефон, или
              почта, или СНИЛС и пароль, которые вы используете для входа на Госуслуги.
            </div>
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="По паспорту"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToMyTaxPassport}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Через ЛК налогоплательщика"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToMyTaxLk}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Через портал Госуслуг"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToMyTaxGosuslugi}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepMyTax2.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToMyTaxPassport: PropTypes.func.isRequired,
  setStepGoToMyTaxLk: PropTypes.func.isRequired,
  setStepGoToMyTaxGosuslugi: PropTypes.func.isRequired,
};

export default ManualPageStepMyTax2;

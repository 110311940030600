import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';

import './AcquiringGettingStep.scss';

const AcquiringGettingStep = ({
  isDisabled,
  id,
  title,
  className,
  onClick,
  activeStep,
  isGotten,
}) => {
  const windowSize = useWindowSize();

  if ((windowSize.innerWidth <= 980 && id === activeStep) || windowSize.innerWidth > 980) {
    return (
      <button
        className={cx('acquiring-getting-step', {
          [className]: className,
          'acquiring-getting-step_active': activeStep === id,
          'acquiring-getting-step_gotten': isGotten,
        })}
        type="button"
        disabled={isDisabled}
        onClick={onClick}
        data-testid="acquiring-getting-step"
      >
        <span className="acquiring-getting-step__content">
          <p className="acquiring-getting-step__text">Шаг {id}</p>
          <span className="acquiring-getting-step__title">{title}</span>
        </span>

        {isGotten && <IconCheck className="acquiring-getting-step__check-icon" />}
      </button>
    );
  }
  return '';
};

AcquiringGettingStep.propTypes = {
  isDisabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  activeStep: PropTypes.number.isRequired,
  isGotten: PropTypes.bool.isRequired,
};

AcquiringGettingStep.defaultProps = {
  isDisabled: false,
  className: null,
  onClick: () => {},
  isGotten: false,
};

export default AcquiringGettingStep;

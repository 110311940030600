// исключить из объекта src поля, перечисленные в keys
// src: object
// keys: string[]
export default function omit(src, keys = []) {
  const obj = {};

  for (const key in src) {
    const value = src[key];

    if (keys.includes(key)) {
      continue;
    }

    obj[key] = value;
  }

  return obj;
}

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import addDays from 'date-fns/addDays';
import { formatDateHuman, setHoursAndMinutesToZero, setHoursAndMinutesTheEndOfDay } from 'helpers';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  setIsOperationsCalendarFilterOpen,
  setSearchDatesRange,
  setSearchWord,
} from 'modules/acquiring/actions';
import CalendarRangePicker from 'modules/shared/components/CalendarRangePicker/CalendarRangePicker';

import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close-small.svg';
import { ReactComponent as IconDownload } from 'static/assets/icon-download.svg';
import { ReactComponent as IconFilters } from 'static/assets/icon-filters.svg';
import { ReactComponent as IconSearch } from 'static/assets/icon-search.svg';

import './FiltersBar.scss';

const FiltersBar = ({
  className,
  openFiltersSidebar,
  isFiltersChosen,
  datesRange,
  dispatch,
  searchWord,
  isOperationsCalendarFilterOpen,
}) => {
  const windowSize = useWindowSize();
  const [dates, setDates] = useState([
    {
      startDate: new Date(setHoursAndMinutesToZero(addDays(new Date(), -30))),
      endDate: new Date(setHoursAndMinutesTheEndOfDay(new Date())),
      key: 'selection',
    },
  ]);
  const [localSearchWord, setLocalSearchWord] = useState('');
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();

  const clearSearchWord = () => {
    dispatch(setSearchWord(''));
    setLocalSearchWord('');
  };

  useEffect(() => {
    if (!localSearchWord) {
      dispatch(setSearchWord(''));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [localSearchWord]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (dates && dates[0] && dates[0].startDate && dates[0].endDate) {
      dispatch(
        setSearchDatesRange([
          {
            startDate: new Date(setHoursAndMinutesToZero(dates[0].startDate)),
            endDate: new Date(setHoursAndMinutesTheEndOfDay(dates[0].endDate)),
            key: 'selection',
          },
        ]),
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dates]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={cx('filters-bar', {
        [className]: className,
      })}
    >
      <div className="filters-bar__search-wrap">
        <input
          type="text"
          className={cx('filters-bar__search-input', {
            'filters-bar__search-input_filled': localSearchWord !== '',
          })}
          placeholder="Транзакция, заказ, покупатель"
          value={localSearchWord}
          onChange={(event) => setLocalSearchWord(event.target.value)}
        />
        {searchWord ? (
          <button className="filters-bar__search-button" type="button" onClick={clearSearchWord}>
            <IconClose className="filters-bar__clear-search-icon" />
          </button>
        ) : (
          <button
            className="filters-bar__search-button"
            type="button"
            onClick={() => dispatch(setSearchWord(localSearchWord))}
          >
            <IconSearch className="filters-bar__search-icon" />
          </button>
        )}
      </div>

      <div className="filters-bar__date-wrap">
        <input
          type="text"
          className={cx('filters-bar__date-input', {
            'filters-bar__date-input_filled':
              datesRange && datesRange[0] && datesRange[0].startDate && datesRange[0].endDate,
          })}
          onClick={() => {
            toggleDrop();
            dispatch(setIsOperationsCalendarFilterOpen(!isOperationsCalendarFilterOpen));
          }}
          value={
            datesRange
              ? `${formatDateHuman(datesRange[0].startDate)} - ${formatDateHuman(
                  datesRange[0].endDate,
                )}`
              : ''
          }
          placeholder="Период"
          readOnly
        />

        {datesRange &&
        datesRange[0] &&
        setHoursAndMinutesToZero(datesRange[0].startDate).getTime() ===
          setHoursAndMinutesToZero(addDays(new Date(), -30)).getTime() &&
        setHoursAndMinutesToZero(datesRange[0].endDate).getTime() ===
          setHoursAndMinutesToZero(new Date()).getTime() ? (
          <IconCalendar
            className="filters-bar__date-icon"
            onClick={() => {
              toggleDrop();
              dispatch(setIsOperationsCalendarFilterOpen(!isOperationsCalendarFilterOpen));
            }}
          />
        ) : (
          <button
            className="filters-bar__date-clear"
            type="button"
            onClick={() =>
              dispatch(
                setSearchDatesRange([
                  {
                    startDate: new Date(setHoursAndMinutesToZero(addDays(new Date(), -30))),
                    endDate: new Date(setHoursAndMinutesTheEndOfDay(new Date())),
                    key: 'selection',
                  },
                ]),
              )
            }
          >
            <IconClose className="filters-bar__date-clear-icon" />
          </button>
        )}
      </div>

      <div className="filters-bar__buttons-wrap">
        <button className="filters-bar__download" type="button">
          <IconDownload className="filters-bar__download-icon" />
          {windowSize.innerWidth <= 480 && <p className="filters-bar__button-text">Скачать</p>}
        </button>

        <button
          className={cx('filters-bar__indicator', {
            'filters-bar__indicator_chosen': isFiltersChosen,
          })}
          type="button"
          onClick={openFiltersSidebar}
        >
          <IconFilters className="filters-bar__indicator-icon" />
          {windowSize.innerWidth <= 480 && <p className="filters-bar__button-text">Фильтры</p>}
        </button>
      </div>

      {isDropOpen && (
        <CalendarRangePicker
          ref={dropEl}
          className="filters-bar__calendar-drop"
          range={dates}
          onChange={setDates}
          onCancel={() => {
            closeExplicitly();
            dispatch(setIsOperationsCalendarFilterOpen(false));
          }}
          onAccept={() => {
            dispatch(
              setSearchDatesRange([
                {
                  startDate: new Date(setHoursAndMinutesToZero(dates[0].startDate)),
                  endDate: new Date(setHoursAndMinutesTheEndOfDay(dates[0].endDate)),
                  key: 'selection',
                },
              ]),
            );
            closeExplicitly();
            dispatch(setIsOperationsCalendarFilterOpen(false));
          }}
        />
      )}
    </div>
  );
};

FiltersBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  openFiltersSidebar: PropTypes.func.isRequired,
  isFiltersChosen: PropTypes.bool.isRequired,
  datesRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    }),
  ),
  searchWord: PropTypes.string,
  isOperationsCalendarFilterOpen: PropTypes.bool.isRequired,
};

FiltersBar.defaultProps = {
  className: null,
  datesRange: null,
  searchWord: null,
};

const mapStateToProps = (state) => ({
  datesRange: state.acquiring.datesRange,
  searchWord: state.acquiring.searchWord,
  isOperationsCalendarFilterOpen: state.acquiring.isOperationsCalendarFilterOpen,
});

export default connect(mapStateToProps)(FiltersBar);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';
import { animated, useSpring } from 'react-spring';

import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import PersonalDataNoticeText from 'modules/profileLegasy/components/PersonalDataNoticeText/PersonalDataNoticeText';
import ChangePersonalDataWrap from 'modules/profileLegasy/components/ChangePersonalDataWrap/ChangePersonalDataWrap';
import ConfirmPersonalDataWrap from 'modules/profileLegasy/components/ConfirmPersonalDataWrap/ConfirmPersonalDataWrap';

import { REGISTERED } from 'constants/index';

import './ProfileSettingsPersonalDataTab.scss';

export const ProfileSettingsPersonalDataTab = ({
  userLastName,
  userFirstName,
  userMiddleName,
  userPassportSerie,
  userPassportNumber,
  userInn,
  userPersonalData,
  userAuthStatus,
  isMenuOpen,
}) => {
  const windowSize = useWindowSize();

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <animated.div
      className="profile-settings-personal-data-tab"
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
      data-testid="profile-settings-personal-data-tab"
    >
      {windowSize.innerWidth > 980 && (
        <h2 className="profile-settings-personal-data-tab__title">Личные данные</h2>
      )}

      <div className="profile-settings-personal-data-tab__content-wrap">
        <div className="profile-settings-personal-data-tab__content">
          {userPersonalData && (
            <>
              {/* ФАМИЛИЯ */}
              <InputWithLabelAnimation
                value={userLastName}
                label="Фамилия"
                disabled
                className="profile-settings-personal-data-tab__input-holder"
              />
              {/* /ФАМИЛИЯ */}

              {/* ИМЯ */}
              <InputWithLabelAnimation
                value={userFirstName}
                label="Имя"
                disabled
                className="profile-settings-personal-data-tab__input-holder"
              />
              {/* /ИМЯ */}

              {/* ОТЧЕСТВО */}
              <InputWithLabelAnimation
                value={userMiddleName}
                label="Отчество"
                disabled
                className="profile-settings-personal-data-tab__input-holder"
              />
              {/* /ОТЧЕСТВО */}

              {/* ИНН */}
              <InputWithLabelAnimation
                value={
                  userInn
                    ? `${userInn.slice(0, 4)}-${userInn.slice(4, 8)}-${userInn.slice(8, 12)}`
                    : ''
                }
                label="ИНН"
                disabled
                className="profile-settings-personal-data-tab__input-holder"
              />
              {/* /ИНН */}

              {/* ДОКУМЕНТ */}
              <InputWithLabelAnimation
                value="Паспорт"
                label="Документ"
                disabled
                className="profile-settings-personal-data-tab__input-holder profile-settings-personal-data-tab__input-holder_focused"
              />
              {/* /ДОКУМЕНТ */}

              {/* ПАСПОРТ И СЕРИЯ */}
              <div className="profile-settings-personal-data-tab__input-list">
                <InputWithLabelAnimation
                  value={userPassportSerie}
                  label="Серия"
                  disabled
                  className="profile-settings-personal-data-tab__input-holder"
                />

                <InputWithLabelAnimation
                  value={userPassportNumber}
                  label="Номер"
                  disabled
                  className="profile-settings-personal-data-tab__input-holder"
                />
              </div>
              {/* /ПАСПОРТ И СЕРИЯ */}
            </>
          )}
        </div>

        {/* КНОПКИ ДЛЯ ОТКРЫТИЯ ПОПАПОВ + СООБЩЕНИЕ ДЛЯ ЮЗЕРА */}
        {userAuthStatus === REGISTERED ? (
          <div className="profile-settings-personal-data-tab__additional-info">
            {/* ССЫКЛКА ДЛЯ ОТКРЫТИЯ ПОПАПА */}
            <div
              className={
                'profile-settings-personal-data-tab__additional-info__confirmPersonalDataWrap'
              }
            >
              <ConfirmPersonalDataWrap isMenuOpen={isMenuOpen} />
            </div>
            {/* /ССЫКЛИ ДЛЯ ОТКРЫТИЯ ПОПАПОВ */}

            {/* СООБЩЕНИЕ - ДАННЫЕ ЗАПОЛНЯЮТСЯ АВТОМАТИЧЕСКИ */}
            <PersonalDataNoticeText />
            {/* /СООБЩЕНИЕ - ДАННЫЕ ЗАПОЛНЯЮТСЯ АВТОМАТИЧЕСКИ */}
          </div>
        ) : (
          <div className="profile-settings-personal-data-tab__additional-info">
            <ChangePersonalDataWrap isMenuOpen={isMenuOpen} />
          </div>
        )}
        {/* /КНОПКИ ДЛЯ ОТКРЫТИЯ ПОПАПОВ + СООБЩЕНИЕ ДЛЯ ЮЗЕРА */}
      </div>
    </animated.div>
  );
};

ProfileSettingsPersonalDataTab.propTypes = {
  userLastName: PropTypes.string,
  userFirstName: PropTypes.string,
  userMiddleName: PropTypes.string,
  userPassportSerie: PropTypes.string,
  userPassportNumber: PropTypes.string,
  userInn: PropTypes.string,
  userPersonalData: PropTypes.shape({}),
  userAuthStatus: PropTypes.string,
  isMenuOpen: PropTypes.bool,
};

ProfileSettingsPersonalDataTab.defaultProps = {
  userLastName: '',
  userFirstName: '',
  userMiddleName: '',
  userPassportSerie: '',
  userPassportNumber: '',
  userInn: '',
  userPersonalData: null,
  userAuthStatus: null,
  isMenuOpen: false,
};

const mapStateToProps = (state) => ({
  userPersonalData: state.auth.user && state.auth.user.personal ? state.auth.user.personal : null,
  userLastName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.lastName
      ? state.auth.user.personal.lastName
      : '',
  userFirstName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.firstName
      ? state.auth.user.personal.firstName
      : '',
  userMiddleName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.middleName
      ? state.auth.user.personal.middleName
      : '',
  userPassportSerie:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.passportSerie
      ? state.auth.user.personal.passportSerie
      : '',
  userPassportNumber:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.passportNumber
      ? state.auth.user.personal.passportNumber
      : '',
  userInn:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.inn
      ? state.auth.user.personal.inn
      : '',
  userAuthStatus: state.auth.user && state.auth.user.group ? state.auth.user.group : null,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(ProfileSettingsPersonalDataTab);

import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen5 from 'static/assets/manual-screen-5.png';
import phone12 from 'static/assets/phone-12.png';

const ManualPageStepSzStatus2 = ({ returnBack, setStepGoToSzConfirmation }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  const szStatus2List = [
    {
      id: 1,
      text:
        'Если на вашем телефоне установлено приложение “Мой налог”, то у вас, скорее всего, сразу появится push-уведомление о предоставлении доступа. Вам нужно просто согласиться с запросом;',
    },
    {
      id: 2,
      text: (
        <>
          Если уведомление не пришло, можно в приложении “Мой налог” зайти в{' '}
          <b>Настройки ➜ Партнёры</b>, в списке найти <b>Самозанятые.рф</b> и подтвердить запрос.
        </>
      ),
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <div className="manual-page__text">
            Далее потребуется указать свой ИНН. После чего нужно будет предоставить права доступа
            сервиса к вашим данным в “Мой налог”:
          </div>

          <ol className="manual-page__text-list">
            {szStatus2List.map(item => (
              <li className="manual-page__list-item" key={item.id}>
                <p className="manual-page__list-item-number">—</p>
                <div className="manual-page__list-item-text">{item.text}</div>
              </li>
            ))}
          </ol>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone12} alt="" className="manual-page__phone manual-page__phone_12" />
              <img src={screen5} alt="" className="manual-page__screen manual-page__screen_5" />
            </div>
          )}

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToSzConfirmation}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone12} alt="" className="manual-page__phone manual-page__phone_12" />
          <img src={screen5} alt="" className="manual-page__screen manual-page__screen_5" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepSzStatus2.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToSzConfirmation: PropTypes.func.isRequired,
};

export default ManualPageStepSzStatus2;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import HintSelfEmployed from 'modules/authLegasy/components/Hint/HintSelfEmployed';
import SESteps from 'modules/profileLegasy/components/SESteps/SESteps';
import Balloon from 'modules/shared/components/Balloon/Balloon';
import GetSzInnInput from 'modules/shared/components/GetSzInnInput/GetSzInnInput';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE, CONSISTENT_ANIMATIONS_DELAY, NO_PERMISSION } from 'constants/index';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import iconRock from 'static/assets/icon-emoji-rock.png';
import profileBg from 'static/assets/profile-bg.png';

const SUCCESS = 'SUCCESS';

export const SEFromLKSuccess = ({ className, inn, userTaxStatus, userTaxInn, onNoRights }) => {
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  /** Проверяем, если овтет с сервера NO_PERMISSION перекидываем на первый этап,
   * ввод номера ИНН */
  useEffect(() => {
    if (userTaxStatus === NO_PERMISSION) {
      onNoRights();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userTaxStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const hints = [
    {
      id: 1,
      title: 'Что дает статус самозанятого?',
      titleForPopup: 'Что дает статус самозанятого?',
      action: toggleDrop,
      openCondition: isDropOpen,
      popup: <HintSelfEmployed />,
      dropEl,
    },
  ];

  return (
    <div
      className={cx('se-from-lk', {
        [className]: className,
      })}
      data-testid="se-from-lk-success"
    >
      {/* ХЭДЕР */}
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="se-from-lk__header">
          <div className="se-from-lk__header-container container container_small">
            <div className="se-from-lk__header-content">
              {/* ПРОГРЕСС ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <SESteps currentStep={3} totalSteps={3} className="se-from-lk__header-step" />
              </CSSTransition>
              {/* /ПРОГРЕСС ПРИ ШИРИНЕ ЭКРАНА > 980 */}

              {/* ЗАГОЛОВОК */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <h1 className="se-from-lk__header-title">Запрос успешно подтвержден</h1>
              </CSSTransition>
              {/* /ЗАГОЛОВОК */}

              {/* СООБЩЕНИЕ ОБ УСПХЕ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-from-lk__header-desc">
                  Вы предоставили нам права. Теперь можете пользоваться всеми разделами сервиса
                </div>
              </CSSTransition>
              {/* СООБЩЕНИЕ ОБ УСПХЕ ПРИ ШИРИНЕ ЭКРАНА > 980 */}

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <div className="se-from-lk__form">
                  <GetSzInnInput
                    value={userTaxInn || inn}
                    status={SUCCESS}
                    disabled
                    readOnly
                    className="se-from-lk__form-input-holder"
                    isEditButton={false}
                  />
                </div>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <Balloon className="se-from-lk__info">
                  <div className="balloon__text">
                    Ура! Все отлично. Наслаждайтесь сервисом по полной!
                  </div>

                  <img
                    src={iconRock}
                    alt=""
                    className="balloon__icon se-from-lk__info-icon_wink"
                    data-testid="balloon-emoji"
                  />
                </Balloon>
              </CSSTransition>
            </div>
          </div>
          <img src={profileBg} alt="Profile bg" className="se-from-lk__header-bg" />

          <Link to="/lk/profile-settings/status" className="se-from-lk__header-close" type="button">
            {windowSize.innerWidth > 980 ? (
              <IconClose className="partial-status__header-close-icon" />
            ) : (
              <IconArrow className="se-from-lk__header-close-icon" />
            )}
          </Link>

          {windowSize.innerWidth <= 980 && (
            <SESteps currentStep={3} totalSteps={3} className="se-from-lk__header-step" />
          )}
        </div>
      </CSSTransition>
      {/* /ХЭДЕР */}

      <div className="se-from-lk__content container container_small">
        {/* СООБЩЕНИЕ ОБ УСПХИ ПРИ ШИРИНЕ ЭКРАНА <= 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <div className="se-from-lk__header-desc">
            Вы предоставили нам права. Теперь можете пользоваться всеми разделами сервиса
          </div>
        </CSSTransition>
        {/* /СООБЩЕНИЕ ОБ УСПХИ ПРИ ШИРИНЕ ЭКРАНА <= 980 */}

        {/* ПОЛЕЗНЫЕ ССЫЛКИ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <>
            <StandardButton
              title="Перейти в кошелек"
              isLink
              href="/lk"
              bgType={BLUE}
              className="se-from-lk__wallet-btn-wrap"
            />
            <HintsList
              hints={hints}
              closeHintHandler={closeExplicitly}
              className="se-from-lk__hint-block"
              title="Вам будет полезно"
            />
          </>
        </CSSTransition>
        {/* /ПОЛЕЗНЫЕ ССЫЛКИ ПРИ ШИРИНЕ ЭКРАНА > 980 */}

        <div className="se-from-lk__form">
          {/* ИНПУТ С НОМЕРОМ ИНН ПРИ ШИРИНЕ ЭКРАНА <= 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <GetSzInnInput
              value={userTaxInn || inn}
              status={SUCCESS}
              disabled
              readOnly
              className="se-from-lk__form-input-holder"
              isEditButton={false}
            />
          </CSSTransition>
          {/* /ИНПУТ С НОМЕРОМ ИНН ПРИ ШИРИНЕ ЭКРАНА <= 980 */}

          {/* ССЫЛКА ПЕРЕХОДА В ЛК ПРИ ШИРИНЕ ЭКРАНА <= 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
            unmountOnExit
          >
            <StandardButton
              title="Перейти в кошелек"
              isLink
              href="/lk"
              bgType={BLUE}
              className="se-from-lk__wallet-btn-wrap"
            />
          </CSSTransition>
          {/* /ССЫЛКА ПЕРЕХОДА В ЛК ПРИ ШИРИНЕ ЭКРАНА <= 980 */}
        </div>
      </div>
    </div>
  );
};

SEFromLKSuccess.propTypes = {
  className: PropTypes.string,
  inn: PropTypes.string.isRequired,
  userTaxStatus: PropTypes.string,
  userTaxInn: PropTypes.string,
  onNoRights: PropTypes.func.isRequired,
};

SEFromLKSuccess.defaultProps = {
  className: null,
  userTaxStatus: null,
  userTaxInn: '',
};

const mapStateToProps = (state) => ({
  inn: state.auth.INN,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  userTaxInn:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.inn
      ? state.auth.user.taxStatus.inn
      : '',
});

export default connect(mapStateToProps)(SEFromLKSuccess);

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Constants
import {
  PAYMENT_TRANSFER_FAIL,
  PAYMENT_TRANSFER_LOADING,
  PAYMENT_TRANSFER_OK,
} from 'constants/index.js';

// Icons
import { ReactComponent as IconPayment } from 'static/assets/icon-payment-process.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';

// Styles
import './PaymentMessage.scss';

const PaymentMessage = ({
  className,
  text,
  status,
  children,
  mainLoaderColor,
  spinnerLoaderColor,
}) => {
  const progress = 0;

  return (
    <div className={cx('payment-message', className)}>
      <div className="payment-message__icon-wrap">
        {status === PAYMENT_TRANSFER_LOADING && (
          <>
            <CircleLoader
              className="payment-message__loader"
              width={60}
              height={60}
              mainColor={mainLoaderColor}
              spinnerColor={spinnerLoaderColor}
            />
            <IconPayment className="payment-message__icon payment-message__icon_waiting" />
          </>
        )}

        {status === PAYMENT_TRANSFER_FAIL && (
          <>
            <CircleProgress
              className="payment-message__loader"
              color="var(--reddish-pink)"
              value={progress}
              radius={28}
            />
            <IconPayment className="payment-message__icon payment-message__icon_fail" />
          </>
        )}

        {status === PAYMENT_TRANSFER_OK && (
          <>
            <CircleProgress
              className="payment-message__loader"
              color={spinnerLoaderColor || 'var(--turquoise-blue)'}
              value={progress}
              radius={28}
            />
            <IconCheck className="payment-message__icon payment-message__icon_success" />
          </>
        )}
      </div>

      {children}

      {text && <div className="payment-message__text">{text}</div>}
    </div>
  );
};

PaymentMessage.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PaymentMessage.defaultProps = {
  className: null,
  children: null,
  text: null,
};

export default PaymentMessage;

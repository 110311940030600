/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { LineText } from 'ui';

import { ReactComponent as IconPostEmpty } from 'static/assets/icon-post-empty.svg';

export const NotDocuments = ({ text }) => (
  <NotDocumentsWrapperStyled>
    <IconPostEmpty />
    {text ? (
      <LineTextStyled>{text}</LineTextStyled>
    ) : (
      <LineTextStyled>
        Здесь отображаются заключенные с <br /> партнерами договоры о сотрудничестве.
      </LineTextStyled>
    )}
  </NotDocumentsWrapperStyled>
);

NotDocuments.propTypes = {
  text: PropTypes.string,
};

NotDocuments.defaultProps = {
  text: '',
};

const NotDocumentsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0 120px;
  border-radius: 6px;
  background-color: var(--white);
  svg {
    width: 42px;
    height: 42px;
  }
`;

const LineTextStyled = styled(LineText)`
  margin-top: 20px;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: var(--bluey-grey);
`;

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';
import { ReactComponent as IconLock } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconPayment } from 'static/assets/icon-payment-process.svg';

// Styles
import './TransferResult.scss';

const TransferResult = ({
  children,
  isLoading,
  isMoneyTransferOk,
  isMoneyTransferFailed,
  closeModal,
  className,
  linkToPayAgainPage,
  textForBayAgainButton,
}) => {
  return (
    <div
      className={cx('transfer-result', {
        [className]: className,
      })}
    >
      {children}

      {isLoading && (
        <div className="transfer-result__icon-wrap">
          <CircleLoader className="transfer-result__loader" width={60} height={60} />
          <IconPayment className="transfer-result__icon transfer-result__icon_loading" />
        </div>
      )}

      {isMoneyTransferOk && (
        <div className="transfer-result__icon-wrap">
          <CircleProgress
            className="transfer-result__progress"
            color="var(--turquoise-blue)"
            value={0}
            radius={28}
          />
          <IconCheck className="transfer-result__icon transfer-result__icon_check" />
        </div>
      )}

      {isMoneyTransferFailed && (
        <div className="transfer-result__icon-wrap">
          <CircleProgress
            className="transfer-result__progress"
            color="var(--reddish-pink)"
            value={0}
            radius={28}
          />
          <IconLock className="transfer-result__icon transfer-result__icon_fail" />
        </div>
      )}

      <div className="transfer-result__button-wrap">
        {isLoading && (
          <button className="transfer-result__button" disabled type="button">
            Перевожу
          </button>
        )}

        {isMoneyTransferOk && (
          <Link to="/lk" className="transfer-result__button" onClick={closeModal}>
            Перейти на главную
          </Link>
        )}

        {isMoneyTransferFailed && (
          <Link
            to={linkToPayAgainPage || '/lk'}
            className="transfer-result__button"
            onClick={closeModal}
          >
            {textForBayAgainButton || 'Перейти на главную'}
          </Link>
        )}
      </div>
    </div>
  );
};

TransferResult.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMoneyTransferOk: PropTypes.bool.isRequired,
  isMoneyTransferFailed: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  linkToPayAgainPage: PropTypes.string,
  textForBayAgainButton: PropTypes.string,
};

TransferResult.defaultProps = {
  closeModal: () => {},
  className: null,
  linkToPayAgainPage: null,
  textForBayAgainButton: null,
};

export default TransferResult;

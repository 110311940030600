import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useInterval from 'react-useinterval';
import { useRecaptcha } from 'react-recaptcha-hook';

// Actions
import { checkRecoveryPasswordCode, startRecoveryPassword } from 'modules/authLegasy/actions';

// Components
import CodeBlock from 'modules/shared/components/CodeBlock/CodeBlock';
import Loader from 'modules/authLegasy/components/Loader/Loader';

const Step2 = (props) => {
  const { onNextStepButtonClick, className, userPhone, smsVerification, dispatch } = props;

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isCodeChecking, setIsCodeChecking] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [isTimeEnded, setIsTimeEnded] = useState(false);
  const [interval, setInterval] = useState(1000);

  /** Timer for resending sms code */
  const startTimer = () => {
    if (seconds > 0) {
      setSeconds((prevState) => prevState - 1);
    } else {
      setIsTimeEnded(true);
      setInterval(null);
    }
  };

  useInterval(startTimer, interval);

  /** Send sms code to change password */
  const onSendCode = (pin) => {
    setIsError(false);
    setIsCodeChecking(true);

    dispatch(checkRecoveryPasswordCode(smsVerification.id, pin.join('')))
      .then(() => {
        setIsCodeChecking(false);
        setIsCodeValid(true);
        setTimeout(() => {
          onNextStepButtonClick();
        }, 1000);
      })
      .catch((result) => {
        setIsCodeChecking(false);
        setIsError(true);
        setErrorMessage(
          (result && result.result && result.result.message) || 'Вы ввели неверный код',
        );

        /** Блокировка пользователя при 410 */
        if (result && result.result && result.result.code === 410) {
          setIsUserBlocked(true);
        }
      });
  };

  /** Инициализация капчи */
  const captchaExecute = useRecaptcha({
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    hideDefaultBadge: false,
  });

  /** Удаляем скрипты и элемент с капчей если уходим с этого щага регистрации */
  useEffect(() => {
    return () => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scripts = document.querySelectorAll("script[src*='recaptcha");
      if (scripts) {
        for (let i = 0; i < scripts.length; i += 1) {
          scripts[i].remove();
        }
      }
    };
  }, []);

  /** Request new sms */
  const onRequestNewSms = () => {
    setIsError(false);
    setIsLoading(true);

    captchaExecute('PasswordRecoveryEnterCode')
      .then((token) => dispatch(startRecoveryPassword(userPhone, token)))
      .then(() => {
        setIsLoading(false);
        setInterval(1000);
        setIsTimeEnded(false);
        setSeconds((smsVerification && smsVerification.resendTime) || 60);
      })
      .catch((result) => {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage((result && result.result && result.result.message) || 'Ошбика');
      });
  };

  /** Get resendTime from server */
  useEffect(() => {
    setSeconds((smsVerification && smsVerification.resendTime) || 60);
  }, [smsVerification, dispatch]);

  return (
    <div
      className={cx('password-recovery', {
        [className]: className,
      })}
    >
      <div className="password-recovery__container container">
        <div className="password-recovery__content">
          <CodeBlock
            className="password-recovery__code-block"
            smsVerification={smsVerification}
            title="Введите код из СМС"
            onSendCode={onSendCode}
            onRequestNewSms={onRequestNewSms}
            isError={isError}
            errorMessage={errorMessage}
            isCodeValid={isCodeValid}
            isCodeChecking={isCodeChecking}
            isUserBlocked={isUserBlocked}
            isTimeEnded={isTimeEnded}
            seconds={seconds}
          />
        </div>
      </div>
      {isLoading && <Loader inverted />}
    </div>
  );
};

Step2.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
  smsVerification: PropTypes.shape({
    id: PropTypes.number,
    resendTime: PropTypes.number,
    attempts: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userPhone: state.auth.phone,
  smsVerification: state.auth.smsVerification,
});

export default connect(mapStateToProps)(Step2);

import useWindowSize from '@rehooks/window-size';
import { setMenuOpenWithTransition } from 'modules/authLegasy/actions';
import Header from 'modules/authLegasy/components/Header/Header';
import Menu from 'modules/authLegasy/components/Menu/Menu';
import React from 'react';
import AriaModal from 'react-aria-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { HeaderCertificateWidget } from '../../modules/documents';

export const HeaderTemplate = () => {
  const location = useLocation();
  const windowSize = useWindowSize();
  const user = useSelector((store) => store.auth.user, shallowEqual);
  const isMenuOpen = useSelector((store) => store.auth.isMenuOpen, shallowEqual);

  const dispatch = useDispatch();

  return (
    <>
      <Switch>
        {process.env.REACT_APP_IS_PROD_BUILD === 'N' && ( //! Не для продакшена
          <Route path="/certificate">
            <HeaderCertificateWidget /> {/* ПОДПИСАННЫЙ ДОКУМЕНТ */}
          </Route>
        )}
        <Route path="/">
          <Header
            // Для логического вывода элементов
            isOnGettingAuthStatus={location.pathname === '/get-authorization'}
            // Для выделения активного состояния пункта меню
            isOnLkServices={
              location.pathname.indexOf('money-request') > -1 ||
              location.pathname.indexOf('income-registration') > -1 ||
              location.pathname.indexOf('bill-creation') > -1
            }
            // Используется !реверсивно! для стилевого модификатора: хэдер и паддингом сверху
            // модификатор внутри хэдера юзается только для страниц незалогиненого юзера
            isUserLoggedIn={
              location.pathname.indexOf('/lk') > -1 ||
              location.pathname.indexOf('/profile') > -1 ||
              (Boolean(user) && location.pathname.indexOf('/kassa') > -1) ||
              (Boolean(user) && location.pathname.indexOf('/kopim') > -1) ||
              (Boolean(user) && location.pathname.indexOf('/moneybox/') > -1) ||
              (Boolean(user) && location.pathname.indexOf('/pay-it/') > -1) ||
              (Boolean(user) && location.pathname.indexOf('/invoice/') > -1) ||
              (Boolean(user) && location.pathname.indexOf('/cashbox/') > -1)
            }
            // Для стилевого модификатора: лого и бургер белого цвета
            // Предполагается для страниц незалогиненго юзера
            isElementsWhite={
              !user &&
              (location.pathname.indexOf('/kassa') > -1 ||
                location.pathname.indexOf('/kopim') > -1 ||
                location.pathname.indexOf('/pay-it/moneybox') > -1 ||
                location.pathname.indexOf('/pay-it/cashbox') > -1 ||
                location.pathname.indexOf('/moneybox/') > -1 ||
                location.pathname.indexOf('/cashbox/') > -1)
            }
            // Для стилевого модификатора: лого и бургер белого цвета c !980px!
            // Без разницы залогинен юзер или нет
            isResponsiveElementsWhite={
              Boolean(user) &&
              (location.pathname.indexOf('/kassa') > -1 ||
                location.pathname.indexOf('/kopim') > -1 ||
                location.pathname.indexOf('/moneybox/') > -1 ||
                location.pathname.indexOf('/cashbox/') > -1 ||
                location.pathname === '/profile' ||
                location.pathname.indexOf('money-request') > -1 ||
                location.pathname.indexOf('income-registration') > -1 ||
                location.pathname.indexOf('bill-creation') > -1 ||
                location.pathname.indexOf('/pay-it/moneybox') > -1 ||
                location.pathname.indexOf('/pay-it/cashbox') > -1)
            }
            // Для стилевого модификатора: чтобы убрать тень
            inNoShadow={
              windowSize.innerWidth <= 980 && location.pathname === '/lk/profile-settings'
            }
            // Для стилевого модификатора: скрываем header
            // Если мы находимся на странице настроек профиля
            isHidden={location.pathname === '/profile-settings'}
            isDisabled={
              location.pathname === '/manual' ||
              location.pathname === '/new-policy' ||
              location.pathname.indexOf('/pay-it/merchant/') > -1
            }
          />
        </Route>
      </Switch>

      <AriaModal
        underlayClass={
          user ? 'menu-wrap animation-menu-open_user' : 'menu-wrap animation-menu-open_no-user'
        }
        onExit={() => dispatch(setMenuOpenWithTransition(false))}
        titleText=" "
        underlayColor="#ffffff"
        mounted={isMenuOpen && ((windowSize.innerWidth <= 1194 && Boolean(user)) || !user)}
        className={
          user ? 'menu-wrap animation-menu-open_user' : 'menu-wrap animation-menu-open_no-user'
        }
      >
        <Menu
          closePopup={() => dispatch(setMenuOpenWithTransition(false))}
          isInProfile={location.pathname === '/profile'}
          isOnGettingAuth={location.pathname === '/get-authorization'}
          isUserLoggedIn={
            location.pathname.indexOf('/lk') > -1 ||
            location.pathname.indexOf('/profile') > -1 ||
            (Boolean(user) && location.pathname.indexOf('/kassa') > -1) ||
            (Boolean(user) && location.pathname.indexOf('/kopim') > -1)
          }
        />
      </AriaModal>
    </>
  );
};

import React, { useState } from 'react';

// Components
import PasswordRecoveryEnterPhone from 'modules/authLegasy/components/PasswordRecoveryEnterPhone/PasswordRecoveryEnterPhone';
import PasswordRecoveryEnterCode from 'modules/authLegasy/components/PasswordRecoveryEnterCode/PasswordRecoveryEnterCode';
import PasswordRecoveryEnterPassword from 'modules/authLegasy/components/PasswordRecoveryEnterPassword/PasswordRecoveryEnterPassword';
import { PasswordRecoveryCompleted } from 'modules/authLegasy/components/PasswordRecoveryCompleted/PasswordRecoveryCompleted';

// Styles
import './PasswordRecovery.scss';
import { Redirect } from 'react-router-dom';

const PasswordRecovery = () => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <>
      {currentStep === 1 && (
        <PasswordRecoveryEnterPhone
          className="password-recovery__step-1"
          onNextStepButtonClick={() => setCurrentStep(currentStep + 1)}
        />
      )}

      {currentStep === 2 && (
        <PasswordRecoveryEnterCode
          className="password-recovery__step-2"
          onNextStepButtonClick={() => setCurrentStep(currentStep + 1)}
        />
      )}

      {currentStep === 3 && (
        <PasswordRecoveryEnterPassword
          className="password-recovery__step-2"
          onNextStepButtonClick={() => setCurrentStep(currentStep + 1)}
        />
      )}

      {currentStep === 4 && (
        <PasswordRecoveryCompleted
          className="password-recovery__step-3"
          onNextStepButtonClick={() => setCurrentStep(currentStep + 1)}
        />
      )}

      {currentStep === 5 && <Redirect to="/login" />}
    </>
  );
};

export default PasswordRecovery;

import useWindowSize from '@rehooks/window-size';
import { disableSpaceEnter, normalizePhone } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Footer from 'modules/authLegasy/components/Footer/Footer';
import {
  addCard,
  removeBankCardData,
  setProfileSettingsActiveTab,
} from 'modules/profileLegasy/actions';
import BankCard from 'modules/profileLegasy/components/BankCard/BankCard';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import ProgressBar from 'modules/shared/components/ProgressBar/ProgressBar';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE, GREY_BORDER, HINT_TIMEOUTS, USER_CARDS_PROFILE_TAB } from 'constants/index';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import emojiOk from 'static/assets/icon-emoji-ok.png';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as ResponsiveLogo } from 'static/assets/logo-responsive.svg';

import './AddNewCard.scss';
import { PaymentDisabledPopup } from '../../../../tools/utils/payments';

const AddNewCard = ({ dispatch, user, startCurrentTime }) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const [redirectOnCards, setRedirectOnCards] = useState(false);

  const [cardNumber, setCardNumber] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [isCardDefault, setIsCardDefault] = useState(false);
  const [isExpireDateValid, setIsExpireDateValid] = useState(true);
  const [cardName, setCardName] = useState('');

  const [now, setNow] = useState(startCurrentTime);

  const isFirstCard = false;

  const [isSavingCardLoading, setIsSavingCardLoading] = useState(false);
  const [isSavingCardSucceed, setIsSavingCardSucceed] = useState(false);
  const [isSavingCardFail, setIsSavingCardFail] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [calculatedProgress, setCalculatedProgress] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);

  /**
   * Проверяем нормальная ли дата пришла с сервера
   * если нет, то берем дату с браузера
   * */
  useEffect(() => {
    if (
      startCurrentTime &&
      startCurrentTime !== 0 &&
      Number.isNaN(startCurrentTime) &&
      startCurrentTime.toString().length === 13
    ) {
      setNow(startCurrentTime);
    } else {
      setNow(new Date().getTime());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startCurrentTime]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем с сервера инфу по текущей дате,
   * формируем из этого дату формата две последнице цифры года + месяц
   * */
  const today = `${new Date(now).getFullYear().toString().slice(2)}${(new Date(now).getMonth() + 1)
    .toString()
    .padStart(2, '0')}`;

  /**
   * Сравниваем введенную дату истечения действия карты
   * с текущей датой, чтобы веденная была больше сегодняшней
   * */
  useEffect(() => {
    const expireDate = Number(cardYear + cardMonth);

    setIsExpireDateValid(true);

    if (
      ((expireDate < Number(today) && cardYear.length === 2 && cardMonth.length === 2) ||
        ((+cardMonth <= 0 || +cardMonth > 12) &&
          cardYear.length === 2 &&
          cardMonth.length === 2)) &&
      cardYear.length === 2 &&
      cardMonth.length === 2
    ) {
      setIsExpireDateValid(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [cardMonth, cardYear]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Клик на кнопку "Добавить" */
  const addCardClick = () => {
    setIsSavingCardLoading(true);
    setIsSavingCardSucceed(false);
    setIsSavingCardFail(false);
    setIsPopupOpen(false);

    dispatch(addCard())
      .then(() => {
        setIsSavingCardLoading(false);
        setIsSavingCardSucceed(true);
        setIsPopupOpen(true);
      })
      .catch(() => {
        setIsSavingCardLoading(false);
        setIsSavingCardFail(true);
        setIsPopupOpen(true);
      });
  };

  /** Устанавливаем isRendered для начала анимаций */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Считаем прогресс и активируем кнопку "Добавить" */
  useEffect(() => {
    if (
      cardNumber &&
      cardNumber.length === 16 &&
      cardYear &&
      cardMonth &&
      cardCvv &&
      isExpireDateValid
    ) {
      setCalculatedProgress(100);
      setIsButtonActive(true);
    }
  }, [cardYear, cardMonth, cardCvv, cardNumber, isExpireDateValid]);

  /**
   * Временная переменная:
   * проверяем если у юзера количетсво карт = 5,
   * то возвращаем его на страницу настроек профиля
   * */
  const hasLimitOfCards = false;

  useEffect(() => {
    if (hasLimitOfCards) {
      dispatch(setProfileSettingsActiveTab(USER_CARDS_PROFILE_TAB));
      setRedirectOnCards(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [hasLimitOfCards]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Устанавливаем вкладку Мли карты в настройках профиля открытой,
   * чтобы редирект произошел на нее
   * */
  const openProfileSettingsWithCardsTab = () => {
    dispatch(setProfileSettingsActiveTab(USER_CARDS_PROFILE_TAB));
    setRedirectOnCards(true);
  };

  /** Редирект на Настройки профиля */
  if (redirectOnCards) {
    return <Redirect to="/lk/profile-settings" />;
  }

  return createPortal(
    <div className="add-new-card">
      <div className="add-new-card__container">
        {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={{
            enter: 100,
            exit: 100,
          }}
          unmountOnExit
        >
          <PopupHeaderWithBalance className="show-case-creation__header" />
        </CSSTransition>
        {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

        {windowSize.innerWidth <= 980 && (
          <div className="show-case-creation__header">
            <Link to="/lk" className="show-case-creation__header-logo-wrap">
              <ResponsiveLogo className="show-case-creation__header-logo" />
            </Link>

            <button
              type="button"
              className="show-case-creation__close"
              onClick={openProfileSettingsWithCardsTab}
            >
              <IconClose className="show-case-creation__close-icon" />
            </button>
          </div>
        )}

        {/* ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={{ enter: 150, exit: 100 }}
          unmountOnExit
        >
          <div className="add-new-card__title-wrap">
            <h1 className="add-new-card__title">Добавление новой карты</h1>

            {windowSize.innerWidth <= 980 && (
              <p className="add-new-card__subtitle">Введите данные карты</p>
            )}

            <ProgressBar progress={calculatedProgress} />
          </div>
        </CSSTransition>
        {/* /ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={{
            enter: 200,
            exit: 100,
          }}
          unmountOnExit
        >
          <div className="add-new-card__content">
            <div className="add-new-card__content-inner">
              {/* БАНКОВСКАЯ КАРТА */}
              <BankCard
                className="add-new-card__card"
                onChangeCardNumberInput={(event) => {
                  if (event.target.value === '') {
                    dispatch(removeBankCardData());
                  }

                  setCardNumber(normalizePhone(event.target.value));
                }}
                onChangeCardMonthInput={(event) => setCardMonth(event.target.value)}
                onChangeCardYearInput={(event) => setCardYear(event.target.value)}
                onChangeCardCvvInput={(event) =>
                  setCardCvv(event.target.value.slice(0, 3).replace(/\D/, ''))
                }
                handleChangeMonthOnBlur={(value) => {
                  if (value && value.length < 2 && +value < 10) {
                    setCardMonth(`0${value}`);
                  }
                }}
                cardNumber={cardNumber}
                cardYear={cardYear}
                cardMonth={cardMonth}
                cardCvv={cardCvv}
                isExpireDateValid={isExpireDateValid}
              />
              {/* /БАНКОВСКАЯ КАРТА */}

              {/* ИНПУТ ДЛЯ НАЗВАНИЯ */}
              <InputWithLabelAnimation
                className="add-new-card__input"
                value={cardName}
                label="Название карты"
                onChange={(event) => setCardName(disableSpaceEnter(event.target.value))}
              />
              {/* /ИНПУТ ДЛЯ НАЗВАНИЯ */}

              {/* ЧЕКБОКС */}
              <div className="add-new-card__default-block">
                <label className="add-new-card__checkbox-wrap">
                  <button
                    className="add-new-card__checkbox-itself"
                    type="button"
                    role="checkbox"
                    aria-label="Карта по умолчанию"
                    onClick={() => setIsCardDefault((prevState) => !prevState)}
                    aria-checked={isFirstCard ? true : isCardDefault}
                    disabled={isFirstCard}
                  />
                  <span className="add-new-card__checkbox-title">Карта по умолчанию</span>
                </label>
              </div>
              {/* /ЧЕКБОКС */}

              {/* КНОПКИ: ОТМЕНА, ДОБАВИТЬ */}
              <div className="add-new-card__buttons">
                <StandardButton
                  bgType={GREY_BORDER}
                  className="add-new-card__buttons-item"
                  isLink={false}
                  title="Вернуться"
                  onClick={openProfileSettingsWithCardsTab}
                />

                <StandardButton
                  bgType={BLUE}
                  className="add-new-card__buttons-item"
                  isLink={false}
                  title="Добавить"
                  disabled={!isButtonActive}
                  onClick={addCardClick}
                  isLoading={isSavingCardLoading}
                />
              </div>
              {/* /КНОПКИ: ОТМЕНА, ДОБАВИТЬ */}
            </div>
          </div>
        </CSSTransition>
      </div>

      <Footer />

      {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth > 980}
        timeout={{
          enter: 100,
          exit: 100,
        }}
        unmountOnExit
      >
        <PopupCloseWithText closePopupHandler={() => setRedirectOnCards(true)} />
      </CSSTransition>
      {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* ПОПАП - УДАЧНОЕ СОЗДАНИЕ КАРТЫ */}
      <CSSTransition in={isSavingCardSucceed && isPopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={isSavingCardSucceed && isPopupOpen}
          emoji={emojiOk}
          closePopup={() => setIsPopupOpen(false)}
          title="Карта добавлена!"
          buttonArray={[
            {
              id: 1,
              title: 'Перейти в Мои карты',
              action: openProfileSettingsWithCardsTab,
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
          ]}
        >
          <p>
            Отлично! Ваша карта добавлена. Вы можете найти ее в разделе {'   '}
            <button onClick={openProfileSettingsWithCardsTab} type="button">
              Мои карты
            </button>
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - УДАЧНОЕ СОЗДАНИЕ КАРТЫ */}

      {/* ПОПАП - НЕ УДАЧНОЕ СОЗДАНИЕ КАРТЫ */}
      <CSSTransition in={isSavingCardFail && isPopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={isSavingCardFail && isPopupOpen}
          emoji={emojiSad}
          closePopup={() => setIsPopupOpen(false)}
          title="Ой! Ошибка!"
          buttonArray={[
            {
              id: 1,
              title: 'Вернуться',
              action: () => setIsPopupOpen(false),
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
          ]}
        >
          <p>
            Что-то пошло не так, попробуйте повторить попытку позже или{' '}
            <span className="js-help">напишите в тех. поддержку</span>.
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - НЕ УДАЧНОЕ СОЗДАНИЕ КАРТЫ */}
      <PaymentDisabledPopup redirect={'/lk'} />
    </div>,
    document.body,
  );
};

AddNewCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  startCurrentTime: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  startCurrentTime: state.auth.startCurrentTime,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AddNewCard);

import { MERCHANT_STATUS_COLOR, OPERATIONS_MONTHS_REDUCED_RU } from 'constants/index';
import moment from 'moment';

// Получаем куку по имени
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const fetchWrapper = (
  url,
  {
    method = 'GET',
    lang = null,
    needCsrfToken = true,
    body = null,
    multipart = false,
    credentials = 'include',
    isBlob = false,
    auth = false,
  } = {},
) => {
  if (!url) {
    throw new Error('Url should be specified');
  }

  const headers = {
    Accept: '*/*',
    'Cache-Control': 'no-cache',
  };

  if (!multipart) {
    headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  if (lang) {
    headers['Accept-Language'] = lang;
  }

  if (auth) {
    headers['Authorization'] = auth;
  }

  if (needCsrfToken) {
    headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
  }

  const options = {
    method,
    headers,
    credentials,
  };

  if (body) {
    options.body = body;
  }

  if (!isBlob) {
    return fetch(url, options).then(handleFetchErrors).then(handleJSON);
  }

  return fetch(url, options).then(handleFetchBlob).then(handleBlob);
};

export const handleFetchErrors = async (res) => {
  return Promise.resolve({
    ok: res.ok,
    statusText: res.statusText,
    status: res.status,
    response: await res.text(),
  });
};

export const handleFetchBlob = async (res) =>
  Promise.resolve({
    ok: res.ok,
    statusText: res.statusText,
    status: res.status,
    response: await res.blob(),
  });

export const handleBlob = (res) => {
  if (res.ok) {
    return res.response;
  }

  let toThrow = null;
  try {
    toThrow = JSON.parse(res.response);
  } catch (e) {
    toThrow = Error(res.statusText || res.status);
  }
  throw toThrow;
};

export const handleJSON = (res) => {
  if (res.ok) {
    try {
      return JSON.parse(res.response);
    } catch (e) {
      return res.response;
    }
  } else {
    let toThrow = null;
    try {
      toThrow = JSON.parse(res.response);
    } catch (e) {
      toThrow = Error(res.statusText || res.status);
    }
    throw toThrow;
  }
};

export const normalizePhone = (input) => {
  return input.replace(/[^0-9]/g, '');
};

export const hasNumber = (myString) => /\d/.test(myString);

export const hasUpperCase = (myString) => /[A-Z]/.test(myString);

export const hasRussianLetters = (myString) => /[А-Яа-яёЁ]/.test(myString);

export const hasSpaceEnter = (myString) => /\s/g.test(myString);

// export const hasOnlyString = myString => /^\d+$/.test(myString);

export const normalizeString = (input) => {
  return input
    .replace(/[^А-Яа-яёЁ|\s-]/gi, '')
    .replace(/-{2,}/g, '-')
    .replace(/ {2,}/g, ' ');
};

// export const normalizeEngString = input => {
//   return input.replace(/[^A-Za-z]/gi, '');
// };

export const disableRussianLetters = (input) => {
  return input.replace(/[А-Яа-яёЁ]/, '');
};
export const disableSpaceEnter = (input) => {
  return input.replace(/\s/g, '');
};

export const validateEmail = (string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// export const normalizeDate = input => {
//   const day = input.slice(0, 2);
//   const month = input.slice(3, 5);
//   const year = input.slice(6);
//
//   return `${month}.${day}.${year}`;
// };

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateHuman = (date = new Date()) => {
  return moment(date).format('DD.MM.YYYY');
};

export const formatDateHumanWithUTCOffset = (date = new Date(), offset = 0) => {
  return moment(date).utcOffset(offset).format('DD.MM.YYYY HH:mm:ss');
};

export const formatDateHumanWithoutYear = (date = new Date()) => {
  return moment(date).format('DD.MM');
};

export const formatDateWithSlash = (date = new Date()) => {
  return moment(date).format('DD/MM/YY');
};

export const formatTimeHuman = (date = new Date()) => {
  return moment(date).format('HH:mm');
};

export const formatTimeHumanCustomOffset = (date = new Date(), offset = 0) => {
  return moment(date).utcOffset(offset, false).format('HH:mm');
};

export const normalizeSum = (input) => {
  if (input === null) {
    return 'Без ограничений';
  }

  if (input === 0) {
    return 'Не доступно';
  }

  const pureSum = input / 100;

  return `До ${pureSum} ₽`;
};

export const normalizeSumForMonth = (input) => {
  if (input === null) {
    return 'Без ограничений в месяц';
  }

  if (input === 0) {
    return 'Не доступно';
  }
  const pureSum = input / 100;

  return `До ${pureSum} ₽ в месяц`;
};

export const changeLookOfPhone = (phone) => {
  if (phone && phone[0] === '7' && phone.length === 11) {
    return `+${phone.slice(0, 1)} (${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(
      7,
      9,
    )}-${phone.slice(9)}`;
  } else {
    return phone;
  }
};

export const replaceStart7By8 = (phone) => {
  return `8${phone.slice(1)}`;
};

export const validatePhoneNumber = (phone) => {
  const re = /^79\d{9}$/;

  return re.test(String(phone));
};

export const isNumeric = (input) => {
  return input
    .replace(/[^\d.]/g, '')
    .replace(/^\.*/, '')
    .replace(/(\.\d{0,2})(.*)/, '$1');
};

// Takes a credit card string value and returns true on valid number
export const validateCreditCard = (cardNumber) => {
  const arr = [];
  const cardNumberString = cardNumber.toString();
  for (let i = 0; i < cardNumberString.length; i += 1) {
    if (i % 2 === 0) {
      const m = parseInt(cardNumberString[i], 10) * 2;
      if (m > 9) {
        arr.push(m - 9);
      } else {
        arr.push(m);
      }
    } else {
      const n = parseInt(cardNumberString[i], 10);
      arr.push(n);
    }
  }

  const sum = arr.reduce((a, b) => a + b);
  return Boolean(!(sum % 10));
};

// Склонение слов в зависимости от числительных
export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

// Удаление 7 или 8 из строки с номером телефона
export const removeCodeFromPhoneNumber = (phoneNumber) => phoneNumber.replace(/^[78]/, '');

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export const getQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  let params = {};
  let tokens;
  const re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const analyticsMoneyViewChange = (sum) => {
  const sumInRub = Math.floor(sum / 100).toString();

  if (sumInRub.length >= 7) {
    return `${sumInRub.slice(0, -6)} млн.р.`;
  } else if (sumInRub.length >= 4) {
    return `${sumInRub.slice(0, -3)} тыс.р.`;
  } else {
    return `${sumInRub} ₽`;
  }
};

export const operationsDateView = (date) => {
  const day = date.getDate();
  const month = OPERATIONS_MONTHS_REDUCED_RU[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day} ${month.toLowerCase()} ${year} ${hours}:${minutes}`;
};

export const setHoursAndMinutesToZero = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return new Date(`${month}/${day}/${year} 00:00`);
};

export const setHoursAndMinutesTheEndOfDay = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return new Date(`${month}/${day}/${year} 23:59`);
};

export const setDateWithTime = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const parseUri = (str) => {
  const o = parseUri.options;
  const m = o.parser[o.strictMode ? 'strict' : 'loose'].exec(str);
  const uri = {};
  let i = 14;

  while (i--) uri[o.key[i]] = m[i] || '';

  uri[o.q.name] = {};
  uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
    if ($1) uri[o.q.name][$1] = $2;
  });

  return uri;
};

/* eslint-disable no-useless-escape */
parseUri.options = {
  strictMode: false,
  key: [
    'source',
    'protocol',
    'authority',
    'userInfo',
    'user',
    'password',
    'host',
    'port',
    'relative',
    'path',
    'directory',
    'file',
    'query',
    'anchor',
  ],
  q: {
    name: 'queryKey',
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict:
      /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose:
      /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
};
/* eslint-enable no-useless-escape */

export const removeZerosAfterDot = (string) => {
  const digitsToChek = string.split('.')[1];
  return digitsToChek === '00' ? string.split('.')[0] : string;
};

export const setStatusBg = (status) => {
  switch (status) {
    case 'SUCCESS':
      return MERCHANT_STATUS_COLOR[2];

    case 'WAIT_APPROVE':
      return MERCHANT_STATUS_COLOR[1];

    case 'BLOCKED':
      return MERCHANT_STATUS_COLOR[3];

    case 'REJECTED':
      return MERCHANT_STATUS_COLOR[3];

    case 'NEED_TO_SET_UP':
      return MERCHANT_STATUS_COLOR[2];

    default:
      return MERCHANT_STATUS_COLOR[0];
  }
};

export const setStatusTitle = (status) => {
  switch (status) {
    case 'SUCCESS':
      return 'Подтвержден';

    case 'WAIT_APPROVE':
      return 'На рассмотрении';

    case 'NEED_TO_SET_UP':
      return 'Требуется настройка';

    case 'BLOCKED':
      return 'Заблокирован';

    case 'REJECTED':
      return 'Отклонен';

    default:
      return 'Новый';
  }
};

export const toUpperCaseFio = (string) => {
  const arr = string.split(' ');
  const upperCaseArr = arr.map((item) => item.charAt(0).toUpperCase() + item.slice(1));

  return upperCaseArr.join(' ');
};

import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Hint from 'modules/shared/components/Hint/Hint';
import HintHowToConfirmData from 'modules/profileLegasy/components/Hint/HintHowToConfirmData';
import OpenPopupButton from 'modules/shared/components/OpenPopupButton/OpenPopupButton';

import { HINT_TIMEOUTS } from 'constants/index.js';

const ConfirmPersonalDataWrap = ({ isMenuOpen }) => {
  const [
    isDropConfirmDataHintOpened,
    dropElConfirmDataHint,
    toggleDropConfirmDataHint,
    closeExplicitlyConfirmDataHint,
  ] = useToggleAndOutClick();

  return (
    <>
      <OpenPopupButton
        onClick={toggleDropConfirmDataHint}
        title="Как подтвердить данные?"
        className="profile-settings-personal-data-tab__hint-item"
      />

      {/* ПОПАП - КАК ПОДТВЕРДИТЬ ДАННЫЕ */}
      <CSSTransition
        in={isDropConfirmDataHintOpened}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <Hint
          ref={dropElConfirmDataHint}
          onCloseBtnClick={closeExplicitlyConfirmDataHint}
          className="profile-settings__confirm-data-sidebar"
          classNameInner="profile-settings__confirm-data-inner"
          title="Как подтвердить данные?"
          isMenuOpen={isMenuOpen}
        >
          <HintHowToConfirmData />
        </Hint>
      </CSSTransition>
      {/* /ПОПАП - КАК ПОДТВЕРДИТЬ ДАННЫЕ */}
    </>
  );
};

ConfirmPersonalDataWrap.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default ConfirmPersonalDataWrap;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ButtonUI } from 'ui';

import { AlertOfDeclineChangeStatus } from '../organisms';

// eslint-disable-next-line import/prefer-default-export
export const ButtonsFormOfChangeStatus = ({
  onPositive,
  onNegative,
  isLoading,
  isRequestError,
}) => {
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  return (
    <>
      <ButtonUI.Normal onClick={onPositive} isLoading={isLoading}>
        Подписать
      </ButtonUI.Normal>
      <ButtonUI.Light onClick={() => setIsOpenAlert(true)}>Отклонить</ButtonUI.Light>
      {isOpenAlert && (
        <AlertOfDeclineChangeStatus
          onDecline={onNegative}
          closeDecline={() => setIsOpenAlert(false)}
          isRequestError={isRequestError}
        />
      )}
    </>
  );
};

ButtonsFormOfChangeStatus.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isRequestError: PropTypes.bool,
  onNegative: PropTypes.func.isRequired,
  onPositive: PropTypes.func.isRequired,
};

ButtonsFormOfChangeStatus.defaultProps = {
  isRequestError: null,
};

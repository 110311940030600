import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { LineText, ParagraphText } from 'ui';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';

import { getUrlOfDownloadDocumentAPI } from '../../../models/document';
import { useDocumentById } from '../../../models/documentList';
import { downloadFile, viewFile } from '../../../../../tools/utils/fileDownloader';

export const DocumentInfoDetailsContainer = ({ docId }) => {
  const { companyName, platform, checkSum } = useDocumentById(docId);
  const [errorDownload, setErrorDownload] = useState(null);
  return (
    <>
      <KeyAndTextItemWrapperStyled>
        <KeyTextStyled>Заказчик</KeyTextStyled>
        <ValueTextStyled>{companyName}</ValueTextStyled>
      </KeyAndTextItemWrapperStyled>
      <KeyAndTextItemWrapperStyled>
        <KeyTextStyled>Документ</KeyTextStyled>
        <ValueTextStyled>
          <LinkStyled
            onClick={async () => {
              try {
                setErrorDownload(null);
                await downloadFile(
                  getUrlOfDownloadDocumentAPI(platform, docId),
                  `doc-${docId}.pdf`,
                );
              } catch (e) {
                setErrorDownload(e);
              }
            }}
          >
            Скачать документ
          </LinkStyled>
          <ValueTextStyled> / </ValueTextStyled>
          <LinkStyled
            onClick={async () => {
              try {
                setErrorDownload(null);
                await viewFile(getUrlOfDownloadDocumentAPI(platform, docId));
              } catch (e) {
                setErrorDownload(e);
              }
            }}
          >
            Просмотреть документ
          </LinkStyled>

          <ErrorDownloadStyled>{errorDownload}</ErrorDownloadStyled>
        </ValueTextStyled>
      </KeyAndTextItemWrapperStyled>
      {checkSum && (
        <KeyAndTextItemWrapperStyled>
          <KeyTextStyled>Контрольная сумма</KeyTextStyled>
          <ParagraphTextStyled>{checkSum}</ParagraphTextStyled>
        </KeyAndTextItemWrapperStyled>
      )}
    </>
  );
};

DocumentInfoDetailsContainer.propTypes = {
  docId: PropTypes.number.isRequired,
};

const KeyAndTextItemWrapperStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  & > *:first-of-type {
    margin-bottom: 4px;
  }
`;

const KeyTextStyled = styled(LineText)`
  font-size: 16px;
  line-height: 24px;
  color: var(--normal-grey-blue);
  ${getFont(WEIGHT['rubik-regular'])}
`;

const ValueTextStyled = styled(LineText)`
  font-size: 16px;
  line-height: 24px;
  ${getFont(WEIGHT['rubik-regular'])}
`;

const LinkStyled = styled.a`
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: var(--deep-sky-blue);
  cursor: pointer;
`;

const ErrorDownloadStyled = styled.div`
  padding-top: 6px;
  font-size: 14px;
  line-height: 20px;
  color: var(--reddish-pink);
`;

const ParagraphTextStyled = styled(ParagraphText)`
  line-break: anywhere;
  font-size: 16px;
  line-height: 24px;
`;

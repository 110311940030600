export const calculateCommission = ({ sum, commission, inverted }) => {
  let totalValue = Number(sum);

  if (!commission) {
    return Math.round(sum);
  }

  if (inverted) {
    if (commission.value) {
      switch (commission.type) {
        case 0:
          totalValue -= (sum * commission.value) / (100 + commission.value);
          break;
        case 1:
          totalValue -= (sum * 100) / (100 - commission.value) - sum;
          break;
        default:
          totalValue -= (sum * commission.value) / (100 + commission.value);
      }
    }
    if (commission.fixValue) {
      totalValue -= commission.fixValue;
    }
    if (commission.minComm && commission.minComm >= sum - totalValue) {
      totalValue = sum - commission.minComm;
    } else if (commission.maxComm && commission.maxComm + sum < totalValue) {
      totalValue = sum - commission.maxComm;
    }
    return Math.floor(totalValue).toFixed(0);
  } else {
    if (commission.value) {
      switch (commission.type) {
        case 0:
          totalValue += (sum / 100) * commission.value;
          break;
        case 1:
          totalValue += (sum * 100) / (100 - commission.value) - sum;
          break;
        default:
          totalValue += (sum / 100) * commission.value;
      }
    }
    if (commission.fixValue) {
      totalValue += commission.fixValue;
    }
    if (commission.minComm && commission.minComm >= totalValue - sum) {
      totalValue = commission.minComm + sum;
    } else if (commission.maxComm && commission.maxComm + sum < totalValue) {
      totalValue = commission.maxComm + sum;
    }
    return Math.round(totalValue).toFixed(0);
  }
};

export const findMinSum = (arrayOfSum) => {
  return Math.min(...arrayOfSum);
};

export const findMaxSum = (arrayOfSum) => {
  return Math.max(...arrayOfSum);
};

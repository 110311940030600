import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { getUser } from 'modules/authLegasy/actions';
import { getLastDateOfOperations, getLastDateOfRequests } from 'modules/profileLegasy/actions';
import HistoryRequests from 'modules/profileLegasy/components/HistoryRequests/HistoryRequests';
import Operations from 'modules/profileLegasy/components/Operations/Operations';
import Service from 'modules/profileLegasy/components/Service/Service';
import UserBalanceCard from 'modules/profileLegasy/components/UserBalanceCard/UserBalanceCard';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';

import { CONSISTENT_ANIMATIONS_DELAY, YES } from 'constants/index';

import { ReactComponent as IconService2 } from 'static/assets/icon-service-2.svg';
import { ReactComponent as IconService4 } from 'static/assets/icon-service-4.svg';
import { ReactComponent as LineGroup } from 'static/assets/line-group.svg';
import ImageService6 from 'static/assets/service6-img.png';

import './History.scss';

export const History = ({ user, dispatch }) => {
  const [isRendered, setIsRendered] = useState(false);

  /** States for tabs */
  const [currentFormTab, setCurrentFormTab] = useState(0);

  const windowSize = useWindowSize();

  /**
   * Ставим анимацию сразу после маунта и
   * берем данные по дате последних операций
   * */
  useEffect(() => {
    dispatch(getLastDateOfOperations())
      .then(() => {})
      .catch(() => {});
    dispatch(getLastDateOfRequests())
      .then(() => {})
      .catch(() => {});

    setIsRendered(true);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const navTabsArray = [
    {
      title: 'Операции',
      click: () => setCurrentFormTab(0),
    },
    {
      title: 'Запросы',
      click: () => setCurrentFormTab(1),
    },
  ];

  /**
   * Устанавливаем нужную вкладку истории,
   *  если в localStorage приходит параметр historyTab
   *  */
  useEffect(() => {
    const historyTab =
      window && window.localStorage ? window.localStorage.getItem('historyTab') : '0';

    if (historyTab && (historyTab === '0' || historyTab === '1')) {
      setCurrentFormTab(+historyTab);
      if (window && window.localStorage) {
        window.localStorage.removeItem('historyTab');
      }
    }
  }, []);

  /** Берем данные по юзеру, чтобы обновить данные по zoneOffset и currentTime */
  useEffect(() => {
    dispatch(getUser()).catch(() => {});

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className="history">
      {/* ЗАГОЛОВОК */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="history__top">
          <h1 className="history__title">История</h1>
        </div>
      </CSSTransition>

      {/* /ЗАГОЛОВОК */}

      {/* ТАБЫ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth < 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
        unmountOnExit
      >
        <TabsWithScrollableActiveState
          className="history__tabs-wrap"
          tabArray={navTabsArray}
          currentTabId={currentFormTab}
          isRendered={isRendered}
        />
      </CSSTransition>
      {/* ТАБЫ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}

      {/* ОСНОВНОЙ КОНТЕНТ */}
      <div className="history__list">
        <div className="history__left-column">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <UserBalanceCard className="history__user-balance-card animation-from-bottom-to-top" />
          </CSSTransition>

          {windowSize.innerWidth >= 1194 && (
            <>
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="history__item animation-from-bottom-to-top">
                  <Service
                    className="service_small service_special"
                    title="Рейтинг самозанятого"
                    isWhite
                    desc="Будет повышать лояльность клиентов и учитываться при выдаче кредита"
                  >
                    <LineGroup className="service__line-group" />
                  </Service>
                </div>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <div className="history__item animation-from-bottom-to-top">
                  <Service
                    className="service_small service_zkh"
                    title="Оплата услуг"
                    desc="Скоро – оплата ЖКХ, телефона, интернета, штрафов, налогов"
                  >
                    <img
                      src={ImageService6}
                      alt="Смартфон с приложением"
                      className="service__img service__img_phone"
                    />
                  </Service>
                </div>
              </CSSTransition>
            </>
          )}
        </div>

        <div className="history__main-column">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth > 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="history__statistic-card animation-from-bottom-to-top-enter">
              <nav className="history__statistic-card-nav">
                <button
                  className={cx('history__statistic-card-btn', {
                    'history__statistic-card-btn_active': currentFormTab === 0,
                  })}
                  type="button"
                  onClick={() => {
                    setCurrentFormTab(0);
                  }}
                >
                  Операции
                </button>
                <button
                  className={cx('history__statistic-card-btn', {
                    'history__statistic-card-btn_active': currentFormTab === 1,
                  })}
                  type="button"
                  onClick={() => {
                    setCurrentFormTab(1);
                  }}
                >
                  Запросы
                </button>
              </nav>

              {currentFormTab === 0 && <Operations className="history__operations" />}

              {currentFormTab === 1 && <HistoryRequests className="history__requests" />}
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="history__responsive-table-content animation-from-bottom-to-top-enter">
              {currentFormTab === 0 && <Operations className="history__operations" />}

              {currentFormTab === 1 && <HistoryRequests className="history__requests" />}
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <div className="history__item animation-from-bottom-to-top-enter">
              <Service
                className="service_small"
                isLink
                title="Запрос денег"
                desc="Для некоммерческих целей - мы создадим ссылку на форму оплаты"
                isWhite
                icon={<IconService2 className="service__icon" />}
                href="/lk/money-request"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
            unmountOnExit
          >
            <div className="history__item animation-from-bottom-to-top-enter">
              <Service
                className="service_small"
                title="Выставить счет"
                desc="Отправьте покупателю платежную форму как самозанятый"
                isWhite
                isLink
                href="/lk/bill-creation"
                icon={<IconService4 className="service__icon" />}
                disabled={
                  user?.taxStatus && (user.taxStatus === null || user.taxStatus.status !== YES)
                }
              />
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth < 1194}
            timeout={CONSISTENT_ANIMATIONS_DELAY[5]}
            unmountOnExit
          >
            <div className="history__item animation-from-bottom-to-top-enter">
              <Service
                className="service_small service_special"
                title="Рейтинг самозанятого"
                isWhite
                desc="Будет повышать лояльность клиентов и учитываться при выдаче кредита"
              >
                <LineGroup className="service__line-group" />
              </Service>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth < 1194}
            timeout={CONSISTENT_ANIMATIONS_DELAY[6]}
            unmountOnExit
          >
            <div className="history__item animation-from-bottom-to-top-enter">
              <Service
                className="service_small service_zkh"
                title="Оплата услуг"
                desc="Скоро – оплата ЖКХ, телефона, интернета, штрафов, налогов"
              >
                <img
                  src={ImageService6}
                  alt="Смартфон с приложением"
                  className="service__img service__img_phone"
                />
              </Service>
            </div>
          </CSSTransition>
        </div>
      </div>
      {/* /ОСНОВНОЙ КОНТЕНТ */}
    </div>
  );
};

History.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    taxStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(History);

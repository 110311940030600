import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { AccessRoute } from 'HOCs';

import PasswordRecovery from 'modules/authLegasy/components/PasswordRecovery/PasswordRecovery';
import Registration from 'modules/authLegasy/components/Registration/Registration';
import AuthStatusContainer from 'modules/profileLegasy/components/AuthStatusContainer/AuthStatusContainer';

import { LoginWidget } from 'modules/auth';

export const AuthTemplate = ({ needToAcceptNewPolicy }) => {
  let location = useLocation();
  return (
    <Switch location={location}>
      <AccessRoute exact path="/">
        <Redirect to="/login" />
      </AccessRoute>
      <AccessRoute path="/login">
        <LoginWidget />
      </AccessRoute>
      <AccessRoute path="/register">
        <Registration />
      </AccessRoute>
      <AccessRoute path="/password-recovery">
        <PasswordRecovery />
      </AccessRoute>
      <AccessRoute
        isPrivate
        needToAcceptNewPolicy={needToAcceptNewPolicy}
        path="/get-authorization"
      >
        <AuthStatusContainer />
      </AccessRoute>
    </Switch>
  );
};

export const GET_IDENTIFICATION_REQUEST_DATA_FETCHING = 'GET_IDENTIFICATION_REQUEST_DATA_FETCHING';
export const GET_IDENTIFICATION_REQUEST_DATA_SUCCESS = 'GET_IDENTIFICATION_REQUEST_DATA_SUCCESS';
export const GET_IDENTIFICATION_REQUEST_DATA_FAIL = 'GET_IDENTIFICATION_REQUEST_DATA_FAIL';

export const CONFIRM_IDENTIFICATION_REQUEST_FETCHING = 'CONFIRM_IDENTIFICATION_REQUEST_FETCHING';
export const CONFIRM_IDENTIFICATION_REQUEST_SUCCESS = 'CONFIRM_IDENTIFICATION_REQUEST_SUCCESS';
export const CONFIRM_IDENTIFICATION_REQUEST_FAIL = 'CONFIRM_IDENTIFICATION_REQUEST_FAIL';

export const DECLINE_IDENTIFICATION_REQUEST_FETCHING = 'DECLINE_IDENTIFICATION_REQUEST_FETCHING';
export const DECLINE_IDENTIFICATION_REQUEST_SUCCESS = 'DECLINE_IDENTIFICATION_REQUEST_SUCCESS';
export const DECLINE_IDENTIFICATION_REQUEST_FAIL = 'DECLINE_IDENTIFICATION_REQUEST_FAIL';

export const SHOW_IDENTIFICATION_REQUEST_POPUP = 'SHOW_IDENTIFICATION_REQUEST_POPUP';
export const HIDE_IDENTIFICATION_REQUEST_POPUP = 'HIDE_IDENTIFICATION_REQUEST_POPUP';

export const SHOW_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP =
  'SHOW_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP';
export const HIDE_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP =
  'HIDE_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP';

export const SHOW_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP =
  'SHOW_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP';
export const HIDE_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP =
  'HIDE_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP';

export const SHOW_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP =
  'SHOW_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP';
export const HIDE_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP =
  'HIDE_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP';

export const RESET_IDENTIFICATION_REQUEST_STATE = 'RESET_IDENTIFICATION_REQUEST_STATE';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import formatNumber from 'helpers/formatNumber';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

const TaxesAmount = ({ isLoading, amount, className }) => {
  return (
    <div
      className={cx('taxes-amount', {
        [className]: className,
      })}
    >
      {isLoading ? (
        <CircleLoader width={15} height={15} />
      ) : (
        <>
          {amount ? (
            <p className="taxes-amount__sum">
              {formatNumber(Math.floor(amount / 100))},{amount.toString().slice(-2)} ₽
            </p>
          ) : (
            <span className="taxes-amount__sum">0,00 ₽</span>
          )}
        </>
      )}
    </div>
  );
};

TaxesAmount.propTypes = {
  isLoading: PropTypes.bool,
  amount: PropTypes.number,
  className: PropTypes.string,
};

TaxesAmount.defaultProps = {
  isLoading: false,
  className: null,
  amount: null,
};

export default TaxesAmount;

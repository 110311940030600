import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { setStatusBg } from 'helpers';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import { ReactComponent as IconExclPoint } from 'static/assets/icon-excl-point.svg';
import { ReactComponent as IconLock } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconDetail } from 'static/assets/icon-profile-settings-detail.svg';

import './ShopStatusTab.scss';

const ShopStatusTab = ({ status, className, blockedReason, declinedReason }) => {
  return (
    <div
      className={cx('shop-status-tab', {
        [className]: className,
      })}
    >
      <div className="shop-status-tab__top">
        <h1 className="shop-status-tab__title">Статус магазина</h1>
        <div className="shop-status-tab__status" style={{ background: setStatusBg(status) }}>
          {status === 'SUCCESS' && 'подтвержден'}
          {status === 'WAIT_APPROVE' && 'На рассмотрении'}
          {status === 'NEED_TO_SET_UP' && 'Требуется настройка'}
          {status === 'BLOCKED' && 'Заблокирован'}
          {status === 'REJECTED' && 'Отклонен'}
        </div>
      </div>

      <div className="shop-status-tab__main">
        <div className="shop-status-tab__icon-wrap">
          {status === 'SUCCESS' && (
            <IconCheck className="shop-status-tab__icon shop-status-tab__icon_check" />
          )}
          {status === 'WAIT_APPROVE' && (
            <IconExclPoint className="shop-status-tab__icon shop-status-tab__icon_point" />
          )}
          {status === 'NEED_TO_SET_UP' && (
            <IconDetail className="shop-status-tab__icon shop-status-tab__icon_detail" />
          )}
          {status === 'BLOCKED' && (
            <IconLock className="shop-status-tab__icon shop-status-tab__icon_lock" />
          )}
          {status === 'REJECTED' && (
            <IconClose className="shop-status-tab__icon shop-status-tab__icon_close" />
          )}
        </div>

        <div className="shop-status-tab__main-title">
          {status === 'SUCCESS' && 'Ваш магазин подтвержден!'}
          {status === 'WAIT_APPROVE' && 'Ваш магазин находится на рассмотрении!'}
          {status === 'NEED_TO_SET_UP' && 'Вашему магазину требуется настройка'}
          {status === 'BLOCKED' && 'Ваш магазин заблокирован!'}
          {status === 'REJECTED' && 'Ваш магазин отклонен'}
        </div>

        <div className="shop-status-tab__main-content">
          {status === 'SUCCESS' && (
            <p className="shop-status-tab__text">
              Отлично, ваш магазин подтвержден и готов к работе. Если статус магазина будет изменен,
              тут появится инструкция для дальнейших действий. По всем вопросам обращайтесь в {'  '}
              <span className="js-help shop-status-tab__text-link">службу поддержки.</span>
            </p>
          )}

          {status === 'WAIT_APPROVE' && (
            <p className="shop-status-tab__text">
              Отлично, мы уже передали заявку нашему специалисту. Как только будет вынесено решение,
              статус вашего магазина изменится. В среднем, проверка длится не более 1 суток. Если
              ваш магазин не пройдет проверку, тогда появится статус “Отклонен”. По всем вопросам
              обращайтесь в {'  '}
              <span className="js-help shop-status-tab__text-link">службу поддержки.</span>
            </p>
          )}

          {status === 'NEED_TO_SET_UP' && (
            <p className="shop-status-tab__text">
              Ваш магазин подтвержден, но не готов к работе. Скорее всего вы потеряли статус
              самозанятого. По всем вопросам обращайтесь в {'  '}
              <span className="js-help shop-status-tab__text-link">службу поддержки.</span>
            </p>
          )}

          {status === 'BLOCKED' && (
            <>
              <p className="shop-status-tab__text">
                К сожалению, мы были вынуждены заблокировать ваш магазин. Вы не сможете отправить
                повторный запрос на его рассмотрение. По всем вопросам обращайтесь в {'  '}
                <span className="js-help shop-status-tab__text-link">службу поддержки.</span>
              </p>

              {blockedReason && (
                <p className="shop-status-tab__text">Причина блокировки: {blockedReason}</p>
              )}
            </>
          )}

          {status === 'REJECTED' && (
            <>
              <p className="shop-status-tab__text">
                К сожалению, мы были вынуждены отклонить заявку на подключение вашего магазина. Вы
                можете отправить повторный запрос, после того как устраните причину. По всем
                вопросам обращайтесь в {'  '}
                <span className="js-help shop-status-tab__text-link">службу поддержки.</span>
              </p>

              {declinedReason && (
                <p className="shop-status-tab__text">Причина отклонения: {declinedReason}</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ShopStatusTab.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  blockedReason: PropTypes.string,
  declinedReason: PropTypes.string,
};

ShopStatusTab.defaultProps = {
  className: null,
  blockedReason: null,
  declinedReason: null,
};

export default ShopStatusTab;

import * as StompJS from '@stomp/stompjs';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  ERROR,
  PARTLY_YES,
  WS_CHANGED_AUTH_STATUS,
  WS_CHANGED_BALANCE,
  WS_CHANGED_SZ_STATUS,
  WS_NEW_MESSAGE,
  YES,
} from '../../constants';
import { getCookie } from '../../helpers';
import {
  setChangedTaxStatus,
  setSzStatusFromDb,
  setUserBalance,
  setUserChangedAuthStatus,
  setUserNewMessages,
} from '../../modules/authLegasy/actions';

/**
 * Подключаем вебсокеты, берем оттуда инфу по:
 * - новым сообщениями,
 * - сменившемуся сз статусу (с YES на что-то другое),
 * - сменившемуся статусу учетки
 * - балансу
 * */
export const useWebSocketEffect = (user) => {
  const dispatch = useDispatch();
  const wsUrl = useRef(process.env.REACT_APP_WS_URL);
  const login = user?.login || null;

  useEffect(() => {
    if (login && wsUrl.current) {
      const ws = StompJS.Stomp.client(wsUrl.current);
      const headers = {};

      if (getCookie('XSRF-TOKEN')) {
        headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
      }

      if (process.env.REACT_APP_IS_PROD_BUILD !== 'N') {
        ws.debug = () => {};
      }

      /** Id подписки - используется для отписки */
      const mySubId = 'my-subscription-id-001';

      /** При закрытии вебсокета - логаутим юзера */
      const errorCallback = () => {
        if (
          window.location.href.indexOf('pro.selfwork.ru') > -1 ||
          window.location.href.indexOf('test.pro.selfwork.ru') > -1
        ) {
          // dispatch(getUser()).catch(() => {});
          // dispatch(logOut()).catch(() => {});
        }
      };

      /** Удачное подключение к вебсокетам */
      const successCallback = () => {
        ws.subscribe(`/user/${login}/**`, (message) => {
          const change = JSON.parse(message.body);

          /** Изменение баланса */
          if (change && change.type === WS_CHANGED_BALANCE) {
            dispatch(setUserBalance(change.balance));
          }

          /** Новое уведомление */
          if (change && change.type === WS_NEW_MESSAGE) {
            dispatch(setUserNewMessages());
          }

          /** Изменение статуса учтеки */
          if (change && change.type === WS_CHANGED_AUTH_STATUS) {
            dispatch(setUserChangedAuthStatus(change.clientGroup));
          }

          /** Изменение сз статуса с YES на что-то другое */
          if (change && change.type === WS_CHANGED_SZ_STATUS) {
            if (change.status === ERROR && change.statusFromDb === YES) {
              dispatch(setSzStatusFromDb(PARTLY_YES));
            } else {
              dispatch(setChangedTaxStatus(change.status));
              dispatch(setSzStatusFromDb(''));
            }
          }
        });
      };

      /** Изначальный коннект */
      ws.connect(
        headers,
        () => successCallback(),
        () => errorCallback(),
        () => errorCallback(),
      );

      // TODO: Не понятно хдлам-легаси или полезная фича, но кем-то закоменчено
      /** Реконнект */
      /*      const reconnect = (socketUrl, success) => {
        let connected = false;
        const reconInv = setInterval(() => {
          ws = StompJS.Stomp.client(wsUrl.current);
          ws.connect(
            headers,
            () => {
              clearInterval(reconInv);
              connected = true;
              success();
            },
            () => {
              if (connected) {
                reconnect(socketUrl, successCallback);
              }
            },
            { id: mySubId },
            errorCallback,
          );
        }, 10000);
      }; */

      /** Отписываемся от подписок */
      return () => {
        ws.unsubscribe(mySubId, {});
      };
    }
    // eslint-disable-next-line
  }, [login]);
};

const formatDateYYYYmmDD = date => {
  if (date && date instanceof Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yy = date.getFullYear();

    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    return `${yy}-${mm}-${dd}`;
  }

  return false;
};

export default formatDateYYYYmmDD;

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

import { ParagraphText } from 'ui/molecules';

export const ButtonLight = ({ children, onClick, width, borderRadius, disabled, className }) => {
  const onCurrentClick = useCallback(
    (event) => {
      event.preventDefault();
      !disabled && onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <ButtonStyled
      onClick={(e) => {
        onCurrentClick(e);
      }}
      type="button"
      borderRadius={borderRadius}
      width={width}
      disabled={disabled}
      className={className}
    >
      <ParagraphText
        weight={WEIGHT['circe-bold']}
        size="16px"
        color={COLOR['deep-sky-blue']}
        disabled={disabled}
      >
        {children}
      </ParagraphText>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  display: block;
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  border-radius: 8px;
  text-decoration: none;
  background: var(--light-blue);

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: unset;
    `}
  & p {
    display: block;
    padding: 13px 0;
    width: 100%;
    margin: auto;
    border-radius: 8px;
  }
`;

ButtonLight.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  className: PTClassNames,
};

ButtonLight.defaultProps = {
  width: '100%',
  borderRadius: '',
  onClick: (f) => f,
  className: '',
  disabled: false,
};

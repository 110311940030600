import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Components
import FooterAppLinks from 'modules/authLegasy/components/FooterAppLinks/FooterAppLinks';

//  TODO: PRO-384
// // Icons
// import iconHand from 'static/assets/icon-emoji-hand.png';

// Styles
import './Footer.scss';

export const Footer = ({
  isMenuOpenWithTransition,
  isUserAuth,
  isInProfile,
  isGreyDesktopBg,
  isDisabled,
}) => {
  //  TODO: PRO-384
  // const windowSize = useWindowSize();

  if (isDisabled) {
    return <></>;
  }

  return (
    <div
      className={cx('footer', {
        'footer_menu-open': isMenuOpenWithTransition,
        footer_login: isUserAuth,
        footer_grey: isInProfile || isGreyDesktopBg,
      })}
    >
      <div className="footer__container">
        {/* ССЫЛКИ НА ПРИЛОЖЕНИЕ */}
        <FooterAppLinks />
        {/* ССЫЛКИ НА ПРИЛОЖЕНИЕ */}

        {/* TODO: PRO-384 */}
        {/* {windowSize.innerWidth > 700 && (
          <button className="footer__chat-button js-help" type="button">
            Чат поддержки{' '}
            <div className="footer__chat-button-image">
              <img src={iconHand} alt="" className="footer__chat-button-icon" />
            </div>
          </button>
        )} */}
      </div>
    </div>
  );
};

Footer.propTypes = {
  isMenuOpenWithTransition: PropTypes.bool.isRequired,
  isUserAuth: PropTypes.bool,
  isInProfile: PropTypes.bool,
  isGreyDesktopBg: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Footer.defaultProps = {
  isUserAuth: false,
  isInProfile: false,
  isGreyDesktopBg: false,
  isDisabled: false,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  isMenuOpenWithTransition: state.auth.isMenuOpenWithTransition,
  isUserAuth: state.auth.isUserAuth,
});

export default connect(mapStateToProps)(Footer);

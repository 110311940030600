import useWindowSize from '@rehooks/window-size';
import { getContext, getMoneybox } from 'api/moneybox';
import { formatDate } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { getUserLimits } from 'modules/profileLegasy/selectors';
import { addSaveMoney, changeMoneybox } from 'modules/cashbox/actions';
import QrCodeSlider from 'modules/cashbox/components/QrCodeSlider/QrCodeSlider';
import SaveMoneyForm from 'modules/cashbox/components/SaveMoneyForm/SaveMoneyForm';
import SaveMoneyMobilePopup from 'modules/cashbox/components/SaveMoneyMobilePopup/SaveMoneyMobilePopup';
import SaveMoneyPreview from 'modules/cashbox/components/SaveMoneyPreview/SaveMoneyPreview';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';
import TitleWithProgressBar from 'modules/shared/components/TitleWithProgressBar/TitleWithProgressBar';

// Misc
import {
  ANY_SUM,
  CONSISTENT_ANIMATIONS_DELAY,
  FIXED_SUM,
  HINT_TIMEOUTS,
  KOPIM_URL,
} from 'constants/index';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import emodjiOk from 'static/assets/icon-emoji-ok.png';
import emodjiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as ResponsiveLogo } from 'static/assets/logo-responsive.svg';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './SaveMoneyCreation.scss';
import { calculateCommission, findMinSum } from '../../../../tools/utils/commission-calculation';
import { maxSumDefault, minSumDefault } from '../../../../tools/constants';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

const colorThemes = [1, 2, 3, 4, 5, 6];

const SaveMoneyCreation = ({
  dispatch,
  userFirstName,
  userMiddleName,
  userLastName,
  userAvatar,
  isUrlLinkLoading,
  isUrlLinkError,
  userLimits,
  user,
}) => {
  const windowSize = useWindowSize();
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;
  const [isRendered, setIsRendered] = useState(false);
  const [redirectOnSaveMoney, setRedirectOnSaveMoney] = useState(false);

  const [purpose, setPurpose] = useState('');
  const [commentary, setCommentary] = useState('');

  const [isNameVisible, setIsNameVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const [saveMoneyColorTheme, setSaveMoneyColorTheme] = useState(colorThemes[0]);
  const [sumType, setSumType] = useState(ANY_SUM);
  const [sum, setSum] = useState('');
  const [minSum, setMinSum] = useState(minSumDefault);
  const [maxSum, setMaxSum] = useState(maxSumDefault);
  // eslint-disable-next-line no-unused-vars
  const [commission, setCommission] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  const [dateEndPayment, setDateEndPayment] = useState(null);

  const [isSaveActionFailed, setIsSaveActionFailed] = useState(false);
  const [isSaveActionLoading, setIsSaveActionLoading] = useState(false);
  const [isSaveActionSucceed, setIsSaveActionSucceed] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isSuccessSaveMoneyChange, setIsSuccessSaveMoneyChange] = useState(false);
  const [isFailPopupOpen, setIsFailPopupOpen] = useState(false);

  const [url, setUrl] = useState('');

  const [isPreviewMobilePopupOpen, setIsPreviewMobilePopupOpen] = useState(false);
  const [isQrCodeMobilePopupOpen, setIsQrCodeMobilePopupOpen] = useState(false);

  const [currentResultTabId, setCurrentResultTabId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [saveMoneyData, setSaveMoneyData] = useState(null);

  const resultTabArray = [
    {
      title: 'Страница',
      click: () => setCurrentResultTabId(0),
    },
    {
      title: 'QR-код',
      click: () => setCurrentResultTabId(1),
    },
  ];

  useEffect(() => {
    setIsRendered(true);
    dispatch(getContext())
      .then((res) => {
        if (res && res.data) {
          setCommission(res.data.commission || null);
          if (res.data.hasOwnProperty('minSum') && res.data.hasOwnProperty('maxSum')) {
            setMinSum(findMinSum([res.data.minSum, res.data.maxSum]));
            setMaxSum(
              calculateCommission({
                sum: findMinSum([userLimits.balanceLimit, res.data.maxSum]),
                commission: res.data.commission || null,
                inverted: true,
              }),
            );
          }
        }
      })
      .catch(() => {});
  }, [dispatch, userLimits]);

  /**
   * Если пользваотель перешел на страницу по ссылке "Отредактировать",
   *  то в localStorage есть showCaseId, в таком случе отправляем на
   *  сервер запрос на данные оп этому id, чтобы отобразить данные по редактируемой витрине
   *  */
  useEffect(() => {
    if (window && window.localStorage && window.localStorage.getItem('saveMoneyId')) {
      const saveMoneyId = window.localStorage.getItem('saveMoneyId');
      setIsLoading(true);

      getMoneybox(saveMoneyId)
        .then((res) => {
          setSaveMoneyData(res.data);
          if (window && window.localStorage) {
            window.localStorage.removeItem('saveMoneyId');
          }

          setPurpose(res.data.name);
          setIsNameVisible(res.data.needFio);
          setIsPhoneVisible(res.data.needPhone);
          setIsEmailVisible(res.data.needEmail);
          setUrl(saveMoneyId);
          setCommentary(res.data.commentary);
          setSaveMoneyColorTheme(colorThemes[+res.data.background - 1]);
          setDateEndPayment(new Date(res.data.dateEndPayment));
          onChangeDaysLeft(new Date(res.data.dateEndPayment));

          if (res.data.sum) {
            setSumType(FIXED_SUM);
            setSum((res.data.sum / 100).toString());
          } else {
            setSumType(ANY_SUM);

            if (res.data.sumHint) {
              setSum((res.data.sumHint / 100).toString());
            }
          }

          setIsLoading(false);
        })
        .catch(() => {
          if (window && window.localStorage) {
            window.localStorage.removeItem('saveMoneyId');
          }
          setIsLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Клик создание копилки */
  const createSaveMoneyClick = () => {
    setIsSaveActionFailed(false);
    setIsSaveActionSucceed(false);
    setIsSaveActionLoading(true);
    setIsSuccessSaveMoneyChange(false);

    dispatch(
      addSaveMoney({
        background: saveMoneyColorTheme,
        commentary,
        dateEndPayment: formatDate(dateEndPayment),
        link: url,
        name: purpose,
        needEmail: isEmailVisible,
        needFio: isNameVisible,
        needPhone: isPhoneVisible,
        sum: sumType === FIXED_SUM ? sum * 100 : 0,
        sumHint: sumType !== FIXED_SUM ? sum * 100 : null,
      }),
    )
      .then(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionSucceed(true);
        setIsSuccessPopupOpen(true);
      })
      .catch(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionFailed(true);
        setIsFailPopupOpen(true);
      });
  };

  /** Клик - редактирование копилки */
  const changeSaveMoneyClick = () => {
    setIsSaveActionFailed(false);
    setIsSaveActionSucceed(false);
    setIsSaveActionLoading(true);
    setIsSuccessSaveMoneyChange(true);

    dispatch(
      changeMoneybox(
        url,
        saveMoneyColorTheme,
        commentary,
        formatDate(dateEndPayment),
        url,
        purpose,
        isEmailVisible,
        isNameVisible,
        isPhoneVisible,
        sumType === FIXED_SUM ? sum * 100 : 0,
        sumType !== FIXED_SUM ? sum * 100 : null,
      ),
    )
      .then(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionSucceed(true);
        setIsSuccessPopupOpen(true);
      })
      .catch(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionFailed(true);
        setIsFailPopupOpen(true);
      });
  };

  /** Считаем дни до выбранной даты */
  const onChangeDaysLeft = (date) => {
    setDateEndPayment(date);

    if (date) {
      const today = new Date().getTime();
      const endDay = new Date(date).getTime();

      setDaysLeft(Math.ceil((endDay - today) / (1000 * 3600 * 24)));
    } else {
      setDaysLeft(null);
    }
  };

  /** Редирект на Копим */
  if (redirectOnSaveMoney) {
    return <Redirect to="/lk/kopim" />;
  }

  return createPortal(
    <>
      <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />
      <div className="save-money-creation">
        <div className="save-money-creation__container">
          {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth > 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <div className="save-money-creation__header-wrap">
              <PopupHeaderWithBalance className="save-money-creation__header" />
            </div>
          </CSSTransition>
          {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

          {windowSize.innerWidth <= 980 && (
            <div className="save-money-creation__header">
              <Link to="/lk" className="save-money-creation__header-logo-wrap">
                <ResponsiveLogo className="save-money-creation__header-logo" />
              </Link>

              <button
                type="button"
                className="save-money-creation__close"
                onClick={() => setRedirectOnSaveMoney(true)}
              >
                <IconClose className="save-money-creation__close-icon" />
              </button>
            </div>
          )}

          {isLoading ? (
            <div className="save-money-creation__loader">
              <CircleLoader />
            </div>
          ) : (
            <>
              {/* ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <TitleWithProgressBar
                  title={saveMoneyData ? 'Редактирование копилки' : 'Создание копилки'}
                  progress={28}
                />
              </CSSTransition>
              {/* /ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="save-money-creation__content">
                  {/* FORM */}
                  <div className="save-money-creation__form-wrap">
                    <SaveMoneyForm
                      purpose={purpose}
                      commentary={commentary}
                      onChangePurpose={(value) => setPurpose(value)}
                      onChangeCommentary={(value) => setCommentary(value)}
                      isNameVisible={isNameVisible}
                      onChangeIsNameVisible={() => setIsNameVisible((prevState) => !prevState)}
                      isPhoneVisible={isPhoneVisible}
                      onChangeIsPhoneVisible={() => setIsPhoneVisible((prevState) => !prevState)}
                      isEmailVisible={isEmailVisible}
                      onChangeIsEmailVisible={() => setIsEmailVisible((prevState) => !prevState)}
                      saveMoneyColorTheme={saveMoneyColorTheme}
                      onChangeSaveMoneyColorTheme={(theme) => setSaveMoneyColorTheme(theme)}
                      sumType={sumType}
                      onChangeSumType={(type) => setSumType(type)}
                      fixedSumTabClick={() => setSumType(FIXED_SUM)}
                      anySumTabClick={() => setSumType(ANY_SUM)}
                      sum={sum.toString()}
                      onChangeSum={setSum}
                      isSaveActionLoading={isSaveActionLoading}
                      createSaveMoneyClick={
                        saveMoneyData ? changeSaveMoneyClick : createSaveMoneyClick
                      }
                      url={url}
                      onChangeDaysLeft={onChangeDaysLeft}
                      daysLeft={daysLeft}
                      dateForSaveMoneyEditing={saveMoneyData && saveMoneyData.dateEndPayment}
                      minSum={Number(minSum)}
                      maxSum={Number(maxSum)}
                      isEditingSaveMoneyCase={Boolean(saveMoneyData)}
                      setUrl={setUrl}
                      isUrlLinkLoading={isUrlLinkLoading}
                      isUrlLinkError={isUrlLinkError}
                    />
                  </div>
                  {/* /FORM */}

                  {windowSize.innerWidth > 980 && (
                    <div className="save-money-creation__result">
                      <div className="save-money-creation__result-inner-wrap">
                        <TabsWithScrollableActiveState
                          className="save-money-creation__result-nav"
                          tabArray={resultTabArray}
                          currentTabId={currentResultTabId}
                          isRendered={isRendered}
                        />

                        <div className="save-money-creation__preview-wrap">
                          {currentResultTabId === 0 && (
                            <SaveMoneyPreview
                              className="save-money-preview_preview"
                              isPreview
                              sum={+sum}
                              colorTheme={saveMoneyColorTheme}
                              daysLeft={daysLeft}
                              purpose={purpose}
                              commentary={commentary}
                              needFio={isNameVisible}
                              needPhone={isPhoneVisible}
                              needEmail={isEmailVisible}
                              avatar={userAvatar ? avatarUrl + userAvatar : stdAvatar}
                              fio={
                                userFirstName && userMiddleName && userLastName
                                  ? `${userFirstName} ${userMiddleName} ${userLastName}`
                                  : 'Анонимный пользователь'
                              }
                              isUserLoggedIn
                            />
                          )}

                          {currentResultTabId === 1 && (
                            <QrCodeSlider
                              url={`${KOPIM_URL}${url}`}
                              className="save-money-creation__slider"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {windowSize.innerWidth <= 980 && (
                    <nav className="save-money-creation__result-nav">
                      <div className="save-money-creation__result-nav-title">Предпросмотр:</div>
                      <div className="save-money-creation__result-nav-item-wrap">
                        <button
                          onClick={() => setIsPreviewMobilePopupOpen(true)}
                          type="button"
                          className="save-money-creation__result-nav-item"
                        >
                          Страница
                        </button>
                      </div>

                      <div className="save-money-creation__result-nav-item-wrap">
                        <button
                          onClick={() => setIsQrCodeMobilePopupOpen(true)}
                          type="button"
                          className="save-money-creation__result-nav-item"
                        >
                          QR-код
                        </button>
                      </div>
                    </nav>
                  )}
                </div>
              </CSSTransition>
            </>
          )}
        </div>

        {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <PopupCloseWithText closePopupHandler={() => setRedirectOnSaveMoney(true)} />
        </CSSTransition>
        {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

        {/* ПОПАП - УДАЧНОЕ СОЗДАНИЕ КОПИЛКИ */}
        <CSSTransition
          in={isSaveActionSucceed && isSuccessPopupOpen}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SmallPopupWithEmoji
            className="small-popup-with-emoji"
            mounted={isSaveActionSucceed && isSuccessPopupOpen}
            emoji={emodjiOk}
            closePopup={() => {
              setIsSuccessPopupOpen(false);
              setRedirectOnSaveMoney(true);
            }}
            title={isSuccessSaveMoneyChange ? `Копилка изменена!` : `Копилка создана!`}
            buttonArray={[
              {
                id: 1,
                title: 'Перейти в Копим',
                action: () => setRedirectOnSaveMoney(true),
                colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
              },
            ]}
          >
            <p>
              Отлично! Копилка была успешно {isSuccessSaveMoneyChange ? ` изменена` : ` создана`}.
              Вы всегда можете ее найти в разделе <Link to="/lk/kopim">Копим</Link>,{'   '}
              чтобы отредактировать или скопировать ссылку.
            </p>
          </SmallPopupWithEmoji>
        </CSSTransition>
        {/* /ПОПАП - УДАЧНОЕ СОЗДАНИЕ КОПИЛКИ */}

        {/* ПОПАП - НЕУДАЧА ПРИ СОЗДАНИИ ВИТРИНЫ */}
        <CSSTransition
          in={isFailPopupOpen && isSaveActionFailed}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SmallPopupWithEmoji
            className="small-popup-with-emoji"
            mounted={isFailPopupOpen && isSaveActionFailed}
            emoji={emodjiSad}
            closePopup={() => setIsFailPopupOpen(false)}
            title="Ой! Ошибка!"
            buttonArray={[
              {
                id: 1,
                title: 'Вернуться',
                action: () => setIsFailPopupOpen(false),
                colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
              },
            ]}
          >
            <p>
              Что-то пошло не так, попробуйте повторить попытку позже или{' '}
              <span className="js-help">напишите в тех. поддержку</span>.
            </p>
          </SmallPopupWithEmoji>
        </CSSTransition>
        {/* /ПОПАП - НЕУДАЧА ПРИ СОЗДАНИИ ВИТРИНЫ */}

        {/* ПОПАП - МОБИЛЬНЫЙ ПРЕВЬЮ СТРАНИЦЫ */}
        <CSSTransition
          in={isPreviewMobilePopupOpen && windowSize.innerWidth <= 980}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SaveMoneyMobilePopup
            className="save-money-mobile-popup"
            mounted={isPreviewMobilePopupOpen && windowSize.innerWidth <= 980}
            closePopup={() => setIsPreviewMobilePopupOpen(false)}
            title="Превью страницы"
          >
            <SaveMoneyPreview
              className="save-money-preview_preview"
              isPreview
              sum={sumType === FIXED_SUM ? +sum : null}
              colorTheme={saveMoneyColorTheme}
              daysLeft={daysLeft}
              purpose={purpose}
              needFio={isNameVisible}
              needPhone={isPhoneVisible}
              needEmail={isEmailVisible}
              commentary={commentary}
              avatar={userAvatar ? avatarUrl + userAvatar : stdAvatar}
              fio={
                userFirstName && userMiddleName && userLastName
                  ? `${userFirstName} ${userMiddleName} ${userLastName}`
                  : 'Анонимный пользователь'
              }
              isUserLoggedIn
            />
          </SaveMoneyMobilePopup>
        </CSSTransition>
        {/* /ПОПАП - МОБИЛЬНЫЙ ПРЕВЬЮ СТРАНИЦЫ */}

        {/* ПОПАП - МОБИЛЬНЫЙ ВЬЮ QR-КОДА */}
        <CSSTransition
          in={isQrCodeMobilePopupOpen && windowSize.innerWidth <= 980}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SaveMoneyMobilePopup
            className="save-money-mobile-popup"
            mounted={isQrCodeMobilePopupOpen && windowSize.innerWidth <= 980}
            closePopup={() => setIsQrCodeMobilePopupOpen(false)}
            title="QR-код"
          >
            <QrCodeSlider url={`${KOPIM_URL}${url}`} className="save-mobile-creation__slider" />
          </SaveMoneyMobilePopup>
        </CSSTransition>
        {/* /ПОПАП - МОБИЛЬНЫЙ ВЬЮ QR-КОДА */}
      </div>
    </>,
    document.body,
  );
};

SaveMoneyCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userFirstName: PropTypes.string,
  userMiddleName: PropTypes.string,
  userLastName: PropTypes.string,
  userAvatar: PropTypes.string,
  isUrlLinkLoading: PropTypes.bool,
  isUrlLinkError: PropTypes.bool,
  userLimits: PropTypes.shape({
    minSum: PropTypes.number.isRequired,
    balanceLimit: PropTypes.number.isRequired,
  }).isRequired,
};

SaveMoneyCreation.defaultProps = {
  isUrlLinkLoading: PropTypes.bool,
  isUrlLinkError: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userAvatar: state.auth.user && state.auth.user.avatar ? state.auth.user.avatar : null,
  userFirstName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.firstName
      ? state.auth.user.personal.firstName
      : null,
  userMiddleName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.middleName
      ? state.auth.user.personal.middleName
      : null,
  userLastName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.lastName
      ? state.auth.user.personal.lastName
      : null,
  isUrlLinkLoading: state.cashBox.isUrlLinkLoading,
  isUrlLinkError: state.cashBox.isUrlLinkError,
  userLimits: getUserLimits()(state),
});

export default connect(mapStateToProps)(SaveMoneyCreation);

import React from 'react';

const HintHowToPayTax = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      <p>
        Квитанция на уплату налога автоматически формируется налоговым органом и направляется в
        приложение{' '}
        <a
          href="https://npd.nalog.ru/app/"
          target="_blank"
          rel="noopener noreferrer"
          className="hint__text-link"
        >
          «Мой Налог»
        </a>{' '}
        .
      </p>
      <p>Получив квитанцию, можно оплатить:</p>
      <p>
        1. Через мобильное приложение «Мой Налог» или личный кабинет на сайте ФНС с помощью
        банковской карты;
      </p>
      <p>2. Через портал ГосУслуги;</p>
      <p> 3. Обратиться с квитанцией в банк, банкомат или платежный терминал;</p>
    </div>
  </div>
);

export default HintHowToPayTax;

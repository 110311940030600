import format from 'date-fns/format';
import useTimer from 'hooks/useTimer';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ButtonUI, LineText } from 'ui';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';

export const ReSendInfo = ({ isOnTimer, time, onResendSMS }) => {
  const [onTimer, currentTime, isTimer] = useTimer();

  const currentTimeFormated = useMemo(
    () => Number(currentTime) && format(new Date(0, 0, 0, 0, 0, currentTime), 'mm:ss'),
    [currentTime],
  );

  useEffect(() => {
    if (isOnTimer && time) onTimer(time);
  }, [isOnTimer, time, onTimer]);

  return (
    <>
      {isTimer && (
        <LineTextStyled>{`Получить новый код можно через ${currentTimeFormated}`}</LineTextStyled>
      )}
      {!isTimer && (
        <LineTextStyled>
          {'Введите код из СМС. '}
          <ButtonLinkStyled onClick={() => onResendSMS()}>Запросить повторно</ButtonLinkStyled>
        </LineTextStyled>
      )}
    </>
  );
};

ReSendInfo.propTypes = {
  isOnTimer: PropTypes.bool.isRequired,
  time: PropTypes.number.isRequired,
  onResendSMS: PropTypes.func.isRequired,
};

const LineTextStyled = styled(LineText)`
  ${getFont(WEIGHT['rubik-regular'])}
  color: var(--bluey-grey);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ButtonLinkStyled = styled(ButtonUI.Link)`
  color: var(--dusk-blue);
`;

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import useToggleAndOutClick from 'use-toggle-and-outclick';

import { getAcquiringSettings, updateMerchantPageStyle } from 'modules/acquiring/actions';
import PagePreviewPopup from 'modules/acquiring/components/PagePreviewPopup/PagePreviewPopup';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import PageTitle from 'modules/shared/components/PageTitle/PageTitle';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';

import { FAIL, HINT_TIMEOUTS, LOADING, SUCCESS } from 'constants/index';

import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';
import iconOk from 'static/assets/icon-emoji-ok.png';
import { ReactComponent as IconPreview } from 'static/assets/icon-preview.svg';
import preview1 from 'static/assets/page-preview-1.png';
import preview2 from 'static/assets/page-preview-2.png';
import preview3 from 'static/assets/page-preview-3.png';
import preview4 from 'static/assets/page-preview-4.png';

import './PaymentPagesTab.scss';

const PaymentPagesTab = ({ chosenShop }) => {
  const dispatch = useDispatch();
  const [isChooseStylePopupOpen, setIsChooseStylePopupOpen] = useState(false);
  const [titleForPopup, setTitleForPopup] = useState('');
  const [imageForPopup, setImageForPopup] = useState('');
  const [idToChangeStyle, setIdToChangeStyle] = useState('');
  const [changeStyleStatus, setChangeStyleStatus] = useState('');
  const [connectionMethod, setConnectionMethod] = useState('');
  const [paymentPageStyle, setPaymentPageStyle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isHintOpen, hintEl, toggleDrop, closeHintExplicitly] = useToggleAndOutClick();

  const list = [
    {
      id: 1,
      title: 'Оплата на внешней странице',
      isActive: connectionMethod === 'SMART_PAYMENT',
      type: 'Смарт оплата',
      cardsList: [
        {
          id: 'card-1',
          idForPopup: 'FIRST_STYLE',
          isChosen: paymentPageStyle === 'FIRST_STYLE',
          background: 'linear-gradient(113.01deg, #9EABF4 0%, #E4AAE6 101.7%)',
          title: 'Светлый минимализм',
          image: preview1,
        },
        {
          id: 'card-2',
          idForPopup: 'SECOND_STYLE',
          isChosen: paymentPageStyle === 'SECOND_STYLE',
          background: 'linear-gradient(112.63deg, #F9627D -1.38%, #C57EFD 120.57%)',
          title: 'Яркая абстракция',
          image: preview2,
        },
      ],
    },
    {
      id: 2,
      title: 'Виджет прямо на вашем сайте',
      isActive: connectionMethod !== 'SMART_PAYMENT',
      type: 'Виджет',
      cardsList: [
        {
          id: 'card-3',
          idForPopup: 'FIRST_STYLE',
          isChosen: paymentPageStyle === 'FIRST_STYLE',
          background: 'linear-gradient(112.76deg, #3D4E81 25.88%, #6E7FF3 106.58%)',
          title: 'Модерн: темный',
          image: preview3,
        },
        {
          id: 'card-4',
          idForPopup: 'SECOND_STYLE',
          isChosen: paymentPageStyle === 'SECOND_STYLE',
          background: 'linear-gradient(111.61deg, #A1CAF9 12.36%, #F2F7FF 123.73%)',
          title: 'Модерн: светлый',
          image: preview4,
        },
      ],
    },
  ];

  /** Берем данные по мерчанту */
  useEffect(() => {
    if (!changeStyleStatus || changeStyleStatus === SUCCESS) {
      dispatch(getAcquiringSettings(chosenShop))
        .then((res) => {
          if (res && res.data) {
            setConnectionMethod(res.data.connectionMethod);
            setPaymentPageStyle(res.data.paymentPageStyle);
            setIsLoading(false);
          }
        })
        .catch(() => {});
    }
  }, [chosenShop, dispatch, changeStyleStatus]);

  /** Выбираем другой стиль */
  const changeStyle = (style) => {
    setChangeStyleStatus(LOADING);
    dispatch(updateMerchantPageStyle(chosenShop, style))
      .then(() => {
        setChangeStyleStatus(SUCCESS);
      })
      .catch(() => {
        setChangeStyleStatus(FAIL);
      });
  };

  /** Открываем попап с выбором другого стиля */
  const chooseNewDesign = (title, idForPopup) => {
    setIsChooseStylePopupOpen(true);
    setTitleForPopup(title);
    setIdToChangeStyle(idForPopup);
  };

  /** Открываем попап с превью страницы оплаты */
  const previewPopupOpen = (title, image) => {
    setTitleForPopup(title);
    setImageForPopup(image);
    toggleDrop();
  };

  return (
    <div className="payment-pages-tab">
      <div className="payment-pages-tab__top">
        <PageTitle title="Страница оплаты" className="payment-pages-tab__title" />

        <div className="payment-pages-tab__desc">
          Эту страницу увидит ваш покупатель, когда будет перенаправлен на страницу оплаты из
          магазина.
        </div>
      </div>

      {isLoading ? (
        <div className="payment-pages-tab__loader">
          <CircleLoader />
        </div>
      ) : (
        <div className="payment-pages-tab__list">
          {list.map((item) => (
            <div
              className={cx('payment-pages-tab__block', {
                'payment-pages-tab__block_in-active': !item.isActive,
              })}
              key={item.id}
              data-testid="payment-pages-tab-block"
            >
              <div className="payment-pages-tab__block-top">
                <div className="payment-pages-tab__block-top-wrap">
                  <h4 className="payment-pages-tab__block-title">{item.title}</h4>

                  {item.isActive && (
                    <div className="payment-pages-tab__block-active-badge">
                      <IconCheck className="payment-pages-tab__block-active-badge-icon" />
                      <p className="payment-pages-tab__block-active-badge-text">Подключено</p>
                    </div>
                  )}
                </div>

                {!item.isActive && item.id === 2 && (
                  <div className="payment-pages-tab__block-desc">
                    Для разблокировки выберите способ подключения "Виджет Сам.Эквайринг" в
                    параметрах магазина
                  </div>
                )}
                {!item.isActive && item.id === 1 && (
                  <div className="payment-pages-tab__block-desc">
                    Для разблокировки выберите способ подключения "Смарт оплата" в параметрах
                    магазина
                  </div>
                )}
              </div>

              <div className="payment-pages-tab__block-list">
                {item.cardsList.map((card) => (
                  <div
                    className="payment-pages-tab__card"
                    key={card.id}
                    style={{ background: card.background }}
                  >
                    <div className="payment-pages-tab__card-top">
                      {item.isActive ? (
                        <>
                          {card.isChosen ? (
                            <div className="payment-pages-tab__choose-button payment-pages-tab__choose-button_chosen">
                              Выбрано
                            </div>
                          ) : (
                            <button
                              className="payment-pages-tab__choose-button"
                              type="button"
                              onClick={() => chooseNewDesign(card.title, card.idForPopup)}
                            >
                              Выбрать
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="payment-pages-tab__choose-button payment-pages-tab__choose-button_unavailable">
                          Недоступно
                        </div>
                      )}

                      <button
                        className="payment-pages-tab__card-preview"
                        type="button"
                        onClick={() => previewPopupOpen(card.title, card.image)}
                        data-testid="preview-button"
                      >
                        <span className="payment-pages-tab__card-preview-text">Превью</span>
                        <IconPreview className="payment-pages-tab__card-preview-icon" />
                      </button>
                    </div>

                    <div className="payment-pages-tab__card-content">
                      <p className="payment-pages-tab__card-type">{item.type}</p>
                      <p className="payment-pages-tab__card-title">{card.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <SmallPopupWithEmoji
        closePopup={() => setIsChooseStylePopupOpen(false)}
        mounted={isChooseStylePopupOpen}
        title="Изменение дизайна"
        emoji={iconOk}
        isLoading={changeStyleStatus === LOADING}
        buttonArray={
          !changeStyleStatus
            ? [
                {
                  id: 1,
                  title: 'Отмена',
                  action: () => {
                    setIsChooseStylePopupOpen(false);
                    setChangeStyleStatus('');
                  },
                  colorType: 'grey-border',
                },
                {
                  id: 2,
                  title: 'Продолжить',
                  action: () => {
                    changeStyle(idToChangeStyle);
                  },
                  colorType: 'blue',
                  isLoading: changeStyleStatus === 'LOADING',
                },
              ]
            : [
                {
                  id: 1,
                  title: 'Хорошо',
                  action: () => {
                    setIsChooseStylePopupOpen(false);
                    setChangeStyleStatus('');
                  },
                  colorType: 'blue',
                },
              ]
        }
        className="payment-pages-tab__popup small-popup-with-emoji"
      >
        {!changeStyleStatus && <p>Вы изменяете дизайн на: &quot;{titleForPopup}&quot;</p>}

        {changeStyleStatus === SUCCESS && (
          <p>Дизайн успешно изменен на: &quot;{titleForPopup}&quot;</p>
        )}

        {changeStyleStatus === FAIL && (
          <p>
            К сожалению, что-то пошло не так, и мы не смогли изменить дизайн. Попробуйте повторить
            запрос позже.
          </p>
        )}
      </SmallPopupWithEmoji>

      <CSSTransition
        in={isHintOpen}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="page-preview-popup"
      >
        <PagePreviewPopup
          ref={hintEl}
          onCloseBtnClick={closeHintExplicitly}
          className="accounting__hint"
          title={titleForPopup}
          image={imageForPopup}
        />
      </CSSTransition>
    </div>
  );
};

PaymentPagesTab.propTypes = {
  chosenShop: PropTypes.string.isRequired,
};

export default PaymentPagesTab;

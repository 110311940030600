/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ButtonLight } from '../molecules';
import { ButtonUI } from 'ui';

import { ACCOUNTS_TYPES } from '../../../../utils/constants';

export const ButtonCard = ({
  status,
  accountType,
  urlToIdentificationAccountDetailsPage,
  urlToEDOAccountDetailsPage,
  urlToAuthorizationAccountDetailsPage,
  children,
}) => {
  const url = useMemo(() => {
    if (accountType === ACCOUNTS_TYPES.IDENTIFIED) return urlToIdentificationAccountDetailsPage;
    if (accountType === ACCOUNTS_TYPES.EDO) return urlToEDOAccountDetailsPage;
    if (accountType === ACCOUNTS_TYPES.AUTHORIZED) return urlToAuthorizationAccountDetailsPage;
    return null;
  }, [
    accountType,
    urlToIdentificationAccountDetailsPage,
    urlToEDOAccountDetailsPage,
    urlToAuthorizationAccountDetailsPage,
  ]);

  switch (status) {
    case 'availabled':
      return (
        <ButtonNormalStyled routed url={url}>
          {children}
        </ButtonNormalStyled>
      );
    case 'actived':
    case 'inProgress':
      return <ButtonLightStyled disabled>{children}</ButtonLightStyled>;
    case 'disabled':
      return <ButtonDisabledStyled>{children}</ButtonDisabledStyled>;
    default:
      return <ButtonLightStyled disabled>{children}</ButtonLightStyled>;
  }
};

ButtonCard.propTypes = {
  accountType: PropTypes.oneOf(['IDENTIFIED', 'AUTHORIZED', 'EDO', 'REGISTERED']).isRequired,
  children: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['availabled', 'inProgress', 'actived', 'disabled']).isRequired,
  urlToAuthorizationAccountDetailsPage: PropTypes.string,
  urlToIdentificationAccountDetailsPage: PropTypes.string,
};

ButtonCard.defaultProps = {
  urlToAuthorizationAccountDetailsPage: null,
  urlToIdentificationAccountDetailsPage: null,
};

const ButtonMixin = css`
  margin-top: 20px;
  min-width: 210px;
`;

const ButtonNormalStyled = styled(ButtonUI.Normal)`
  ${ButtonMixin}
`;

const ButtonLightStyled = styled(ButtonLight)`
  ${ButtonMixin}
`;

const ButtonDisabledStyled = styled(ButtonUI.Disabled)`
  ${ButtonMixin}
`;

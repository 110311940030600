import { ButtonDisabled } from './ButtonDisabled';
import { ButtonLight } from './ButtonLight';
import { ButtonNormal } from './ButtonNormal';
import { ButtonPlate } from './ButtonPlate';
import { ButtonLink } from './ButtonLink';
import { ButtonRouteLink } from './ButtonRouteLink';

export const ButtonUI = {
  Normal: ButtonNormal,
  Light: ButtonLight,
  Disabled: ButtonDisabled,
  Plate: ButtonPlate,
  Link: ButtonLink,
  RouteLink: ButtonRouteLink,
};

import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DocumentsTabledWidget } from 'modules/profile';
import { usePartnerIdSelector } from 'modules/profileLegasy/selectors';

const PartnerDocumentsTab = () => {
  const { platformName, partnershipId } = useParams();
  const partnerId = usePartnerIdSelector(platformName, Number(partnershipId));
  if (!partnerId) {
    return <Redirect to="/lk/profile-settings/partners" />;
  }

  return (
    partnerId && (
      <DocumentsTabledWidgetStyled
        isNotWidgetTitle
        partnerIdDefaultFilter={partnerId}
        platformNameDefaultFilter={platformName}
        includesColumns={{
          documentName: true,
          data: true,
          state: true,
        }}
      />
    )
  );
};

export default PartnerDocumentsTab;

const DocumentsTabledWidgetStyled = styled(DocumentsTabledWidget)`
  margin-top: 31px;
  padding: 0;
`;

import PropTypes from 'prop-types';
import React from 'react';

import logoLight from 'static/assets/sbp-logo-btn-light.svg';
import logoDark from 'static/assets/sbp-logo-btn-dark.svg';

import './SBPButton.scss';
import cx from "classnames";

const SBPButton = ({ className, disablePayButton, onClick }) => {

  return (
    <button
      type="button"
      className={cx('standard-button sbp-button', {
        [className]: className,
      })}
      disabled={disablePayButton}
      onClick={onClick}
    >Оплатить через
      <img className="sbp-button__logo" src={disablePayButton ? logoLight : logoDark} alt="SBP Pay" />
    </button>
  );
};

SBPButton.propTypes = {
  className: PropTypes.string,
  disablePayButton: PropTypes.bool,
  onClick: PropTypes.func,
};

SBPButton.defaultProps = {
  className: null,
  disablePayButton: false,
  onClick: () => {},
};

export default SBPButton;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './TaxesProgressBar.scss';

const TaxesProgressBar = ({ isLimitNear, progress }) => {
  return (
    <div
      className={cx('taxes-progress-bar', {
        'taxes-progress-bar_limit': isLimitNear,
      })}
    >
      <div className="taxes-progress-bar__itself" style={{ width: `${progress}%` }} />
    </div>
  );
};

TaxesProgressBar.propTypes = {
  isLimitNear: PropTypes.bool,
  progress: PropTypes.number.isRequired,
};

TaxesProgressBar.defaultProps = {
  isLimitNear: false,
};

export default TaxesProgressBar;

import { fetchWrapper } from 'helpers';

/**
 * Запросить код подтверждения для закрытия кошелька.
 * @return {Promise<object>}
 */
export async function getWalletClosingSmsCode() {
  const url = `${process.env.REACT_APP_API_URL}/settings/close`;
  const { data } = await fetchWrapper(url, {
    method: 'PUT',
  });

  return data;
}

/**
 * Запросить новый смс-код.
 * @param {string} operationId - id операции из запроса getWalletClosingSmsCode
 * @return {Promise<void>}
 */
export async function resendWalletClosingSmsCode(operationId) {
  const url = `${process.env.REACT_APP_API_URL}/pinaccept/resend/${operationId}`;
  const { data } = await fetchWrapper(url);

  return data;
}

/**
 * Закрыть кошелек.
 * @param {number} actionPinAcceptId - id операции из запроса getWalletClosingSmsCode
 * @param {string} pin - пинкод из смс
 * @return {Promise<void>}
 */
export async function closeWallet(actionPinAcceptId, pin) {
  const url = `${process.env.REACT_APP_API_URL}/settings/close/confirm`;
  const { data } = await fetchWrapper(url, {
    method: 'POST',
    body: JSON.stringify({
      actionPinAcceptId,
      pin,
    }),
  });

  return data;
}

import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

import './EditButton.scss';

const EditButton = ({ action }) => {
  return (
    <button className="edit-button" type="button" onClick={action}>
      <IconEdit className="edit-button__icon" />
    </button>
  );
};

EditButton.propTypes = {
  action: PropTypes.func.isRequired,
};

export default EditButton;

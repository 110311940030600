/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getDocumentsCertificateThunk } from './thunk';

const initialState = {
  data: null,
  request: {
    state: null,
    pending: false,
    fulfilled: false,
    rejected: false,
    error: null,
  },
};

const documentsCertificateSlice = createSlice({
  name: 'documentsCertificate',
  initialState,
  reducers: {
    clearAction: () => initialState,
  },
  extraReducers: {
    [getDocumentsCertificateThunk.pending]: (state) => ({
      data: null,
      request: {
        state: 'pending',
        pending: true,
        fulfilled: false,
        rejected: false,
        error: null,
      },
    }),
    [getDocumentsCertificateThunk.fulfilled]: (state, action) => ({
      data: action.payload || {},
      request: {
        state: 'fulfilled',
        pending: false,
        fulfilled: true,
        rejected: false,
        error: null,
      },
    }),
    [getDocumentsCertificateThunk.rejected]: (state, action) => ({
      data: null,
      request: {
        state: 'rejected',
        pending: false,
        fulfilled: false,
        rejected: true,
        error: action.payload,
      },
    }),
  },
});

export const { clearAction } = documentsCertificateSlice.actions;
const { reducer } = documentsCertificateSlice;
export { reducer as documentsCertificateReducer };

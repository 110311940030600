import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Overlay from '../Overlay';
import iconEmojiSad from 'static/assets/icon-emoji-sad.png';

import { hidePopupEditBankCard, hidePopupEditBankCardFail } from '../../actions';

import styles from './PopupEditBankCardFail.module.scss';

const PopupEditBankCardFail = ({ dispatch }) => {
  const text =
    'Что-то пошло не так, попробуйте повторить попытку позже или напишите в тех.поддержку.';

  function onClose() {
    dispatch(hidePopupEditBankCard());
    dispatch(hidePopupEditBankCardFail());
  }

  return createPortal(
    <Overlay
      title="Ой! Ошибка!"
      icon={iconEmojiSad}
      text={() => text}
      nav={(props) => <PopupEditBankCardFailNav {...props} />}
      onClose={onClose}
      onSuccess={onClose}
    />,
    document.body,
  );
};

PopupEditBankCardFail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const PopupEditBankCardFailNav = ({ onSuccess }) => {
  return (
    <div className={styles.popupEditBankCardFailNav}>
      <button onClick={onSuccess}>Вернуться</button>
    </div>
  );
};

export default connect()(PopupEditBankCardFail);

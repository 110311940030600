import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import useWindowSize from '@rehooks/window-size';

import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';
import TaxesToPayTooltip from 'modules/tax/components/TaxesToPayTooltip/TaxesToPayTooltip';

import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

// Styles
import './TotalTaxToPay.scss';

const TotalTaxToPay = ({ className, isSumLoading, amount }) => {
  const [isTooltipOpen, tooltipDropEl, tooltipToggleDrop] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  return (
    <div
      className={cx('total-tax-to-pay', {
        [className]: className,
      })}
      data-testid="total-tax-to-pay"
    >
      <p className="total-tax-to-pay__title">Итого к оплате:</p>

      <TaxesAmount className="total-tax-to-pay__sum" isLoading={isSumLoading} amount={amount} />

      {/* КНОПКА ОТКРЫТИЯ ТУЛТИПА */}
      <div
        className={cx('total-tax-to-pay__tooltip-wrap', {
          'total-tax-to-pay__tooltip-wrap_tooltip-open': isTooltipOpen,
        })}
      >
        <button
          className="total-tax-to-pay__tooltip-btn"
          type="button"
          onClick={tooltipToggleDrop}
          data-testid="tooltip-open-button"
        >
          <IconInfo className="total-tax-to-pay__tooltip-btn-icon" />
        </button>

        {/* ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА > 1194 */}
        {windowSize.innerWidth > 1194 && isTooltipOpen && (
          <TaxesToPayTooltip
            isTooltipOpen={isTooltipOpen}
            ref={tooltipDropEl}
            type="TAXES_TO_PAY"
          />
        )}
        {/* /ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА > 1194 */}
      </div>
      {/* /КНОПКА ОТКРЫТИЯ ТУЛТИПА */}

      {/* ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА <= 1194 */}
      {windowSize.innerWidth <= 1194 && isTooltipOpen && (
        <TaxesToPayTooltip isTooltipOpen={isTooltipOpen} ref={tooltipDropEl} type="TAXES_TO_PAY" />
      )}
      {/* /ТУЛТИП ПРИ ШИРИНЕ ЭКРАНА <= 1194 */}
    </div>
  );
};

TotalTaxToPay.propTypes = {
  className: PropTypes.string,
  isSumLoading: PropTypes.bool,
  amount: PropTypes.number,
};

TotalTaxToPay.defaultProps = {
  className: null,
  isSumLoading: false,
  amount: null,
};

export default TotalTaxToPay;

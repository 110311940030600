import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import QRCode from 'qrcode';

import { capitalizeFirstLetter, formatDateHuman, formatTimeHuman } from 'helpers';

import styles from './Check.module.scss';

const Check = ({
  // номер чека
  id,
  // время операции
  operationTime,
  // имя
  firstName,
  // фамилия
  lastName,
  // отчество
  middleName,
  // номер ИНН
  inn,
  // список услуг
  services,
  // общая сумма всех услуг
  totalSum,
  // ссылка на чек
  link,
}) => {
  const qrcodeRef = React.useRef();

  const _date = new Date(operationTime);
  const date = formatDateHuman(_date);
  const time = formatTimeHuman(_date);
  const fio =
    capitalizeFirstLetter(firstName || '') +
    ' ' +
    capitalizeFirstLetter(lastName || '') +
    ' ' +
    capitalizeFirstLetter(middleName || '');

  React.useEffect(() => {
    if (!link) {
      return;
    }

    QRCode.toCanvas(qrcodeRef.current, link).catch(() => {});
  }, [link]);

  return (
    <div className={styles.check}>
      <div className={styles.checkContainer}>
        <div className={styles.checkSection}>
          <div className={styles.checkTitle}>Чек № {id}</div>
          <div className={styles.checkDateTime}>
            <time className={styles.checkDate}>{date}</time>
            <time className={styles.checkTime}>{time}</time>
          </div>
        </div>
        <div className={styles.checkSection}>
          <div className={styles.checkPayer}>{fio}</div>
          {inn && <div className={styles.checkInn}>{inn}</div>}
          <div className={styles.checkRegime}>Режим НО: НПД</div>
        </div>
        <div className={styles.checkSection}>
          <div className={styles.checkServiceList}>
            <header className={styles.checkServiceListHeader}>
              <span>Наименование</span>
              <span>Сумма</span>
            </header>
            {services.map((service, index) => (
              <div key={index} className={styles.checkServiceListItem}>
                <span>{index + 1 + '. ' + service.name}</span>
                <span>{service.amount} ₽</span>
              </div>
            ))}
          </div>
        </div>
        <div className={cx(styles.checkSection, styles.checkTotal)}>
          <span>Итого:</span>
          <span>{totalSum} ₽</span>
        </div>
        {link && (
          <div className={styles.checkQrCode}>
            <canvas className={styles.checkQrCodeCanvas} ref={qrcodeRef}></canvas>
          </div>
        )}
      </div>
      <div className={styles.checkWave}></div>
    </div>
  );
};

Check.propTypes = {
  id: PropTypes.string.isRequired,
  operationTime: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  inn: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      sum: PropTypes.number,
    }),
  ),
  totalSum: PropTypes.number.isRequired,
  link: PropTypes.string,
};

export default Check;

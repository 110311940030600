import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import TaxListContainer from 'modules/tax/components/TaxListContainer/TaxListContainer';

// Styles
import './TaxListWrap.scss';

const TaxListWrap = ({ history, monthList, taxList, dataLoadingStatus }) => {
  const windowSize = useWindowSize();
  const mobileView = windowSize.innerWidth <= 980;

  return (
    <>
      {mobileView ? (
        <Switch>
          <Route
            exact
            path="/lk/tax-list"
            history={history}
            render={() => (
              <TaxListContainer
                taxList={taxList}
                monthList={monthList}
                dataLoadingStatus={dataLoadingStatus}
              />
            )}
          />

          {monthList &&
            monthList.length >= 1 &&
            monthList.map((item) => (
              <Route
                path={`/lk/tax-list/month-${item.monthNumber}`}
                history={history}
                key={item.month}
                render={() => (
                  <TaxListContainer
                    isInnerPage
                    taxList={taxList.filter(
                      (tax) => Number(tax.dateCreate.slice(5, 7)) === item.monthNumber,
                    )}
                    currentMonth={item}
                    dataLoadingStatus={dataLoadingStatus}
                  />
                )}
              />
            ))}
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/lk/tax-list"
            render={() => (
              <TaxListContainer
                history={history}
                monthList={monthList}
                taxList={taxList}
                dataLoadingStatus={dataLoadingStatus}
              />
            )}
          />

          {monthList &&
            monthList.length >= 1 &&
            monthList.map((item) => (
              <Redirect
                from={`/lk/tax-list/month-${item.monthNumber}`}
                to="/lk/tax-list"
                key={item.month}
              />
            ))}
        </Switch>
      )}
    </>
  );
};

TaxListWrap.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  monthList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      month: PropTypes.string,
      sum: PropTypes.number,
    }),
  ),
  taxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      purpose: PropTypes.string,
      type: PropTypes.string,
      sum: PropTypes.number,
      month: PropTypes.string,
    }),
  ),
  dataLoadingStatus: PropTypes.string,
};

TaxListWrap.defaultProps = {
  monthList: [],
  taxList: [],
  dataLoadingStatus: null,
};

export default withRouter(TaxListWrap);

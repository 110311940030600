import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import {
  PAYMENT_TRANSFER_OK as STATUS_OK,
  PAYMENT_TRANSFER_FAIL as STATUS_FAIL,
  PAYMENT_TRANSFER_LOADING as STATUS_LOADING,
  PARTLY_YES,
} from 'constants/index';

// Icons
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

// Styles
import './HintAnnulCheck.scss';

const REASON_REVERT = 'deal.delete-state.revert';
const REASON_ERROR = 'deal.delete-state.error';

const HintAnnulCheck = ({ onSubmit, status, error, closeExplicitly, szStatusFromDb }) => {
  const [reason, setReason] = useState(REASON_REVERT);

  return (
    <div className="hint-annul-check">
      {/* ПОКА ЧЕК НЕ АННУЛИРОВАН */}
      {status !== STATUS_OK && (
        <>
          <div className="hint-annul-check__title">Вы уверены, что хотите аннулировать чек?</div>
          <div className="hint-annul-check__text">
            Аннулирование чека может вызвать проверку ФНС. Если вы не уверены в своем действии, то
            аннулировать чек не нужно. Если вы уверены, то выберите причину:
          </div>

          {/* ОПЦИИ */}
          <div className="hint-annul-check__options-list">
            <label className="hint-annul-check__options-item">
              <button
                className="hint-annul-check__options-item-checkbox"
                type="button"
                role="checkbox"
                aria-label="Возврат денег покупателю"
                onClick={() => setReason(REASON_REVERT)}
                aria-checked={reason === REASON_REVERT}
              />
              <span className="hint-annul-check__options-item-title">Возврат денег покупателю</span>
            </label>

            <label className="hint-annul-check__options-item">
              <button
                className="hint-annul-check__options-item-checkbox"
                type="button"
                role="checkbox"
                aria-label="Чек сформирован по ошибке"
                onClick={() => setReason(REASON_ERROR)}
                aria-checked={reason === REASON_ERROR}
              />
              <span className="hint-annul-check__options-item-title">
                Чек сформирован по ошибке
              </span>
            </label>
          </div>
          {/* /ОПЦИИ */}

          {/* КНОПКА */}
          <div className="hint-annul-check__button-wrap">
            <button
              className="hint-annul-check__button"
              type="button"
              onClick={(e) => {
                onSubmit(e, reason);
              }}
              disabled={
                status === STATUS_LOADING ||
                (status === STATUS_FAIL && error) ||
                (szStatusFromDb && szStatusFromDb === PARTLY_YES)
              }
            >
              {status !== STATUS_LOADING && <span> Аннулировать</span>}
              {status === STATUS_LOADING && (
                <CircleLoader width={30} height={30} mainColor="#ffffff" />
              )}
            </button>
          </div>
          {/* /КНОПКА */}

          {/* ПРИ ПОТРЕ СВЯЗИ В ФНС ОТОБРАЖАЕМ ОШИБКУ */}
          {szStatusFromDb && szStatusFromDb === PARTLY_YES && (
            <div className="hint-annul-check__error-wrap">
              <p className="hint-annul-check__error">
                <IconInfo className="hint-annul-check__icon-info" />
                На данный момент Вы не можете аннулировать чек, поскольку потеряна связсь с ФНС.
                Попробуйте повторить операцию позднее, если проблема не решится,{' '}
                <span className="js-help hint-annul-check__error">напишите нам</span>
              </p>
            </div>
          )}
          {/* ПРИ ПОТРЕ СВЯЗИ В ФНС ОТОБРАЖАЕМ ОШИБКУ */}

          {/* ОШИБКА */}
          {status === STATUS_FAIL && error && (
            <div className="hint-annul-check__error-wrap">
              <p className="hint-annul-check__error">
                <IconInfo className="hint-annul-check__icon-info" />
                {error}
              </p>
            </div>
          )}
          {/* /ОШИБКА */}
        </>
      )}

      {/* ЧЕК АННУЛИРОВАН */}
      {status === STATUS_OK && (
        <>
          <div className="hint-annul-check__title">Вы аннулировали чек</div>

          <p className="hint-annul-check__text">
            Чек успешно аннулирован и данные направлены в ФНС
          </p>

          <div className="hint-annul-check__button-wrap">
            <button type="button" className="hint-annul-check__button" onClick={closeExplicitly}>
              Продолжить
            </button>
          </div>
        </>
      )}
    </div>
  );
};

HintAnnulCheck.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string,
  error: PropTypes.string,
  closeExplicitly: PropTypes.func.isRequired,
  szStatusFromDb: PropTypes.string,
};

HintAnnulCheck.defaultProps = {
  error: null,
  status: null,
  szStatusFromDb: '',
};

export default HintAnnulCheck;

import React from 'react';
import { ReactComponent as PostEmptyIcon } from 'static/assets/icon-post-empty.svg';
import styles from './StubPartners.module.scss';

const StubPartners = () => (
  <div className={styles.stubPartners}>
    <PostEmptyIcon className={styles.stubPartnersIcon} />
    <div className={styles.stubPartnersText}>
      У вас нет партнеров. Они появятся, когда вы примите <br /> запрос на подключение от компании.
    </div>
  </div>
);

export default StubPartners;

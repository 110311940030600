import React from 'react';

const HintYouDeregisteredFromFNS = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      Возможно вы потеряли статус самозанятого (плательщика налога на профессиональный доход) в ФНС.
      Это могло произойти из-за превышения допустимого годового лимита в 2,4 млн рублей или по
      каким-то другим причинам.
    </div>
  </div>
);

export default HintYouDeregisteredFromFNS;

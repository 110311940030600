import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SadEmoji from './assents/sad-emoji.png';

import { WindowAlert } from '../../molecules';
import { ParagraphText } from '../../../../ParagraphText';

export const WindowOnlyAuthorized = ({ onClose, onClick }) => (
  <WindowAlert
    header="Вы еще не прошли идентификацию"
    emoji={
      <SadEmojiWraperStyled>
        <SadEmojiStyled />
      </SadEmojiWraperStyled>
    }
    buttonText="Подтвердить данные"
    onClose={onClose}
    onClick={onClick}
  >
    <ParagraphTextStyled>
      Вы сможете воспользоваться функциями Кошелька после того как подтвердите паспортные данные
    </ParagraphTextStyled>
  </WindowAlert>
);

WindowOnlyAuthorized.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ParagraphTextStyled = styled(ParagraphText)`
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  max-width: 570px;
`;

const SadEmojiWraperStyled = styled.div`
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SadEmojiStyled = styled.img.attrs(() => ({ src: SadEmoji }))`
  width: 40px;
  height: 40px;
`;

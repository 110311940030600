import React from 'react';
import cx from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import useThrottle from 'hooks/useThrottle';
import { ReactComponent as IconArrowUp } from 'static/assets/icon-arrow-up.svg';
import styles from './ScrollToTopButton.module.scss';

const ScrollToTopButton = React.forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const throttlePositionChange = useThrottle(100);
  const match = useRouteMatch('/lk/partner/:platformName/:partnerId/payments');

  React.useEffect(() => {
    const elem = ref.current;

    if (!elem) {
      return;
    }

    function onPositionChange() {
      throttlePositionChange(function () {
        const offset = elem.scrollTop;

        if (offset > 100 && match) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }

    onPositionChange();

    elem.addEventListener('scroll', onPositionChange);
    window.addEventListener('resize', onPositionChange);

    return () => {
      elem.removeEventListener('scroll', onPositionChange);
      window.removeEventListener('resize', onPositionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, match]);

  function scrollToTop() {
    if (!ref.current) {
      return;
    }

    ref.current.scrollTop = 0;
    setIsVisible(false);
  }

  return (
    <button
      className={cx(styles.scrollToTopButton, isVisible && styles.scrollToTopButtonVisible)}
      onClick={scrollToTop}
    >
      <IconArrowUp className={styles.scrollToTopButtonIcon} />
    </button>
  );
});

export default ScrollToTopButton;

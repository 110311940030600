export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const PARSE_PASSPORT_DATA_SUCCESS = 'PARSE_PASSPORT_DATA_SUCCESS';
export const PARSE_PASSPORT_DATA_FAIL = 'PARSE_PASSPORT_DATA_FAIL';
export const SENT_PASSPORT_DATA_SUCCESS = 'SENT_PASSPORT_DATA_SUCCESS';
export const SENT_PASSPORT_DATA_FAIL = 'SENT_PASSPORT_DATA_FAIL';
export const PARTIAL_AUTH_STATUS_SUCCESS = 'PARTIAL_AUTH_STATUS_SUCCESS';
export const PARTIAL_AUTH_STATUS_FAIL = 'PARTIAL_AUTH_STATUS_FAIL';
export const GET_UNCONFIRMED_EMAIL_SUCCESS = 'GET_UNCONFIRMED_EMAIL_SUCCESS';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const SENT_PASSPORT_AND_SELFIE_SUCCESS = 'SENT_PASSPORT_AND_SELFIE_SUCCESS';
export const SENT_PASSPORT_AND_SELFIE_FAIL = 'SENT_PASSPORT_AND_SELFIE_FAIL';
export const SAVE_PASSPORT_PHOTO = 'SAVE_PASSPORT_PHOTO';
export const SET_PASSPORT_ERROR_MESSAGE = 'SET_PASSPORT_ERROR_MESSAGE';
export const CLOSE_WALLET_SUCCESS = 'CLOSE_WALLET_SUCCESS';
export const CLOSE_WALLET_FAIL = 'CLOSE_WALLET_FAIL';
export const REQUEST_CODE_CLOSE_WALLET_SUCCESS = 'REQUEST_CODE_CLOSE_WALLET_SUCCESS';
export const REQUEST_CODE_CLOSE_WALLET_FAIL = 'REQUEST_CODE_CLOSE_WALLET_FAIL';
export const SET_AVATAR_SUCCESS = 'SET_AVATAR_SUCCESS';
export const SET_AVATAR_FAIL = 'SET_AVATAR_FAIL';
export const SET_AVATAR_ERROR = 'SET_AVATAR_ERROR';
export const CHECK_AVATAR_SUCCESS = 'CHECK_AVATAR_SUCCESS';
export const CHECK_AVATAR_FAIL = 'CHECK_AVATAR_FAIL';
export const REQUEST_CODE_TO_CHANGE_PASSWORD_SUCCESS = 'REQUEST_CODE_TO_CHANGE_PASSWORD_SUCCESS';
export const REQUEST_CODE_TO_CHANGE_PASSWORD_FAIL = 'REQUEST_CODE_TO_CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const REQUEST_CODE_CHANGE_PHONE_SUCCESS = 'REQUEST_CODE_CHANGE_PHONE_SUCCESS';
export const REQUEST_CODE_CHANGE_PHONE_FAIL = 'REQUEST_CODE_CHANGE_PHONE_FAIL';
export const CHANGE_PHONE_CONFIRM_SUCCESS = 'CHANGE_PHONE_CONFIRM_SUCCESS';
export const CHANGE_PHONE_CONFIRM_FAIL = 'CHANGE_PHONE_CONFIRM_FAIL';
export const SET_AUTH_STEP = 'SET_AUTH_STEP';
export const SET_RENDER_BETWEEN_TABS = 'SET_RENDER_BETWEEN_TABS';
export const GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS';
export const GET_OPERATIONS_FAIL = 'GET_OPERATIONS_FAIL';
export const CLEAR_OPERATIONS_SUCCESS = 'CLEAR_OPERATIONS_SUCCESS';
export const GET_LAST_DATE_OF_OPERATIONS_SUCCESS = 'GET_LAST_DATE_OF_OPERATIONS_SUCCESS';
export const GET_LAST_DATE_OF_OPERATIONS_FAIL = 'GET_LAST_DATE_OF_OPERATIONS_FAIL';
export const GET_INVOICE_CONTEXT_SUCCESS = 'GET_INVOICE_CONTEXT_SUCCESS';
export const GET_INVOICE_CONTEXT_FAIL = 'GET_INVOICE_CONTEXT_FAIL';
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_PDF_FAIL = 'GET_INVOICE_PDF_FAIL';
export const GET_CERTIFICATE_INCOME_STATEMENT_SUCCESS = 'GET_CERTIFICATE_INCOME_STATEMENT_SUCCESS';
export const GET_CERTIFICATE_INCOME_STATEMENT_FAIL = 'GET_CERTIFICATE_INCOME_STATEMENT_FAIL';
export const GET_CERTIFICATE_REGISTRATION_SUCCESS = 'GET_CERTIFICATE_REGISTRATION_SUCCESS';
export const GET_CERTIFICATE_REGISTRATION_FAIL = 'GET_CERTIFICATE_REGISTRATION_FAIL';

/** Notifications */
export const GET_ALL_MESSAGES_SUCCESS = 'GET_ALL_MESSAGES_SUCCESS';
export const GET_ALL_MESSAGES_FAIL = 'GET_ALL_MESSAGES_FAIL';
export const GET_UNREAD_MESSAGES_SUCCESS = 'GET_UNREAD_MESSAGES_SUCCESS';
export const GET_UNREAD_MESSAGES_FAIL = 'GET_UNREAD_MESSAGES_FAIL';
export const READ_ALL_MESSAGES_SUCCESS = 'READ_ALL_MESSAGES_SUCCESS';
export const READ_ALL_MESSAGES_FAIL = 'READ_ALL_MESSAGES_FAIL';
export const READ_EXACT_MESSAGE_SUCCESS = 'READ_EXACT_MESSAGE_SUCCESS';
export const READ_EXACT_MESSAGE_FAIL = 'READ_EXACT_MESSAGE_FAIL';
export const MARK_ALL_MESSAGES_AS_READ = 'MARK_ALL_MESSAGES_AS_READ';
export const CLEAR_UNREAD_MESSAGES = 'CLEAR_UNREAD_MESSAGES';

export const CHECK_BANK_CARD_SUCCESS = 'CHECK_BANK_CARD_SUCCESS';
export const CHECK_BANK_CARD_FAIL = 'CHECK_BANK_CARD_FAIL';

/** Money request */
export const CREATE_MONEY_REQUEST_SUCCESS = 'CREATE_MONEY_REQUEST_SUCCESS';
export const CREATE_MONEY_REQUEST_FAIL = 'CREATE_MONEY_REQUEST_FAIL';
export const GET_MONEY_REQUEST_SUCCESS = 'GET_MONEY_REQUEST_SUCCESS';
export const GET_MONEY_REQUEST_FAIL = 'GET_MONEY_REQUEST_FAIL';
export const TRANSFER_MONEY_BY_MONEY_REQUEST_SUCCESS = 'TRANSFER_MONEY_BY_MONEY_REQUEST_SUCCESS';
export const TRANSFER_MONEY_BY_MONEY_REQUEST_FAIL = 'TRANSFER_MONEY_BY_MONEY_REQUEST_FAIL';
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_FAIL = 'GET_REQUESTS_FAIL';
export const CLEAR_HISTORY_REQUESTS_SUCCESS = 'CLEAR_HISTORY_REQUESTS_SUCCESS';
export const GET_MONEY_REQUEST_LIMITS_SUCCESS = 'GET_MONEY_REQUEST_LIMITS_SUCCESS';
export const GET_MONEY_REQUEST_LIMITS_FAIL = 'GET_MONEY_REQUEST_LIMITS_FAIL';
export const REMOVE_BANK_CARD_DATA = 'REMOVE_BANK_CARD_DATA';
export const GET_LAST_DATE_OF_REQUESTS_SUCCESS = 'GET_LAST_DATE_OF_REQUESTS_SUCCESS';
export const GET_LAST_DATE_OF_REQUESTS_FAIL = 'GET_LAST_DATE_OF_REQUESTS_FAIL';

/** Invoice */
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';
export const TRANSFER_MONEY_BY_INVOICE_SUCCESS = 'TRANSFER_MONEY_BY_INVOICE_SUCCESS';
export const TRANSFER_MONEY_BY_INVOICE_FAIL = 'TRANSFER_MONEY_BY_INVOICE_FAIL';

/** Business card */
export const TOGGLE_BUSINESS_CARD = 'TOGGLE_BUSINESS_CARD';
export const GET_BUSINESS_CARD_INFO_SUCCESS = 'GET_BUSINESS_CARD_INFO_SUCCESS';
export const GET_BUSINESS_CARD_INFO_FAIL = 'GET_BUSINESS_CARD_INFO_FAIL';
export const TRANSFER_MONEY_BY_BUSINESS_CARD_SUCCESS = 'TRANSFER_MONEY_BY_BUSINESS_CARD_SUCCESS';
export const TRANSFER_MONEY_BY_BUSINESS_CARD_FAIL = 'TRANSFER_MONEY_BY_BUSINESS_CARD_FAIL';
export const GET_TRANSACTION_OPERATION_STATUS_SUCCESS = 'GET_TRANSACTION_OPERATION_STATUS_SUCCESS';
export const GET_TRANSACTION_OPERATION_STATUS_FAIL = 'GET_TRANSACTION_OPERATION_STATUS_FAIL';
export const MONEY_TRANSFER_INFO_SUCCESS = 'MONEY_TRANSFER_INFO_SUCCESS';
export const MONEY_TRANSFER_INFO_FAIL = 'MONEY_TRANSFER_INFO_FAIL';

/** Deals */
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAIL = 'GET_DEALS_FAIL';
export const GET_INCOME_CHECK_BY_ID_SUCCESS = 'GET_INCOME_CHECK_BY_ID_SUCCESS';
export const GET_INCOME_CHECK_BY_ID_FAIL = 'GET_INCOME_CHECK_BY_ID_FAIL';
export const DELETE_CHECK_SUCCESS = 'DELETE_CHECK_SUCCESS';
export const DELETE_CHECK_FAIL = 'DELETE_CHECK_FAIL';
export const SET_INCOME_CHECK_DATA = 'SET_INCOME_CHECK_DATA';

/** Taxes */
export const GET_TAX_INFO_SUCCESS = 'GET_TAX_INFO_SUCCESS';
export const GET_TAX_INFO_FAIL = 'GET_TAX_INFO_FAIL';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_FAIL = 'GET_TAXES_FAIL';
export const SET_TAX_PAYING_PROGRESS = 'SET_TAX_PAYING_PROGRESS';

export const SET_PROFILE_SETTINGS_ACTIVE_TAB = 'SET_PROFILE_SETTINGS_ACTIVE_TAB';
export const CREATE_APPLE_PAY_SESSION_SUCCESS = 'CREATE_APPLE_PAY_SESSION_SUCCESS';
export const CREATE_APPLE_PAY_SESSION_FAIL = 'CREATE_APPLE_PAY_SESSION_FAIL';

export const REMOVE_FNS_RIGHTS_SUCCESS = 'REMOVE_FNS_RIGHTS_SUCCESS';
export const REMOVE_FNS_RIGHTS_FAIL = 'REMOVE_FNS_RIGHTS_FAIL';

export const GET_INN_LIST_SUCCESS = 'GET_INN_LIST_SUCCESS';
export const GET_INN_LIST_FAIL = 'GET_INN_LIST_FAIL';

/** Partner List */
export const GET_PARTNER_LIST_FETCHING = 'GET_PARTNER_LIST_FETCHING';
export const GET_PARTNER_LIST_SUCCESS = 'GET_PARTNER_LIST_SUCCESS';
export const GET_PARTNER_LIST_FAIL = 'GET_PARTNER_LIST_FAIL';
export const CLEAR_PARTNER_LIST = 'CLEAR_PARTNER_LIST';
export const HIDE_PARTNER_REQUEST_BY_ID = 'HIDE_PARTNER_REQUEST_BY_ID';
export const ADD_PARTNER = 'ADD_PARTNER';

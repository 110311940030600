/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { LineText } from 'ui';

import { ReactComponent as IconArrowDown } from 'static/assets/icon-arrow-down.svg';

export const Dropdown = ({ title, values, changeValue, currentValue, placeholder }) => {
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);

  const isSelected = useMemo(() => !!currentValue, [currentValue]);
  const mainValue = useMemo(
    () => values[currentValue] || placeholder,
    [currentValue, placeholder, values],
  );

  const onDropDown = () => setIsDropdownMenuVisible((isCurrentVisible) => !isCurrentVisible);
  const onSelectItem = (key) => {
    changeValue(key);
    onDropDown();
  };

  return (
    <DropdownWrapper>
      <DropdownButtonStyled
        onClick={onDropDown}
        isSelected={isSelected}
        isDropdownMenuVisible={isDropdownMenuVisible}
      >
        <LineTextStyled>{title}:</LineTextStyled>
        <SelectedItemTextStyled>{mainValue}</SelectedItemTextStyled>
        <IconArrowDown />
      </DropdownButtonStyled>
      <MenuWraperStyled isDropdownMenuVisible={isDropdownMenuVisible}>
        {Object.keys(values).map((key) => (
          <HeaderItemWrapper key={key} onClick={() => onSelectItem(key)}>
            <HeaderItem isSelected={key === currentValue}>{values[key]}</HeaderItem>
          </HeaderItemWrapper>
        ))}
      </MenuWraperStyled>
    </DropdownWrapper>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/require-default-props
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  placeholder: PropTypes.string.isRequired,
  changeValue: PropTypes.func.isRequired,
};

const DropdownWrapper = styled.div`
  position: relative;
  width: 130px;
  cursor: pointer;
`;

const DropdownButtonStyled = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  white-space: nowrap;
  svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s;
    ${({ isDropdownMenuVisible }) =>
      isDropdownMenuVisible &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const MenuWraperStyled = styled.div`
  width: 100%;
  padding-right: 2px;
  position: absolute;
  z-index: 1;
  top: 26px;
  left: 0px;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(23, 28, 36, 0.12);
  background-color: var(--white);
  transition: opacity 0.3s;
  ${({ isDropdownMenuVisible }) =>
    isDropdownMenuVisible &&
    css`
      pointer-events: auto;
      opacity: 1;
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const LineTextStyled = styled(LineText)`
  color: var(--normal-grey-blue);
  font-size: 14px;
`;

const SelectedItemTextStyled = styled(LineTextStyled)`
  color: var(--dusk-blue);
  padding-left: 4px;
`;

const HeaderItemWrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--pale-grey);
  }
`;

const HeaderItem = styled(LineTextStyled)`
  padding-left: 16px;
  color: var(--bluey-grey);
  &,
  * {
    transition: color 0.3s;
  }
  ${HeaderItemWrapper}:hover & {
    color: var(--dusk-blue);
  }
`;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './PageTitle.scss';

const PageTitle = ({ title, className }) => {
  return (
    <h1
      className={cx('page-title', {
        [className]: className,
      })}
    >
      {title}
    </h1>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  className: null,
};

export default PageTitle;

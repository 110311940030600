export const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('При загрузке документа произошла ошибка');
    }
    let myBlob = await response.blob();
    myBlob = myBlob.slice(0, myBlob.size, 'application/pdf');
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(myBlob);
    link.download = filename;
    link.click();
    link.remove();
    setTimeout(() => {
      window.URL.revokeObjectURL(link.href);
    }, 200);
  } catch (e) {
    // eslint-disable-next-line
    throw 'При загрузке документа произошла ошибка';
  }
};

export const viewFile = async (url) => {
  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('При загрузке документа произошла ошибка');
    }
    let myBlob = await response.blob();
    myBlob = myBlob.slice(0, myBlob.size, 'application/pdf');
    const link = window.URL.createObjectURL(myBlob);
    window.open(link);
    setTimeout(() => {
      window.URL.revokeObjectURL(link);
    }, 200);
  } catch (e) {
    // eslint-disable-next-line
    throw 'При загрузке документа произошла ошибка';
  }
};

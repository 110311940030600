import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import {
  disableRussianLetters,
  disableSpaceEnter,
  hasNumber,
  hasRussianLetters,
  hasSpaceEnter,
  hasUpperCase,
} from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { setUserPassword } from 'modules/authLegasy/actions';
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import ShowHidePasswordButton from 'modules/shared/components/ShowHidePasswordButton/ShowHidePasswordButton';
import StandardHoverButton from 'modules/shared/components/StandardHoverButton/StandardHoverButton';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconLock } from 'static/assets/icon-lock.svg';

// Styles
import './PasswordBlock.scss';

const PasswordBlock = ({
  isMenuOpen,
  className,
  onNextButtonClick,
  dispatch,
  password,
  errorMessage,
  isLoading,
  isOnPasswordRecoveryPage,
  isOnSettingNewPasswordPage,
  isOldNewPasswordsNotEqual,
}) => {
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [isStartEnteringPassword, setIsStartEnteringPassword] = useState(false);
  const [valid, setValid] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordsEqual, setIsPasswordsEqual] = useState(false);
  const windowSize = useWindowSize();

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Check password */
  useEffect(() => {
    /** Check if user started to enter password */
    if (password && password.length >= 1) {
      setIsStartEnteringPassword(true);
    } else {
      setIsStartEnteringPassword(false);
    }

    /** Check password validity */
    if (
      password &&
      password.length >= 8 &&
      hasUpperCase(password) &&
      !hasRussianLetters(password) &&
      !hasSpaceEnter(password) &&
      !isOldNewPasswordsNotEqual
    ) {
      setValid(100);
    } else if (
      password &&
      password.length >= 5 &&
      (hasRussianLetters(password) || hasSpaceEnter(password) || isOldNewPasswordsNotEqual)
    ) {
      setValid(0);
    } else if (
      password &&
      password.length >= 5 &&
      (hasUpperCase(password) || hasNumber(password) || isOldNewPasswordsNotEqual)
    ) {
      setValid(50);
    } else {
      setValid(0);
    }

    /** Check if repeated password is equal
     * to first entered password */
    if (password && password === passwordRepeat) {
      setIsPasswordsEqual(true);
    } else {
      setIsPasswordsEqual(false);
    }
  }, [password, passwordRepeat, isOldNewPasswordsNotEqual]);

  /** Initial settings of progress bar */
  let circleProgressValue = 66;
  let circleProgressColor = '#ff2d55';

  /** Define props for CircleProgress
   * depend on validity of password */
  if (valid === 0) {
    circleProgressValue = 66;
    circleProgressColor = '#ff2d55';
  } else if (valid === 50) {
    circleProgressValue = 33;
    circleProgressColor = '#ffd254';
  } else {
    circleProgressValue = 0;
    circleProgressColor = '#00adb5';
  }

  /** Add userPassword in store */
  const handleFieldChange = ({ target }) => {
    const normPassword = disableSpaceEnter(disableRussianLetters(target.value));
    dispatch(setUserPassword(normPassword));
  };

  return (
    <div
      className={cx('password-block', {
        [className]: className,
      })}
    >
      <div className="password-block__list">
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <div className="password-block__item">
            <div className="password-block__input-holder">
              {/* ВВОД ПАРОЛЯ */}
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                maxLength={28}
                autoComplete="new-password"
                onChange={handleFieldChange}
                placeholder="Придумайте надежный пароль"
                className={cx('password-block__input', 'password-block__input__validation', {
                  'password-block__input_red': isStartEnteringPassword && valid === 0,
                  'password-block__input_yellow': valid === 50,
                  'password-block__input_green': valid === 100,
                  'password-block__input_password-visible': isPasswordVisible,
                })}
                value={password}
                name="password"
                data-cy="password-block-input"
              />
              {/* /ВВОД ПАРОЛЯ */}

              {/* КНОПКИ ПОКАЗА ПАРОЛЯ + ПРОГРЕСС - КОГДА ПОЛЬОВАТЕЛЬ НАЧАЛ ВВОДИТЬ ПАРОЛЬ */}
              {isStartEnteringPassword && (
                <div className="password-block__password-controls">
                  <ShowHidePasswordButton
                    changePasswordVisibility={() => setIsPasswordVisible((prevState) => !prevState)}
                    isPasswordVisible={isPasswordVisible}
                    className="password-block__password-visibility"
                  />

                  {className !== 'password-block_password-recovery' && windowSize.innerWidth > 500 && (
                    <div className="password-block__password-secure-wrap">
                      <CircleProgress
                        value={circleProgressValue}
                        color={circleProgressColor}
                        className="password-block__password-secure"
                      />
                      {valid !== 100 && (
                        <IconLock
                          className={cx('password-block__password-secure-lock', {
                            'password-block__password-secure-lock_red': valid === 0,
                            'password-block__password-secure-lock_yellow': valid === 50,
                          })}
                        />
                      )}
                      {valid === 100 && (
                        <IconLockClosed className="password-block__password-secure-lock" />
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* /КНОПКИ ПОКАЗА ПАРОЛЯ + ПРОГРЕСС - КОГДА ПОЛЬОВАТЕЛЬ НАЧАЛ ВВОДИТЬ ПАРОЛЬ */}
            </div>
          </div>
        </CSSTransition>

        {/* ИНПУТ ДЛЯ ПОВТОРНОГО ВВОДА ПАРОЛЯ */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && valid === 100 && !isOldNewPasswordsNotEqual}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <div className="password-block__item">
            <div className="password-block__input-holder">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Повторите пароль"
                autoComplete="new-password"
                onChange={(e) => {
                  setPasswordRepeat(disableSpaceEnter(disableRussianLetters(e.target.value)));
                }}
                className={cx('password-block__input', {
                  'password-block__input_green': isPasswordsEqual,
                  'password-block__input_password-visible': isPasswordVisible,
                })}
                value={passwordRepeat}
                data-cy="password-repeat-block-input"
              />
            </div>

            {/* КНОПКА ДАЛЕЕ КОГДА НАЧАЛИ ВВОДИТЬ ПАРОЛЬ */}
            {isStartEnteringPassword &&
              ((windowSize.innerWidth > 1100 && className === 'password-block_hint') ||
                (windowSize.innerWidth > 700 && className !== 'password-block_hint')) &&
              className !== 'password-block_password-recovery' && (
                <button
                  type="button"
                  className={cx('password-block__next-btn ', {
                    'password-block__next-btn_visible': isStartEnteringPassword,
                    'password-block__next-btn_active': isPasswordsEqual,
                  })}
                  onClick={onNextButtonClick}
                  disabled={!isPasswordsEqual}
                  data-cy="password-block-btn"
                >
                  <IconArrow className="password-block__next-btn-icon" />
                </button>
              )}
            {/* /КНОПКА ДАЛЕЕ КОГДА НАЧАЛИ ВВОДИТЬ ПАРОЛЬ */}
          </div>
        </CSSTransition>
      </div>
      {/* /ИНПУТ ДЛЯ ПОВТОРНОГО ВВОДА ПАРОЛЯ */}

      {/* ТЕКСТ ОШИБКИ */}
      {errorMessage && isOnPasswordRecoveryPage && (
        <div className="password-block__error-message">{errorMessage}</div>
      )}
      {/* /ТЕКСТ ОШИБКИ */}

      {/* ТЕКСТ ОШИБКИ */}
      {errorMessage && isOnSettingNewPasswordPage && (
        <div className="password-block__error-message">{errorMessage}</div>
      )}
      {/* /ТЕКСТ ОШИБКИ */}

      {/* ПОДСКАЗКА - КАКИМ ДОЛЖЕН БЫТЬ ПАРОЛЬ ПРИ ШИРИНЕ ЭКРАНА < 700 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={!isMenuOpen && isRendered && windowSize.innerWidth < 700}
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <p
          className={cx('password-block__tip', {
            'password-block__tip_error':
              (isStartEnteringPassword && valid === 0) || (isStartEnteringPassword && valid === 50),
          })}
        >
          {hasRussianLetters(password) || hasSpaceEnter(password)
            ? 'Используйте только буквы (a–z, A–Z), цифры и символы ! @ # $ % ^ & * ( ) - _ + = ; : , . / ? \\ | ` ~ { }'
            : 'Пароль должен быть не менее 8 символов и содержать хотя бы одну заглавную букву'}
        </p>
      </CSSTransition>
      {/* /ПОДСКАЗКА - КАКИМ ДОЛЖЕН БЫТЬ ПАРОЛЬ ПРИ ШИРИНЕ ЭКРАНА < 700 */}

      {/* КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА < 1100 ДЛЯ ПОПАПА */}
      {windowSize.innerWidth < 1100 && className === 'password-block_hint' && (
        <StandardHoverButton
          title="Продолжить"
          isLink={false}
          className="password-block__btn"
          onClick={onNextButtonClick}
          disabled={!isPasswordsEqual}
        />
      )}
      {/* /КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА < 1100 ДЛЯ ПОПАПА */}

      {/* КНОПКА ПРОДОЛЖИТЬ ПРИ ЭКРАНЕ < 700 НЕ ДЛЯ ПОПАПА И НЕ ДЛЯ ВОССТАНОВЛЕНИЯ ПАРОЛЯ */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={
          !isMenuOpen &&
          isRendered &&
          windowSize.innerWidth < 700 &&
          className !== 'password-block_hint' &&
          className !== 'password-block_password-recovery'
        }
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <StandardHoverButton
          title="Продолжить"
          isLink={false}
          className="password-block__btn"
          onClick={onNextButtonClick}
          disabled={!isPasswordsEqual}
        />
      </CSSTransition>
      {/* /КНОПКА ПРОДОЛЖИТЬ ПРИ ЭКРАНЕ < 700 НЕ ДЛЯ ПОПАПА И НЕ ДЛЯ ВОССТАНОВЛЕНИЯ ПАРОЛЯ */}

      {/* ПОДСКАЗКА - КАКИМ ДОЛЖЕН БЫТЬ ПАРОЛЬ ПРИ ШИРИНЕ ЭКРАНА > 700 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={!isMenuOpen && isRendered && windowSize.innerWidth > 700}
        timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
        unmountOnExit
      >
        <p
          className={cx('password-block__tip', {
            'password-block__tip_error':
              (isStartEnteringPassword && valid === 0) || (isStartEnteringPassword && valid === 50),
          })}
        >
          {hasRussianLetters(password) || hasSpaceEnter(password)
            ? 'Используйте только буквы (a–z, A–Z), цифры и символы ! @ # $ % ^ & * ( ) - _ + = ; : , . / ? \\ | ` ~ { }'
            : 'Пароль должен быть не менее 8 символов и содержать хотя бы одну заглавную букву'}
        </p>
      </CSSTransition>
      {/* /ПОДСКАЗКА - КАКИМ ДОЛЖЕН БЫТЬ ПАРОЛЬ ПРИ ШИРИНЕ ЭКРАНА > 700 */}

      {/* КНОПКА ВОЙТИ ДЛЯ ВОССТАНОВЛЕНИЯ ПАРОЛЯ */}
      {className === 'password-block_password-recovery' && (
        <div className="password-block__btn-wrap">
          <StandardHoverButton
            title="Продолжить"
            isLink={false}
            className="password-block__btn"
            onClick={onNextButtonClick}
            disabled={!isPasswordsEqual}
            isLoading={isLoading}
          />
        </div>
      )}
      {/* /КНОПКА ВОЙТИ ДЛЯ ВОССТАНОВЛЕНИЯ ПАРОЛЯ */}
    </div>
  );
};

PasswordBlock.propTypes = {
  className: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  isOnPasswordRecoveryPage: PropTypes.bool,
  isOnSettingNewPasswordPage: PropTypes.bool,
  isOldNewPasswordsNotEqual: PropTypes.bool,
};

PasswordBlock.defaultProps = {
  errorMessage: '',
  isLoading: null,
  isOnPasswordRecoveryPage: false,
  isOnSettingNewPasswordPage: false,
  isOldNewPasswordsNotEqual: false,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  password: state.auth.password,
});

export default connect(mapStateToProps)(PasswordBlock);

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { PTClassNames } from 'tools/types/propTypes';

export const CircleLoader = (props) => {
  const { mainColor, spinnerColor, width, height, borderWidth, className } = props;

  return (
    <CircleLoaderWraperStyled className={className} width={width} height={height}>
      <CircleLoaderMainStyled mainColor={mainColor} borderWidth={borderWidth} />
      <CircleLoaderSpinningStyled spinnerColor={spinnerColor} borderWidth={borderWidth} />
    </CircleLoaderWraperStyled>
  );
};

const CircleLoaderWraperStyled = styled.div.attrs(() => ({
  'data-testid': 'circle-loader',
}))`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
`;

const CircleLoaderMainStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-color: ${(props) => props.mainColor};
  border-width: ${(props) => `${props.borderWidth}px`};
`;

const CircleLoaderSpinningStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-style: solid;
  border-image: initial;
  animation: animation 1s linear infinite forwards running;
  border-color: ${(props) => `${props.spinnerColor} transparent transparent`};
  border-width: ${(props) => `${props.borderWidth}px`};
`;

CircleLoader.propTypes = {
  mainColor: PropTypes.string,
  spinnerColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  borderWidth: PropTypes.number,
  className: PTClassNames,
};

CircleLoader.defaultProps = {
  mainColor: 'rgba(40, 65, 149, 0.1)',
  spinnerColor: '#b0b5c4',
  width: 30,
  height: 30,
  borderWidth: 2,
  className: '',
};

export default CircleLoader;

import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';

export const useSignIdOfConfirmDocumentSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentData?.id,
      (id) => id,
    ),
    shallowEqual,
  );

export const useIsPendingSignOfApprovDocumentRequestSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentRequest,
      ({ pending, requestType }) => requestType === 'signOfApprov' && pending,
    ),
    shallowEqual,
  );

export const useIsFulfilledSignOfApprovDocumentRequestSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentRequest,
      ({ fulfilled, requestType }) => requestType === 'signOfApprov' && fulfilled,
    ),
    shallowEqual,
  );

export const useIsPendingConfirmDocumentRequestSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentRequest,
      ({ pending, requestType }) => requestType === 'approv' && pending,
    ),
    shallowEqual,
  );

export const useIsFulfilledConfirmDocumentRequestSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentRequest,
      ({ fulfilled, requestType }) => requestType === 'approv' && fulfilled,
    ),
    shallowEqual,
  );

export const useResendTimeSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument.confirmDocumentData?.resendTime,
      (resendTime) => resendTime,
    ),
    shallowEqual,
  );

export const useRequestErrorSelector = () =>
  useSelector(
    createSelector(
      (store) => store.confirmDocument?.confirmDocumentRequest.error,
      (error) => error,
    ),
    shallowEqual,
  );

export function isSBPDataValid(number, bankId) {
  return number.length >= 11 && bankId.length === 12;
}

export function isSBPWalletValid(number) {
  return number.length >= 11;
}

export function isSBPBankValid(bankId) {
  return bankId.length === 12;
}

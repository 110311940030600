import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AcquiringTimeLineStep from 'modules/acquiring/components/AcquiringTimeLineStep/AcquiringTimeLineStep';
import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';

import { CANCELED, ERROR, NOT_PAYED, PROCESS, REJECTED, SUCCESS } from 'constants/index';

import './AcquiringOperationsTimeLine.scss';

const AcquiringOperationsTimeLine = ({ status, amount, startDate, lastDate, processDate }) => {
  const [list, setList] = useState([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (status === SUCCESS) {
      setList([
        {
          id: 1,
          text: (
            <>
              <span>Платеж на</span> {'  '}
              <TaxesAmount
                amount={amount}
                isLoading={false}
                className="acquiring-time-line-step__title-amount"
              />
              {'  '}
              <span>создан</span>
            </>
          ),
          background: 'var(--turquoise-blue)',
          date: startDate,
        },
        {
          id: 2,
          text: 'Платеж обработан',
          background: 'var(--turquoise-blue)',
          date: lastDate || processDate || startDate,
        },
        {
          id: 3,
          text: 'Оплачено',
          background: 'var(--turquoise-blue)',
          date: lastDate || startDate,
        },
      ]);
    } else if (status === PROCESS) {
      setList([
        {
          id: 1,
          text: (
            <>
              <span>Платеж на</span> {'  '}
              <TaxesAmount
                amount={amount}
                isLoading={false}
                className="acquiring-time-line-step__title-amount"
              />
              {'  '}
              <span>создан</span>
            </>
          ),
          background: 'var(--turquoise-blue)',
          date: startDate,
        },
        {
          id: 2,
          text: 'Обрабатывается',
          background: '#E4E8EE',
          date: processDate || lastDate || startDate,
        },
        {
          id: 3,
          background: '#E4E8EE',
        },
      ]);
    } else if (status === ERROR) {
      setList([
        {
          id: 1,
          text: (
            <>
              <span>Платеж на</span> {'  '}
              <TaxesAmount
                amount={amount}
                isLoading={false}
                className="acquiring-time-line-step__title-amount"
              />
              {'  '}
              <span>создан</span>
            </>
          ),
          background: 'var(--turquoise-blue)',
          date: startDate,
        },
        {
          id: 2,
          text: 'Платеж обработан',
          background: 'var(--light-mustard)',
          date: lastDate || processDate || startDate,
        },
        {
          id: 3,
          text: 'Ошибка при оплате',
          background: 'var(--light-mustard)',
          date: lastDate || startDate,
        },
      ]);
    } else if (status === REJECTED) {
      setList([
        {
          id: 1,
          text: (
            <>
              <span>Платеж на</span> {'  '}
              <TaxesAmount
                amount={amount}
                isLoading={false}
                className="acquiring-time-line-step__title-amount"
              />
              {'  '}
              <span>создан</span>
            </>
          ),
          background: 'var(--turquoise-blue)',
          date: startDate,
        },
        {
          id: 2,
          text: 'Платеж обработан',
          background: 'var(--turquoise-blue)',
          date: lastDate || processDate || startDate,
        },
        {
          id: 3,
          text: 'Оплачено',
          background: 'var(--reddish-pink)',
          date: lastDate || processDate || startDate,
        },
        {
          id: 4,
          text: 'Возвращен',
          background: 'var(--reddish-pink)',
          date: lastDate || startDate,
        },
      ]);
    } else if (status === NOT_PAYED) {
      setList([]);
    } else if (status === CANCELED) {
      setList([
        {
          id: 1,
          text: (
            <>
              <span>Платеж на</span> {'  '}
              <TaxesAmount
                amount={amount}
                isLoading={false}
                className="acquiring-time-line-step__title-amount"
              />
              {'  '}
              <span>создан</span>
            </>
          ),
          background: 'var(--turquoise-blue)',
          date: startDate,
        },
        {
          id: 2,
          text: 'Платеж обработан',
          background: 'var(--light-mustard)',
          date: lastDate || processDate || startDate,
        },
        {
          id: 3,
          text: 'Отменен',
          background: 'var(--light-mustard)',
          date: lastDate || startDate,
        },
      ]);
    } else {
      setList([]);
    }
  }, [status, amount, startDate, lastDate, processDate]);

  return (
    <div className="acquiring-operations-time-line">
      {list &&
        list.length >= 1 &&
        list.map((item, index) => (
          <AcquiringTimeLineStep
            key={item.id}
            text={item.text}
            background={item.background}
            date={item.date}
            width={
              windowSize.innerWidth > 880
                ? list.length - 1 === index
                  ? 16
                  : 100 / (list.length - 1)
                : 100
            }
            isLastStep={list.length - 1 === index}
          />
        ))}
    </div>
  );
};

AcquiringOperationsTimeLine.propTypes = {
  status: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  lastDate: PropTypes.string,
  processDate: PropTypes.string,
};

AcquiringOperationsTimeLine.defaultProps = {
  processDate: null,
  lastDate: null,
};

export default AcquiringOperationsTimeLine;

import React from 'react';
import PropTypes from 'prop-types';
import MobileCalendarRangePicker from 'modules/shared/components/MobileCalendarRangePicker/MobileCalendarRangePicker';
import styles from './CalendarMobile.module.scss';

const CalendarMobile = ({ dates, setDates, onChange }) => {
  const [clickNumber, setClickNumber] = React.useState(0);

  React.useEffect(() => {
    if (clickNumber % 2 === 0 && clickNumber !== 0) {
      onChange();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [clickNumber]);

  return (
    <div className={styles.calendarMobile}>
      <MobileCalendarRangePicker
        range={dates}
        onChange={(item) => {
          setDates(item);
          setClickNumber((number) => number + 1);
        }}
      />
    </div>
  );
};

CalendarMobile.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string,
    }),
  ).isRequired,
  setDates: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CalendarMobile;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import PasswordBlock from 'modules/shared/components/PasswordBlock/PasswordBlock';

// Styles
import './ChangePassword.scss';

const ChangePassword = (props) => {
  const {
    className,
    login,
    title,
    onNextButtonClick,
    isLoading,
    errorMessage,
    isOnSettingNewPasswordPage,
    isOldNewPasswordsNotEqual,
  } = props;

  return (
    <div
      className={cx('change-password', {
        [className]: className,
      })}
    >
      <input type="hidden" value={login} autoComplete="phone" />

      {title && <h2 className="change-password__title">{title}</h2>}

      <div className="change-password__content">
        <PasswordBlock
          className="password-block_hint"
          onNextButtonClick={onNextButtonClick}
          isLoading={isLoading}
          errorMessage={errorMessage}
          isOnSettingNewPasswordPage={isOnSettingNewPasswordPage}
          isOldNewPasswordsNotEqual={isOldNewPasswordsNotEqual}
        />
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
  login: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onNextButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isOnSettingNewPasswordPage: PropTypes.bool,
  isOldNewPasswordsNotEqual: PropTypes.bool,
};

ChangePassword.defaultProps = {
  className: null,
  title: null,
  errorMessage: null,
  isOnSettingNewPasswordPage: false,
  isOldNewPasswordsNotEqual: false,
};

export default ChangePassword;

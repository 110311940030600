import React from 'react';

const HowDoesItWork = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      Работает просто: указываете сумму и назначение запроса денег, мы генерируем письмо или ссылку
      — зависит от вашего выбора. Письмо с ссылкой на оплату будет доставлено на указанный адрес
      e-mail. Если выберите отправку ссылкой, то достаточно скопировать ее и отправить удобным
      способом. Получатель увидит форму для оплаты запроса и сможет сразу перевести деньги: ровно ту
      сумму, которую вы указали.
    </div>
  </div>
);

export default HowDoesItWork;

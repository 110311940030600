import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ReactComponent as IconPayCard } from 'static/assets/icon-pay-card.svg';
import { ReactComponent as IconSBPLogo } from 'static/assets/icon-sbp-logo.svg';
import { ReactComponent as IconWallet } from 'static/assets/icon-payment-wallet.svg';
import { ReactComponent as IconQiwiLogo } from 'static/assets/icon-qiwi-logo.svg';
import { ReactComponent as IconWebMoney } from 'static/assets/icon-web-money-logo.svg';
import { ReactComponent as IconUMoney } from 'static/assets/icon-umoney.svg';

import { resetMoneyTransferSteps } from 'modules/money-transfer/actions';

import './Sidebar.scss';

import { checkSBPEnabled } from '../../../../tools/utils/sbp';

const Sidebar = ({ dispatch, moneyTransferServices }) => {
  const links = [
    {
      url: '/lk/money-transfer/bankcard',
      text: 'По номеру карты',
      name: 'card',
      Icon: IconPayCard,
    },
    {
      url: '/lk/money-transfer/sbp',
      text: 'По СБП',
      name: 'sbp',
      Icon: IconSBPLogo,
      hide: !checkSBPEnabled(),
    },
    {
      url: '/lk/money-transfer/sz',
      text: 'Кошелек самозанятых',
      name: 'wallet',
      Icon: IconWallet,
      hide: true,
    },
    {
      url: '/lk/money-transfer/umoney',
      text: 'ЮMoney',
      name: 'umoney',
      Icon: IconUMoney,
      hide: true,
    },
    {
      url: '/lk/money-transfer/webmoney',
      text: 'Webmoney',
      name: 'webmoney',
      Icon: IconWebMoney,
      hide: true,
    },
    {
      url: '/lk/money-transfer/qiwi',
      text: 'QIWI кошелек',
      name: 'qiwi',
      Icon: IconQiwiLogo,
      hide: true,
    },
  ];

  function resetPayment() {
    dispatch(resetMoneyTransferSteps());
  }

  return (
    <div className="money-transfer__sidebar">
      {links
        .filter((link) => link.hide !== true)
        .map((link, index) => (
          <NavLink
            key={index}
            to={link.url}
            className={(isActive) =>
              'money-transfer__sidebar-link' +
              (!isActive
                ? ' money-transfer__sidebar-link-inactive'
                : ' money-transfer__sidebar-link-active')
            }
            onClick={resetPayment}
          >
            <div className="money-transfer__sidebar-link-title">{link.text}</div>
            <link.Icon
              className={
                'money-transfer__sidebar-link-icon money-transfer__sidebar-link-icon_' + link.name
              }
            />
          </NavLink>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  moneyTransferServices: state.moneyTransfer.info.services,
});

Sidebar.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Sidebar);

import React from 'react';
import PropTypes from 'prop-types';

const TransferResultErrorMessage = ({ errorMessage }) => {
  return (
    <div className="transfer-result__text">
      {errorMessage ? (
        <>
          Упс. Что-то пошло не так, и мы не смогли совершить платеж. Возможная причина:{' '}
          {errorMessage}. Если проблема не будет решена,{' '}
          <button type="button" className="transfer-result__text-link js-help">
            напишите нам.
          </button>
        </>
      ) : (
        <>
          Упс. Что-то пошло не так, и мы не смогли совершить платеж. Если проблема не будет решена,{' '}
          <button type="button" className="transfer-result__text-link js-help">
            напишите нам.
          </button>
        </>
      )}
    </div>
  );
};

TransferResultErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

TransferResultErrorMessage.defaultProps = {
  errorMessage: null,
};

export default TransferResultErrorMessage;

import * as Api from 'api/identification-request';
import * as types from './action-types';

export const getIdentificationRequestDataFetching = () => ({
  type: types.GET_IDENTIFICATION_REQUEST_DATA_FETCHING,
});

export const getIdentificationRequestDataSuccess = (payload) => ({
  type: types.GET_IDENTIFICATION_REQUEST_DATA_SUCCESS,
  payload,
});

export const getIdentificationRequestDataFail = () => ({
  type: types.GET_IDENTIFICATION_REQUEST_DATA_FAIL,
});

/** Получить информацию из запроса на идентификацию. */
export const getIdentificationRequestData = () => (dispatch) => {
  dispatch(getIdentificationRequestDataFetching());

  Api.getIdentificationRequestData()
    .then((payload) => dispatch(getIdentificationRequestDataSuccess(payload)))
    .catch(() => dispatch(getIdentificationRequestDataFail()));
};

export const confirmIdentificationRequestFetching = () => ({
  type: types.CONFIRM_IDENTIFICATION_REQUEST_FETCHING,
});

export const confirmIdentificationRequestSuccess = (payload) => ({
  type: types.CONFIRM_IDENTIFICATION_REQUEST_SUCCESS,
  payload,
});

export const confirmIdentificationRequestFail = () => ({
  type: types.CONFIRM_IDENTIFICATION_REQUEST_FAIL,
});

/** Подтвердить правильность данных. */
export const confirmIdentificationRequest = () => (dispatch, getState) => {
  const state = getState();
  const { actionPinAcceptId } = state.identificationRequest.requestData;

  dispatch(confirmIdentificationRequestFetching());

  Api.confirmIdentificationRequest(actionPinAcceptId)
    .then((payload) => {
      dispatch(confirmIdentificationRequestSuccess(payload));
      dispatch(showIdentificationRequestCheckedSuccessPopup());
    })
    .catch(() => dispatch(confirmIdentificationRequestFail()));
};

export const declineIdentificationRequestFetching = () => ({
  type: types.DECLINE_IDENTIFICATION_REQUEST_FETCHING,
});

export const declineIdentificationRequestSuccess = (payload) => ({
  type: types.DECLINE_IDENTIFICATION_REQUEST_SUCCESS,
  payload,
});

export const declineIdentificationRequestFail = () => ({
  type: types.DECLINE_IDENTIFICATION_REQUEST_FAIL,
});

/** Отклонить идентификацию. */
export const declineIdentificationRequest = () => (dispatch) => {
  dispatch(declineIdentificationRequestFetching());

  Api.declineIdentificationRequest()
    .then((payload) => {
      dispatch(declineIdentificationRequestSuccess(payload));
      dispatch(hideIdentificationRequestPopup());
    })
    .catch(() => dispatch(declineIdentificationRequestFail()));
};

/** Показать попап с подтверждением данных */
export const showIdentificationRequestPopup = () => ({
  type: types.SHOW_IDENTIFICATION_REQUEST_POPUP,
});

/** Скрыть попап с подтверждением данныз */
export const hideIdentificationRequestPopup = () => ({
  type: types.HIDE_IDENTIFICATION_REQUEST_POPUP,
});

/** Показать попап с инструкцией в случае нахождения ошибки */
export const showIdentificationRequestFoundMistakePopup = () => ({
  type: types.SHOW_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP,
});

/** Скрыть попап с инструкцией в случае нахождения ошибки */
export const hideIdentificationRequestFoundMistakePopup = () => ({
  type: types.HIDE_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP,
});

/** Показать попап в случае подтверждения документов */
export const showIdentificationRequestCheckedSuccessPopup = () => ({
  type: types.SHOW_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP,
});

/** Скрыть попап в случае подтверждения документов */
export const hideIdentificationRequestCheckedSuccessPopup = () => ({
  type: types.HIDE_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP,
});

/** Показать попап в случае отказа от идентификации */
export const showIdentificationRequestDeclineIdentificationPopup = () => ({
  type: types.SHOW_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP,
});

/** Скрыть попап в случае отказа от идентификации */
export const hideIdentificationRequestDeclineIdentificationPopup = () => ({
  type: types.HIDE_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP,
});

/** Сбросить данные идентификации. Происходит после закрытия главного попапа */
export const resetIdentificationRequestState = () => ({
  type: types.RESET_IDENTIFICATION_REQUEST_STATE,
});

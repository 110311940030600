import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SadEmoji from './assents/sad-emoji.png';

import { WindowAlert } from '../../molecules';
import { ParagraphText } from '../../../../ParagraphText';

export const WindowUnavailableContent = ({ onClose }) => (
  <WindowAlert
    header="Внимание"
    emoji={
      <SadEmojiWraperStyled>
        <SadEmojiStyled />
      </SadEmojiWraperStyled>
    }
    buttonText="Вернуться"
    onClose={onClose}
    onClick={onClose}
  >
    <ParagraphTextStyled>
      Работа сервиса доступна в ограниченном режиме в связи с техническими проблемами
    </ParagraphTextStyled>
  </WindowAlert>
);

WindowUnavailableContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ParagraphTextStyled = styled(ParagraphText)`
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  max-width: 435px;
`;

const SadEmojiWraperStyled = styled.div`
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SadEmojiStyled = styled.img.attrs(() => ({ src: SadEmoji }))`
  width: 40px;
  height: 40px;
`;

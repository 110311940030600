import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Spring } from 'react-spring/renderprops';

import './CircleProgress.scss';

const CircleProgress = ({ className, value, color, radius }) => {
  const CIRCUMFERENCE = 2 * Math.PI * radius;
  const STROKE_WIDTH = 2;
  const CX = radius + STROKE_WIDTH;
  const CY = radius + STROKE_WIDTH;

  const progress = value / 100;
  const dashoffset = CIRCUMFERENCE * (1 - progress);

  return (
    <Spring from={{ value: CIRCUMFERENCE }} to={{ value: CIRCUMFERENCE - dashoffset }}>
      {(styles) => (
        <svg
          className={cx('circle-progress', {
            [className]: className,
          })}
          width={CX * 2}
          height={CY * 2}
          viewBox={`0 0 ${CX * 2} ${CY * 2}`}
        >
          <circle
            className="circle-progress__meter"
            cx={CX}
            cy={CY}
            r={radius}
            strokeWidth={STROKE_WIDTH}
            style={{
              stroke: color,
            }}
          />
          <circle
            className="circle-progress__value"
            cx={CX}
            cy={CY}
            r={radius}
            strokeWidth={STROKE_WIDTH}
            style={{
              strokeDashoffset: `${styles.value}`,
              strokeDasharray: `${CIRCUMFERENCE}`,
              stroke: color,
            }}
          />
        </svg>
      )}
    </Spring>
  );
};

CircleProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  radius: PropTypes.number,
};

CircleProgress.defaultProps = {
  radius: 20,
};

export default CircleProgress;

import React from 'react';

export default function useThrottle(ms) {
  const timer = React.useRef();

  React.useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  return function useThrottleFn(fn) {
    clearTimeout(timer.current);
    timer.current = setTimeout(fn, ms);
  };
}

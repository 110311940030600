/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { ParagraphText } from 'ui';

export const ParagraphTextOfBlogMixin = css`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  & > b {
    font-weight: 600;
  }
`;

export const ParagraphTextOfBlog = styled(ParagraphText)`
  ${ParagraphTextOfBlogMixin}
`;

import useWindowSize from '@rehooks/window-size';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { getAllMoneyboxes } from 'modules/cashbox/actions';
import DeleteItemPopup from 'modules/cashbox/components/DeleteItemPopup/DeleteItemPopup';
import HintCommissionAndLimits from 'modules/profileLegasy/components/Hint/HintCommissionAndLimits';
import HintHowMoneyboxWork from 'modules/profileLegasy/components/Hint/HintHowMoneyboxWork';
import HintWhatIsMoneyBox from 'modules/profileLegasy/components/Hint/HintWhatIsMoneybox';
import CopiedUrlHover from 'modules/shared/components/CopiedUrlHover/CopiedUrlHover';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import ProgressBar from 'modules/shared/components/ProgressBar/ProgressBar';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';

import { CONSISTENT_ANIMATIONS_DELAY, HINT_TIMEOUTS, KOPIM_URL } from 'constants/index';
import { dateProgress, getDaysLeft } from 'helpers/date';
import formatNumber from 'helpers/formatNumber';
import { declOfNum } from 'helpers/index';

import { ReactComponent as IconLine } from 'static/assets/arrow-line.svg';
import { ReactComponent as IconBasket } from 'static/assets/icon-basket.svg';
import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

// Styles
import './SaveMoneyList.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

const SaveMoneyList = ({ dispatch, user, moneyboxes }) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const [redirectOnLk, setRedirectOnLk] = useState(false);
  const [redirectOnSaveMoneyCreation, setRedirectOnSaveMoneyCreation] = useState(false);

  const [isDeleteSaveMoneyPopupOpen, setIsDeleteSaveMoneyPopupOpen] = useState(false);
  const [saveMoneyNameForPopup, setSaveMoneyNameForPopup] = useState(null);
  const [saveMoneybox, setSaveMoneybox] = useState(null);

  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [currentOpenPopupId, setCurrentOpenPopupId] = useState(null);

  useEffect(() => {
    setIsRendered(true);
    dispatch(getAllMoneyboxes());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Закрытие поапов- подсказок */
  const closeHintHandler = () => {
    closeExplicitly();
    setCurrentOpenPopupId(null);
  };

  /** Флаг, переключаем если кликнули на url */
  const [isCopied, setIsCopied] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState('');

  /** Клик на копирование ссылки витрины */
  const copyClick = (urlText) => {
    setCopiedUrl(urlText);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const hints = [
    {
      id: 0,
      title: 'Как это работает?',
      titleForPopup: 'Как это работает?',
      action: () => {
        setCurrentOpenPopupId(0);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 0 && isDropOpen,
      popup: <HintHowMoneyboxWork />,
      dropEl,
    },
    {
      id: 1,
      title: 'Что такое копилка?',
      titleForPopup: 'Что такое копилка?',
      action: () => {
        setCurrentOpenPopupId(1);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 1 && isDropOpen,
      popup: <HintWhatIsMoneyBox />,
      dropEl,
    },
    {
      id: 2,
      title: 'Комиссия и лимиты',
      titleForPopup: 'Комиссия и лимиты',
      action: () => {
        setCurrentOpenPopupId(2);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 2 && isDropOpen,
      popup: <HintCommissionAndLimits />,
      dropEl,
    },
  ];

  /** Редирект на ЛК */
  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  if (redirectOnSaveMoneyCreation) {
    return <Redirect to="/lk/save-money-creation" />;
  }

  return createPortal(
    <>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <div className="save-money-list">
        <div className="save-money-list__container">
          {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth > 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <div className="save-money-list__header-wrap">
              <PopupHeaderWithBalance className="save-money-list__header" />

              {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              <PopupCloseWithText closePopupHandler={() => setRedirectOnLk(true)} />
              {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            </div>
          </CSSTransition>
          {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

          {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <ResponsiveHeader
              closePopupHandler={() => setRedirectOnLk(true)}
              className="save-money-list__header"
            />
          </CSSTransition>
          {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

          {/* ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="save-money-list__title-wrap">
              <div className="save-money-list__title-container">
                <h1 className="save-money-list__title">Копим</h1>

                <div className="save-money-list__count-wrap">
                  <p className="save-money-list__count-text">Создано копилок: </p>

                  <p className="save-money-list__count">{moneyboxes.length} из 10</p>
                </div>
              </div>

              <ProgressBar progress={(moneyboxes.length / 10) * 100} />
            </div>
          </CSSTransition>
          {/* /ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}

          <div className="save-money-list__content">
            {/* КОПИЛКИ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <div className="save-money-list__main">
                {moneyboxes.length < 10 && (
                  <button
                    type="button"
                    className="save-money-list__create-button"
                    disabled={false}
                    onClick={() => setRedirectOnSaveMoneyCreation(true)}
                  >
                    + Создать новую копилку
                  </button>
                )}

                {moneyboxes.length === 10 && (
                  <div className="save-money-list__create-button">
                    Вы создали максимальное количество копилок. Удалите или отредактируйте
                    существующую копилку.
                  </div>
                )}

                {moneyboxes.length >= 1 && (
                  <div className="save-money-list__list-wrap">
                    {moneyboxes.map((goal) => (
                      <div className="save-money-list__item" key={goal.uid ? goal.uid : goal.name}>
                        {goal.dateAdd &&
                          goal.dateEndPayment &&
                          new Date(goal.dateEndPayment) >= new Date() && (
                            <ProgressBar
                              className="save-money-list__item-progress"
                              progress={dateProgress(goal.dateAdd, goal.dateEndPayment)}
                            />
                          )}
                        <div className="save-money-list__item-top">
                          <div className="save-money-list__item-title-wrap">
                            <p className="save-money-list__item-label">Название цели</p>

                            <div className="save-money-list__item-title">{goal.name}</div>
                          </div>

                          <div className="save-money-list__item-top-right-part">
                            <div className="save-money-list__item-sum-wrap">
                              <p className="save-money-list__item-label">Собрано</p>

                              <p className="save-money-list__item-sum">
                                {(goal.payedSum / 100).toString().indexOf('.') > -1
                                  ? formatNumber(goal.payedSum / 100).replace('.', ',')
                                  : `${formatNumber(goal.payedSum / 100)},00`}{' '}
                                ₽
                              </p>
                            </div>

                            {goal.dateEndPayment && (
                              <div className="save-money-list__item-deadline-wrap">
                                {new Date(goal.dateEndPayment) > new Date() ? (
                                  <>
                                    <p className="save-money-list__item-label">До конца сбора</p>

                                    <p className="save-money-list__item-sum">
                                      {formatNumber(getDaysLeft(goal.dateEndPayment))} {'   '}
                                      {declOfNum(Math.abs(getDaysLeft(goal.dateEndPayment)), [
                                        'день',
                                        'дня',
                                        'дней',
                                      ])}
                                    </p>
                                  </>
                                ) : (
                                  <p className="save-money-list__item-sum">
                                    Сбор средств закончился
                                  </p>
                                )}
                              </div>
                            )}

                            <div className="save-money-list__item-controls">
                              {/* КОПИРОВАТЬ ССЫЛКУ */}
                              {new Date(goal.dateEndPayment) < new Date() ? (
                                <button
                                  type="button"
                                  name="copyButton"
                                  disabled
                                  className="save-money-list__item-control"
                                >
                                  <IconCopy className="save-money-list__item-control-icon save-money-list__item-control-icon_copy" />
                                </button>
                              ) : (
                                <CopyToClipboard
                                  text={`${KOPIM_URL}${goal.uid}`}
                                  className="save-money-list__item-control"
                                  onCopy={() => copyClick(goal.uid)}
                                >
                                  <button type="button" name="copyButton">
                                    <IconCopy className="save-money-list__item-control-icon save-money-list__item-control-icon_copy" />
                                  </button>
                                </CopyToClipboard>
                              )}
                              {/* /КОПИРОВАТЬ ССЫЛКУ */}

                              {/* РЕДАКТИРОВАТЬ КОПИЛКУ */}
                              <Link
                                to="/lk/save-money-creation"
                                onClick={() => window.localStorage.setItem('saveMoneyId', goal.uid)}
                                name="editButton"
                                className="save-money-list__item-control"
                              >
                                <IconEdit className="save-money-list__item-control-icon save-money-list__item-control-icon_edit" />
                              </Link>
                              {/* /РЕДАКТИРОВАТЬ КОПИЛКУ */}

                              {/* УДАЛИТЬ КОПИЛКУ */}
                              <button
                                type="button"
                                name="deleteButton"
                                className="save-money-list__item-control"
                                onClick={() => {
                                  setIsDeleteSaveMoneyPopupOpen(true);
                                  setSaveMoneyNameForPopup(goal.name);
                                  setSaveMoneybox(goal);
                                }}
                              >
                                <IconBasket className="save-money-list__item-control-icon save-money-list__item-control-icon_basket" />
                              </button>
                              {/* /УДАЛИТЬ КОПИЛКУ */}
                            </div>
                          </div>
                        </div>

                        <CopiedUrlHover
                          isVisible={isCopied && copiedUrl === goal.uid}
                          className="save-money-list__copied"
                        />
                      </div>
                    ))}
                  </div>
                )}

                {moneyboxes && moneyboxes.length === 0 && (
                  <div className="save-money-list__no-goals">
                    <div className="save-money-list__no-goals-container">
                      <IconLine className="save-money-list__no-goals-arrow-icon" />

                      <NoOperationsMade
                        text="Вы пока не создали ни одной копилки. Вы можете создать до 10 копилок, используйте их с умом. Сервис Копим это лучший способ накопить на давнюю мечту!"
                        className="no-operations-made_save-money"
                      />
                    </div>
                  </div>
                )}
              </div>
            </CSSTransition>
            {/* /КОПИЛКИ */}

            {/* ПОДСКАЗКИ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
              unmountOnExit
            >
              <HintsList hints={hints} closeHintHandler={closeHintHandler} />
            </CSSTransition>
            {/* /ПОДСКАЗКИ */}
          </div>
        </div>

        {/* ПОПАП - УДАЛИТЬ ВИТРИНУ */}
        <CSSTransition in={isDeleteSaveMoneyPopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
          <DeleteItemPopup
            isOpen={isDeleteSaveMoneyPopupOpen}
            title={saveMoneyNameForPopup}
            closePopup={() => setIsDeleteSaveMoneyPopupOpen(false)}
            id={saveMoneybox && saveMoneybox.uid}
            type="MONEYBOX"
          />
        </CSSTransition>
        {/* /ПОПАП - УДАЛИТЬ ВИТРИНУ */}
      </div>
    </>,
    document.body,
  );
};

SaveMoneyList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  moneyboxes: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      payedSum: PropTypes.number,
      dateEndPayment: PropTypes.string,
      dateAdd: PropTypes.string,
    }).isRequired,
  ),
};

SaveMoneyList.defaultProps = {
  moneyboxes: [],
};

const mapStateToProps = (state) => ({
  moneyboxes: state.cashBox.moneyboxes,
  user: state.auth.user,
});

export default connect(mapStateToProps)(SaveMoneyList);

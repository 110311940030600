import * as types from '../actions/action-types';

const requestStages = {
  fetching: false,
  success: false,
  fail: false,
};

const initialState = {
  isWalletClosingBalancePopupVisible: false,
  isWalletClosingUnprocessingOperationPopupVisible: false,
  isWalletClosingConfirmationPopupVisible: false,
  isWalletClosingSuccessPopupVisible: false,
  isWalletClosingErrorPopupVisible: false,

  isWalletClosingWrongCode: false,
  isWalletClosingAttemptsLimit: false,
  isWalletClosingSmsLimit: false,
  isWalletClosingCodeExpiration: false,

  smsRequest: {
    id: 0,
    resendTime: 0,
    length: 0,
    attempts: 0,
  },

  getWalletClosingSmsCode: { ...requestStages },
  resendWalletClosingSmsCode: { ...requestStages },
  closeWallet: { ...requestStages },
};

const closeWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WALLET_CLOSING_SMS_CODE_FETCHING:
      return {
        ...state,
        getWalletClosingSmsCode: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_WALLET_CLOSING_SMS_CODE_SUCCESS:
      return {
        ...state,
        getWalletClosingSmsCode: {
          fetching: false,
          success: true,
          fail: false,
        },
        smsRequest: {
          id: action.payload.id,
          resendTime: action.payload.resendTime,
          length: action.payload.length,
          attempts: action.payload.attempts,
        },
      };

    case types.GET_WALLET_CLOSING_SMS_CODE_FAIL:
      return {
        ...state,
        getWalletClosingSmsCode: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.RESEND_WALLET_CLOSING_SMS_CODE_FETCHING:
      return {
        ...state,
        resendWalletClosingSmsCode: {
          fetching: true,
          success: false,
          fail: false,
        },
        isWalletClosingSmsLimit: false,
        isWalletClosingCodeExpiration: false,
      };

    case types.RESEND_WALLET_CLOSING_SMS_CODE_SUCCESS:
      return {
        ...state,
        resendWalletClosingSmsCode: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.RESEND_WALLET_CLOSING_SMS_CODE_FAIL:
      return {
        ...state,
        resendWalletClosingSmsCode: {
          fetching: false,
          success: false,
          fail: true,
        },
        isWalletClosingSmsLimit: action.payload === 'SMS_LIMIT',
        isWalletClosingCodeExpiration: action.payload === 'EXPIRED_CODE',
      };

    case types.CLOSE_WALLET_FETCHING:
      return {
        ...state,
        closeWallet: {
          fetching: true,
          success: false,
          fail: false,
        },
        isWalletClosingWrongCode: false,
        isWalletClosingAttemptsLimit: false,
        isWalletClosingCodeExpiration: false,
      };

    case types.CLOSE_WALLET_SUCCESS:
      return {
        ...state,
        closeWallet: {
          fetching: false,
          success: true,
          fail: false,
        },
        isWalletClosingWrongCode: false,
        isWalletClosingAttemptsLimit: false,
        isWalletClosingCodeExpiration: false,
      };

    case types.CLOSE_WALLET_FAIL:
      return {
        ...state,
        closeWallet: {
          fetching: false,
          success: false,
          fail: true,
        },
        isWalletClosingWrongCode: action.payload === 'WRONG_CODE',
        isWalletClosingAttemptsLimit: action.payload === 'ATTEMPTS_LIMIT',
        isWalletClosingCodeExpiration: action.payload === 'EXPIRED_CODE',
      };

    case types.SHOW_WALLET_CLOSING_BALANCE_POPUP:
      return {
        ...state,
        isWalletClosingBalancePopupVisible: true,
      };

    case types.HIDE_WALLET_CLOSING_BALANCE_POPUP:
      return {
        ...state,
        isWalletClosingBalancePopupVisible: false,
      };

    case types.SHOW_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP:
      return {
        ...state,
        isWalletClosingUnprocessingOperationPopupVisible: true,
      };

    case types.HIDE_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP:
      return {
        ...state,
        isWalletClosingUnprocessingOperationPopupVisible: false,
      };

    case types.SHOW_WALLET_CLOSING_CONFIRMATION_POPUP:
      return {
        ...state,
        isWalletClosingConfirmationPopupVisible: true,
      };

    case types.HIDE_WALLET_CLOSING_CONFIRMATION_POPUP:
      return {
        ...state,
        isWalletClosingConfirmationPopupVisible: false,
      };

    case types.SHOW_WALLET_CLOSING_SUCCESS_POPUP:
      return {
        ...state,
        isWalletClosingSuccessPopupVisible: true,
      };

    case types.HIDE_WALLET_CLOSING_SUCCESS_POPUP:
      return {
        ...state,
        isWalletClosingSuccessPopupVisible: false,
      };

    case types.SHOW_WALLET_CLOSING_ERROR_POPUP:
      return {
        ...state,
        isWalletClosingErrorPopupVisible: true,
      };

    case types.HIDE_WALLET_CLOSING_ERROR_POPUP:
      return {
        ...state,
        isWalletClosingErrorPopupVisible: false,
      };

    case types.RESET_WALLET_CLOSING_STATE:
      return { ...initialState };

    default:
      return state;
  }
};

export default closeWalletReducer;

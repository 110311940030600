import React from 'react';
import { Link } from 'react-router-dom';

const HintHowToKnowIfRequestPayed = () => {
  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Истории со страницы запроса денег, чтобы открыть там нужную вкладку
   * */
  const handleRequestsHistoryLink = () => {
    window.localStorage.setItem('historyTab', '1');
  };

  return (
    <div className="hint__text-wrap">
      <div className="hint__text">
        <p>
          Когда запрос денег будет оплачен, вам придет стандартное уведомление в личном кабинете
          (уведомление, пуш), а деньги будут зачислены на кошелек.
        </p>

        <p>
          Посмотреть всю историю запросов, а также их статусы можно в любой момент на
          <Link to="/lk/history" className="hint__text-link">
            странице «История»
          </Link>
          , во
          <Link to="/lk/history" className="hint__text-link" onClick={handleRequestsHistoryLink}>
            вкладке «Запросы».
          </Link>
          . Там же можно найти время создания запроса, время оплаты и другую информацию.
        </p>
      </div>
    </div>
  );
};

export default HintHowToKnowIfRequestPayed;

import cx from 'classnames';
import { changeLookOfPhone } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Misc
import formatNumber from 'helpers/formatNumber';

// Images
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './PopupHeaderWithBalance.scss';

const PopupHeaderWithBalance = ({ className, user }) => {
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;

  return (
    <div
      className={cx('popup-header-with-balance', {
        [className]: className,
      })}
    >
      <div className="popup-header-with-balance__user">
        <div className="popup-header-with-balance__user-balance-block">
          <p className="popup-header-with-balance__user-balance-text">Баланс счета</p>

          {user &&
            user.clientAccounts &&
            Array.isArray(user.clientAccounts) &&
            user.clientAccounts[0] && (
              <div className="popup-header-with-balance__user-balance">
                {user.clientAccounts[0].balance !== 0 && (
                  <>
                    <span className="popup-header-with-balance__user-balance-rubles">
                      {formatNumber(Math.floor(user.clientAccounts[0].balance / 100))}
                    </span>
                    ,
                    <span className="popup-header-with-balance__user-balance-penny">
                      {user.clientAccounts[0].balance.toString().slice(-2)} {'    '}
                    </span>
                  </>
                )}
                {user.clientAccounts[0].balance === 0 && (
                  <>
                    <span className="popup-header-with-balance__user-balance-rubles">
                      {user.clientAccounts[0].balance}
                    </span>
                    ,<span className="popup-header-with-balance__user-balance-penny">00 </span>
                  </>
                )}
                ₽
              </div>
            )}
        </div>

        <div className="popup-header-with-balance__user-avatar-wrap">
          <img
            src={user && user.avatar ? avatarUrl + user.avatar : stdAvatar}
            alt="Изображение пользователя"
            className="popup-header-with-balance__user-avatar"
          />
        </div>

        {user && user.login && (
          <div className="popup-header-with-balance__user-phone">
            {changeLookOfPhone(user.login)}
          </div>
        )}
      </div>
    </div>
  );
};

PopupHeaderWithBalance.propTypes = {
  user: PropTypes.shape({
    login: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
    avatar: PropTypes.string,
    unreadMessages: PropTypes.number,
  }),
  className: PropTypes.string,
};

PopupHeaderWithBalance.defaultProps = {
  user: null,
  className: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PopupHeaderWithBalance);

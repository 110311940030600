import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { deletePayIt } from 'modules/cashbox/actions';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';

import { CASHBOX, COMPANY_BILL, MONEYBOX } from 'constants/index';

import iconEmojiOk from 'static/assets/icon-emoji-ok.png';
import iconEmojiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import iconEmojiSad from 'static/assets/icon-emoji-sad.png';

const DeleteItemPopup = ({ dispatch, isOpen, id, type, title, closePopup, initialText }) => {
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeText, setTypeText] = useState([]);
  const [wordEnding, setWordEnding] = useState('');
  const [sitePageTitle, setSitePageTitle] = useState('');

  const initialState = !isSuccess && !isFail;

  const deleteAction = () => {
    setIsLoading(true);
    setIsFail(false);
    setIsSuccess(false);

    dispatch(deletePayIt(id, type === MONEYBOX ? 'MONEY_BOX' : type))
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsFail(true);
      });
  };

  /** Массив кнопок для изначального состояния попапа удаления витрины */
  const initialButtonsArray = [
    {
      id: 1,
      title: 'Отмена',
      action: closePopup,
      colorType: 'grey-border',
    },
    {
      id: 2,
      title: 'Удалить',
      action: deleteAction,
      colorType: 'red-border',
    },
  ];

  /** Массив кнопок для попапа удаления витрины после удаления */
  const resultButtonsArray = [
    {
      id: 1,
      title: `Вернуться в ${sitePageTitle}`,
      action: closePopup,
      colorType: 'blue',
    },
  ];

  useEffect(() => {
    if (type === CASHBOX) {
      setTypeText(['витрина', 'витрину']);
      setWordEnding('а');
      setSitePageTitle('кассу');
    } else if (type === MONEYBOX) {
      setTypeText(['копилка', 'копилку']);
      setWordEnding('a');
      setSitePageTitle('копилку');
    } else {
      setTypeText(['счет', 'счет']);
      setWordEnding('');
      setSitePageTitle('счета для компаний');
    }
  }, [type]);

  return (
    <div>
      <SmallPopupWithEmoji
        className="small-popup-with-emoji small-popup-with-emoji__autoheight"
        mounted={isOpen}
        emoji={initialState ? iconEmojiPuzzled : isSuccess ? iconEmojiOk : iconEmojiSad}
        closePopup={closePopup}
        title={
          initialState
            ? `Вы точно хотите удалить ${typeText[1]}?`
            : isSuccess
            ? `${typeText[0].slice(0, 1).toUpperCase() + typeText[0].slice(1)} удален${wordEnding}!`
            : 'Упс! Что-то пошло не так.'
        }
        buttonArray={initialState ? initialButtonsArray : resultButtonsArray}
        isLoading={isLoading}
      >
        {initialState && (
          <p>
            {initialText || (
              <>
                Вы собираетесь удалить {'  '}
                {typeText[1]} “<span>{title}</span>”, если вы продолжите, вся информация о ней будет
                удалена безвозвратно.
              </>
            )}
          </p>
        )}

        {isSuccess && (
          <p>
            {typeText[0].slice(0, 1).toUpperCase() + typeText[0].slice(1)} “<span>{title}</span>”
            был{wordEnding} {'  '}
            удален{wordEnding}.
          </p>
        )}

        {isFail && (
          <p>
            К сожалению, у нас не получилось удалить {typeText[1]}{' '}
            {type !== COMPANY_BILL && (
              <>
                “<span>{title}</span>”
              </>
            )}
            . Попробуйте повторить операцию позже.
          </p>
        )}
      </SmallPopupWithEmoji>
    </div>
  );
};

DeleteItemPopup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  initialText: PropTypes.string,
};

DeleteItemPopup.defaultProps = {
  initialText: null,
  title: null,
};

export default connect()(DeleteItemPopup);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import checkImg from 'static/assets/check-img.png';
import manualSzStatus from 'static/assets/manual-sz-status.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepStart = ({ setStepCheckCitizenship, setStepWallet }) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__content manual-page__col">
          <h1 className="manual-page__title">Вы уже получили статус самозанятого?</h1>

          <div className="manual-page__button-list">
            <StandardButton
              title="Нет. Как его получить?"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepCheckCitizenship}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Да. Что делать дальше?"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepWallet}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <div className="manual-page__col">
        <img src={checkImg} className="manual-page__check" alt="" />

        <img src={manualSzStatus} className="manual-page__status" alt="" />

        <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
        <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
        <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
        <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

        <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
        <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
      </div>
    </div>
  );
};

ManualPageStepStart.propTypes = {
  setStepCheckCitizenship: PropTypes.func.isRequired,
  setStepWallet: PropTypes.func.isRequired,
};

export default ManualPageStepStart;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import ru from 'date-fns/locale/ru';
import useOpenDropdown from 'hooks/useOpenDropdown';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import { getInnList } from 'modules/profileLegasy/actions';
import CompanyNameInputWithDropdown from 'modules/profileLegasy/components/CompanyNameInputWithDropdown/CompanyNameInputWithDropdown';
import IncomeRegistrationSummary from 'modules/profileLegasy/components/IncomeRegistrationSummary/IncomeRegistrationSummary';
import InnInputWithDropdown from 'modules/profileLegasy/components/InnInputWithDropdown/InnInputWithDropdown';
import DatePickerCustomInput from 'modules/shared/components/DatePickerCustomInput/DatePickerCustomInput';
import FormSelect from 'modules/shared/components/FormSelect/FormSelect';

import {
  INCOME_REG_FROM_FOREIGN_AGENCY,
  INCOME_REG_FROM_INDIVIDUAL,
  INCOME_REG_FROM_LEGAL_ENTITY,
  MONTHSRU,
  WEEK_DAYS_FIRST_SUNDAY_RU,
} from 'constants/index';
import checkValidInn from 'helpers/checkValidInn';

import headerBg from 'static/assets/card-header-bg.jpg';
import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';
import { formatDateRFC3339WithCurrentTime } from '../../../../helpers/formatDateRFC3339';
// Styles
import './IncomeRegistrationCard.scss';

const options = [
  { value: INCOME_REG_FROM_INDIVIDUAL, label: 'Физическое лицо' },
  { value: INCOME_REG_FROM_LEGAL_ENTITY, label: 'Юридическое лицо' },
  { value: INCOME_REG_FROM_FOREIGN_AGENCY, label: 'Иностранная компания' },
];

/** States for datePicker */
registerLocale('ru', {
  ...ru,
  localize: {
    ...ru.localize,
    month: (n) => MONTHSRU[n],
    day: (n) => WEEK_DAYS_FIRST_SUNDAY_RU[n],
  },
});

const IncomeRegistrationCard = ({
  dispatch,
  className,
  onSubmit,
  handleRepeatOperationClick,
  isLoading,
  isFailed,
}) => {
  const windowSize = useWindowSize();
  const [currentSelectedOption, setCurrentSelectedOption] = useState(1);

  const [isPreApproved, setIsPreApproved] = useState(false);

  const [sum, setSum] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [purpose, setPurpose] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyInn, setCompanyInn] = useState('');
  const [valid, setValid] = useState(false);
  const [validInnErrorMsg, setValidInnErrorMsg] = useState('');
  const [isInnChosen, setIsInnChosen] = useState(false);
  const [isCompanyNameChosen, setIsCompanyNameChosen] = useState(false);
  const [innDropdownOptions, setInnDropdownOptions] = useState([]);
  const [isInnChanging, setIsInnChanging] = useState(false);
  const [isCompanyNameChanging, setIsCompanyNameChanging] = useState(false);
  const [companyNameDropdownOptions, setCompanyNameDropdownOptions] = useState([]);
  const [isInnDropOpen, dropInnEl, toggleInnDrop, closeInnDrop] = useOpenDropdown();
  const [isCompanyNameDropOpen, dropCompanyNameEl, toggleCompanyNameDrop, closeCompanyNameDrop] =
    useOpenDropdown();

  /** Меняем инн */
  const changeInn = (typedInn) => {
    setIsInnChanging(true);
    setCompanyInn(typedInn);
    if (typedInn && (typedInn.length < 12 || typedInn.length < 10)) {
      setIsInnChosen(false);
    }
  };

  /** Меняем название компании */
  const changeCompanyName = (typedCompanyName) => {
    setIsCompanyNameChanging(true);
    setCompanyName(typedCompanyName);
  };

  useEffect(() => {
    if (
      companyNameDropdownOptions &&
      companyNameDropdownOptions.length >= 1 &&
      !isCompanyNameChosen &&
      companyName &&
      companyName.length >= 1
    ) {
      toggleCompanyNameDrop();
      setInnDropdownOptions([]);
      closeInnDrop();
    } else {
      closeCompanyNameDrop();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [companyNameDropdownOptions, isCompanyNameChosen, companyName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      innDropdownOptions &&
      innDropdownOptions.length >= 1 &&
      !isInnChosen &&
      companyInn &&
      companyInn.length >= 5
    ) {
      toggleInnDrop();
      setCompanyNameDropdownOptions([]);
      closeCompanyNameDrop();
    } else {
      closeInnDrop();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [innDropdownOptions, isInnChosen, companyInn]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isInnChanging && companyInn && companyInn.length >= 5 && !isInnChosen) {
      dispatch(getInnList(companyInn))
        .then((data) => {
          if (data && data.suggestions) {
            setInnDropdownOptions(data.suggestions);
            setCompanyNameDropdownOptions([]);
          }
        })
        .catch(() => {});
    } else {
      setInnDropdownOptions([]);
      setCompanyNameDropdownOptions([]);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [companyInn, isInnChanging, isInnChosen]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isCompanyNameChanging && companyName && companyName.length >= 5 && !isCompanyNameChosen) {
      dispatch(getInnList(companyName))
        .then((data) => {
          if (data && data.suggestions) {
            setCompanyNameDropdownOptions(data.suggestions);
            setInnDropdownOptions([]);
          }
        })
        .catch(() => {});
    } else {
      setCompanyNameDropdownOptions([]);
      setInnDropdownOptions([]);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [companyName, isCompanyNameChosen, isCompanyNameChanging]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onBackClick = () => {
    setIsPreApproved(false);
    setCurrentSelectedOption(currentSelectedOption);
  };

  const isFieldsCompleted = () => {
    if (
      currentSelectedOption === INCOME_REG_FROM_INDIVIDUAL &&
      purpose &&
      sum &&
      +sum >= 1 &&
      +sum <= 2400000 &&
      startDate
    ) {
      return true;
    }

    if (
      currentSelectedOption === INCOME_REG_FROM_LEGAL_ENTITY &&
      purpose &&
      companyName &&
      companyInn &&
      companyInn.length >= 10 &&
      valid &&
      sum &&
      +sum >= 1 &&
      +sum <= 2400000 &&
      startDate
    ) {
      return true;
    }

    if (
      currentSelectedOption === INCOME_REG_FROM_FOREIGN_AGENCY &&
      purpose &&
      companyName &&
      sum &&
      +sum >= 1 &&
      +sum <= 2400000 &&
      startDate
    ) {
      return true;
    }

    return false;
  };

  const prepareSubmitCall = () => {
    // const currentTimeString = new Date().toLocaleString('en-GB').substr(-8, 8);
    // const date = `${startDate.toDateString()} ${currentTimeString}`;
    onSubmit({
      currentSelectedOption,
      purpose,
      sum: +sum,
      dateProvideService: formatDateRFC3339WithCurrentTime(startDate),
      // startDate: new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000,
      // requestTime: new Date().getTime() + new Date().getTimezoneOffset() * 60000,
      companyName,
      companyInn,
    });
  };

  /** Клик по варианту в дропдауне с инн */
  const innDropdownItemClick = (inn, name) => {
    setCompanyInn(inn);
    setCompanyName(name);
    setInnDropdownOptions([]);
    setCompanyNameDropdownOptions([]);
    setIsInnChosen(true);
    closeInnDrop();
  };

  /** Клик по варианту в дропдауне с названиями компаний */
  const companyNameDropdownItemClick = (inn, name) => {
    setCompanyInn(inn);
    setCompanyName(name);
    setCompanyNameDropdownOptions([]);
    setInnDropdownOptions([]);
    setIsCompanyNameChosen(true);
    closeCompanyNameDrop();
  };

  useEffect(() => {
    setValid(false);

    if (companyInn && companyInn.length > 6 && companyInn.length < 10 && !isInnDropOpen) {
      setValidInnErrorMsg('ИНН может состоять только из 10 или 12 цифр');
    } else if (
      companyInn &&
      companyInn.length === 10 &&
      companyInn.length < 12 &&
      !checkValidInn(companyInn) &&
      !isInnDropOpen
    ) {
      setValidInnErrorMsg('Указанный ИНН некорректен');
    } else if (
      companyInn &&
      companyInn.length > 10 &&
      companyInn.length < 12 &&
      !checkValidInn(companyInn) &&
      !isInnDropOpen
    ) {
      setValidInnErrorMsg('ИНН может состоять только из 10 или 12 цифр');
    } else if (
      companyInn &&
      companyInn.length === 12 &&
      !checkValidInn(companyInn) &&
      !isInnDropOpen
    ) {
      setValidInnErrorMsg('Указанный ИНН некорректен');
    } else if (companyInn.length === 10 && checkValidInn(companyInn)) {
      setValid(true);
      setValidInnErrorMsg('');
    } else if (companyInn.length === 12 && checkValidInn(companyInn)) {
      setValid(true);
      setValidInnErrorMsg('');
    } else {
      setValid(false);
      setValidInnErrorMsg('');
    }
  }, [companyInn, isInnDropOpen]);

  return (
    <div
      className={cx('income-registration-card', {
        [className]: className,
      })}
    >
      {windowSize.innerWidth > 980 && (
        <div
          className="income-registration-card__header"
          style={{ backgroundImage: `url(${headerBg})` }}
        >
          <div className="income-registration-card__header-icon-wrap">
            <IconBag className="income-registration-card__header-icon" />
          </div>

          {isPreApproved && !isFailed && (
            <div className="income-registration-card__header-text">
              Перед созданием чека, не забудьте <br /> проверить указанные данные
            </div>
          )}
        </div>
      )}

      <div className="income-registration-card__content">
        {/* ФОРМА */}
        {!isPreApproved && (
          <>
            {/* ЛЕВАЯ ЧАСТЬ - ИНПУТЫ */}
            <div className="income-registration-card__left-part">
              {/* ИНПУТЫ ПРИ ВЫБОРЕ ФИЗИЧЕСКОГО ЛИЦА */}
              {currentSelectedOption === 1 && (
                <>
                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">НАЗВАНИЕ УСЛУГИ</p>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="income-registration-card__textarea"
                      placeholder="Наример: Фотосессия"
                      onChange={(event) => setPurpose(event.target.value)}
                      maxLength={256}
                      value={purpose}
                      readOnly={isLoading}
                    />
                    {Boolean(purpose) && (
                      <IconCheck className="income-registration-card__input-check" />
                    )}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">ДАТА ОКАЗАНИЯ УСЛУГИ</p>
                    <DatePicker
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      selected={startDate}
                      className="income-registration-card__input"
                      onChange={(date) => setStartDate(date)}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={10}
                      maxDate={new Date()}
                      minDate={new Date().setFullYear(new Date().getFullYear() - 5)}
                      customInput={<DatePickerCustomInput />}
                    />
                    {!startDate && (
                      <IconCalendar className="income-registration-card__calendar-icon" />
                    )}
                    {startDate && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">СУММА</p>
                    <NumberFormat
                      className="income-registration-card__input"
                      placeholder="Введите сумму"
                      value={sum}
                      readOnly={isLoading}
                      thousandSeparator=" "
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      decimalScale={2}
                      isNumericString
                      allowedDecimalSeparators={[',', '.']}
                      type="tel"
                      onValueChange={(values) => {
                        const { floatValue, value } = values;

                        if (floatValue < 1) {
                          setSum(1);
                        } else if (floatValue > 2400000) {
                          setSum(2400000);
                        } else if (value === '') {
                          setSum('');
                        } else {
                          setSum(value);
                        }
                      }}
                      isAllowed={(values) => {
                        const { value } = values;

                        if (value.charAt(0) === '0') {
                          return value.charAt(1) === '.';
                        }

                        return true;
                      }}
                    />
                    {Boolean(sum) && +sum >= 1 && +sum <= 2400000 && (
                      <IconCheck className="income-registration-card__input-check" />
                    )}
                  </div>
                </>
              )}
              {/* /ИНПУТЫ ПРИ ВЫБОРЕ ФИЗИЧЕСКОГО ЛИЦА */}

              {/* ИНПУТЫ ПРИ ВЫБОРЕ ЮРИДИЧЕСКОГО ЛИЦА */}
              {currentSelectedOption === 2 && (
                <>
                  <div
                    className={cx('income-registration-card__input-holder', {
                      'income-registration-card__input-holder_hide-long-text': purpose,
                    })}
                  >
                    <p className="income-registration-card__input-label">НАЗВАНИЕ УСЛУГИ</p>
                    <input
                      type="text"
                      className="income-registration-card__input"
                      placeholder="Наример: Фотосессия"
                      onChange={(event) => setPurpose(event.target.value)}
                      maxLength={256}
                      value={purpose}
                      readOnly={isLoading}
                    />
                    {purpose && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <CompanyNameInputWithDropdown
                    value={companyName}
                    placeholder='Наример: ООО "Абсолют"'
                    onChange={changeCompanyName}
                    label="НАЗВАНИЕ КОМПАНИИ"
                    dropdownItemClick={companyNameDropdownItemClick}
                    className="income-registration-card__input-holder"
                    dropdownOptions={companyNameDropdownOptions}
                    isNoAnimationStyles
                    isDropOpen={isCompanyNameDropOpen}
                    ref={dropCompanyNameEl}
                  />

                  <InnInputWithDropdown
                    inn={companyInn}
                    label="ИНН КОМПАНИИ"
                    onChange={changeInn}
                    className="income-registration-card__input-holder"
                    dropdownOptions={innDropdownOptions}
                    dropdownItemClick={innDropdownItemClick}
                    disabled={false}
                    isInnChosen={isInnChosen}
                    isNoAnimationStyles
                    placeholder="Введите ИНН"
                    isValid={valid}
                    validInnErrorMsg={validInnErrorMsg}
                    isDropOpen={isInnDropOpen}
                    ref={dropInnEl}
                  />

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">ДАТА ОКАЗАНИЯ УСЛУГИ</p>
                    <DatePicker
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      selected={startDate}
                      className="income-registration-card__input"
                      onChange={(date) => setStartDate(date)}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={10}
                      maxDate={new Date()}
                      minDate={new Date().setFullYear(new Date().getFullYear() - 5)}
                      customInput={<DatePickerCustomInput />}
                    />
                    {!startDate && (
                      <IconCalendar className="income-registration-card__calendar-icon" />
                    )}
                    {startDate && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">СУММА</p>
                    <NumberFormat
                      className="income-registration-card__input"
                      placeholder="Введите сумму"
                      value={sum}
                      readOnly={isLoading}
                      thousandSeparator=" "
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      decimalScale={2}
                      isNumericString
                      allowedDecimalSeparators={[',', '.']}
                      type="tel"
                      onValueChange={(values) => {
                        const { floatValue, value } = values;

                        if (floatValue < 1) {
                          setSum(1);
                        } else if (floatValue > 2400000) {
                          setSum(2400000);
                        } else if (value === '') {
                          setSum('');
                        } else {
                          setSum(value);
                        }
                      }}
                      isAllowed={(values) => {
                        const { value } = values;

                        if (value.charAt(0) === '0') {
                          return value.charAt(1) === '.';
                        }

                        return true;
                      }}
                    />
                    {Boolean(sum) && +sum >= 1 && +sum <= 2400000 && (
                      <IconCheck className="income-registration-card__input-check" />
                    )}
                  </div>
                </>
              )}
              {/* /ИНПУТЫ ПРИ ВЫБОРЕ ЮРИДИЧЕСКОГО ЛИЦА */}

              {/* ИНПУТЫ ПРИ ВЫБОРЕ ИНОСТРАННОЙ КОМПАНИИ */}
              {currentSelectedOption === 3 && (
                <>
                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">НАЗВАНИЕ УСЛУГИ</p>
                    <input
                      type="text"
                      className="income-registration-card__input"
                      placeholder="Наример: Фотосессия"
                      onChange={(event) => setPurpose(event.target.value)}
                      maxLength={256}
                      value={purpose}
                      readOnly={isLoading}
                    />
                    {purpose && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">НАЗВАНИЕ КОМПАНИИ</p>
                    <input
                      type="text"
                      className="income-registration-card__input"
                      placeholder='Наример: ООО "Абсолют"'
                      onChange={(event) => setCompanyName(event.target.value)}
                      value={companyName}
                      readOnly={isLoading}
                    />
                    {companyName && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">ДАТА ОКАЗАНИЯ УСЛУГИ</p>
                    <DatePicker
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      selected={startDate}
                      className="income-registration-card__input"
                      onChange={(date) => setStartDate(date)}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={10}
                      maxDate={new Date()}
                      minDate={new Date().setFullYear(new Date().getFullYear() - 5)}
                      customInput={<DatePickerCustomInput />}
                    />
                    {!startDate && (
                      <IconCalendar className="income-registration-card__calendar-icon" />
                    )}
                    {startDate && <IconCheck className="income-registration-card__input-check" />}
                  </div>

                  <div className="income-registration-card__input-holder">
                    <p className="income-registration-card__input-label">СУММА</p>
                    <NumberFormat
                      className="income-registration-card__input"
                      placeholder="Введите сумму"
                      value={sum}
                      readOnly={isLoading}
                      thousandSeparator=" "
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      decimalScale={2}
                      isNumericString
                      allowedDecimalSeparators={[',', '.']}
                      type="tel"
                      onValueChange={(values) => {
                        const { floatValue, value } = values;

                        if (floatValue < 1) {
                          setSum(1);
                        } else if (floatValue > 2400000) {
                          setSum(2400000);
                        } else if (value === '') {
                          setSum('');
                        } else {
                          setSum(value);
                        }
                      }}
                      isAllowed={(values) => {
                        const { value } = values;

                        if (value.charAt(0) === '0') {
                          return value.charAt(1) === '.';
                        }

                        return true;
                      }}
                    />
                    {Boolean(sum) && +sum >= 1 && +sum <= 2400000 && (
                      <IconCheck className="income-registration-card__input-check" />
                    )}
                  </div>
                </>
              )}
              {/* /ИНПУТЫ ПРИ ВЫБОРЕ ИНОСТРАННОЙ КОМПАНИИ */}

              {/* КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
              {windowSize.innerWidth < 980 && (
                <div className="income-registration-card__button-wrap">
                  <button
                    className="income-registration-card__button"
                    type="button"
                    disabled={!isFieldsCompleted() || isLoading}
                    onClick={() => {
                      setIsPreApproved(true);
                    }}
                  >
                    Продолжить
                  </button>
                </div>
              )}
              {/* /КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
            </div>
            {/* /ЛЕВАЯ ЧАСТЬ - ИНПУТЫ */}

            {/* ПРАВАЯ ЧАСТЬ ФОРМЫ - СЕЛЕКТ + ОПИСАНИЕ + КНОПКА */}
            <div className="income-registration-card__right-part">
              <div className="income-registration-card__right-part-top">
                <FormSelect
                  className="income-registration-card__select income-registration-select"
                  options={options}
                  showDefault={true}
                  handleSelectChange={(event) => setCurrentSelectedOption(event.value)}
                  currentSelectedOption={currentSelectedOption}
                />

                {currentSelectedOption === INCOME_REG_FROM_INDIVIDUAL && (
                  <p className="income-registration-card__tip">
                    Если вы продали товар или оказали услугу человеку, а не компании
                  </p>
                )}

                {currentSelectedOption === INCOME_REG_FROM_LEGAL_ENTITY && (
                  <p className="income-registration-card__tip">
                    Если вы оказали услугу или продали товар компании резиденту РФ
                  </p>
                )}

                {currentSelectedOption === INCOME_REG_FROM_FOREIGN_AGENCY && (
                  <p className="income-registration-card__tip">
                    Если вы оказали услугу или продали товар компании нерезиденту РФ
                  </p>
                )}
              </div>

              {/* КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              {windowSize.innerWidth > 980 && (
                <div className="income-registration-card__button-wrap">
                  <button
                    className="income-registration-card__button"
                    type="button"
                    disabled={!isFieldsCompleted() || isLoading}
                    onClick={() => {
                      setIsPreApproved(true);
                    }}
                  >
                    Продолжить
                  </button>
                </div>
              )}
              {/* /КНОПКА ПРОДОЛЖИТЬ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            </div>
            {/* ПРАВАЯ ЧАСТЬ ФОРМЫ - СЕЛЕКТ + ОПИСАНИЕ + КНОПКА */}
          </>
        )}
        {/* /ФОРМА */}

        {/* ТЕКСТ ПРОВЕРИТЬ ИНФУ < 980 */}
        {windowSize.innerWidth <= 980 && isPreApproved && !isFailed && (
          <div className="income-registration-card__check-text">
            Перед созданием чека, не забудьте <br /> проверить указанные данные
          </div>
        )}
        {/* /ТЕКСТ ПРОВЕРИТЬ ИНФУ < 980 */}

        {/* СВОДКА ПОСЛЕ НАЖАТИЯ НА ПРОДОЛЖИТЬ */}
        {isPreApproved && !isFailed && (
          <IncomeRegistrationSummary
            purpose={purpose}
            sum={sum}
            date={startDate}
            onSubmit={prepareSubmitCall}
            onBackClick={onBackClick}
            isLoading={isLoading}
            buyer={currentSelectedOption !== INCOME_REG_FROM_INDIVIDUAL ? companyName : null}
          />
        )}
        {/* /СВОДКА ПОСЛЕ НАЖАТИЯ НА ПРОДОЛЖИТЬ */}

        {/* ОШИБКА */}
        {isPreApproved && isFailed && (
          <div className="income-registration-card__fail">
            <div className="income-registration-card__fail-text">
              Упс. Что-то пошло не так и мы не смогли зарегистрировать доход. Если проблема не будет
              решена,{' '}
              <button type="button" className="js-help">
                напишите нам
              </button>
            </div>

            <div className="income-registration-card__fail-icon-wrap">
              <>
                <CircleProgress
                  className="income-registration-card__fail-loader"
                  color="var(--reddish-pink)"
                  value={0}
                  radius={28}
                />
                <IconLockClosed className="income-registration-card__fail-icon" />
              </>
            </div>

            <button
              className="income-registration-card__button income-registration-card__button_again"
              type="button"
              disabled={isLoading}
              onClick={() => {
                setIsPreApproved(false);
                handleRepeatOperationClick();
              }}
            >
              Попробовать снова
            </button>
          </div>
        )}
        {/* /ОШИБКА */}
      </div>
    </div>
  );
};

IncomeRegistrationCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleRepeatOperationClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
};

IncomeRegistrationCard.defaultProps = {
  className: null,
};

export default connect()(IncomeRegistrationCard);

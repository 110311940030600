import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonUI, LineText } from 'ui';
import { PTClassNames } from 'tools/types/propTypes';

import { ReactComponent as BackArrowIcon } from 'static/assets/AR.svg';

export const ButtomOfBack = ({ className }) => {
  const history = useHistory();
  const onClick = useCallback(() => history.goBack(), [history]);

  return (
    <ButtomPlateStyled Icon={BackArrowIcon} onClick={onClick} className={className}>
      <LineTextStyled>Вернуться</LineTextStyled>
      <BackArrowIcon />
    </ButtomPlateStyled>
  );
};

ButtomOfBack.propTypes = {
  className: PTClassNames,
};

ButtomOfBack.defaultProps = {
  className: '',
};

const ButtomPlateStyled = styled(ButtonUI.Plate).attrs(() => ({
  borderRadius: '56px',
  width: '115px',
}))`
  padding: 5px 16px;
`;

const LineTextStyled = styled(LineText)`
  margin-right: 8px;
`;

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';
import { confirmRecoveryPassword } from 'modules/authLegasy/actions';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import PasswordBlock from 'modules/shared/components/PasswordBlock/PasswordBlock';

export const PasswordRecoveryEnterPassword = ({
  className,
  password,
  dispatch,
  smsVerification,
  userPhone,
  isMenuOpen,
  onNextStepButtonClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    setErrorMessage(null);

    dispatch(confirmRecoveryPassword(smsVerification.id, password, userPhone))
      .then(() => {
        setIsLoading(false);
        onNextStepButtonClick();
      })
      .catch((result) => {
        setErrorMessage((result && result.result && result.result.message) || 'Ошибка');
        setIsLoading(false);
      });
  };

  return (
    <div
      className={cx('password-recovery', {
        [className]: className,
      })}
    >
      <div className="password-recovery__container container">
        <div className="password-recovery__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="password-recovery__title">Установите новый пароль</h1>
          </CSSTransition>

          <div className="password-recovery__form form">
            <PasswordBlock
              className="password-block_password-recovery"
              onNextButtonClick={handleClick}
              errorMessage={errorMessage}
              isOnPasswordRecoveryPage
            />
          </div>
        </div>
      </div>

      {isLoading && <Loader inverted className="loader_main" />}
    </div>
  );
};

PasswordRecoveryEnterPassword.propTypes = {
  className: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
  smsVerification: PropTypes.shape({
    id: PropTypes.number,
    resendTime: PropTypes.number,
    attempts: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onNextStepButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userPhone: state.auth.phone,
  password: state.auth.password,
  smsVerification: state.auth.smsVerification,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(PasswordRecoveryEnterPassword);

import { LOG_OUT_SUCCESS } from 'modules/authLegasy/actions/action-types';
import * as types from 'modules/profileLegasy/actions/action-types';

import formatDateYYYYmmDD from 'helpers/formatDateYYYYmmDD';

const initialState = {
  unconfirmedEmail: '',
  passport: null,
  passportErrorMessage: '',
  smsVerification: {},
  avatar: null,
  authStep: 1,
  notifications: [],
  isRenderedBetweenTabs: false,
  operations: {},
  requests: {},
  deals: [],
  unreadMessages: {},
  allMessages: {},
  moneyTransferProgress: 0,
  taxPayingProgress: 0,
  bankCardData: {},
  isBusinessCardOpen: false,
  moneyTransferInfo: null,
  taxesInfo: {},
  taxes: [],
  incomeCheckData: null,
  isAuthStatusGettingInProcess: false,
  lastOperationsDate: null,
  lastHistoryRequestsDate: null,
  profileSettingsActiveTab: 'SETTINGS_PROFILE_TAB',
  isAvatarError: false,
  appleSessionStatus: null,
  partnerRequestList: [],
  partnerList: [],
  documentList: {
    items: [],
    hasMore: false,
  },
  getPartnerList: {
    fetching: false,
    success: false,
    fail: false,
  },
  getDocumentList: {
    fetching: false,
    success: false,
    fail: false,
  },
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARSE_PASSPORT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
      };

    case types.SENT_PASSPORT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.PARTIAL_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        isAuthStatusGettingInProcess: action.payload.data.data,
      };

    case types.GET_UNCONFIRMED_EMAIL_SUCCESS:
      return {
        ...state,
        unconfirmedEmail: action.payload.data.data,
      };

    case types.SENT_PASSPORT_AND_SELFIE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.SAVE_PASSPORT_PHOTO:
      return {
        ...state,
        passport: action.payload.passport,
      };

    case types.SET_PASSPORT_ERROR_MESSAGE:
      return {
        ...state,
        passportErrorMessage: action.payload.passportErrorMessage,
      };

    case types.REQUEST_CODE_CLOSE_WALLET_SUCCESS:
      return {
        ...state,
        smsVerification: action.payload.data.data,
      };

    case types.CLOSE_WALLET_SUCCESS:
      return {
        ...state,
      };

    case types.SET_AVATAR_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.SET_AVATAR_ERROR:
      return {
        ...state,
        isAvatarError: action.payload.isAvatarError,
      };

    case types.CHECK_AVATAR_SUCCESS:
      return {
        ...state,
        avatar: Boolean(action.payload.data),
      };

    case types.REQUEST_CODE_TO_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        smsVerification: action.payload.data.data,
      };

    case types.REQUEST_CODE_CHANGE_PHONE_SUCCESS:
      return {
        ...state,
        smsVerification: action.payload.data.data,
      };

    case types.CHANGE_PHONE_CONFIRM_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.SET_AUTH_STEP:
      return {
        ...state,
        authStep: action.payload.authStep,
      };
    case types.SET_RENDER_BETWEEN_TABS:
      return {
        ...state,
        isRenderedBetweenTabs: true,
      };

    case types.GET_LAST_DATE_OF_OPERATIONS_SUCCESS:
      return {
        ...state,
        lastOperationsDate: action.payload.res.data
          ? action.payload.res.data
          : formatDateYYYYmmDD(new Date()),
      };

    case types.GET_LAST_DATE_OF_OPERATIONS_FAIL:
      return {
        ...state,
        lastOperationsDate: formatDateYYYYmmDD(new Date()),
      };

    case types.GET_LAST_DATE_OF_REQUESTS_SUCCESS:
      return {
        ...state,
        lastHistoryRequestsDate: action.payload.res.data
          ? action.payload.res.data
          : formatDateYYYYmmDD(new Date()),
      };

    case types.GET_LAST_DATE_OF_REQUESTS_FAIL:
      return {
        ...state,
        lastHistoryRequestsDate: formatDateYYYYmmDD(new Date()),
      };

    case types.SET_TAX_PAYING_PROGRESS:
      return {
        ...state,
        taxPayingProgress: action.payload.taxPayingProgress,
      };

    case types.GET_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations:
          action.payload.page === 1
            ? action.payload.res.data
            : {
                ...state.operations,
                payments: action.payload.res.data.payments
                  ? [...state.operations.payments, ...action.payload.res.data.payments]
                  : state.operations.payments,
              },
      };

    case types.CLEAR_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: {
          ...state.operations,
          total: 0,
          numberOfPages: 0,
          pageSize: 0,
          payments: [],
        },
      };

    case types.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests:
          action.payload.page === 1
            ? action.payload.res.data
            : {
                ...state.requests,
                payIts: action.payload.res.data.payIts
                  ? [...state.requests.payIts, ...action.payload.res.data.payIts]
                  : state.requests.payIts,
              },
      };

    case types.CLEAR_HISTORY_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          total: 0,
          numberOfPages: 0,
          pageSize: 0,
          payIts: [],
        },
      };

    case types.GET_DEALS_SUCCESS:
      return {
        ...state,
        deals:
          action.payload.offset === 0
            ? action.payload.items
            : [...state.deals, ...action.payload.items],
      };

    case types.GET_UNREAD_MESSAGES_SUCCESS:
      return {
        ...state,
        unreadMessages: action.payload.res.data,
      };

    case types.GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        allMessages: action.payload.res.data,
      };

    case types.MARK_ALL_MESSAGES_AS_READ:
      return {
        ...state,
        unreadMessages: {
          ...state.unreadMessages,
          messages: (state.unreadMessages.messages || []).map((item) => ({
            ...item,
            read: true,
          })),
        },
        allMessages: {
          ...state.allMessages,
          messages: (state.allMessages.messages || []).map((item) => ({
            ...item,
            read: true,
          })),
        },
      };

    case types.CLEAR_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: {},
      };

    case types.CHECK_BANK_CARD_SUCCESS:
      return {
        ...state,
        bankCardData: action.payload.data,
      };

    case types.TOGGLE_BUSINESS_CARD:
      return {
        ...state,
        isBusinessCardOpen: !state.isBusinessCardOpen,
        bankCardData: {},
      };

    case types.REMOVE_BANK_CARD_DATA:
      return {
        ...state,
        bankCardData: {},
      };

    case types.MONEY_TRANSFER_INFO_SUCCESS:
      return {
        ...state,
        moneyTransferInfo: action.payload.res.data,
      };

    case types.GET_TAX_INFO_SUCCESS:
      return {
        ...state,
        taxesInfo: action.payload.res.data,
      };

    case types.GET_TAXES_SUCCESS:
      return {
        ...state,
        taxes: action.payload.res.data,
      };

    case types.DELETE_CHECK_SUCCESS:
      return {
        ...state,
        deals: state.deals.map((item) => {
          if (item.id === action.payload.dealId) {
            return {
              ...item,
              canceled: true,
            };
          }
          return item;
        }),
      };

    case types.SET_INCOME_CHECK_DATA:
      return {
        ...state,
        incomeCheckData: action.payload.incomeCheckData,
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        unconfirmedEmail: '',
        authStep: 1,
        email: '',
        deals: [],
        operations: {},
        requests: {},
        taxes: [],
        unreadMessages: {},
        allMessages: {},
        notifications: [],
        taxesInfo: {},
        lastOperationsDate: null,
        lastHistoryRequestsDate: null,
      };

    case types.SET_PROFILE_SETTINGS_ACTIVE_TAB:
      return {
        ...state,
        profileSettingsActiveTab: action.payload.profileSettingsActiveTab,
      };

    case types.TRANSFER_MONEY_BY_BUSINESS_CARD_SUCCESS:
      return {
        ...state,
        appleSessionStatus: 'SUCCESS',
      };

    case types.TRANSFER_MONEY_BY_BUSINESS_CARD_FAIL:
      return {
        ...state,
        appleSessionStatus: 'ERROR',
      };

    case types.GET_PARTNER_LIST_FETCHING:
      return {
        ...state,
        getPartnerList: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        getPartnerList: {
          fetching: false,
          success: true,
          fail: false,
        },
        partnerRequestList: [...action.payload.requests],
        partnerList: [...action.payload.partnerships],
      };

    case types.GET_PARTNER_LIST_FAIL:
      return {
        ...state,
        getPartnerList: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CLEAR_PARTNER_LIST:
      return {
        ...state,
        getPartnerList: {
          fetching: false,
          success: false,
          fail: false,
        },
        partnerRequestList: [],
        partnerList: [],
      };

    case types.HIDE_PARTNER_REQUEST_BY_ID:
      return {
        ...state,
        partnerRequestList: state.partnerRequestList.filter(
          (request) => request.id !== action.payload,
        ),
      };

    case types.ADD_PARTNER:
      return {
        ...state,
        partnerList: [...state.partnerList, action.payload],
      };

    default:
      return state;
  }
};

export default profileReducer;

/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ListOfBlog = styled.ul``;

export const ItemListMarkerOfBlog = styled.li`
  margin-bottom: 12px;
  position: relative;
  margin-left: 19px;
  &:before {
    content: '·';
    font-size: 32px;
    color: var(--dark-slate-blue);
    display: inline-block;
    position: absolute;
    height: 5px;
    line-height: 5px;
    top: 8px;
    left: -19px;
  }
`;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

import { setUrlLinkError, setUrlLinkLoading, validateLink } from 'modules/cashbox/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { KASSA_URL, KOPIM_URL } from 'constants/index';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';

import './CreateLinkInput.scss';

const KOPIM = 'moneybox';

export const CreateLinkInput = ({ type, isEditing, setUrl, url, dispatch, isUrlLinkLoading }) => {
  const finalKopimUrl = KOPIM_URL.replace('http://', '').replace('https://', '');
  const finalKassaUrl = KASSA_URL.replace('http://', '').replace('https://', '');
  const baseUrlNameRef = useRef(null);
  const userUrlRef = useRef(null);
  const windowSize = useWindowSize();

  const [isLinkNameTooLong, setIsLinkNameTooLong] = useState(true);
  const [errorText, setErrorText] = useState('');

  /**
   * Проверяем ширину текста в основной части url
   * для создания ссылки витрины
   * */
  useEffect(() => {
    setIsLinkNameTooLong(false);

    /** Если основная часть url длиннее 169px, ставим флаг в true */
    if (
      baseUrlNameRef &&
      baseUrlNameRef.current &&
      baseUrlNameRef.current.clientWidth &&
      baseUrlNameRef.current.clientWidth >= 169
    ) {
      setIsLinkNameTooLong(true);
    }

    /** Если вписанное юзером название больше 140px, ставим флаг в true */
    if (
      url &&
      userUrlRef &&
      userUrlRef.current &&
      userUrlRef.current.clientWidth &&
      userUrlRef.current.clientWidth > 140
    ) {
      setIsLinkNameTooLong(true);
    }

    if (windowSize.innerWidth <= 600) {
      setIsLinkNameTooLong(true);
    }
  }, [url, baseUrlNameRef, windowSize.innerWidth]);

  /**
   * Проверяем занято ли название для ссылки
   * на витрину, которую создает юзер при каждом изменении инпута,
   * потому что проджект менеджер так сказал
   * */
  const onChangeUrl = value => {
    setUrl(value);
    setErrorText('');
    dispatch(setUrlLinkError(false));
    dispatch(setUrlLinkLoading(true));

    if (value !== '') {
      validateLink(value, type)
        .then(() => {
          dispatch(setUrlLinkLoading(false));
        })
        .catch(err => {
          if (err && err.result) {
            if (err.result.message === 'pay-it.link.has.no.valid.format') {
              setErrorText(
                'Ссылка имеет не верный формат. Придумайте другую, используя английские символы',
              );
            } else {
              setErrorText('Такая ссылка уже занята. Придумайте другую.');
            }
          } else {
            setErrorText('Такая ссылка уже занята. Придумайте другую.');
          }
          dispatch(setUrlLinkLoading(false));
          dispatch(setUrlLinkError(true));
        });
    }
  };

  /** Флаг, переключаем если кликнули на url */
  const [isCopied, setIsCopied] = useState(false);

  /** Клик на копирование ссылки витрины */
  const copyClick = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="create-link-input">
      <div
        className="create-link-input__link-base create-link-input__link-base_hidden"
        ref={baseUrlNameRef}
      >
        {type === KOPIM ? finalKopimUrl : finalKassaUrl}
      </div>

      <div
        className="create-link-input__link-base create-link-input__link-base_hidden"
        ref={userUrlRef}
      >
        {url}
      </div>

      <div
        className={cx('create-link-input__input-holder create-link-input__input-holder_focused', {
          'create-link-input__input-holder_error': errorText,
          'create-link-input__input-holder_disabled': isEditing,
        })}
      >
        <input
          type="text"
          className={cx('create-link-input__input', {
            'create-link-input__input_link-name-too-long': isLinkNameTooLong,
          })}
          value={url}
          onChange={event => onChangeUrl(event.target.value.toLowerCase())}
          placeholder="vashe_nazvanie"
          maxLength={20}
          disabled={isEditing}
        />

        {!isLinkNameTooLong ? (
          <div className="create-link-input__link-base" data-testid="create-link-input-link-base">
            {type === KOPIM ? finalKopimUrl : finalKassaUrl}
          </div>
        ) : (
          <div className="create-link-input__link-base">../</div>
        )}
        <p className="create-link-input__label">
          Ссылка на {`${type === KOPIM ? 'копилку' : 'витрину'}`}
        </p>

        {!isCopied ? (
          <CopyToClipboard
            text={`${type === KOPIM ? KOPIM_URL : KASSA_URL}${url}`}
            onCopy={() => copyClick(`${type === KOPIM ? KOPIM_URL : KASSA_URL}${url}`)}
            className="create-link-input__copy-button"
          >
            <button disabled={isUrlLinkLoading || url === '' || errorText} type="button">
              <IconCopyContent className="create-link-input__copy-icon" />
            </button>
          </CopyToClipboard>
        ) : (
          <IconCheck className="create-link-input__copy-done-icon" />
        )}

        {isUrlLinkLoading && (
          <CircleLoader
            className="create-link-input__url-input-loader"
            width={15}
            height={15}
            mainColor="rgba(40, 65, 149, 0.1)"
            borderWidth={1.5}
          />
        )}
      </div>

      {errorText && <p className="create-link-input__error-message">{errorText}</p>}
    </div>
  );
};

CreateLinkInput.propTypes = {
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  setUrl: PropTypes.func.isRequired,
  url: PropTypes.string,
  isUrlLinkLoading: PropTypes.bool,
};

CreateLinkInput.defaultProps = {
  isEditing: false,
  url: '',
  isUrlLinkLoading: false,
};

const mapStateToProps = state => ({
  isUrlLinkLoading: state.cashBox.isUrlLinkLoading,
});

export default connect(mapStateToProps)(CreateLinkInput);

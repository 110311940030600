import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Icons
import { ReactComponent as IconPostEmpty } from 'static/assets/icon-post-empty.svg';

// Styles
import './NoOperationsMade.scss';

const NoOperationsMade = ({ className, text, children }) => {
  return (
    <div
      className={cx('no-operations-made', {
        [className]: className,
      })}
    >
      <div className="no-operations-made__icon-wrap">
        <IconPostEmpty className="no-operations-made__icon" />
      </div>

      {text && <div className="no-operations-made__text">{text}</div>}

      {children}
    </div>
  );
};

NoOperationsMade.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};

NoOperationsMade.defaultProps = {
  className: null,
  text: null,
  children: null,
};

export default NoOperationsMade;

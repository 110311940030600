import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

// Styles
import './PopupCloseWithText.scss';

const PopupCloseWithText = ({ closePopupHandler, className, isLink, href }) => {
  return (
    <>
      {isLink ? (
        <Link
          className={cx('popup-close-with-text', {
            [className]: className,
          })}
          onClick={closePopupHandler}
          data-testid="popup-close-with-text"
          to={href}
        >
          <p className="popup-close-with-text__close-text">Закрыть</p>
          <IconClose className="popup-close-with-text__close-icon" />
        </Link>
      ) : (
        <button
          type="button"
          className={cx('popup-close-with-text', {
            [className]: className,
          })}
          onClick={closePopupHandler}
          data-testid="popup-close-with-text"
        >
          <p className="popup-close-with-text__close-text">Закрыть</p>
          <IconClose className="popup-close-with-text__close-icon" />
        </button>
      )}
    </>
  );
};

PopupCloseWithText.propTypes = {
  className: PropTypes.string,
  closePopupHandler: PropTypes.func,
  isLink: PropTypes.bool,
  href: PropTypes.string,
};

PopupCloseWithText.defaultProps = {
  className: null,
  isLink: false,
  closePopupHandler: () => {},
  href: null,
};

export default PopupCloseWithText;

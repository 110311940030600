import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PopupBalance from '../PopupBalance';
import PopupConfirmation from '../PopupConfirmation';
import PopupUnprocessingOperation from '../PopupUnprocessingOperation';
import PopupSuccess from '../PopupSuccess';
import PopupError from '../PopupError';

const WalletClosingOverlay = ({
  isWalletClosingBalancePopupVisible,
  isWalletClosingConfirmationPopupVisible,
  isWalletClosingUnprocessingOperationPopupVisible,
  isWalletClosingSuccessPopupVisible,
  isWalletClosingErrorPopupVisible,
}) => (
  <React.Fragment>
    {isWalletClosingBalancePopupVisible && <PopupBalance />}
    {isWalletClosingConfirmationPopupVisible && <PopupConfirmation />}
    {isWalletClosingUnprocessingOperationPopupVisible && <PopupUnprocessingOperation />}
    {isWalletClosingSuccessPopupVisible && <PopupSuccess />}
    {isWalletClosingErrorPopupVisible && <PopupError />}
  </React.Fragment>
);

WalletClosingOverlay.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isWalletClosingBalancePopupVisible: PropTypes.bool.isRequired,
  isWalletClosingConfirmationPopupVisible: PropTypes.bool.isRequired,
  isWalletClosingUnprocessingOperationPopupVisible: PropTypes.bool.isRequired,
  isWalletClosingSuccessPopupVisible: PropTypes.bool.isRequired,
  isWalletClosingErrorPopupVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isWalletClosingBalancePopupVisible: state.walletClosing.isWalletClosingBalancePopupVisible,
  isWalletClosingConfirmationPopupVisible:
    state.walletClosing.isWalletClosingConfirmationPopupVisible,
  isWalletClosingUnprocessingOperationPopupVisible:
    state.walletClosing.isWalletClosingUnprocessingOperationPopupVisible,
  isWalletClosingSuccessPopupVisible: state.walletClosing.isWalletClosingSuccessPopupVisible,
  isWalletClosingErrorPopupVisible: state.walletClosing.isWalletClosingErrorPopupVisible,
});

export default connect(mapStateToProps)(WalletClosingOverlay);

import { useEffect, useState } from 'react';

const useFocus = (refs, mountDeps = [], defaultState = false) => {
  const [focused, setFocused] = useState(new Array(refs.length).fill(defaultState));

  useEffect(() => {
    const onFocus = event =>
      setFocused(prevState => {
        const index = refs.findIndex(item => item.current === event.target);
        if (index > -1) {
          return prevState.map((focusValue, focusIndex) => {
            if (focusIndex === index) {
              return true;
            }
            return focusValue;
          });
        }
        return prevState;
      });

    const onBlur = event =>
      setFocused(prevState => {
        const index = refs.findIndex(item => item.current === event.target);
        if (index > -1) {
          return prevState.map((focusValue, focusIndex) => {
            if (focusIndex === index) {
              return false;
            }
            return focusValue;
          });
        }
        return prevState;
      });

    if (mountDeps.every(dep => dep)) {
      refs.forEach(ref => {
        ref.current.addEventListener('focus', onFocus);
        ref.current.addEventListener('blur', onBlur);
      });
    }

    return () => {
      if (mountDeps.every(dep => dep)) {
        refs.forEach(ref => {
          if (ref.current) {
            ref.current.removeEventListener('focus', onFocus);
            ref.current.removeEventListener('blur', onBlur);
          }
        });
      }
    };
    /* eslint-disable */
  }, [...mountDeps]);
  /* eslint-enable */

  return focused;
};

export default useFocus;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import PasswordInput from 'modules/shared/components/PasswordInput/PasswordInput';

// Styles
import './OldPassword.scss';

const OldPassword = ({ className, title, value, onChangeOldPassword }) => {
  return (
    <div
      className={cx('old-password', {
        [className]: className,
      })}
    >
      {title && <h2 className="change-password__title">{title}</h2>}

      <div className="old-password__content">
        <PasswordInput
          onEnterOldPassword={onChangeOldPassword}
          value={value}
          placeholder="Укажите текущий пароль"
          autocomplete="current-password"
        />
      </div>
    </div>
  );
};

OldPassword.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeOldPassword: PropTypes.func.isRequired,
  title: PropTypes.string,
};

OldPassword.defaultProps = {
  className: null,
  title: null,
};

export default OldPassword;

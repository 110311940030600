import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  OKShareButton,
  TelegramShareButton,
  //TODO?: функционал был выключен в рамках задачи SWPS-192
  //TODO?: функционал был выключен в в связи блокировкой на территории РФ
  // FacebookShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';

// Components
import SaveMoneyPreviewForm from 'modules/cashbox/components/SaveMoneyPreviewForm/SaveMoneyPreviewForm';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';
import BlockedUserText from 'modules/shared/components/BlockedUserText/BlockedUserText';
import CircleWithEmoji from 'modules/shared/components/CircleWithEmoji/CircleWithEmoji';
import PaymentProcessCard from 'modules/shared/components/PaymentProcessCard/PaymentProcessCard';
import PaymentSuccessHeader from 'modules/shared/components/PaymentSuccessHeader/PaymentSuccessHeader';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

import {
  BLUE_BORDER,
  PAYMENT_TRANSFER_FAIL,
  PAYMENT_TRANSFER_LOADING,
  PAYMENT_TRANSFER_OK,
} from 'constants/index';
import formatNumber from 'helpers/formatNumber';
import { declOfNum, toUpperCaseFio } from 'helpers/index';

import { ReactComponent as IconBurger } from 'static/assets/icon-burger.svg';
import emojiSad from 'static/assets/icon-emoji-sad.png';
//TODO?: функционал был выключен в рамках задачи SWPS-192
//TODO?: функционал был выключен в в связи блокировкой на территории РФ
// import { ReactComponent as IconShareFb } from 'static/assets/icon-share-fb.svg';
import { ReactComponent as IconShareTg } from 'static/assets/icon-share-tg.svg';
import { ReactComponent as IconShareVk } from 'static/assets/icon-share-vk.svg';
import { ReactComponent as IconShareWa } from 'static/assets/icon-share-wa.svg';
import { ReactComponent as IconShareOk } from 'static/assets/icon-share-ok.svg';
import { ReactComponent as IconLogo } from 'static/assets/logo-white.svg';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './SaveMoneyPreview.scss';

const SaveMoneyPreview = ({
  className,
  isPreview,
  colorTheme,
  fio,
  daysLeft,
  purpose,
  commentary,
  isUserLoggedIn,
  sumHint,
  sum,
  maxLimitSum,
  moneyboxId,
  needFio,
  needPhone,
  needEmail,
  avatar,
  loginLastChars,
  isMoneyTransferOk,
  isMoneyTransferFail,
  isSending,
  urlForRepeatPayment,
  amountFromLocalStorage,
  isMoneyCollectingFinished,
  isUserBlocked,
  isReplenishmentBlocked,
  isNotAvailable,
}) => {
  const windowSize = useWindowSize();
  const INITIAL_STATUS = !isSending && !isMoneyTransferOk && !isMoneyTransferFail;
  const linkForShare =
    urlForRepeatPayment && !isPreview
      ? process.env.REACT_APP_URL + urlForRepeatPayment.slice(1)
      : process.env.REACT_APP_URL;

  const [errorMessage, setErrorMessage] = useState('');

  const [showedIframe, setShowedIframe] = useState(false);
  const [resetPay, setResetPay] = useState(0);

  useEffect(() => {
    if (window && window.sessionStorage) {
      const localErrorMessage = window.sessionStorage.getItem('transactionStateMessage');
      if (localErrorMessage) {
        setErrorMessage(localErrorMessage);
        window.sessionStorage.removeItem('transactionStateMessage');
      }
    }
  }, []);

  const summaryFields = [
    {
      source: loginLastChars,
      title: 'Номер кошелька',
      value: `+7 *** *** ${loginLastChars}`,
    },
    {
      source: purpose,
      title: 'Цель',
      value: purpose,
    },
    {
      source: amountFromLocalStorage,
      title: 'Сумма',
      value: `${formatNumber(amountFromLocalStorage)} ₽`,
    },
  ];

  const reset = () => {
    setResetPay((trigger) => trigger + 1);
  };

  return (
    <div
      className={cx('save-money-preview', {
        'save-money-preview_color-theme-1': isUserBlocked || isReplenishmentBlocked || !colorTheme,
        [`save-money-preview_color-theme-${colorTheme}`]:
          colorTheme && !isUserBlocked && !isReplenishmentBlocked,
        'save-money-preview_logged-in': isUserLoggedIn,
        'save-money-preview_real': !isPreview,
        [className]: className,
      })}
    >
      {isPreview && (
        <div className="save-money-preview__header">
          <div className="save-money-preview__header-top">
            <IconLogo className="save-money-preview__header-logo" />

            <IconBurger className="save-money-preview__header-burger" />
          </div>
        </div>
      )}

      {!isPreview && (
        <div
          className={cx('save-money-preview__back-button-wrapper', {
            'container container_small': !isPreview,
          })}
        >
          {showedIframe && (
            <div className="save-money-preview__back-button" onClick={reset}>
              <IconArrow className={cx('save-money-preview__back-button-icon')} />
              <span className="save-money-preview__back-button-text">Назад</span>
            </div>
          )}
        </div>
      )}

      <div className="save-money-preview__body">
        <div
          className={cx('save-money-preview__container', {
            'container container_small': !isPreview,
          })}
        >
          {isUserBlocked || isReplenishmentBlocked ? (
            <div className="save-money-preview__blocked-user">
              <CircleWithEmoji emoji={emojiSad} className="save-money-preview__blocked-user-img" />
              {isUserBlocked && (
                <BlockedUserText className="save-money-preview__blocked-user-text" />
              )}

              {isReplenishmentBlocked && (
                <BlockedUserText
                  className="save-money-preview__blocked-user-text"
                  title={'Пополнение кошелька заблокировано'}
                  desc={'Пополнение кошелька заблокировано и нельзя совершить платеж.'}
                />
              )}
            </div>
          ) : (
            <>
              <div className="save-money-preview__col save-money-preview__col_about">
                <div className="save-money-preview__author-block">
                  {windowSize.innerWidth > 600 && (
                    <div className="save-money-preview__author-block-avatar-wrap">
                      <div className="save-money-preview__author-block-avatar-holder">
                        <img
                          src={avatar || stdAvatar}
                          alt="Аватарка создателя копилки"
                          className="save-money-preview__author-block-avatar"
                        />
                      </div>
                    </div>
                  )}

                  <div className="save-money-preview__author-block-info-wrap">
                    <div className="save-money-preview__info-block">
                      <p className="save-money-preview__info-block-title">Собирает</p>
                      <p className="save-money-preview__info-block-text">
                        {fio && toUpperCaseFio(fio)}
                      </p>
                    </div>

                    {daysLeft && daysLeft >= 0 && (
                      <div className="save-money-preview__info-block">
                        <p className="save-money-preview__info-block-title">До окончания сбора</p>
                        <p className="save-money-preview__info-block-text">
                          {daysLeft} <> {declOfNum(Math.abs(daysLeft), ['день', 'дня', 'дней'])}</>
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {purpose && (
                  <div className="save-money-preview__info-block">
                    <p className="save-money-preview__info-block-title">На что собирает</p>
                    <p className="save-money-preview__info-block-text save-money-preview__info-block-text_purpose">
                      {purpose}
                    </p>
                  </div>
                )}

                {commentary && (
                  <div className="save-money-preview__info-block">
                    <p className="save-money-preview__info-block-title">Подробнее</p>
                    <p className="save-money-preview__info-block-text save-money-preview__info-block-text_plain-text">
                      {commentary}
                    </p>
                  </div>
                )}
              </div>

              <div className="save-money-preview__col save-money-preview__col_form">
                <div className="save-money-preview__form-wrap">
                  {/* FORM */}
                  {INITIAL_STATUS ? (
                    <SaveMoneyPreviewForm
                      moneyboxId={moneyboxId}
                      sum={sum}
                      maxLimitSum={maxLimitSum}
                      sumHint={sumHint}
                      needFio={needFio}
                      needPhone={needPhone}
                      needEmail={needEmail}
                      isPreview={isPreview}
                      isMoneyCollectingFinished={isMoneyCollectingFinished}
                      showedIframe={(e) => setShowedIframe(e)}
                      resetPay={resetPay}
                      isNotAvailable={isNotAvailable}
                    />
                  ) : (
                    <div className="save-money-preview__payment">
                      {isMoneyTransferOk ? (
                        <div className="save-money-preview__payment-card save-money-preview__payment-card_success">
                          <PaymentSuccessHeader paymentStatus={PAYMENT_TRANSFER_OK} />

                          <PaymentSummary
                            className="web-money-card__payment-summary"
                            summaryFields={summaryFields.filter((item) => item.source)}
                          />
                        </div>
                      ) : (
                        <PaymentProcessCard
                          className="save-money-preview__payment-card"
                          paymentStatus={
                            isSending ? PAYMENT_TRANSFER_LOADING : PAYMENT_TRANSFER_FAIL
                          }
                        />
                      )}

                      {isMoneyTransferFail && (
                        <div className="save-money-preview__payment-fail-reason">
                          <span className="save-money-preview__payment-fail-reason-key">
                            Возможная причина:
                          </span>
                          <span className="save-money-preview__payment-fail-reason-value">
                            {errorMessage || 'Ведутся работы на сервере'}
                          </span>
                        </div>
                      )}

                      {isMoneyTransferOk && (
                        <StandardButton
                          title={urlForRepeatPayment ? 'Повторить платеж' : 'Вернуться в копилку'}
                          bgType={BLUE_BORDER}
                          isLink
                          href={urlForRepeatPayment || '/lk/kopim'}
                          className="save-money-preview__payment-button"
                        />
                      )}

                      {isMoneyTransferFail && (
                        <StandardButton
                          title={urlForRepeatPayment ? 'Повторить платеж' : 'Вернуться в копилку'}
                          bgType={BLUE_BORDER}
                          isLink
                          href={urlForRepeatPayment || '/lk/kopim'}
                          className="save-money-preview__payment-button"
                        />
                      )}
                    </div>
                  )}
                  {/* /FORM */}
                </div>

                {windowSize.innerWidth > 600 && !errorMessage && (
                  <div className="save-money-preview__share-nav">
                    <VKShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      title={purpose}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareVk className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_vk" />
                    </VKShareButton>

                    <TelegramShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      title={purpose}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareTg className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_tg" />
                    </TelegramShareButton>

                    <OKShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      title={purpose}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareOk className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_ok" />
                    </OKShareButton>

                    <WhatsappShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      title={purpose}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareWa className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_wa" />
                    </WhatsappShareButton>

                    {
                      //TODO?: функционал был выключен в рамках задачи SWPS-192
                      //TODO?: функционал был выключен в в связи блокировкой на территории РФ
                    }
                    {/*
                    <FacebookShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareFb className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_fb" />
                    </FacebookShareButton>
                    */}
                    {/*
                    <TwitterShareButton
                      className="save-money-preview__share-nav-btn"
                      url={linkForShare}
                      disabled={isMoneyCollectingFinished}
                    >
                      <IconShareTw className="save-money-preview__share-nav-icon save-money-preview__share-nav-icon_tw" />
                    </TwitterShareButton>
                     */}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

SaveMoneyPreview.propTypes = {
  className: PropTypes.string,
  moneyboxId: PropTypes.string,
  isPreview: PropTypes.bool.isRequired,
  colorTheme: PropTypes.number,
  fio: PropTypes.string,
  daysLeft: PropTypes.number,
  purpose: PropTypes.string,
  commentary: PropTypes.string,
  isUserLoggedIn: PropTypes.bool.isRequired,
  sumHint: PropTypes.number,
  sum: PropTypes.number,
  maxLimitSum: PropTypes.number,
  needFio: PropTypes.bool,
  needPhone: PropTypes.bool,
  needEmail: PropTypes.bool,
  avatar: PropTypes.string,
  loginLastChars: PropTypes.string,
  isMoneyTransferOk: PropTypes.bool,
  isMoneyTransferFail: PropTypes.bool,
  isSending: PropTypes.bool,
  urlForRepeatPayment: PropTypes.string,
  amountFromLocalStorage: PropTypes.number,
  isMoneyCollectingFinished: PropTypes.bool,
  isUserBlocked: PropTypes.bool,
  isReplenishmentBlocked: PropTypes.bool,
};

SaveMoneyPreview.defaultProps = {
  className: null,
  daysLeft: null,
  moneyboxId: null,
  colorTheme: null,
  fio: null,
  purpose: null,
  commentary: null,
  sumHint: null,
  sum: null,
  maxLimitSum: null,
  needFio: false,
  needPhone: false,
  needEmail: false,
  avatar: null,
  loginLastChars: null,
  isMoneyTransferOk: false,
  isMoneyTransferFail: false,
  isSending: false,
  urlForRepeatPayment: null,
  amountFromLocalStorage: 0,
  isMoneyCollectingFinished: false,
  isUserBlocked: false,
  isReplenishmentBlocked: false,
};

export default SaveMoneyPreview;

import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../Header';
import Check from '../Check';
import { hidePopupCheck } from '../../actions';
import { getPopupCheckPayment } from '../../selectors';
import styles from './PopupCheck.module.scss';

const PopupCheck = ({ dispatch, payment }) => {
  return createPortal(
    <div className={styles.popupCheck}>
      <div className={styles.popupCheckWrapper}>
        <Header onClose={() => dispatch(hidePopupCheck())} />
        <div className={styles.popupCheckContainer}>
          <Check
            id={payment.id}
            operationTime={payment.operationTime}
            firstName={payment.firstName}
            lastName={payment.lastName}
            middleName={payment.middleName}
            inn={payment.inn}
            services={payment.services}
            totalSum={payment.totalAmount}
            link={payment.link}
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};

PopupCheck.propTypes = {
  dispatch: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string.isRequired,
    operationTime: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
        receiptId: PropTypes.string,
      }),
    ).isRequired,
    link: PropTypes.string.isRequired,
    inn: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  payment: getPopupCheckPayment()(state),
});

export default connect(mapStateToProps)(PopupCheck);

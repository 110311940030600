import React from 'react';
import { Redirect } from 'react-router-dom';
import Dialog from 'modules/shared/components/Dialog';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import styles from './PopupUnprocessingOperation.module.scss';

const PopupUnprocessingOperation = () => {
  const [isRedirected, setIsRedirected] = React.useState(false);

  function onClose() {
    setIsRedirected(true);
  }

  if (isRedirected) {
    return <Redirect to="/lk/profile-settings" />;
  }

  return (
    <Dialog onClose={onClose}>
      <h1 className={styles.popupUnprocessingOperationTitle}>Нужно подождать</h1>
      <p className={styles.popupUnprocessingOperationText}>
        У вас есть не завершенные операции. Их выполнение может занять несколько минут. Пожалуйста,
        повторите попытку позже.
      </p>
      <nav className={styles.popupUnprocessingOperationNav}>
        <StandardButton
          className={styles.popupUnprocessingOperationNavButton}
          isLink={false}
          title="Понятно"
          onClick={onClose}
        />
      </nav>
    </Dialog>
  );
};

export default PopupUnprocessingOperation;

import React, { useEffect } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

import './PagePreviewPopup.scss';

const PagePreviewPopup = React.forwardRef((props, ref) => {
  const { className, onCloseBtnClick, title, image } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  return createPortal(
    <div
      className={cx('page-preview-popup', {
        [className]: className,
      })}
      ref={ref}
      data-testid="page-preview-popup"
    >
      <div className="page-preview-popup__inner">
        <div className="page-preview-popup__content">
          <div className="page-preview-popup__title" data-testid="page-preview-popup-title">
            {title}
          </div>
          <div className="page-preview-popup__image-wrap">
            <img src={image} alt="" className="page-preview-popup__image" />
          </div>
        </div>
      </div>

      <button
        className="page-preview-popup__close"
        type="button"
        onClick={onCloseBtnClick}
        aria-label="Закрыть попап"
        data-cy="page-preview-popup-close-btn"
      >
        <IconClose className="page-preview-popup__close-icon" />
      </button>
    </div>,
    document.body,
  );
});

PagePreviewPopup.propTypes = {
  className: PropTypes.string,
  onCloseBtnClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

PagePreviewPopup.defaultProps = {
  className: null,
};

export default PagePreviewPopup;

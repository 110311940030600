import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';

import './MonthTaxCard.scss';

const MonthTaxCard = ({
  disabled,
  isActive,
  month,
  monthNumber,
  sum,
  className,
  isSumLoading,
  onClick,
  isLink,
}) => {
  return (
    <>
      {isLink ? (
        <Link
          to={`/lk/tax-list/month-${monthNumber}`}
          className={cx('month-tax-card', {
            [className]: className,
            'month-tax-card_active': isActive,
          })}
          onClick={onClick}
          data-testid="month-tax-card"
        >
          <p className="month-tax-card__month">{month}</p>

          {sum && (
            <TaxesAmount className="month-tax-card__sum" isLoading={isSumLoading} amount={sum} />
          )}
        </Link>
      ) : (
        <button
          className={cx('month-tax-card', {
            [className]: className,
            'month-tax-card_active': isActive,
          })}
          disabled={disabled}
          type="button"
          data-testid="month-tax-card"
          onClick={onClick}
        >
          <p className="month-tax-card__month">{month}</p>

          {sum && (
            <TaxesAmount className="month-tax-card__sum" isLoading={isSumLoading} amount={sum} />
          )}
        </button>
      )}
    </>
  );
};

MonthTaxCard.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isSumLoading: PropTypes.bool,
  month: PropTypes.string.isRequired,
  monthNumber: PropTypes.number,
  sum: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
};

MonthTaxCard.defaultProps = {
  isSumLoading: false,
  disabled: false,
  isActive: false,
  sum: null,
  className: null,
  isLink: false,
  onClick: () => {},
  monthNumber: null,
};

export default MonthTaxCard;

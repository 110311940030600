import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ApplePayBox from 'modules/shared/components/ApplePayBox/ApplePayBox';
import SamsungPayButton from 'modules/shared/components/SamsungPayButton/SamsungPayButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

// import { loadScript } from 'helpers/loader';
import { BLUE } from 'constants/index';

import './PaymentBox.scss';
import SBPButton from '../SBPButton/SBPButton';
import { checkSBPEnabled } from '../../../../tools/utils/sbp';

const PaymentBox = ({
  isDisabled,
  onPayByCard,
  onPayBySBP,
  amount,
  isOneColumn,
  hideApplePay,
  hideSamsungPay,
  hideSBP,
  hideCard,
  disableApplePayButton,
  disableSamsungPayButton,
  disableSBPButton,
  payByApplePay,
  bgType,
}) => {
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(
    //!hideApplePay && window.ApplePaySession;,
    false,
  );
  const [isSamsungPayAvailable, setIsSamsungPayAvailable] = useState(
    //!hideSamsungPay && window.SamsungPay && window.SamsungPay.checkMobileAndRunnable(),
    false,
  );

  const [isSBPAvailable, setIsSBPAvailable] = useState(true);

  useEffect(() => {
    setIsSamsungPayAvailable(false);
    setIsApplePayAvailable(false);

    if (hideSBP || !checkSBPEnabled()) {
      setIsSBPAvailable(false);
    }
    // if (hideSamsungPay || window.SamsungPay) {
    //   return;
    // }
    //
    // loadScript(`${process.env.PUBLIC_URL}/pc_gsmpi_web_sdk.js`)
    //   .then(() => {
    //     setIsSamsungPayAvailable(window.SamsungPay.checkMobileAndRunnable());
    //   })
    //   .catch(() => {
    //     // todo: add logic
    //   });
  }, [hideApplePay, hideSamsungPay, hideSBP, hideCard]);

  return (
    <div
      className={cx('payment-box', {
        'mod-one-column': isOneColumn,
      })}
    >
      {!hideCard && (
        <div
          className={cx('payment-box__column', {
            'mod-one-column':
              (!isApplePayAvailable && !isSamsungPayAvailable && !isSBPAvailable) || isOneColumn,
          })}
        >
          <StandardButton
            isLink={false}
            title={'Оплатить картой'}
            disabled={isDisabled}
            onClick={() => onPayByCard()}
            className="payment-box__button"
            bgType={bgType || BLUE}
          />
        </div>
      )}

      {isSBPAvailable && (
        <div
          className={cx('payment-box__column', {
            'mod-one-column': isOneColumn,
          })}
        >
          <SBPButton
            onClick={() => onPayBySBP()}
            className="payment-box__button"
            amount={amount}
            disablePayButton={isDisabled || disableSBPButton}
          />
        </div>
      )}

      {isApplePayAvailable && (
        <div
          className={cx('payment-box__column', {
            'mod-one-column': isOneColumn,
          })}
        >
          <ApplePayBox
            amount={amount}
            disableApplePayButton={disableApplePayButton}
            payByApplePay={payByApplePay}
          />
        </div>
      )}
      {isSamsungPayAvailable && (
        <div
          className={cx('payment-box__column', {
            'mod-one-column': isOneColumn,
          })}
        >
          <SamsungPayButton amount={amount} disableSamsungPayButton={disableSamsungPayButton} />
        </div>
      )}
    </div>
  );
};

PaymentBox.propTypes = {
  isDisabled: PropTypes.bool,
  isOneColumn: PropTypes.bool,
  hideApplePay: PropTypes.bool,
  hideSamsungPay: PropTypes.bool,
  hideSBP: PropTypes.bool,
  hideCard: PropTypes.bool,
  onPayByCard: PropTypes.func.isRequired,
  onPayBySBP: PropTypes.func,
  amount: PropTypes.number.isRequired,
  disableApplePayButton: PropTypes.bool,
  disableSamsungPayButton: PropTypes.bool,
  disableSBPButton: PropTypes.bool,
  payByApplePay: PropTypes.func,
  bgType: PropTypes.string,
};

PaymentBox.defaultProps = {
  isDisabled: false,
  isOneColumn: false,
  hideApplePay: false,
  hideSamsungPay: false,
  hideSBP: false,
  hideCard: false,
  disableApplePayButton: false,
  disableSamsungPayButton: false,
  disableSBPButton: false,
  payByApplePay: () => {},
  onPayBySBP: () => {},
  bgType: BLUE,
};

export default PaymentBox;

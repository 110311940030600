import { normalizePhone } from 'helpers';
import React, {
  //  TODO: PRO-384
  // useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';
import { AnimatedUI, ButtonUI, InputUI, LineText, TitleText } from 'ui';

import {
  loginApiThunk,
  useLoginFailSelector,
  useLoginFetchingSelector,
} from '../../../models/login';

export const LoginContainer = () => {
  const dispatch = useDispatch();

  const isMenuOpen = useSelector((store) => store.auth.isMenuOpen);
  const { fail, error } = useLoginFailSelector();
  const isLoading = useLoginFetchingSelector();

  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [isValid, setIsValid] = useState(false);
  //  TODO: PRO-384
  // const [isVisibleWidget, setIsVisibleWidget] = useState(false);
  // const { icDone } = window;
  // useLayoutEffect(() => {
  //   icDone && setIsVisibleWidget(true);
  // }, [icDone]);

  useEffect(() => {
    setIsValid(!!(phone && normalizePhone(phone).length === 11 && password.length >= 1));
  }, [phone, password]);

  const handleSubmit = () => {
    dispatch(
      loginApiThunk({
        login: normalizePhone(phone),
        password,
      }),
    );
  };

  //  TODO: PRO-384
  // if (isVisibleWidget)
  return (
    <WrapperStyled>
      <FormStyled>
        <AnimatedTitleStyled id={0} finishId={4} isVisible={!isMenuOpen}>
          <TitleTextStyled weight={WEIGHT['circe-extra-bold']}>Вход в платформу</TitleTextStyled>
        </AnimatedTitleStyled>
        <AnimatedPhoneStyled id={1} finishId={4} isVisible={!isMenuOpen}>
          <InputUI.Phone
            value={phone}
            onChange={(valuePhone) => setPhone(normalizePhone(valuePhone))}
            isFail={fail}
            placeholder="Введите номер телефона"
            onKeyEnter={() => isValid && handleSubmit()}
            required
          />
        </AnimatedPhoneStyled>
        <AnimatedPasswordStyled id={2} finishId={4} isVisible={!isMenuOpen}>
          <InputUI.Password
            value={password}
            onChange={(value) => setPassword(value)}
            onKeyEnter={() => isValid && handleSubmit()}
            isFail={fail}
            required
          />
        </AnimatedPasswordStyled>

        <AnimatedInfoOneStyled id={3} finishId={4} isVisible={!isMenuOpen}>
          {error ? <TextErrorStyled>{error + ' '}</TextErrorStyled> : null}
          <InfoTextStyled>
            <RouteLinkStyled to="/password-recovery">Забыли пароль?</RouteLinkStyled>
          </InfoTextStyled>
        </AnimatedInfoOneStyled>
        <AnimatedButtonStyled id={3} finishId={4} isVisible={!isMenuOpen}>
          <SubmitButtonStyled
            onClick={() => handleSubmit()}
            isLoading={isLoading}
            disabled={!isValid}
          >
            Войти
          </SubmitButtonStyled>
        </AnimatedButtonStyled>

        <AnimatedInfoTwoStyled id={3} finishId={4} isVisible={!isMenuOpen}>
          <InfoTextStyled>{'Ещё нет профиля? '}</InfoTextStyled>
          <InfoTextStyled>
            <RouteLinkStyled to="/register">Зарегистрируйтесь</RouteLinkStyled>
          </InfoTextStyled>
        </AnimatedInfoTwoStyled>
      </FormStyled>
    </WrapperStyled>
  );
  //  TODO: PRO-384
  // return null;
};

const WrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormStyled = styled.form`
  max-width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  height: min-content;
  width: inherit;
`;

const AnimatedTitleStyled = styled(AnimatedUI)`
  margin-bottom: 20px;
`;

const TitleTextStyled = styled(TitleText)`
  display: inline-block;
  width: 100%;
  font-weight: 800;
  font-size: 36px;
  line-height: 53px;
  margin-bottom: 20px;
  text-align: center;
  color: #1d2e69;
`;

const AnimatedPhoneStyled = styled(AnimatedUI)`
  margin-bottom: 11px;
`;

const AnimatedPasswordStyled = styled(AnimatedUI)`
  margin-bottom: 8px;
`;

const AnimatedInfoOneStyled = styled(AnimatedUI)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #1d2e69;
`;

const AnimatedInfoTwoStyled = styled(AnimatedInfoOneStyled)`
  margin-top: 61px;
  margin-bottom: 0;
`;

const TextErrorStyled = styled(LineText)`
  color: #ff2d55;
`;

const InfoTextStyled = styled(LineText)`
  ${getFont(WEIGHT['circe-normal'])}
  color: #B0B5C4;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const RouteLinkStyled = styled(ButtonUI.RouteLink)`
  color: #284195;
  text-decoration: none;
  cursor: pointer;
`;

const AnimatedButtonStyled = styled(AnimatedUI)`
  display: flex;
  justify-content: center;
`;

const SubmitButtonStyled = styled(ButtonUI.Light)`
  border-radius: 30px;
  height: 60px;
  width: 240px;
  @media (max-width: 435px) {
    width: 100%;
  }
`;

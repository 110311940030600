import React from 'react';
import { Link } from 'react-router-dom';

const HintHowToLiftLimits = () => (
  <>
    <div className="hint__text-wrap">
      <div className="hint__text">
        Подтвердить личные данные можно одним из следующих способов. Например, получить
        «Авторизованная» учетную запись, перейдя на{' '}
        <Link to="/get-authorization" className="hint__text-link">
          эту страницу
        </Link>{' '}
        или связаться с нашей службой поддержки клиентов
      </div>
    </div>
  </>
);

export default HintHowToLiftLimits;

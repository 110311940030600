import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmailConfirmFailTemplate, EmailConfirmSuccessTemplate } from './templates';

/**
 * ШАБЛОН: ПОДТВЕРЖДЕНИЕ E-MAIL
 */
export const EmailConfirmTemplate = () => (
  <Switch>
    <Route exact path="/email/confirm/success">
      <EmailConfirmSuccessTemplate />
    </Route>
    <Route exact path={['/email/confirm/notfound', '/email/confirm/error']}>
      <EmailConfirmFailTemplate />
    </Route>
  </Switch>
);

import PropTypes from 'prop-types';

import { COLOR, WEIGHT } from '../constants';

export const PTClassNames = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.object, PropTypes.string]),
  ),
]);

export const PTChildrenElement = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.bool,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool])),
]);

export const PTColor = PropTypes.oneOf(Object.keys(COLOR));
export const PTCWeight = PropTypes.oneOf(Object.keys(WEIGHT));

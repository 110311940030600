/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { documentListGetRequestAPIThunk } from './thunk';

const initialState = {
  documentsData: {
    items: [],
    renderItems: [],
    hasMore: false,
    page: 0,
    filter: {
      companyName: null,
      status: null,
      partnerId: null,
      partnerName: null,
    },
  },
  documentListLoading: {
    fetching: false,
    success: false,
    fail: false,
    fetchingByFilter: false,
    error: null,
  },
};

const addFilter = (filterType) => (state, action) => {
  state.documentListLoading.fetchingByFilter = true;
  state.documentsData.page = 0;
  state.documentsData.filter[filterType] = action.payload;
};

const documentListSlice = createSlice({
  name: 'documentList',
  initialState,
  reducers: {
    addCompanyNameFilterAction: addFilter('companyName'),
    addStatusFilterAction: addFilter('status'),
    addPartnerIdFilterAction: addFilter('partnerId'),
    changeStatusDocAction: (state, action) => {
      state.documentsData.renderItems.filter((i) => i.id === action.payload.id)[0].state =
        action.payload.newState;
      state.documentsData.items.filter((i) => i.id === action.payload.id)[0].state =
        action.payload.newState;
      const { status, companyName } = state.documentsData.filter;
      state.documentsData.renderItems = state.documentsData.renderItems.filter(
        (i) =>
          (status ? i.state === status : true) &&
          (companyName ? i.companyName === companyName : true),
      );
    },
    clearAction: () => initialState,
  },
  extraReducers: {
    [documentListGetRequestAPIThunk.pending]: (state) => {
      state.documentListLoading = {
        fetching: true,
        success: false,
        fail: false,
        fetchingByFilter: state.documentListLoading.fetchingByFilter,
        error: null,
      };
    },
    [documentListGetRequestAPIThunk.fulfilled]: (state, action) => {
      const currentFetchingByFilter = state.documentListLoading.fetchingByFilter;
      const hasMore = action.payload?.length === 100;
      const items = currentFetchingByFilter
        ? [...action.payload]
        : [...state.documentsData.items, ...action.payload];
      state.documentListLoading = {
        fetching: false,
        success: true,
        fail: false,
        fetchingByFilter: false,
      };
      state.documentsData = {
        items,
        renderItems: items,
        hasMore,
        filter: state.documentsData.filter,
        page: state.documentsData.page + 1,
      };
    },
    [documentListGetRequestAPIThunk.rejected]: (state, action) => {
      state.documentListLoading = {
        fetching: false,
        success: false,
        fail: true,
        error: action.payload,
        fetchingByFilter: state.documentListLoading.fetchingByFilter,
      };
    },
  },
});

export const {
  actions: {
    addCompanyNameFilterAction,
    addStatusFilterAction,
    addPartnerIdFilterAction,
    clearAction,
    changeStatusDocAction,
  },
} = documentListSlice;

const { reducer } = documentListSlice;
export { reducer as documentListReducer };

/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';

export const LineOfMobile = () => (
  <LineWraper>
    <Line />
  </LineWraper>
);

const LineWraper = styled.div`
  position: relative;
  height: 48px;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    margin: 0 -20px;
  }
  @media screen and (min-width: 601px) and (max-width: 715px) {
    display: block;
    margin: 0 -50px;
  }
`;

const Line = styled.hr`
  position: absolute;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 30px;
  border: none;
  color: #e4e8ee;
  background-color: #e4e8ee;
  height: 1px;
`;

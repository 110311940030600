/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

import { ParagraphText } from '../../molecules';

export const ButtonDisabled = ({ children, Icon, onClick, width, className }) => {
  const onCurrentClick = useCallback(
    (event) => {
      event.preventDefault();
      onClick(event);
    },
    [onClick],
  );

  return (
    <ButtonStyled
      onClick={(e) => {
        onCurrentClick(e);
      }}
      type="button"
      width={width}
      className={className}
      disabled
    >
      {Icon}
      <ParagraphText weight={WEIGHT['circe-bold']} size="16px" color={COLOR['light-grey-blue']}>
        {children}
      </ParagraphText>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  border-radius: 8px;
  text-decoration: none;
  background: var(--pale-grey);
  cursor: unset;
  & p {
    display: block;
    padding: 13px 0;
    width: 100%;
    margin: auto;
    border-radius: 8px;
  }
`;

ButtonDisabled.propTypes = {
  children: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  onClick: PropTypes.func,
  width: PropTypes.string,
  className: PTClassNames,
};

ButtonDisabled.defaultProps = {
  Icon: null,
  width: '100%',
  onClick: (f) => f,
  className: '',
};

import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './HintCancelRequestOnSzStatus.scss';

const HintCancelRequestOnSzStatus = ({ cancelRequestOnSzStatus, continueRequest }) => {
  return (
    <div className="hint-cancel-request-sz-status">
      <div className="hint-cancel-request-sz-status__title">Хотите отменить запрос?</div>
      <div className="hint-cancel-request-sz-status__text">
        Сейчас вы пытаетесь отменить отправленный запрос и указать другой ИНН. Для подтверждения
        статуса необходимо предоставить права в приложении &quot;Мой Налог&quot;
      </div>

      <div className="hint-cancel-request-sz-status__button-wrap">
        <button
          className="hint-cancel-request-sz-status__button"
          type="button"
          onClick={cancelRequestOnSzStatus}
        >
          Отменить запрос
        </button>
      </div>

      <div className="hint-cancel-request-sz-status__continue-button-wrap">
        <button
          className="hint-cancel-request-sz-status__continue-button"
          type="button"
          onClick={continueRequest}
        >
          Продолжить проверку
        </button>
      </div>
    </div>
  );
};

HintCancelRequestOnSzStatus.propTypes = {
  cancelRequestOnSzStatus: PropTypes.func.isRequired,
  continueRequest: PropTypes.func.isRequired,
};

export default HintCancelRequestOnSzStatus;

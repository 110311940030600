import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonUI, ParagraphText } from 'ui';

import { ReactComponent as IconCloseSmall } from 'static/assets/icon-close-small.svg';

export const ResetButton = ({ isDisabled = true, onClick }) =>
  isDisabled ? (
    <ButtonDisabledStyled
      Icon={
        <IconWraperStyled>
          <IconCloseSmall />
        </IconWraperStyled>
      }
    >
      <ParagraphTextStyled>Сбросить</ParagraphTextStyled>
    </ButtonDisabledStyled>
  ) : (
    <ButtonNormalStyled
      onClick={() => onClick()}
      Icon={
        <IconWraperStyled>
          <IconCloseSmall />
        </IconWraperStyled>
      }
    >
      <ParagraphTextStyled>Сбросить</ParagraphTextStyled>
    </ButtonNormalStyled>
  );

ResetButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ResetButton.defaultProps = {
  isDisabled: false,
};

const ButtonMixin = css`
  width: 116px;
  height: 40px;
  padding: 0 17px 0 11px;
  p {
    padding: 0;
    width: min-content;
  }
`;

const ButtonDisabledStyled = styled(ButtonUI.Disabled)`
  ${ButtonMixin}
`;
const ButtonNormalStyled = styled(ButtonUI.Normal)`
  ${ButtonMixin}
  color: var(--white);
`;

const IconWraperStyled = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  path {
    stroke: var(--white);
  }
  ${ButtonDisabledStyled}:disabled & {
    path {
      stroke: var(--bluey-grey);
    }
  }
`;
const ParagraphTextStyled = styled(ParagraphText)`
  font-size: 14px;
  color: var(--bluey-grey);
`;

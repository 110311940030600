/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LineText } from 'ui';

import {
  clearAction,
  declineDocumentAPIThunk,
  resignOfConfirmDocumentAPIThunk,
  signConfirmDocumentAPIThunk,
  signOfConfirmDocumentAPIThunk,
  useIsFulfilledSignOfApprovDocumentRequestSelector,
  useIsPendingConfirmDocumentRequestSelector,
  useIsPendingSignOfApprovDocumentRequestSelector,
  useRequestErrorSelector,
  useResendTimeSelector,
  useSignIdOfConfirmDocumentSelector,
} from '../../../models/document';
import {
  ButtonsFormOfChangeStatus,
  InputFormOfChangeStatus,
  ReSendInfoOfChangeStatus,
} from '../ui';

export const ChangeDocStatusFormContainer = ({ platform, docId }) => {
  const dispatch = useDispatch();
  const [isInput, setIsInput] = useState(false);
  const isLoadingOfButton = useIsPendingSignOfApprovDocumentRequestSelector();
  const isSuccessOfButton = useIsFulfilledSignOfApprovDocumentRequestSelector();
  const isConfirmPending = useIsPendingConfirmDocumentRequestSelector();
  const isRequestError = useRequestErrorSelector();
  const resendTime = useResendTimeSelector();

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => () => dispatch(clearAction()), []);

  const actionPinAcceptId = useSignIdOfConfirmDocumentSelector();

  const onFinishInput = useCallback(
    (pin) => {
      pin = String(pin);
      if (docId && actionPinAcceptId && pin)
        dispatch(signConfirmDocumentAPIThunk({ docId, actionPinAcceptId, pin }));
    },
    [actionPinAcceptId, docId, dispatch],
  );

  useEffect(() => {
    if (isSuccessOfButton) setIsInput(true);
  }, [isSuccessOfButton]);

  return (
    <ChangeDocStatusFormStyled>
      {!isInput && (
        <ButtonsFormOfChangeStatus
          onPositive={() =>
            dispatch(signOfConfirmDocumentAPIThunk({ docPlatform: platform, docId }))
          }
          onNegative={() => dispatch(declineDocumentAPIThunk({ docPlatform: platform, docId }))}
          isRequestError={isRequestError}
          isLoading={isLoadingOfButton}
        />
      )}
      {isInput && (
        <InputFormOfChangeStatus
          onFinishInput={onFinishInput}
          isError={!!isRequestError}
          isLoading={isConfirmPending}
        />
      )}
      {isInput && (
        <ReSendInfoOfChangeStatus
          time={resendTime}
          isOnTimer={isSuccessOfButton}
          onResendSMS={() => {
            dispatch(resignOfConfirmDocumentAPIThunk());
          }}
        />
      )}
      {isRequestError && <LineTextErrorStyled>{isRequestError}</LineTextErrorStyled>}
    </ChangeDocStatusFormStyled>
  );
};

ChangeDocStatusFormContainer.propTypes = {
  docId: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired,
};

const ChangeDocStatusFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const LineTextErrorStyled = styled(LineText)`
  font-size: 14px;
  line-height: 20px;
  color: #ff2d55;
  margin-top: 20px;
`;

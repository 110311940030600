import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Dialog from 'modules/shared/components/Dialog';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SupportButton from 'modules/shared/components/SupportButton';
import { hideWalletClosingBalancePopup } from '../../actions';
import styles from './PopupBalance.module.scss';

const PopupBalance = ({ dispatch }) => {
  function onClose() {
    dispatch(hideWalletClosingBalancePopup());
  }

  return (
    <Dialog onClose={onClose}>
      <h1 className={styles.popupBalanceTitle}>Не забудьте про остаток</h1>
      <p className={styles.popupBalanceText}>
        На вашем балансе еще есть деньги. Напишите в техподдержку на{' '}
        <a href="mailto:support@selfwork.ru">support@selfwork.ru</a> и мы поможем вывести средства.
      </p>
      <nav className={styles.popupBalanceNav}>
        <SupportButton
          className={cx(styles.popupBalanceNavButton, styles.popupBalanceNavLeftButton)}
        >
          Написать техподдержке
        </SupportButton>
        <StandardButton
          className={styles.popupBalanceNavButton}
          isLink={true}
          href="/lk/money-transfer"
          title="Вывести остаток"
        />
      </nav>
    </Dialog>
  );
};

PopupBalance.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PopupBalance);

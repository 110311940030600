export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const FIRST_LOG_IN_SUCCESS = 'FIRST_LOG_IN_SUCCESS';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const SET_PHONE = 'SET_PHONE';
export const SET_USER_BALANCE = 'SET_USER_BALANCE';
export const SET_NEW_USER_MESSAGES = 'SET_NEW_USER_MESSAGES';
export const SET_CHANGED_TAX_STATUS = 'SET_CHANGED_TAX_STATUS';
export const SET_CHANGED_AUTH_STATUS = 'SET_CHANGED_AUTH_STATUS';
export const SET_INN = 'SET_INN';
export const REQUEST_SMS_SUCCESS = 'REQUEST_SMS_SUCCESS';
export const REQUEST_SMS_FAIL = 'REQUEST_SMS_FAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS';
export const CHECK_CODE_FAIL = 'CHECK_CODE_FAIL';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const SET_EMAIL = 'SET_EMAIL';
export const SAVE_EMAIL_SUCCESS = 'SAVE_EMAIL_SUCCESS';
export const SAVE_EMAIL_FAIL = 'SAVE_EMAIL_FAIL';
export const START_RECOVERY_PASSWORD_SUCCESS = 'START_RECOVERY_PASSWORD_SUCCESS';
export const START_RECOVERY_PASSWORD_FAIL = 'START_RECOVERY_PASSWORD_FAIL';
export const SET_MENU_OPEN = 'SET_MENU_OPEN';
export const SET_MENU_OPEN_WITH_TRANSITION = 'SET_MENU_OPEN_WITH_TRANSITION';
export const SET_HINT_OPEN = 'SET_HINT_OPEN';
export const CONFIRM_RECOVERY_PASSWORD_SUCCESS = 'CONFIRM_RECOVERY_PASSWORD_SUCCESS';
export const CONFIRM_RECOVERY_PASSWORD_FAIL = 'CONFIRM_RECOVERY_PASSWORD_FAIL';
export const CHECK_INN_SUCCESS = 'CHECK_INN_SUCCESS';
export const CHECK_INN_FAIL = 'CHECK_INN_FAIL';
export const SET_REGISTRATION_STEP = 'SET_REGISTRATION_STEP';
export const CHECK_INN_STATUS_SUCCESS = 'CHECK_INN_STATUS_SUCCESS';
export const CHECK_INN_STATUS_FAIL = 'CHECK_INN_STATUS_FAIL';
export const SET_INN_SENT_ON_CHECK = 'SET_INN_SENT_ON_CHECK';
export const LOG_IN_AFTER_REGISTRATION_SUCCESS = 'LOG_IN_AFTER_REGISTRATION_SUCCESS';
export const LOG_IN_AFTER_REGISTRATION_FAIL = 'LOG_IN_AFTER_REGISTRATION_FAIL';
export const GET_LIMITS_SUCCESS = 'GET_LIMITS_SUCCESS';
export const CHECK_INN_WITH_REQUEST_IN_FNS_SUCCESS = 'CHECK_INN_WITH_REQUEST_IN_FNS_SUCCESS';
export const CHECK_INN_WITH_REQUEST_IN_FNS_FAIL = 'CHECK_INN_WITH_REQUEST_IN_FNS_FAIL';
export const REMOVE_REDIRECT = 'REMOVE_REDIRECT';
export const SET_SZ_STATUS_FROM_DB = 'SET_SZ_STATUS_FROM_DB';
export const ACCEPT_PRIVACY_POLICY_SUCCESS = 'ACCEPT_PRIVACY_POLICY_SUCCESS';

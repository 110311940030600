import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import addDays from 'date-fns/addDays';
import { ru } from 'date-fns/esm/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import NumberFormat from 'react-number-format';

import CreateLinkInput from 'modules/cashbox/components/CreateLinkInput/CreateLinkInput';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import DatePickerCustomInput from 'modules/shared/components/DatePickerCustomInput/DatePickerCustomInput';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';

import { ANY_SUM, FIXED_SUM, MONTHSRU, WEEK_DAYS_FIRST_SUNDAY_RU } from 'constants/index';

import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';

// Styles
import './SaveMoneyForm.scss';

const colorThemes = [1, 2, 3, 4, 5, 6];
const KOPIM = 'moneybox';

const SaveMoneyForm = ({
  className,
  purpose,
  onChangePurpose,
  commentary,
  onChangeCommentary,
  isNameVisible,
  onChangeIsNameVisible,
  isPhoneVisible,
  onChangeIsPhoneVisible,
  isEmailVisible,
  onChangeIsEmailVisible,
  saveMoneyColorTheme,
  onChangeSaveMoneyColorTheme,
  sumType,
  onChangeSumType,
  sum,
  onChangeSum,
  fixedSumTabClick,
  anySumTabClick,
  isSaveActionLoading,
  createSaveMoneyClick,
  url,
  onChangeDaysLeft,
  daysLeft,
  dateForSaveMoneyEditing,
  minSum,
  maxSum,
  isEditingSaveMoneyCase,
  setUrl,
  isUrlLinkError,
  isUrlLinkLoading,
}) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);

  const [date, setDate] = useState(null);

  /** States for datePicker */
  registerLocale('ru', {
    ...ru,
    localize: {
      ...ru.localize,
      month: (n) => MONTHSRU[n],
      day: (n) => WEEK_DAYS_FIRST_SUNDAY_RU[n],
    },
  });

  useEffect(() => {
    if (dateForSaveMoneyEditing) {
      setDate(new Date(dateForSaveMoneyEditing));
    }
  }, [dateForSaveMoneyEditing]);

  /** Устанавливаем активное состояние кнопки - Опбликовать витрину */
  useEffect(() => {
    setIsSaveButtonActive(false);

    if (
      sumType === ANY_SUM &&
      purpose &&
      purpose.length >= 1 &&
      url &&
      url.length >= 1 &&
      !isUrlLinkError &&
      !isUrlLinkLoading
    ) {
      setIsSaveButtonActive(true);
    }

    if (
      sumType === FIXED_SUM &&
      purpose &&
      purpose.length >= 1 &&
      sum &&
      +sum >= 1 &&
      url &&
      url.length >= 1 &&
      !isUrlLinkError &&
      !isUrlLinkLoading
    ) {
      setIsSaveButtonActive(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [sumType, purpose, sum, url, isUrlLinkError, isUrlLinkLoading]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Set isRendered for animations start */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const navTabsArray = [
    {
      title: 'Любая сумма',
      click: anySumTabClick,
    },
    {
      title: 'Фикс. сумма',
      click: fixedSumTabClick,
    },
  ];

  return (
    <div
      className={cx('save-money-form', {
        [className]: className,
      })}
    >
      <div className="save-money-form__content">
        {windowSize.innerWidth <= 600 && (
          <TabsWithScrollableActiveState
            className="save-money-form__tabs"
            tabArray={navTabsArray}
            currentTabId={sumType === ANY_SUM ? 0 : 1}
            isRendered={isRendered}
          />
        )}

        {/* ВЫБОР ФОНА */}
        <div className="save-money-form__section save-money-form__section_colors">
          <div className="save-money-form__block">
            <p className="save-money-form__block-title">Фон копилки</p>

            <div className="save-money-form__checkbox-list">
              {colorThemes &&
                Array.isArray(colorThemes) &&
                colorThemes.length > 0 &&
                colorThemes.map((color) => (
                  <button
                    key={color}
                    className={cx('save-money-form__color-radio', {
                      [`save-money-form__color-radio_theme-${color}`]:
                        colorThemes[color - 1] === color,
                    })}
                    role="radio"
                    type="button"
                    aria-label="Цвет фона для копилки"
                    aria-checked={color === saveMoneyColorTheme}
                    onClick={() => onChangeSaveMoneyColorTheme(color)}
                  />
                ))}
            </div>
          </div>
        </div>
        {/* /ВЫБОР ФОНА */}

        <div className="save-money-form__section">
          {/* ЦЕЛЬ КОПИЛКИ */}
          <div className="save-money-form__block">
            <div
              className={cx('save-money-form__input-holder', {
                'save-money-form__input-holder_focused': purpose !== '',
              })}
            >
              <input
                required
                type="text"
                className="save-money-form__input"
                onChange={({ target }) => onChangePurpose(target.value)}
                value={purpose}
                maxLength={100}
              />
              <p className="save-money-form__label">Название цели</p>
            </div>
          </div>
          {/* /ЦЕЛЬ КОПИЛКИ */}

          {/* КОММЕНТАРИЙ */}
          <div className="save-money-form__block">
            <div
              className={cx('save-money-form__input-holder', {
                'save-money-form__input-holder_focused': commentary !== '',
              })}
            >
              <textarea
                cols="30"
                rows="10"
                className="save-money-form__textarea"
                onChange={({ target }) => onChangeCommentary(target.value)}
                value={commentary}
                maxLength={256}
              />

              <p className="save-money-form__label">Расскажите подробнее о вашей цели</p>
            </div>
          </div>
          {/* /КОММЕНТАРИЙ */}

          {/* ВЫБОР ДАТЫ */}
          <div className="save-money-form__block">
            <div
              className={cx('save-money-form__input-holder save-money-form__input-holder_date', {
                'save-money-form__input-holder_focused': date,
              })}
            >
              <DatePicker
                locale="ru"
                dateFormat="dd.MM.yyyy"
                showYearDropdown
                className="save-money-form__date-input"
                scrollableYearDropdown
                yearDropdownItemNumber={10}
                minDate={addDays(new Date(), 1)}
                customInput={<DatePickerCustomInput placeholderText={date ? '__/__/____' : ''} />}
                selected={date}
                onChange={(dateLocal) => {
                  onChangeDaysLeft(dateLocal);
                  setDate(dateLocal);
                }}
              />

              <p className="save-money-form__label">Последний день сбора</p>

              <IconCalendar className="save-money-form__calendar-icon" />
            </div>
            <div className="save-money-form__block-descr">
              После этой даты сбор закроется — копилка будет недоступна
            </div>
          </div>
          {/* /ВЫБОР ДАТЫ */}
        </div>

        <div className="save-money-form__section">
          {/* ТИП СУММЫ */}
          {windowSize.innerWidth > 600 && (
            <div className="save-money-form__block">
              <nav className="save-money-form__sum-type">
                <button
                  type="button"
                  className={cx('save-money-form__sum-type-btn', {
                    'save-money-form__sum-type-btn_active': sumType === ANY_SUM,
                  })}
                  onClick={() => onChangeSumType(ANY_SUM)}
                >
                  Любая сумма
                </button>

                <button
                  type="button"
                  className={cx('save-money-form__sum-type-btn', {
                    'save-money-form__sum-type-btn_active': sumType === FIXED_SUM,
                  })}
                  onClick={() => onChangeSumType(FIXED_SUM)}
                >
                  Фиксированная сумма
                </button>
              </nav>
            </div>
          )}
          {/*  ТИП СУММЫ */}

          {/* ВВОД СУММЫ */}
          <div className="save-money-form__block">
            <div
              className={cx('save-money-form__input-holder', {
                'save-money-form__input-holder_focused': sum !== '',
              })}
            >
              <NumberFormat
                className="save-money-form__input"
                value={sum}
                thousandSeparator=" "
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                isNumericString
                allowedDecimalSeparators={[',', '.']}
                type="tel"
                suffix=" ₽"
                required={sumType === FIXED_SUM}
                onValueChange={(values) => {
                  const { floatValue, value } = values;

                  if (floatValue < minSum / 100) {
                    onChangeSum(minSum / 100);
                  } else if (floatValue > maxSum / 100) {
                    onChangeSum(maxSum / 100);
                  } else if (value === '') {
                    onChangeSum('');
                  } else {
                    onChangeSum(value);
                  }
                }}
                isAllowed={(values) => {
                  const { value } = values;

                  if (value.charAt(0) === '0') {
                    return value.charAt(1) === '.';
                  }

                  return true;
                }}
              />

              <p className="save-money-form__label">
                {sumType === FIXED_SUM ? 'Сумма' : 'Сумма подсказки'}
              </p>
            </div>
          </div>
          {/* /ВВОД СУММЫ */}
        </div>

        <div className="save-money-form__section save-money-form__section_customer-info">
          {/* ИНФОМРАЦИЯ О ПЛАТЕЛЬЩИКЕ */}
          <div className="save-money-form__block">
            <p className="save-money-form__block-title">Попросить у плательщика:</p>

            <div className="save-money-form__checkbox-list">
              {/* ФИО ЧЕКБОКС */}
              <label className="save-money-form__checkbox-wrap">
                <button
                  className="save-money-form__checkbox"
                  role="checkbox"
                  type="button"
                  aria-label="ФИО"
                  aria-checked={isNameVisible}
                  onClick={onChangeIsNameVisible}
                />

                <div className="save-money-form__checkbox-text-wrap">
                  <p className="save-money-form__checkbox-text">ФИО</p>
                </div>
              </label>
              {/* /ФИО ЧЕКБОКС */}

              {/* ТЕЛЕФОН ЧЕКБОКС */}
              <label className="save-money-form__checkbox-wrap">
                <button
                  className="save-money-form__checkbox"
                  role="checkbox"
                  type="button"
                  aria-label="Телефон"
                  aria-checked={isPhoneVisible}
                  onClick={onChangeIsPhoneVisible}
                />

                <div className="save-money-form__checkbox-text-wrap">
                  <p className="save-money-form__checkbox-text">Телефон</p>
                </div>
              </label>
              {/* /ТЕЛЕФОН ЧЕКБОКС */}

              {/* EMAIL ЧЕКБОКС */}
              <label className="save-money-form__checkbox-wrap">
                <button
                  className="save-money-form__checkbox"
                  role="checkbox"
                  type="button"
                  aria-label="E-mail"
                  aria-checked={isEmailVisible}
                  onClick={onChangeIsEmailVisible}
                />

                <div className="save-money-form__checkbox-text-wrap">
                  <p className="save-money-form__checkbox-text">E-mail</p>
                </div>
              </label>
              {/* /EMAIL ЧЕКБОКС */}
            </div>
          </div>
          {/* /ИНФОМРАЦИЯ О ПЛАТЕЛЬЩИКЕ */}
        </div>

        <div className="save-money-form__section">
          <CreateLinkInput
            type={KOPIM}
            isEditing={isEditingSaveMoneyCase}
            setUrl={setUrl}
            url={url}
          />
        </div>

        {/* КНОПКА СОХРАНИТЬ */}
        <div className="save-money-form__button-wrap">
          <button
            className="save-money-form__button"
            type="button"
            onClick={createSaveMoneyClick}
            disabled={isSaveActionLoading || !isSaveButtonActive || !url || !daysLeft}
          >
            {isSaveActionLoading ? (
              <CircleLoader />
            ) : (
              <>{isEditingSaveMoneyCase ? 'Изменить ' : 'Опубликовать '} копилку</>
            )}
          </button>
        </div>
        {/* /КНОПКА СОХРАНИТЬ */}
      </div>
    </div>
  );
};

SaveMoneyForm.propTypes = {
  className: PropTypes.string,
  purpose: PropTypes.string,
  commentary: PropTypes.string,
  onChangePurpose: PropTypes.func,
  onChangeCommentary: PropTypes.func,
  isNameVisible: PropTypes.bool.isRequired,
  onChangeIsNameVisible: PropTypes.func.isRequired,
  isPhoneVisible: PropTypes.bool.isRequired,
  onChangeIsPhoneVisible: PropTypes.func.isRequired,
  isEmailVisible: PropTypes.bool.isRequired,
  onChangeIsEmailVisible: PropTypes.func.isRequired,
  saveMoneyColorTheme: PropTypes.number.isRequired,
  onChangeSaveMoneyColorTheme: PropTypes.func.isRequired,
  sumType: PropTypes.string.isRequired,
  onChangeSumType: PropTypes.func.isRequired,
  sum: PropTypes.string.isRequired,
  onChangeSum: PropTypes.func.isRequired,
  fixedSumTabClick: PropTypes.func.isRequired,
  anySumTabClick: PropTypes.func.isRequired,
  isSaveActionLoading: PropTypes.bool.isRequired,
  createSaveMoneyClick: PropTypes.func.isRequired,
  url: PropTypes.string,
  setUrl: PropTypes.func.isRequired,
  onChangeDaysLeft: PropTypes.func.isRequired,
  daysLeft: PropTypes.number,
  dateForSaveMoneyEditing: PropTypes.string,
  minSum: PropTypes.number,
  maxSum: PropTypes.number,
  isEditingSaveMoneyCase: PropTypes.bool,
  isUrlLinkError: PropTypes.bool,
  isUrlLinkLoading: PropTypes.bool,
};

SaveMoneyForm.defaultProps = {
  className: null,
  purpose: null,
  commentary: null,
  daysLeft: null,
  onChangePurpose: () => {},
  onChangeCommentary: () => {},
  dateForSaveMoneyEditing: null,
  minSum: 1,
  maxSum: Infinity,
  isEditingSaveMoneyCase: false,
  url: '',
  isUrlLinkError: false,
  isUrlLinkLoading: false,
};

export default SaveMoneyForm;

import React from 'react';
import { ReactComponent as SBPTransferLogo } from 'static/assets/sbp-transfer-logo.svg';
import './CardHeaderSBP.scss';

const CardHeaderSBP = () => (
  <div className="money-transfer-card-header-sbp">
    <SBPTransferLogo className="money-transfer-card-header-sbp__logo" />
  </div>
);

export default CardHeaderSBP;

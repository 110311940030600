import { fetchWrapper } from 'helpers';

import * as types from './action-types';

export const getTaxesToPay = (maxMonth, type, year) => (dispatch) => {
  let url = `${process.env.REACT_APP_API_URL}/client/taxes/payment`;

  if (maxMonth) {
    url += `&maxMonth=${maxMonth}`;
  }

  if (type) {
    url += `&type=${type}`;
  }

  if (year) {
    url += `&year=${year}`;
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((res) => {
        dispatch({
          type: types.GET_TAXES_TO_PAY_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_TAXES_TO_PAY_FAIL,
        });
        reject(err);
      });
  });
};

export const setTaxesListToPay = (taxesToPay) => ({
  type: types.SET_TAXES_TO_PAY_LIST,
  payload: {
    taxesToPay,
  },
});

export const payTaxBySzCard = (taxes) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/payment/taxes`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        taxes,
      }),
    })
      .then((res) => {
        dispatch({
          type: types.PAY_TAXES_BY_SZ_CARD_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.PAY_TAXES_BY_SZ_CARD_FAIL,
        });
        reject(err);
      });
  });
};

export const getTaxPaymentStatus = (type, id, timeout) => {
  const url = `${process.env.REACT_APP_API_URL}/payment/taxes/result/state`;
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetchWrapper(url).then(resolve).catch(reject);
    }, timeout);
  });
};

import { useCallback, useEffect, useRef, useState } from 'react';

export default function useTimer({ progressive = false, fnOfStart, fnOfStop } = {}) {
  const [startTime, setStartTime] = useState(null);
  const [finichTime, setFinichTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isTimer, setIsTimer] = useState(null);
  const setIntervalRef = useRef();

  const clearTimer = useCallback(() => {
    setCurrentTime(startTime);
    setIsTimer(null);
  }, [startTime]);

  const onTimer = useCallback(
    (initTime) => {
      clearTimer();
      if (Number.isInteger(initTime)) {
        if (fnOfStart) fnOfStart();
        setCurrentTime(initTime);
        setIsTimer(true);
        setStartTime(progressive ? 0 : initTime);
        setFinichTime(progressive ? initTime : 0);
        setIntervalRef.current = setInterval(
          () => setCurrentTime((time) => (progressive ? time + 1 : time - 1)),
          1000,
        );
      }
    }, // eslint-disable-next-line
    [fnOfStart, progressive],
  );

  useEffect(() => {
    if (finichTime === currentTime) {
      clearInterval(setIntervalRef.current);
      setIsTimer(false);
      if (fnOfStop) fnOfStop();
    }
  }, [currentTime, finichTime, fnOfStop, setIsTimer, setIntervalRef]);

  useEffect(
    () => () => {
      clearInterval(setIntervalRef.current);
      clearTimer();
    }, // eslint-disable-next-line
    [],
  );

  return [onTimer, currentTime, isTimer, clearTimer];
}

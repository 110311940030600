import { createSelector } from '@reduxjs/toolkit';
import cx from 'classnames';
import addDays from 'date-fns/addDays';
import { formatDateHuman, setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from 'helpers';
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';

import {
  hidePopupCalendar,
  restorePartnerPaymentsCurrentRangeTab,
  setPartnerPaymentsRange,
} from '../../actions';
import CalendarMobile from '../CalendarMobile';
import Header from '../Header';
import styles from './PopupCalendar.module.scss';

const PopupCalendar = () => {
  const dispatch = useDispatch();
  const paymentsRange = useSelector(
    createSelector(
      (store) => store.partners.paymentsRange,
      ({ startDate, endDate }) => ({
        startDate: startDate || addDays(setHoursAndMinutesToZero(new Date()), -6),
        endDate: endDate || setHoursAndMinutesTheEndOfDay(new Date()),
      }),
    ),
    shallowEqual,
  );

  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState('');
  const [dates, setDates] = useState([
    {
      startDate: paymentsRange.startDate,
      endDate: paymentsRange.endDate,
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setDatePickerValue(
      `${formatDateHuman(paymentsRange.startDate)} - ${formatDateHuman(paymentsRange.endDate)}`,
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  function onDateChange() {
    setDatePickerValue(
      `${formatDateHuman(dates[0].startDate)} - ${formatDateHuman(dates[0].endDate)}`,
    );

    if (isCalendarVisible) {
      setCalendarVisible(false);
    }
  }

  function toggleCalendar() {
    setCalendarVisible(!isCalendarVisible);
  }

  function applyDates() {
    dispatch(
      setPartnerPaymentsRange({
        startDate: dates[0].startDate,
        endDate: dates[0].endDate,
      }),
    );
    dispatch(hidePopupCalendar());
  }

  return createPortal(
    <div className={styles.popupCalendar}>
      <div className={styles.popupCalendarWrapper}>
        <Header
          onClose={() => {
            dispatch(restorePartnerPaymentsCurrentRangeTab());
            dispatch(hidePopupCalendar());
          }}
        />
        <div className={styles.popupCalendarContainer}>
          <div className={styles.popupCalendarTitle}>Свой диапазон</div>
          <div
            className={cx(
              styles.popupCalendarDatePicker,
              isCalendarVisible && styles.popupCalendarDatePickerActive,
            )}
            onClick={toggleCalendar}
          >
            <div className={styles.popupCalendarDatePickerTitle}>отображать за</div>
            <div className={styles.popupCalendarDatePickerPeriod}>{datePickerValue}</div>
            <IconCalendar className={styles.popupCalendarDatePickerCalendarIcon} />
          </div>

          {!isCalendarVisible && (
            <button className={styles.popupCalendarApplyButton} onClick={applyDates}>
              Применить
            </button>
          )}

          {isCalendarVisible && (
            <div className={styles.popupCalendarBlock}>
              <CalendarMobile dates={dates} setDates={setDates} onChange={onDateChange} />
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default PopupCalendar;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Icons
import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';

// Styles
import './ChangePasswordSuccess.scss';

const ChangePasswordSuccess = ({ className }) => (
  <div
    className={cx('change-password-success', {
      [className]: className,
    })}
  >
    <div className="change-password-success__icon-wrap">
      <IconCheck className="change-password-success__icon" />
    </div>
    <h2 className="change-password-success__title">Пароль изменен</h2>
    <div className="change-password-success__content">
      Вы установили новый пароль к личному кабинету
    </div>
  </div>
);

ChangePasswordSuccess.propTypes = {
  className: PropTypes.string,
};

ChangePasswordSuccess.defaultProps = {
  className: null,
};

export default ChangePasswordSuccess;

// Проверить, является ли номер банковской карты валидным
// number: string
export default function isBankCardNumberValid(number) {
  if (number.length === 13 || number.length >= 16) {
  } else {
    return false;
  }

  if (/[^0-9-\s]+/.test(number)) return false;
  if (!/^[2-6]/.test(number)) return false;
  let nCheck = 0,
    bEven = false;
  number = number.replace(/\D/g, '');
  for (let n = number.length - 1; n >= 0; n--) {
    let cDigit = number.charAt(n),
      nDigit = parseInt(cDigit, 10);
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
}

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import HintNotifications from 'modules/shared/components/HintNotifications/HintNotifications';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Misc
import createPagination from 'helpers/paginate';
import { HINT_TYPES } from 'constants/index';

// Styles
import './NotificationsList.scss';

const NotificationsList = ({
  className,
  messages,
  currentPage,
  numberOfPages,
  setPage,
  onUnreadItemClick,
  hasToInitMarkAllAsRead,
  hasToMarkAllAsReadCallback,
  isLoading,
}) => {
  const [readMessages, setReadMessages] = useState({});
  const [transitionDelays, setTransitionDelays] = useState(messages.map(() => 0));
  const [hasToMarkAllAsRead, setHasToMarkAllAsRead] = useState(false);
  const [currentNotification, setCurrentNotification] = useState({});
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isNotificationEntered, setIsNotificationEntered] = useState(false);

  /**
   * При hasToInitMarkAllAsRead = true
   * сообщения должны улететь по одному
   */
  useEffect(() => {
    if (hasToInitMarkAllAsRead) {
      setTransitionDelays(messages.map((_item, index) => (index + 1) * 150));
      setHasToMarkAllAsRead(true);
      if (hasToMarkAllAsReadCallback) {
        setTimeout(() => {
          hasToMarkAllAsReadCallback();
        }, (messages.length - 1) * 150 + 400);
      }
    }
  }, [hasToInitMarkAllAsRead, messages, hasToMarkAllAsReadCallback]);

  const handleItemClick = (event, item) => {
    /**
     * Остановить всплытие события обязательно
     * для того чтобы не сработал аутклик
     * так как мы не хотим закрывать дроп при клике на другое сообщение
     * мы хотим, чтобы в дроп пробросился новый контент
     */
    // event.stopPropagation();

    setIsNotificationOpened(true);

    setCurrentNotification(item);

    /**
     * Отмечаем уведомление как прочитанное
     * Отправляем запрос на сервер, но и отмечаем не дожидаясь ответа
     * в локальном стейте readMessages
     */
    if (!item.read && !readMessages[item.id] && typeof onUnreadItemClick === 'function') {
      onUnreadItemClick(item.id);
      setReadMessages((prevState) => ({
        ...prevState,
        [item.id]: true,
      }));
    }
  };

  /** Закрываем попап нотификейшна */
  const deactivateModal = () => {
    setIsNotificationEntered(false);

    setTimeout(() => {
      setIsNotificationOpened(false);
    }, 300);
  };

  return (
    <div className={cx('notifications-list', className)}>
      {/* LIST */}
      <div
        className={cx('notifications-list__itself', {
          'notifications-list__itself_loading': isLoading,
        })}
      >
        {isLoading && (
          <CircleLoader className="notifications-list__loading" width={50} height={50} />
        )}

        {messages &&
          Array.isArray(messages) &&
          messages.map((item, index) => (
            <React.Fragment key={item.id}>
              <button
                type="button"
                className={cx('notifications-list__item', {
                  'notifications-list__item_unread': !item.read && !readMessages[item.id],
                  'notifications-list__item_has-to-fly-away': hasToMarkAllAsRead,
                })}
                style={{
                  transitionDelay: hasToMarkAllAsRead ? `${transitionDelays[index]}ms` : '200ms',
                  transitionDuration: hasToMarkAllAsRead ? '400ms' : '200ms',
                }}
                onClick={(e) => handleItemClick(e, item)}
              >
                <p className="notifications-list__item-date">{item.dateTimeAdd}</p>
                <p className="notifications-list__item-title">{item.title}</p>

                {/* ЕСТЬ ТИП И ЕСТЬ ОПИСАНИЕ ДЛЯ ТИПА */}
                {item.type && typeof item.type === 'number' && HINT_TYPES[item.type] && (
                  <p className="notifications-list__item-type">{HINT_TYPES[item.type].title}</p>
                )}
                {/* /ЕСТЬ ТИП И ЕСТЬ ОПИСАНИЕ ДЛЯ ТИПА */}

                {/* ЕСТЬ ТИП И НЕТ ОПИСАНИЯ ДЛЯ ТИПА */}
                {item.type && typeof item.type === 'number' && !HINT_TYPES[item.type] && (
                  <p className="notifications-list__item-type">{HINT_TYPES.default.title}</p>
                )}
                {/* /ЕСТЬ ТИП И НЕТ ОПИСАНИЯ ДЛЯ ТИПА */}
              </button>
            </React.Fragment>
          ))}
      </div>
      {/* /LIST */}

      <HintNotifications
        isNotificationOpened={isNotificationOpened}
        isNotificationEntered={isNotificationEntered}
        notification={currentNotification}
        hintCloseHandler={deactivateModal}
        hintEnterHandler={() => setIsNotificationEntered(true)}
      >
        {/* eslint-disable react/no-danger */}
        <div
          className="notifications-list__item-text"
          dangerouslySetInnerHTML={{
            __html: currentNotification.message?.replace(
              /(https?:\/\/)([^ ]+)/g,
              '<a target="_blank" href="$&">$2</a>',
            ),
          }}
        />
        {/* eslint-enable react/no-danger */}
      </HintNotifications>

      {/* PAGINATION */}
      {numberOfPages > 1 && (
        <div className="notifications-list__pagination">
          <button
            className="notifications-list__pagination-item notifications-list__pagination-item_prev"
            type="button"
            onClick={() => {
              setPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
          >
            &lt; Назад
          </button>

          {createPagination(currentPage, numberOfPages, 2).map((item) => (
            <button
              key={item}
              className={cx('notifications-list__pagination-item', {
                'notifications-list__pagination-item_current': currentPage === item,
              })}
              type="button"
              onClick={() => {
                setPage(item);
              }}
              disabled={item === null || currentPage === item}
            >
              {item === null ? '...' : item}
            </button>
          ))}

          <button
            className="notifications-list__pagination-item notifications-list__pagination-item_next"
            type="button"
            onClick={() => {
              setPage(currentPage + 1);
            }}
            disabled={currentPage >= numberOfPages}
          >
            Дальше &gt;
          </button>
        </div>
      )}
      {/* /PAGINATION */}
    </div>
  );
};

NotificationsList.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      dateTimeAdd: PropTypes.string,
      id: PropTypes.number,
      message: PropTypes.string,
      title: PropTypes.string,
      read: PropTypes.bool,
    }),
  ).isRequired,
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onUnreadItemClick: PropTypes.func,
  hasToInitMarkAllAsRead: PropTypes.bool,
  hasToMarkAllAsReadCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

NotificationsList.defaultProps = {
  className: null,
  onUnreadItemClick: null,
  hasToInitMarkAllAsRead: false,
  hasToMarkAllAsReadCallback: null,
  isLoading: false,
};

export default NotificationsList;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import cx from 'classnames';

import SaveMoneyCreation from 'modules/cashbox/components/SaveMoneyCreation/SaveMoneyCreation';
import SaveMoneyList from 'modules/cashbox/components/SaveMoneyList/SaveMoneyList';
import Accounting from 'modules/profileLegasy/components/Accounting/Accounting';
import AddNewCard from 'modules/profileLegasy/components/AddNewCard/AddNewCard';
import BillCreation from 'modules/profileLegasy/components/BillCreation/BillCreation';
import CashBox from 'modules/cashbox/components/CashBox/CashBox';
import Acquiring from 'modules/acquiring/components/Acquiring/Acquiring';
import MoneyTransfer from 'modules/money-transfer/components/MoneyTransfer/MoneyTransfer';
import MoneyRequest from 'modules/profileLegasy/components/MoneyRequest/MoneyRequest';
import AcquiringSettingsWrap from 'modules/acquiring/components/AcquiringSettingsWrap/AcquiringSettingsWrap';
import ShowCaseCreation from 'modules/cashbox/components/ShowCaseCreation/ShowCaseCreation';
// import CompanyBill from 'modules/profileLegasy/components/CompanyBill/CompanyBill';
// import CreateCompanyBillPage from 'modules/profileLegasy/components/CreateCompanyBillPage/CreateCompanyBillPage';
import GetMoney from 'modules/profileLegasy/components/GetMoney/GetMoney';
import History from 'modules/profileLegasy/components/History/History';
import IncomeRegistration from 'modules/profileLegasy/components/IncomeRegistration/IncomeRegistration';
import Notifications from 'modules/profileLegasy/components/Notifications/Notifications';
import TaxPaymentContainer from 'modules/tax/components/TaxPaymentContainer/TaxPaymentContainer';
import TaxListPage from 'modules/tax/components/TaxListPage/TaxListPage';

import Partner from 'modules/partners/components/Partner';
import WalletClosing from 'modules/wallet-closing/components/WalletClosing';

import { ErrorTemplate } from '../ErrorTemplate';
import { ProfileTemplate } from './templates';

// Styles
import './style.scss';
import { DevWidget } from '../../../../modules/profile/widgets/devWidget';

const Lk = ({ isMenuOpenWithTransition, hasLowZIndex }) => {
  return (
    <div
      className={cx('lk', {
        'lk_menu-open': isMenuOpenWithTransition,
        'lk_low-zindex': hasLowZIndex,
      })}
      data-testid="lk-component"
    >
      <Switch>
        <Route exact path="/lk" component={GetMoney} />
        {/* редирект на /lk */}
        <Route path="/lk/bill-creation" component={BillCreation} />
        <Route path="/lk/kassa" component={CashBox} />
        <Route path="/lk/acquiring" component={Acquiring} />
        <Route path="/lk/money-transfer" component={MoneyTransfer} />
        <Route path="/lk/money-request" component={MoneyRequest} />
        {/* редирект на /lk */}
        <Route path="/lk/showcase-creation" component={ShowCaseCreation} />
        <Route path="/lk/acquiring-settings" component={AcquiringSettingsWrap} />
        <Route path="/lk/accounting" component={Accounting} />
        <Route path="/lk/notifications" component={Notifications} />
        <Route path="/lk/history" component={History} />
        {/* редирект на /lk */}
        <Route path="/lk/income-registration" component={IncomeRegistration} />
        {/* редирект на /lk/tax-list */}
        <Route path="/lk/tax-payment" component={TaxPaymentContainer} />
        <Route path="/lk/tax-list" component={TaxListPage} />
        <Route path="/lk/kopim" component={SaveMoneyList} />
        <Route path="/lk/save-money-creation" component={SaveMoneyCreation} />
        <Route path="/lk/profile-settings" component={ProfileTemplate} />
        <Route path="/lk/add-new-card" component={AddNewCard} />
        {/* <Route path="/lk/bill-for-company" component={CompanyBill} /> */}
        {/* <Route path="/lk/create-bill-for-company" component={CreateCompanyBillPage} /> */}

        {/* не работает */}
        <Route path="/lk/partner" component={Partner} />
        <Route path="/lk/close-wallet" component={WalletClosing} />

        <Route path="/lk/dev-settings" component={DevWidget} />
        <Route>
          <ErrorTemplate />
        </Route>
      </Switch>
    </div>
  );
};

Lk.propTypes = {
  isMenuOpenWithTransition: PropTypes.bool.isRequired,
  hasLowZIndex: PropTypes.bool,
};

Lk.defaultProps = {
  hasLowZIndex: false,
};

const mapStateToProps = (state) => ({
  isMenuOpenWithTransition: state.auth.isMenuOpenWithTransition,
});

export const LkTemplate = connect(mapStateToProps)(Lk);

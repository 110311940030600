import { getLocalStorageValue, setLocalStorageValue } from '../hooks/useLocalStorage';

const storageNameSBPEnabled = 'SBPEnabled';
const defaultSBPEnabled =
  process.env.REACT_APP_ENABLE_SBP !== undefined
    ? process.env.REACT_APP_ENABLE_SBP === 'true'
    : true;

export const checkSBPEnabled = () => {
  return getLocalStorageValue(storageNameSBPEnabled, defaultSBPEnabled);
};

export const setSBPEnabled = (enabled) => {
  return setLocalStorageValue(storageNameSBPEnabled, enabled);
};

if (defaultSBPEnabled) {
  setSBPEnabled(true);
}

/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLOR, SIZE, WEIGHT } from 'tools/constants';
import { PTClassNames, PTColor, PTCWeight } from 'tools/types/propTypes';

import { LineText } from '../atoms';

export const TitleText = ({ children, size, color, weight, className }) => (
  <LineTextStyled
    color={COLOR[color]}
    weight={WEIGHT[weight]}
    size={SIZE[size]}
    className={className}
  >
    {children}
  </LineTextStyled>
);

const LineTextStyled = styled(LineText)`
  font-size: ${({ size }) =>
    size === SIZE.hr
      ? '32px'
      : size === SIZE.lg
      ? '24px'
      : size === SIZE.md
      ? '20px'
      : size === SIZE.sm
      ? '18px'
      : size === SIZE.tn
      ? '16px'
      : size === SIZE.htn
      ? '14px'
      : '20px'};
  line-height: ${({ size }) =>
    size === SIZE.hr
      ? '40px'
      : size === SIZE.lg
      ? '32px'
      : size === SIZE.md
      ? '28px'
      : size === SIZE.sm
      ? '24px'
      : size === SIZE.th
      ? '24px'
      : size === SIZE.hth
      ? '18px'
      : '28px'};
`;

TitleText.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZE)),
  color: PTColor,
  weight: PTCWeight,
  className: PTClassNames,
};

TitleText.defaultProps = {
  color: COLOR['dark-slate-blue'],
  size: SIZE.md,
  weight: WEIGHT['circe-bold'],
  className: '',
};

/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import PopupPageWrap from 'modules/profileLegasy/components/PopupPageWrap/PopupPageWrap';

import { useDocumentById } from '../../models/documentList';
import { DOC_STATUSES } from '../../utils/constants';

import { ChangeDocStatusFormContainer, DocumentInfoDetailsContainer } from './containers';
import { Plate } from './ui';

export const DocumentInfoPopupWidget = ({ docId, isOpen, onOpen }) => {
  const { documentName, state, platform } = useDocumentById(docId);

  return (
    <PopupPageWrap
      textTitle={documentName}
      iconTitle={
        <Plate
          active={state === 'SEND_TO_SIGN' || state === 'DRAFT'}
          resolved={state === 'SIGNED'}
          rejected={
            state === 'REJECTED' ||
            state === 'EXPIRED' ||
            state === 'BLOCKED' ||
            state === 'TERMINATED'
          }
          text={DOC_STATUSES.get(state) || ''}
        />
      }
      mainContent={
        <WrapperStyled>
          <DocumentInfoDetailsContainer docId={docId} />
          {state === 'SEND_TO_SIGN' && (
            <ChangeDocStatusFormContainerStyled platform={platform} docId={docId} />
          )}
        </WrapperStyled>
      }
      isMainContentFullWidth
      isOpen={isOpen}
      onOpen={onOpen}
    />
  );
};

DocumentInfoPopupWidget.propTypes = {
  docId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
};

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
  & > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const ChangeDocStatusFormContainerStyled = styled(ChangeDocStatusFormContainer)`
  margin-top: 40px;
`;

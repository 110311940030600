import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { hideCheck } from '../../actions';
import Check from '../Check';
import { getCheckPayment } from '../../selectors';
import styles from './PartnerPaymentsCheck.module.scss';

const PartnerPaymentsCheck = ({ payment }) => {
  // function cancelCheck() {}
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(hideCheck());
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <div className={styles.partnerPaymentsCheck}>
      <div className={styles.partnerPaymentsCheckContainer}>
        <Check
          id={payment.id}
          operationTime={payment.operationTime}
          firstName={payment.firstName}
          lastName={payment.lastName}
          middleName={payment.middleName}
          inn={payment.inn}
          services={payment.services}
          totalSum={payment.totalAmount}
          link={payment.link}
        />
      </div>
      {/*TODO(WLT-123) Починить кнопку */}
      {/*<div className={styles.partnerPaymentsCheckButton}>*/}
      {/*  <button onClick={cancelCheck}>Аннулировать чек</button>*/}
      {/*</div>*/}
    </div>
  );
};

PartnerPaymentsCheck.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string.isRequired,
    operationTime: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
        receiptId: PropTypes.string,
      }),
    ).isRequired,
    link: PropTypes.string.isRequired,
    inn: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  payment: getCheckPayment()(state),
});

export default connect(mapStateToProps)(PartnerPaymentsCheck);

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useThrottle from 'hooks/useThrottle';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Footer from 'modules/authLegasy/components/Footer/Footer';
import Warning from 'modules/profileLegasy/components/Warning/Warning';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import './PopupPageWrap.scss';

const PopupPageWrap = ({
  isOpen,
  onOpen,
  redirectPage,
  textTitle,
  iconTitle,
  mainContent,
  hints,
  isStandardTitle,
  title,
  needFooter,
  needSzCondition,
  isUserNoSz,
  isMainContentFullWidth,
  className,
  customWarning,
  noHeader,
}) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(isOpen);
  const [isRunAnimation, setIsRunAnimation] = useState(false);
  const isLink = useMemo(() => !!redirectPage, [redirectPage]);
  const throttle = useThrottle(50);

  const noSzWarningText = (
    <div className="warning__text">
      Для использования сервиса “Счета для компаний” необходимо подтвердить статус «Самозанятого» в
      разделе
      <Link to="/self-employment-from-lk" className="warning__link">
        Мой статус
      </Link>
    </div>
  );

  useEffect(() => {
    setIsRendered(true);
    throttle(() => setIsRunAnimation(true));
  }, [throttle]);

  return (isLink && isRendered) || isOpen
    ? createPortal(
        <div
          className={cx('popup-page-wrap', {
            [className]: className,
          })}
          data-testid="popup-page-wrap"
        >
          <CSSTransition
            classNames="animation-from-top-to-bottom"
            in={needSzCondition && isUserNoSz && windowSize.innerWidth > 600}
            timeout={{ enter: 600, exit: 200 }}
            unmountOnExit
          >
            <Warning isStatic customText={noSzWarningText} />
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-top-to-bottom"
            in={customWarning && !isUserNoSz}
            timeout={{ enter: 600, exit: 200 }}
            unmountOnExit
          >
            <Warning isStatic customText={<p className="warning__text">{customWarning}</p>} />
          </CSSTransition>

          <div className="popup-page-wrap__container">
            {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered && windowSize.innerWidth > 980}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              <div
                className={cx('popup-page-wrap__header-wrap', {
                  'popup-page-wrap__header-wrap_no-header': noHeader,
                })}
              >
                {!noHeader && <PopupHeaderWithBalance className="popup-page-wrap__header" />}

                {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
                <PopupCloseWithText
                  isLink={isLink}
                  closePopupHandler={() => {
                    if (!redirectPage) {
                      onOpen();
                      setIsRendered((state) => !state);
                    }
                  }}
                  href={redirectPage || null} // '/lk'
                />
                {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              </div>
            </CSSTransition>
            {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

            {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRunAnimation && windowSize.innerWidth <= 980}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              <ResponsiveHeader
                isCloseButtonLink={isLink}
                closePopupHandler={() => {
                  if (!redirectPage) {
                    onOpen();
                    setIsRendered((state) => !state);
                  }
                }}
                href={redirectPage || null} // '/lk'
                className="popup-page-wrap__header"
              />
            </CSSTransition>
            {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

            {/* ТЕКСТОВЫЙ ЗАГОЛОВОК */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRunAnimation && isStandardTitle && Boolean(textTitle)}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="popup-page-wrap__title-wrap">
                <div className="popup-page-wrap__title-container">
                  <h1 className="popup-page-wrap__title">{textTitle}</h1>
                  <div className="popup-page-wrap__icon-wrap">{iconTitle}</div>
                </div>

                <CSSTransition
                  classNames="animation-from-top-to-bottom"
                  in={isUserNoSz && windowSize.innerWidth <= 600}
                  timeout={{ enter: 600, exit: 200 }}
                  unmountOnExit
                >
                  <Warning isStatic customText={noSzWarningText} />
                </CSSTransition>
              </div>
            </CSSTransition>
            {/* /ТЕКСТОВЫЙ ЗАГОЛОВОК */}

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRunAnimation && !isStandardTitle && Boolean(title)}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <>{title}</>
            </CSSTransition>

            <div
              className={cx('popup-page-wrap__content', {
                'popup-page-wrap__content_full-width': !hints,
              })}
              data-testid="popup-page-wrap-content"
            >
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRunAnimation}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div
                  className={cx('popup-page-wrap__main', {
                    'popup-page-wrap__main_full-width': isMainContentFullWidth,
                  })}
                  data-testid="popup-page-wrap-main"
                >
                  {mainContent}
                </div>
              </CSSTransition>

              {/* ПОДСКАЗКИ */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRunAnimation}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <div className="popup-page-wrap__hints">{hints}</div>
              </CSSTransition>
              {/* /ПОДСКАЗКИ */}
            </div>
          </div>

          {needFooter && <Footer />}
        </div>,
        document.body,
      )
    : null;
};

PopupPageWrap.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  textTitle: PropTypes.string,
  iconTitle: PropTypes.element,
  title: PropTypes.shape({}),
  mainContent: PropTypes.shape({}),
  hints: PropTypes.shape({}),
  isStandardTitle: PropTypes.bool,
  needFooter: PropTypes.bool,
  needSzCondition: PropTypes.bool,
  isUserNoSz: PropTypes.bool,
  isMainContentFullWidth: PropTypes.bool,
  className: PropTypes.string,
  redirectPage: PropTypes.string,
  customWarning: PropTypes.string,
  noHeader: PropTypes.bool,
};

PopupPageWrap.defaultProps = {
  isOpen: false,
  onOpen: (f) => f,
  textTitle: null,
  iconTitle: null,
  title: null,
  mainContent: null,
  hints: null,
  isStandardTitle: true,
  needFooter: false,
  needSzCondition: false,
  isUserNoSz: false,
  isMainContentFullWidth: false,
  className: null,
  redirectPage: null,
  customWarning: null,
  noHeader: false,
};

export default PopupPageWrap;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { WindowAlert } from '../../molecules';
import { ParagraphText } from '../../../../ParagraphText';

export const WindowUniversal = ({ header, text, buttonText, onClose }) => (
  <WindowAlert header={header} buttonText={buttonText} onClose={onClose} onClick={onClose}>
    <ParagraphTextStyled>{text}</ParagraphTextStyled>
  </WindowAlert>
);

WindowUniversal.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ParagraphTextStyled = styled(ParagraphText)`
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  max-width: 435px;
`;

import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Footer from 'modules/authLegasy/components/Footer/Footer';
import { getAllShowCase } from 'modules/cashbox/actions';
import DeleteItemPopup from 'modules/cashbox/components/DeleteItemPopup/DeleteItemPopup';
import ShowCaseItemSum from 'modules/cashbox/components/ShowCaseItemSum/ShowCaseItemSum';
import Warning from 'modules/profileLegasy/components/Warning/Warning';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CopiedUrlHover from 'modules/shared/components/CopiedUrlHover/CopiedUrlHover';
import DashedCreateNewItemButton from 'modules/shared/components/DashedCreateNewItemButton/DashedCreateNewItemButton';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import ProgressBar from 'modules/shared/components/ProgressBar/ProgressBar';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';

import { HINT_TIMEOUTS, PARTLY_YES, YES } from 'constants/index';
import formatNumber from 'helpers/formatNumber';
import { formatDateHuman } from 'helpers/index';

import { ReactComponent as IconLine } from 'static/assets/arrow-line.svg';
import { ReactComponent as IconBasket } from 'static/assets/icon-basket.svg';
import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

import './CashBox.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';
import HintCommissionAndLimits from '../../../profileLegasy/components/Hint/HintCommissionAndLimits';

const CashBox = ({ userTaxStatus, szStatusFromDb, dispatch, showCases, user }) => {
  const [isRendered, setIsRendered] = useState(false);
  const noSzCondition =
    !userTaxStatus || (userTaxStatus !== YES && !szStatusFromDb && szStatusFromDb !== PARTLY_YES);
  const windowSize = useWindowSize();

  const [isShowCasesLoading, setIsShowCasesLoading] = useState(false);
  const [redirectOnLk, setRedirectOnLk] = useState(false);
  const [isDeleteShowCasePopupOpen, setIsDeleteShowCasePopupOpen] = useState(false);
  const [showCaseNameForPopup, setShowCaseNameForPopup] = useState(null);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [currentOpenPopupId, setCurrentOpenPopupId] = useState(null);
  const [currentOpenItemId, setCurrentOpenItemId] = useState(null);
  const [showCasesCount, setShowCasesCount] = useState(0);

  /** Раскрытие витрины по клику */
  const itemClick = (event, id) => {
    if (
      event.target.name === 'deleteButton' ||
      event.target.name === 'editButton' ||
      event.target.name === 'copyButton'
    ) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setCurrentOpenItemId(currentOpenItemId === id ? null : id);
    }
  };

  /** Флаг, переключаем если кликнули на url */
  const [isCopied, setIsCopied] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState('');

  /** Клик на копирование ссылки витрины */
  const copyClick = (urlText) => {
    setCopiedUrl(urlText);
    setIsCopied(true);
    setCurrentOpenItemId(null);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  /** Закрытие попапа-подсказки */
  const closeHintHandler = () => {
    closeExplicitly();
    setCurrentOpenPopupId(null);
  };

  /** Флаг для анимации */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Берем с сервера витрины */
  useEffect(() => {
    setIsShowCasesLoading(true);

    dispatch(getAllShowCase())
      .then((res) => {
        setIsShowCasesLoading(false);
        setShowCasesCount(res && res.data && res.data.length);
      })
      .catch(() => {
        setIsShowCasesLoading(false);
      });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const hints = [
    {
      id: 1,
      title: 'Как это работает?',
      titleForPopup: 'Как это работает?',
      action: () => {
        setCurrentOpenPopupId(1);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 1 && isDropOpen,
      popup: (
        <div className="hint__text-wrap">
          <div className="hint__text">
            <p>
              Чтобы быстро выставить счет создайте витрину — быструю ссылку на определенный товар
              или услугу. Разместите ссылку на своем сайте или отправьте клиенту лично. Когда клиент
              перейдет по ссылке, он сможет сразу оплатить счет и скачать чек.
            </p>

            <p>
              После оплаты счета и зачисления денег на ваш кошелек, мы сообщим об этом по почте и в
              пуш-уведомлении. Чеки обо всех оплаченных счетах будут храниться в разделе
              <Link
                to="/lk/accounting"
                className="hint__text-link"
                onClick={() => window.localStorage.setItem('accountingTab', '1')}
              >
                «Отчетность → Сделки»
              </Link>
              .
            </p>

            <p>
              Вы можете создать до 10 витрин. К примеру, выберите свои самые популярные услуги,
              создайте под них витрины и автоматически выставляйте счета клиентам. Все витрины и
              информация по заработку на них буду храниться в разделе
              <button type="button" className="hint__text-link" onClick={closeHintHandler}>
                «Касса»
              </button>
              .
            </p>
          </div>
        </div>
      ),
      dropEl,
    },
    {
      id: 2,
      title: 'Что такое витрина?',
      titleForPopup: 'Что такое витрина?',
      action: () => {
        setCurrentOpenPopupId(2);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 2 && isDropOpen,
      popup: (
        <div className="hint__text-wrap">
          <div className="hint__text">
            <p>Витрина — быстрая ссылка на страницу с определенным товаром или услугой.</p>
          </div>
        </div>
      ),
      dropEl,
    },
    {
      id: 3,
      title: 'Комиссия и лимиты',
      titleForPopup: 'Комиссия и лимиты',
      action: () => {
        setCurrentOpenPopupId(3);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 3 && isDropOpen,
      popup: <HintCommissionAndLimits />,
      dropEl,
    },
    // {
    //   id: 4,
    //   title: 'Эквайринг для самозанятого',
    //   titleForPopup: 'Эквайринг для самозанятого',
    //   action: () => {
    //     setCurrentOpenPopupId(4);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 4 && isDropOpen,
    //   popup: (
    //     <div className="hint__text-wrap">
    //       <div className="hint__text">
    //         <p>
    //           У вас есть возможность принимайть платежи от клиентов прямо на сайте с карт и Apple
    //           Pay / Google Pay / Samsung Pay. Автоматически выдаём чеки покупателям, деньги сразу
    //           поступают на ваш кошелек. Автоматически регистрируем доход в ФНС и расчитываем налоги
    //           по ним.
    //         </p>
    //       </div>
    //     </div>
    //   ),
    //   dropEl,
    // },
  ];

  /** Редирект на ЛК */
  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  return createPortal(
    <>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <div className="cash-box">
        <CSSTransition
          classNames="animation-from-top-to-bottom"
          in={noSzCondition && windowSize.innerWidth > 600}
          timeout={{ enter: 600, exit: 200 }}
          unmountOnExit
        >
          <Warning isStatic />
        </CSSTransition>

        <div className="cash-box__container">
          {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth > 980}
            timeout={{ enter: 100, exit: 100 }}
            unmountOnExit
          >
            <div className="cash-box__header-wrap">
              <PopupHeaderWithBalance className="cash-box__header" />

              {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              <PopupCloseWithText closePopupHandler={() => setRedirectOnLk(true)} />
              {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            </div>
          </CSSTransition>
          {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

          {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={{ enter: 100, exit: 100 }}
            unmountOnExit
          >
            <ResponsiveHeader
              closePopupHandler={() => setRedirectOnLk(true)}
              className="cash-box__header"
            />
          </CSSTransition>
          {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

          {/* ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={{
              enter: 150,
              exit: 100,
            }}
            unmountOnExit
          >
            <div className="cash-box__title-wrap">
              <div className="cash-box__title-container">
                <h1 className="cash-box__title">Касса</h1>

                <div className="cash-box__showcase-count-wrap">
                  <p className="cash-box__showcase-count-text">Создано витрин: </p>

                  <p className="cash-box__showcase-count">{showCasesCount} из 10</p>
                </div>
              </div>
              <ProgressBar progress={(showCasesCount / 10) * 100} />

              <CSSTransition
                classNames="animation-from-top-to-bottom"
                in={noSzCondition && windowSize.innerWidth <= 600}
                timeout={{ enter: 600, exit: 200 }}
                unmountOnExit
              >
                <Warning isStatic />
              </CSSTransition>
            </div>
          </CSSTransition>
          {/* /ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}

          <div className="cash-box__content">
            {/* ВИТРИНЫ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={{ enter: 200, exit: 100 }}
              unmountOnExit
            >
              <div className="cash-box__main">
                {isShowCasesLoading ? (
                  <div className="cash-box__loader">
                    <CircleLoader />
                  </div>
                ) : (
                  <>
                    {/* КНОПКА ДОБАВЛЕНИЯ НОВОЙ ВИТРИНЫ */}
                    {showCases && showCases.length < 10 && (
                      <DashedCreateNewItemButton
                        title="+ Создать новую витрину"
                        isLink={!noSzCondition}
                        disabled={noSzCondition}
                        href="/lk/showcase-creation"
                      />
                    )}
                    {/* /КНОПКА ДОБАВЛЕНИЯ НОВОЙ ВИТРИНЫ */}

                    {/* СООБЩЕНИЕ, ЧТО У ЮЗЕРА МАКС. КОЛ-ВО ВИТРИН */}
                    {showCases && showCases.length === 10 && (
                      <div className="cash-box__create-button">
                        Вы создали максимальное количество витрин. Удалите или отредактируйте
                        существующую витрину.
                      </div>
                    )}
                    {/* /СООБЩЕНИЕ, ЧТО У ЮЗЕРА МАКС. КОЛ-ВО ВИТРИН */}

                    {/* СПИСОК ВИТРИН */}
                    {showCases && showCases.length >= 1 && (
                      <div className="cash-box__list">
                        {showCases.map((item) => (
                          <button
                            className={cx('cash-box__item', {
                              'cash-box__item_open': currentOpenItemId === item.uid,
                            })}
                            key={item.uid}
                            type="button"
                            onClick={(event) => itemClick(event, item.uid)}
                            disabled={
                              !userTaxStatus ||
                              (userTaxStatus !== YES &&
                                !szStatusFromDb &&
                                szStatusFromDb !== PARTLY_YES)
                            }
                          >
                            <div className="cash-box__item-top">
                              <div className="cash-box__item-title-wrap">
                                <p className="cash-box__item-label">Название витрины</p>

                                {windowSize.innerWidth > 980 ? (
                                  <Link
                                    to="/lk/showcase-creation"
                                    className="cash-box__item-title"
                                    onClick={() =>
                                      window.localStorage.setItem('showCaseId', item.uid)
                                    }
                                  >
                                    {item.name.length >= 70
                                      ? `${item.name.slice(0, 70)}...`
                                      : item.name}
                                  </Link>
                                ) : (
                                  <div className="cash-box__item-title">
                                    {item.name.length >= 70
                                      ? `${item.name.slice(0, 70)}...`
                                      : item.name}
                                  </div>
                                )}
                              </div>

                              {windowSize.innerWidth > 980 && (
                                <div className="cash-box__item-top-right-part">
                                  <ShowCaseItemSum
                                    sum={item.sum}
                                    sumHint={item.sumHint}
                                    className="cash-box__item-sum-wrap"
                                  />

                                  <div className="cash-box__item-controls">
                                    {/* КОПИРОВАТЬ ССЫЛКУ */}
                                    <CopyToClipboard
                                      text={`${window.location.origin}/kassa/${item.uid}`}
                                      onCopy={() => copyClick(item.uid)}
                                      className="cash-box__item-control"
                                      name="copyButton"
                                    >
                                      <a
                                        href="/"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <IconCopy className="cash-box__item-control-icon cash-box__item-control-icon_copy" />
                                      </a>
                                    </CopyToClipboard>
                                    {/* /КОПИРОВАТЬ ССЫЛКУ */}

                                    {/* РЕДАКТИРОВАТЬ ВИТРИНУ */}
                                    <Link
                                      className="cash-box__item-control"
                                      to="/lk/showcase-creation"
                                      onClick={() =>
                                        window.localStorage.setItem('showCaseId', item.uid)
                                      }
                                      name="editButton"
                                    >
                                      <IconEdit className="cash-box__item-control-icon cash-box__item-control-icon_edit" />
                                    </Link>
                                    {/* /РЕДАКТИРОВАТЬ ВИТРИНУ */}

                                    {/* УДАЛИТЬ ВИТРИНУ */}
                                    <a
                                      href="/"
                                      className="cash-box__item-control"
                                      role="button"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setIsDeleteShowCasePopupOpen(true);
                                        setShowCaseNameForPopup(item.name);
                                        setCurrentOpenItemId(item.uid);
                                      }}
                                      name="deleteButton"
                                    >
                                      <IconBasket className="cash-box__item-control-icon cash-box__item-control-icon_basket" />
                                    </a>
                                    {/* /УДАЛИТЬ ВИТРИНУ */}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="cash-box__item-bottom">
                              {windowSize.innerWidth <= 980 && (
                                <ShowCaseItemSum
                                  sum={item.sum}
                                  sumHint={item.sumHint}
                                  className="cash-box__item-sum-wrap"
                                />
                              )}

                              <div className="cash-box__item-details">
                                {item.dateAdd && (
                                  <div className="cash-box__item-details-line">
                                    <p className="cash-box__item-details-line-key">
                                      Дата создания витрины:
                                    </p>
                                    <p className="cash-box__item-details-line-desc">
                                      {formatDateHuman(new Date(item.dateAdd))}
                                    </p>
                                  </div>
                                )}

                                <div className="cash-box__item-details-line">
                                  <p className="cash-box__item-details-line-key">
                                    Количество платежей:
                                  </p>
                                  <p className="cash-box__item-details-line-desc">
                                    {item.paymentsAmount}
                                  </p>
                                </div>

                                {item.sumOutLastPayment.toString() !== '0' && (
                                  <div className="cash-box__item-details-line">
                                    <p className="cash-box__item-details-line-key">
                                      Сумма последнего платежа:
                                    </p>
                                    <p className="cash-box__item-details-line-desc">
                                      {(item.sumOutLastPayment / 100).toString().indexOf('.') > -1
                                        ? formatNumber(item.sumOutLastPayment / 100).replace(
                                            '.',
                                            ',',
                                          )
                                        : `${formatNumber(item.sumOutLastPayment / 100)},00`}{' '}
                                      ₽
                                    </p>
                                  </div>
                                )}

                                {item.dateSuccessLastPayment && (
                                  <div className="cash-box__item-details-line">
                                    <p className="cash-box__item-details-line-key">
                                      Дата последнего платежа:
                                    </p>
                                    <p className="cash-box__item-details-line-desc">
                                      {formatDateHuman(new Date(item.dateSuccessLastPayment))}
                                    </p>
                                  </div>
                                )}
                              </div>

                              {/* ИТОГО ЗАРАБОТАНО НА ВИТРИНЕ */}
                              <div className="cash-box__item-total-income-wrap">
                                <p className="cash-box__item-label">Итого заработано на витрине:</p>

                                {item.payedSum ? (
                                  <div className="cash-box__item-total-income">
                                    {(item.payedSum / 100).toString().indexOf('.') > -1
                                      ? formatNumber(item.payedSum / 100).replace('.', ',')
                                      : `${formatNumber(item.payedSum / 100)},00`}{' '}
                                    ₽
                                  </div>
                                ) : (
                                  <div className="cash-box__item-total-income">0,00 ₽</div>
                                )}
                              </div>
                              {/* /ИТОГО ЗАРАБОТАНО НА ВИТРИНЕ */}

                              {windowSize.innerWidth <= 980 && (
                                <div className="cash-box__item-controls-wrap">
                                  <div className="cash-box__item-controls">
                                    {/* КОПИРОВАТЬ ССЫЛКУ */}
                                    <CopyToClipboard
                                      text={`${window.location.origin}/kassa/${item.uid}`}
                                      onCopy={() => copyClick(item.uid)}
                                      className="cash-box__item-control"
                                      name="copyButton"
                                    >
                                      <a
                                        href="/"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <IconCopy className="cash-box__item-control-icon cash-box__item-control-icon_copy" />
                                      </a>
                                    </CopyToClipboard>
                                    {/* /КОПИРОВАТЬ ССЫЛКУ */}

                                    {/* РЕДАКТИРОВАТЬ ВИТРИНУ */}
                                    <Link
                                      to="/lk/showcase-creation"
                                      onClick={() =>
                                        window.localStorage.setItem('showCaseId', item.uid)
                                      }
                                      className="cash-box__item-control"
                                      name="editButton"
                                    >
                                      <IconEdit className="cash-box__item-control-icon cash-box__item-control-icon_edit" />
                                    </Link>
                                    {/* /РЕДАКТИРОВАТЬ ВИТРИНУ */}

                                    {/* УДАЛИТЬ ВИТРИНУ */}
                                    <a
                                      href="/"
                                      className="cash-box__item-control"
                                      role="button"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setIsDeleteShowCasePopupOpen(true);
                                        setShowCaseNameForPopup(item.name);
                                        setCurrentOpenItemId(item.uid);
                                      }}
                                      name="deleteButton"
                                    >
                                      <IconBasket className="cash-box__item-control-icon cash-box__item-control-icon_basket" />
                                    </a>
                                    {/* /УДАЛИТЬ ВИТРИНУ */}
                                  </div>

                                  <CopiedUrlHover
                                    isVisible={
                                      isCopied &&
                                      copiedUrl === item.uid &&
                                      windowSize.innerWidth < 980
                                    }
                                    className="cash-box__copied"
                                  />
                                </div>
                              )}
                            </div>

                            <CopiedUrlHover
                              isVisible={
                                isCopied && copiedUrl === item.uid && windowSize.innerWidth > 980
                              }
                            />
                          </button>
                        ))}
                      </div>
                    )}
                    {/* /СПИСОК ВИТРИН */}

                    {/* СООБЩЕНИЕ, ЧТО ВИТРИН НЕТ */}
                    {showCases && showCases.length === 0 && (
                      <div className="cash-box__no-showcase">
                        <div className="cash-box__no-showcase-message-container">
                          <IconLine className="cash-box__no-showcase-line" />

                          <NoOperationsMade
                            text="Вы пока не создали ни одной витрины. Вы можете создать до 10 витрин, используйте их с умом. И помните, сервис Касса - это верный путь к первому миллиону!"
                            className="cash-box__no-showcase-message"
                          />
                        </div>
                      </div>
                    )}
                    {/* /СООБЩЕНИЕ, ЧТО ВИТРИН НЕТ */}
                  </>
                )}
              </div>
            </CSSTransition>
            {/* /ВИТРИНЫ */}

            {/* ПОДСКАЗКИ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={{ enter: 250, exit: 100 }}
              unmountOnExit
            >
              <HintsList
                hints={hints}
                className="cash-box__hints"
                closeHintHandler={closeExplicitly}
              />
            </CSSTransition>
            {/* /ПОДСКАЗКИ */}
          </div>
        </div>

        <Footer />

        {/* ПОПАП - УДАЛИТЬ ВИТРИНУ */}
        <CSSTransition in={isDeleteShowCasePopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
          <DeleteItemPopup
            isOpen={isDeleteShowCasePopupOpen}
            title={showCaseNameForPopup}
            closePopup={() => setIsDeleteShowCasePopupOpen(false)}
            id={currentOpenItemId}
            type="CASHBOX"
          />
        </CSSTransition>
        {/* /ПОПАП - УДАЛИТЬ ВИТРИНУ */}
      </div>
    </>,
    document.body,
  );
};

CashBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  user: PropTypes.object.isRequired,
  showCases: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      needEmail: PropTypes.bool,
      needFio: PropTypes.bool,
      needPhone: PropTypes.bool,
      sum: PropTypes.number,
      sumHint: PropTypes.number,
      uid: PropTypes.string,
      dateAdd: PropTypes.string,
      paymentsAmount: PropTypes.number,
      dateSuccessLastPayment: PropTypes.string,
      sumOutLastPayment: PropTypes.number,
    }),
  ),
};

CashBox.defaultProps = {
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  szStatusFromDb: state.auth.szStatusFromDb,
  showCases: state.cashBox.showCases,
  user: state.auth.user,
});

export default connect(mapStateToProps)(CashBox);

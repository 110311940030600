import React from 'react';
import PropTypes from 'prop-types';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import { PAYMENT_TRANSFER_IN_PROCESS, PAYMENT_TRANSFER_OK } from 'constants/index';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

import './PaymentSuccessHeader.scss';

const PaymentSuccessHeader = ({ paymentStatus, progressValue }) => {
  return (
    <>
      {[PAYMENT_TRANSFER_OK, PAYMENT_TRANSFER_IN_PROCESS].includes(paymentStatus) && (
        <div className="payment-success-header" data-testid="payment-success-header">
          <div className="payment-success-header__success-icon-wrap">
            <CircleProgress
              className="payment-success-header__success-progress"
              color="var(--turquoise-blue)"
              value={paymentStatus === PAYMENT_TRANSFER_IN_PROCESS ? 66 : progressValue}
            />
            {paymentStatus === PAYMENT_TRANSFER_IN_PROCESS ? (
              <span className="payment-success-header__in-process-icon">!</span>
            ) : (
              <IconCheck className="payment-success-header__success-icon" />
            )}
          </div>

          <div className="payment-success-header__success-text">
            <p className="payment-success-header__success-text-title">Ваш платеж</p>
            <p className="payment-success-header__success-text-result">
              {paymentStatus === PAYMENT_TRANSFER_IN_PROCESS ? 'Отправляется' : 'Успешно отправлен'}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

PaymentSuccessHeader.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  progressValue: PropTypes.number,
};

PaymentSuccessHeader.defaultProps = {
  progressValue: 0,
};

export default PaymentSuccessHeader;

import React from 'react';
import { Link } from 'react-router-dom';

const HintHowToLiftLimits = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      Чтобы повысить лимиты на операции с кошельком, нужно получить следующую учетную запись
      («Авторизованную»). Подробнее о лимитах, и о том как это можно сделать можно
      <Link to="/lk/profile-settings/account-type" className="hint__text-link">
        найти здесь
      </Link>
    </div>
  </div>
);

export default HintHowToLiftLimits;

import React from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';
import { Link } from 'react-router-dom';

import { PAYMENT_TRANSFER_OK, PAYMENT_TRANSFER_FAIL } from 'constants/index';

// Styles
import './TaxPaymentActionButtons.scss';

const TaxPaymentActionButtons = ({
  isTransferPreApproved,
  paymentStatus,
  payTaxClick,
  tryAgainClick,
}) => {
  const windowSize = useWindowSize();

  return (
    <div className="tax-payment-action-buttons">
      {/* НЕУСПЕШНЫЙ ПЛАТЕЖ */}
      {paymentStatus === PAYMENT_TRANSFER_FAIL && (
        <div className="tax-payment-action-buttons__wrap">
          <Link
            to="/lk/tax-list"
            type="button"
            className="tax-payment-action-buttons__btn tax-payment-action-buttons__btn_back"
          >
            Вернуться
          </Link>

          <button type="button" className="tax-payment-action-buttons__btn" onClick={tryAgainClick}>
            Попробовать снова
          </button>
        </div>
      )}
      {/* /НЕУСПЕШНЫЙ ПЛАТЕЖ */}

      {/* УСПЕШНЫЙ ПЛАТЕЖ */}
      {paymentStatus === PAYMENT_TRANSFER_OK && (
        <div className="tax-payment-action-buttons__wrap">
          <button
            type="button"
            className="tax-payment-action-buttons__btn tax-payment-action-buttons__btn_to-accounting"
          >
            Вернуться к отчетности
          </button>
        </div>
      )}
      {/* /УСПЕШНЫЙ ПЛАТЕЖ */}

      {!paymentStatus && windowSize.innerWidth > 980 && (
        <div className="tax-payment-action-buttons__wrap">
          <button
            type="button"
            className="tax-payment-action-buttons__btn"
            disabled={!isTransferPreApproved}
            onClick={payTaxClick}
          >
            Оплатить
          </button>
        </div>
      )}

      {!paymentStatus && windowSize.innerWidth <= 980 && isTransferPreApproved && (
        <div className="tax-payment-action-buttons__wrap">
          <button
            type="button"
            className="tax-payment-action-buttons__btn"
            disabled={!isTransferPreApproved}
            onClick={payTaxClick}
          >
            Оплатить
          </button>
        </div>
      )}
    </div>
  );
};

TaxPaymentActionButtons.propTypes = {
  isTransferPreApproved: PropTypes.bool.isRequired,
  paymentStatus: PropTypes.string,
  payTaxClick: PropTypes.func.isRequired,
  tryAgainClick: PropTypes.func.isRequired,
};

TaxPaymentActionButtons.defaultProps = {
  paymentStatus: null,
};

export default TaxPaymentActionButtons;

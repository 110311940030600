import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import Card from '../Card/Card';
import CardHeaderSz from '../CardHeaderSz/CardHeaderSz';
import MoneyInputAndPaymentButton from '../MoneyInputAndPaymentButton/MoneyInputAndPaymentButton';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { normalizePhone, removeCodeFromPhoneNumber } from 'helpers';
import rublesToPenny from '../../helpers/rublesToPenny';
import pennyToRubles from '../../helpers/pennyToRubles';
import isSzWalletNumberValid from '../../helpers/isSzWalletNumberValid';

import {
  setMoneyTransferSum,
  setMoneyTransferCardOrWalletNumber,
  setMoneyTransferMessage,
  setMoneyTransferNextStep,
} from 'modules/money-transfer/actions';

import {
  getMinSumByTransferMethod,
  getMaxSumByTransferMethod,
  getUserBalance,
} from 'modules/money-transfer/selectors';

import './SzCard.scss';

const CzCard = ({
  dispatch,
  isMoneyTransferAllowed,
  sum,
  cardOrWalletNumber,
  message,
  maxLimitSum,
  minSum,
  maxSum,
  userBalance,
}) => {
  const [isRendered, setIsRendered] = React.useState(false);
  const [walletNumber, setWalletNumber] = React.useState('');
  const [localMessage, setLocalMessage] = React.useState('');
  const [localSum, setLocalSum] = React.useState('');
  const [isWalletNumberValid, setIsWalletNumberValid] = React.useState(false);
  const [isSumValid, setIsSumValid] = React.useState(true);

  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (isSzWalletNumberValid(walletNumber)) {
      setIsWalletNumberValid(true);
    }

    if (!isSzWalletNumberValid(walletNumber) && isWalletNumberValid) {
      setIsWalletNumberValid(false);
    }
  }, [walletNumber]);

  React.useEffect(() => {
    if (cardOrWalletNumber) {
      setWalletNumber(cardOrWalletNumber);
    }
  }, [cardOrWalletNumber]);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (localSum) {
      dispatch(setMoneyTransferSum(rublesToPenny(parseFloat(localSum))));
    } else {
      dispatch(setMoneyTransferSum(0));
    }
  }, [localSum]);

  React.useEffect(() => {
    if (message) {
      setLocalMessage(message);
    }
  }, [message]);

  React.useEffect(() => {
    if (sum !== 0) {
      setLocalSum(sum.toString());
    }
  }, [sum]);

  function onWalletNumberChange(event) {
    setWalletNumber(event.target.value);
  }

  function beforeMaskedValueChange(newState, prevState, userInput) {
    let { value } = newState;

    if (userInput && userInput.length >= 11) {
      value = `${value.slice(0, 1)}${removeCodeFromPhoneNumber(normalizePhone(userInput))}`;
    }

    return {
      ...newState,
      value,
    };
  }

  function onMessageChange(event) {
    setLocalMessage(event.target.value);
  }

  function onSumChange(values) {
    const { floatValue, value } = values;

    if (floatValue > maxLimitSum || floatValue > maxSum) {
      setLocalSum(Math.min(maxLimitSum, maxSum));
    } else if (value === '') {
      setLocalSum('');
    } else {
      setLocalSum(value);
    }

    if (floatValue > userBalance) {
      setIsSumValid(false);
    } else {
      setIsSumValid(true);
    }
  }

  function onPay() {
    dispatch(setMoneyTransferCardOrWalletNumber(`7${walletNumber.slice(1, 11)}`));
    dispatch(setMoneyTransferMessage(localMessage));
    dispatch(setMoneyTransferNextStep());
  }

  return (
    <CSSTransition classNames="animation-fade" in={isRendered} timeout={0} unmountOnExit>
      <div className="money-transfer-sz-card">
        <Card className="money-transfer-sz-card__card">
          <header className="money-transfer-sz-card__header">
            <CardHeaderSz />
          </header>
          <div className="money-transfer-sz-card__content">
            <div className="money-transfer-sz-card__wallet-number">
              <InputMask
                type="tel"
                className="money-transfer-sz-card__wallet-number-input"
                placeholder="Номер кошелька"
                maskChar={null}
                mask="89999999999"
                value={walletNumber}
                onChange={onWalletNumberChange}
                beforeMaskedValueChange={beforeMaskedValueChange}
                disabled={!isMoneyTransferAllowed}
              />
              {isWalletNumberValid && (
                <IconCheck className="money-transfer-sz-card__wallet-number-icon" />
              )}
            </div>
            <div className="money-transfer-sz-card__message">
              <input
                type="text"
                className="money-transfer-sz-card__message-input"
                placeholder="Сообщение получателю*"
                maxLength="50"
                onChange={onMessageChange}
                value={localMessage}
                disabled={!isMoneyTransferAllowed}
              />
            </div>
            <div className="money-transfer-sz-card__tip">*Не обязательно</div>
            {!isMoneyTransferAllowed && (
              <p className="money-transfer-sz-card__error">
                Для вашей учетной записи вывод денег недоступен
              </p>
            )}
          </div>
        </Card>

        {isWalletNumberValid && (
          <div className="money-transfer-sz-card__nav">
            <MoneyInputAndPaymentButton
              sum={localSum}
              onSumChange={onSumChange}
              isSumValid={isSumValid}
              onPay={onPay}
            />
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

CzCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMoneyTransferAllowed: PropTypes.bool.isRequired,
  sum: PropTypes.number.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  maxLimitSum: PropTypes.number.isRequired,
  minSum: PropTypes.number.isRequired,
  maxSum: PropTypes.number.isRequired,
  userBalance: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isMoneyTransferAllowed: state.moneyTransfer.info.services.sz.available,
  sum: pennyToRubles(state.moneyTransfer.payment.sum),
  cardOrWalletNumber: state.moneyTransfer.payment.cardOrWalletNumber,
  message: state.moneyTransfer.payment.message,
  maxLimitSum: pennyToRubles(state.moneyTransfer.info.maxLimitSum),
  minSum: pennyToRubles(getMinSumByTransferMethod('sz')(state)),
  maxSum: pennyToRubles(getMaxSumByTransferMethod('sz')(state)),
  userBalance: pennyToRubles(getUserBalance()(state)),
});

export default connect(mapStateToProps)(CzCard);

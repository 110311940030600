import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useWindowSize from '@rehooks/window-size';
import AriaModal from 'react-aria-modal';
import cx from 'classnames';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

import styles from './style.module.scss';

export const MobileDropdownNavigationWidget = ({
  navigationComponent: NavigationComponent,
  navList,
}) => {
  const location = useLocation();
  const windowSize = useWindowSize();

  const [isProfileSettingsDropdownOpened, setIsProfileSettingsDropdownOpened] = useState(false);

  return (
    <div className={styles.mobileNavigation}>
      {/* КНОПКА ДЛЯ ОТКРЫТИЯ ВЫПАДАШКА ДЛЯ НАВИГАЦИЯ МОБАЙЛЕ */}
      {windowSize.innerWidth <= 980 && (
        <button
          className={styles.navSelect}
          type="button"
          onClick={() => setIsProfileSettingsDropdownOpened((prevState) => !prevState)}
        >
          <div className={styles.navSelectText}>
            {navList.find((i) => i.link === location.pathname)?.text}
          </div>

          <IconArrow
            className={cx(styles.navSelectIcon, {
              [styles.open]: isProfileSettingsDropdownOpened,
            })}
          />
        </button>
      )}

      {/* ВЫПАДАШКА ДЛЯ НАВИГАЦИЯ МОБАЙЛЕ */}
      <AriaModal
        onExit={() => setIsProfileSettingsDropdownOpened(false)}
        titleText=" "
        underlayColor="rgba(10, 17, 37, 0.5)"
        underlayStyle={{ top: windowSize.innerWidth > 800 ? '140px' : '120px' }}
        mounted={windowSize.innerWidth <= 980 && isProfileSettingsDropdownOpened}
        focusTrapPaused
      >
        {/* НАВИГАЦИЯ МОБАЙЛЕ */}
        <NavigationComponent
          navList={navList}
          closeHandler={() => setIsProfileSettingsDropdownOpened(false)}
        />
      </AriaModal>
    </div>
  );
};

MobileDropdownNavigationWidget.propTypes = {
  navigationComponent: PropTypes.elementType.isRequired,
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.shape({}),
      activeIcon: PropTypes.shape({}),
      link: PropTypes.string,
    }),
  ).isRequired,
};

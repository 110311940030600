import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import { ReactComponent as LogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';

import { setMenuOpen } from 'modules/authLegasy/actions';

import './Header.scss';

const Header = ({ dispatch, isMenuOpen, onClose }) => {
  const [redirectOnLk, setRedirectOnLk] = React.useState(false);

  function toggleMenu() {
    dispatch(setMenuOpen(!isMenuOpen));
  }

  function onCloseButtonClick() {
    if (onClose) {
      return onClose();
    }

    setRedirectOnLk(true);
  }

  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  return (
    <div className="money-transfer-header">
      <div className="money-transfer-header_desktop">
        <PopupHeaderWithBalance />
        <PopupCloseWithText closePopupHandler={onCloseButtonClick} />
      </div>
      <div className="money-transfer-header_mobile">
        <Link to="/" className="money-transfer-header__link">
          <LogoResponsive className="money-transfer-header__logo" />
        </Link>
        <button
          className={cx('money-transfer-header__burger-menu-button', {
            'money-transfer-header__burger-menu-button_pressed': isMenuOpen,
          })}
          type="button"
          onClick={toggleMenu}
        >
          <div className="money-transfer-header__burger-menu-button__inner">
            <IconLine1 className="money-transfer-header__burger-menu-button__line" />
            <IconLine2 className="money-transfer-header__burger-menu-button__line" />
            <IconLine3 className="money-transfer-header__burger-menu-button__line" />
          </div>
        </button>
      </div>
    </div>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(Header);

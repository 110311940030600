import PropTypes from 'prop-types';
import React from 'react';
import AriaModal from 'react-aria-modal';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

// Styles
import './SaveMoneyMobilePopup.scss';

const SaveMoneyMobilePopup = ({ className, closePopup, mounted, children, title }) => {
  return (
    <AriaModal
      underlayClass={className}
      onExit={closePopup}
      mounted={mounted}
      titleText=" "
      underlayColor="#ffffff"
    >
      <div className="show-case-mobile-popup__header">
        <button type="button" className="show-case-mobile-popup__arrow-wrap" onClick={closePopup}>
          <IconArrow className="show-case-mobile-popup__arrow" />
        </button>

        <button type="button" className="show-case-mobile-popup__close" onClick={closePopup}>
          <IconClose className="show-case-mobile-popup__close-icon" />
        </button>
      </div>

      <div className="show-case-mobile-popup__content">
        <div className="show-case-mobile-popup__title">{title}</div>

        {children}
      </div>
    </AriaModal>
  );
};

SaveMoneyMobilePopup.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  children: PropTypes.node,
  closePopup: PropTypes.func.isRequired,
  title: PropTypes.string,
};

SaveMoneyMobilePopup.defaultProps = {
  className: null,
  title: null,
  children: null,
  mounted: false,
};

export default SaveMoneyMobilePopup;

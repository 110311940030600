import React from 'react';
import PartnerPaymentsTable from '../PartnerPaymentsTable';
import styles from './PartnerPaymentsContainer.module.scss';

const PartnerPaymentsContainer = () => {
  return (
    <div className={styles.partnerPaymentsContainer}>
      <PartnerPaymentsTable />
    </div>
  );
};

export default PartnerPaymentsContainer;

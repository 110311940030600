/*
	Карточка для подтверждения перевода с помощью push-уведомления в приложении
	или с помощью смс-сообщения.
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import Card from '../Card/Card';
import CircleLoader from 'modules/authLegasy/components/CircleLoader/CircleLoader';
import CodeInput from 'modules/shared/components/CodeInput/CodeInput';

import formatNumber from 'helpers/formatNumber';
import pennyToRubles from '../../helpers/pennyToRubles';
import { changeLookOfPhone } from 'helpers';

import {
  MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD,
  MONEY_TRANSFER_CONFIRMATION_SMS_METHOD,
} from 'constants/index';

import {
  checkMoneyTransferConfirmationViaPush,
  changeMoneyTransferConfirmationMethod,
  recreateMoneyTransferConfirmationViaSms,
  sendMoneyTransferSmsCode,
  confirmMoneyTransferViaSms,
} from 'modules/money-transfer/actions';

import { getTotalSumByMoneyTransferMethod, getUserPhone } from 'modules/money-transfer/selectors';

import './ConfirmationCard.scss';

const ConfirmationCard = ({
  dispatch,
  confirmationMethod,
  phoneNumber,
  cardOrWalletNumber,
  codeLength,
  resendTime,
  attempts,
  confirmMoneyTransferViaSmsFail,
  sendMoneyTransferSmsCodeSuccess,
  sendMoneyTransferSmsCodeFail,
}) => {
  // используются хуки вместо connect, т.к. с помощью connect нет доступа к параметру paymentMethod
  const { paymentMethod } = useParams();
  const totalSum = pennyToRubles(useSelector(getTotalSumByMoneyTransferMethod(paymentMethod)));

  const [code, setCode] = React.useState('');
  const [wasPushMethodAFirst, setWasPushMethodAFirst] = React.useState(false);
  const [isCodeValid, setIsCodeValid] = React.useState(true);
  const [timerValue, setTimerValue] = React.useState(resendTime);

  const hasSmsLimit = sendMoneyTransferSmsCodeFail === true;
  const hasAttemptsLimit = attempts === 0;

  // учитывая, какой метод подтверждения выбран
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    // если метод подтверждения по-умолчанию - PUSH метод, то
    if (confirmationMethod === MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD) {
      // запомнить, что первым был push-метод
      setWasPushMethodAFirst(true);
      // начать отправлять запросы, отслеживающие подтверждение
      dispatch(checkMoneyTransferConfirmationViaPush(paymentMethod));
    }

    // если метод подтверждения SMS и до этого метод по-умолчанию был PUSH, то
    if (confirmationMethod === MONEY_TRANSFER_CONFIRMATION_SMS_METHOD && wasPushMethodAFirst) {
      // создать запрос для смены метода отправки
      // Важно! Проверки "!wasPushMethodAFirst" нужна, чтобы отслежить смену методов, поскольку
      // если метод по-умолчанию был PUSH, то необходимо сделать пересоздание.
      // А если метод по-умолчанию был SMS, то делать этот запрос не нужно
      dispatch(recreateMoneyTransferConfirmationViaSms());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [confirmationMethod]);

  // TODO: вынести таймер в отдельный компонент, т.к. логика слишком сложная получается
  // обратный отсчет для повторной отправки кнопки
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (sendMoneyTransferSmsCodeSuccess) {
      setTimerValue(resendTime);
    }
  }, [sendMoneyTransferSmsCodeSuccess]);

  React.useEffect(() => {
    if (timerValue <= 0) {
      return;
    }

    if (confirmationMethod !== MONEY_TRANSFER_CONFIRMATION_SMS_METHOD) {
      return;
    }

    const timer = setTimeout(() => {
      setTimerValue((value) => value - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [confirmationMethod, timerValue]);

  // если подтвердить смс-код не удалось, то
  React.useEffect(() => {
    if (confirmMoneyTransferViaSmsFail) {
      // сбрасывем код у компонента для ввода
      resetCode();
      // помечаем поля, как невалидные
      setIsCodeValid(false);
    }
  }, [confirmMoneyTransferViaSmsFail]);

  function resetCode() {
    setCode('');
  }

  function onCodeChange(value) {
    // если начинаем вводить код после того, как поля были помечены как неправильные
    // то сбрасываем эту отметку
    if (!isCodeValid) {
      setIsCodeValid(true);
    }

    setCode(value);
  }

  function onCodeEnter(value) {
    dispatch(confirmMoneyTransferViaSms(value));
  }

  function switchConfirmationMethod() {
    dispatch(changeMoneyTransferConfirmationMethod(MONEY_TRANSFER_CONFIRMATION_SMS_METHOD));
  }

  function sendNewCode() {
    if (!isCodeValid) {
      setIsCodeValid(true);
    }

    dispatch(sendMoneyTransferSmsCode());
  }

  return (
    <div className="money-transfer-confirmation-card">
      <Card className="money-transfer-confirmation-card__card">
        <header className="money-transfer-confirmation-card__header">
          <div className="money-transfer-confirmation-card__header-row">
            <span className="money-transfer-confirmation-card__header-row-title">Сумма</span>
            <span className="money-transfer-confirmation-card__header-row-value">
              {formatNumber(totalSum.toFixed(2))} ₽
            </span>
          </div>
          <div className="money-transfer-confirmation-card__header-row">
            <span className="money-transfer-confirmation-card__header-row-title">
              {paymentMethod === 'bank-card' ? 'Карта' : 'Кошелек'} получателя
            </span>
            <span className="money-transfer-confirmation-card__header-row-value">
              {cardOrWalletNumber}
            </span>
          </div>
        </header>

        <div className="money-transfer-confirmation-card__content">
          {/* Подтверждение через push */}
          {confirmationMethod === MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD && (
            <React.Fragment>
              <p className="money-transfer-confirmation-card__content-title">
                Подтвердите в телефоне
                <CircleLoader className="money-transfer-confirmation-card__content-loader" />
              </p>
              <p className="money-transfer-confirmation-card__content-notice">
                Сейчас придет Push-уведомление:
                <br />
                просто нажмите на него
              </p>
              <button
                className="money-transfer-confirmation-card__content-change-method-button"
                onClick={switchConfirmationMethod}
              >
                Push не пришел, подтвердить через смс
              </button>
            </React.Fragment>
          )}

          {/* Подтверждение через смс */}
          {confirmationMethod === MONEY_TRANSFER_CONFIRMATION_SMS_METHOD && (
            <React.Fragment>
              <p className="money-transfer-confirmation-card__content-title">
                Укажите код подтверждения
              </p>
              <p className="money-transfer-confirmation-card__content-notice">
                Код отправлен на номер {phoneNumber}
              </p>
              <div className="money-transfer-confirmation-card__content-input">
                <CodeInput
                  codeLength={codeLength}
                  isValid={isCodeValid}
                  autoFocus={true}
                  value={code}
                  isDisabled={false}
                  onChange={onCodeChange}
                  onCodeEnter={onCodeEnter}
                />
              </div>
              <div className="money-transfer-confirmation-card__content-info">
                {hasSmsLimit && (
                  <p className="money-transfer-confirmation-card__content-info-message">
                    Превышен лимит отправки СМС с кодом
                  </p>
                )}
                {!hasSmsLimit && (
                  <React.Fragment>
                    {hasAttemptsLimit && (
                      <p className="money-transfer-confirmation-card__content-info-message">
                        Превышено количество попыток ввода кода. Попробуйте позже.
                      </p>
                    )}
                    {!hasAttemptsLimit && (
                      <React.Fragment>
                        {!isCodeValid && (
                          <p className="money-transfer-confirmation-card__content-info-message1">
                            Вы ввели неверный код
                          </p>
                        )}
                        {timerValue !== 0 && (
                          <p className="money-transfer-confirmation-card__content-info-message">
                            Получить новый можно через {timerValue} сек
                          </p>
                        )}
                        {timerValue === 0 && (
                          <button
                            className="money-transfer-confirmation-card__content-info-button"
                            onClick={sendNewCode}
                          >
                            Отправить еще раз
                          </button>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </Card>
    </div>
  );
};

ConfirmationCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmationMethod: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  codeLength: PropTypes.number.isRequired,
  resendTime: PropTypes.number.isRequired,
  attempts: PropTypes.number.isRequired,
  confirmMoneyTransferViaSmsFetching: PropTypes.bool.isRequired,
  confirmMoneyTransferViaSmsFail: PropTypes.bool.isRequired,
  sendMoneyTransferSmsCodeSuccess: PropTypes.bool.isRequired,
  sendMoneyTransferSmsCodeFail: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  confirmationMethod: state.moneyTransfer.payment.confirmationMethod,
  phoneNumber: changeLookOfPhone(getUserPhone()(state)),
  cardOrWalletNumber: state.moneyTransfer.payment.cardOrWalletNumber,
  codeLength: state.moneyTransfer.payment.codeLength,
  resendTime: state.moneyTransfer.payment.resendTime,
  attempts: state.moneyTransfer.payment.attempts,
  confirmMoneyTransferViaSmsFetching: state.moneyTransfer.confirmMoneyTransferViaSms.fetching,
  confirmMoneyTransferViaSmsFail: state.moneyTransfer.confirmMoneyTransferViaSms.fail,
  sendMoneyTransferSmsCodeSuccess: state.moneyTransfer.sendMoneyTransferSmsCode.success,
  sendMoneyTransferSmsCodeFail: state.moneyTransfer.sendMoneyTransferSmsCode.fail,
});

export default connect(mapStateToProps)(ConfirmationCard);

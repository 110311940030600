import { AccessRoute } from 'HOCs';
import SelfEmployedRegistration from 'modules/authLegasy/components/SelfEmployedRegistrationContainer/SelfEmployedRegistrationContainer';
import ManualPage from 'modules/manual/components/ManualPage/ManualPage';
import SEFromLKRegistrationContainer from 'modules/profileLegasy/components/SEFromLKRegistrationContainer/SEFromLKRegistrationContainer';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { PTClassNames } from 'tools/types/propTypes';
import {
  AcceptNewAgreementTemplate,
  AuthTemplate,
  CertificateTemplate,
  ShowCaseTemplate,
  SaveMoneyTemplate,
  BusinessCardTemplate,
  PayItTemplate,
  InvoicePaymenTemplate,
  EmailConfirmTemplate,
  ErrorTemplate,
  LkTemplate,
  PrivacyPolicyTemplate,
} from './templates';

export const BodyTemplate = ({ className }) => {
  const [needToAcceptNewPolicy, setNeedToAcceptNewPolicy] = useState(false);
  const user = useSelector((store) => store.auth.user, shallowEqual);
  useEffect(() => {
    if (user) {
      if (
        user.ofertaAccepted === null ||
        !Object.prototype.hasOwnProperty.call(user, 'ofertaAccepted')
      ) {
        setNeedToAcceptNewPolicy(false);
      } else {
        if (user.ofertaAccepted.toString() === 'false') {
          setNeedToAcceptNewPolicy(true);
        }

        if (user.ofertaAccepted.toString() === 'true') {
          setNeedToAcceptNewPolicy(false);
        }
      }
    }
  }, [user]);

  return (
    <div className={className}>
      <Switch>
        {/* LOCAL CSS TRANSITIONS ROUTS: локально внутри компонентов */}
        <AccessRoute
          isPrivate
          needToAcceptNewPolicy={needToAcceptNewPolicy}
          path="/lk"
          component={LkTemplate}
          locationDetector={{
            propName: 'hasLowZIndex',
            pattern: 'money-request',
          }}
        />
        <Route path="/:platform/certificate/:id">
          <CertificateTemplate /> {/* ПОДПИСАННЫЙ ДОКУМЕНТ */}
        </Route>
        {/* /LOCAL CSS TRANSITIONS ROUTS: локально внутри компонентов */}

        {/* GLOBAL CSS TRANSITIONS РОУТЫ: глобально из текущего компонента */}
        <Route
          render={({ location }) => {
            return (
              <CSSTransition
                timeout={{ enter: 300, exit: 300 }}
                key={location.key}
                classNames="fade"
              >
                <Switch location={location}>
                  <AccessRoute exact path="/">
                    <Redirect to="/login" />
                  </AccessRoute>

                  <Route path={['/login', '/register', '/password-recovery', '/get-authorization']}>
                    <AuthTemplate needToAcceptNewPolicy={needToAcceptNewPolicy} />
                  </Route>

                  <AccessRoute path="/self-employed-register">
                    <SelfEmployedRegistration />
                  </AccessRoute>

                  <AccessRoute
                    isPrivate
                    needToAcceptNewPolicy={needToAcceptNewPolicy}
                    path="/self-employment-from-lk"
                  >
                    <SEFromLKRegistrationContainer />
                  </AccessRoute>

                  <Route path={['/invoice/:checkId', '/pay-it/invoice']}>
                    {/** ВЫСТАВЛЕННЫЙ СЧЕТ - ОПЛАТА ВЫСТАВЛЕННОГО СЧЕТА */}
                    <InvoicePaymenTemplate />
                  </Route>

                  <Route path={['/kassa/:showCaseId', '/cashbox/:showCaseId', '/pay-it/cashbox']}>
                    {/** КАССА - ОПЛАТА ВИТРИНЫ КАССЫ */}
                    <ShowCaseTemplate />
                  </Route>

                  <Route path={['/kopim/:moneyboxId', '/moneybox/:moneyboxId', '/pay-it/moneybox']}>
                    {/** КОПИМ - ОПЛАТА СБОРА ДЕНЕГ */}
                    <SaveMoneyTemplate />
                  </Route>

                  <Route path={['/pay-it/:requestId', '/pay-it/money/']}>
                    {/** ЗАПРОС ДЕНЕГ - ОПЛАТА ЗАПРОСА ДЕНЕГ*/}
                    <PayItTemplate />
                  </Route>

                  <Route path={['/to/:uniqueId', '/apple-pay-business-card/:uniqueId', '/receipt']}>
                    {/** ССЫЛКА-ВИЗИТКА  - ОПЛАТА ССЫЛКИ-ВИЗИТКИ */}
                    <BusinessCardTemplate />
                  </Route>

                  <Route path="/email">
                    <EmailConfirmTemplate /> {/*  ПОДТВЕРЖДЕНИЕ E-MAIL */}
                  </Route>

                  <Route path="/manual">
                    <ManualPage />
                  </Route>

                  <Route path="/privacy-policy">
                    <PrivacyPolicyTemplate />
                  </Route>

                  <Route path="/new-policy">
                    {/* ПРИНЯТИЕ НОВОГО ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ */}
                    <AcceptNewAgreementTemplate />{' '}
                  </Route>

                  {/* СТРАНИЦА 404 */}
                  <Route path="/error">
                    <ErrorTemplate />
                  </Route>

                  <Route>
                    <ErrorTemplate />
                  </Route>
                  {/* /СТРАНИЦА 404 */}
                </Switch>
              </CSSTransition>
            );
          }}
        />
        {/* /GLOBAL CSS TRANSITIONS РОУТЫ: глобально из текущего компонента */}
      </Switch>
    </div>
  );
};

BodyTemplate.propTypes = {
  className: PTClassNames,
};

BodyTemplate.defaultProps = {
  className: '',
};

import { useEffect, useState } from 'react';

import { useInitScriptElement } from './hooks';
import { setCookie } from './utils';

const methodNameArr = [
  'connect',
  'track',
  'identify',
  'auth',
  'onReady',
  'addCallback',
  'removeCallback',
  'trackMessageInteraction',
];

export const useCarrotQuest = (connectId, isAuth) => {
  const [isReady, setIsReady] = useState(false);
  const [isReloadWindow, setIsReloadWindow] = useState(false);
  const { scriptEl } = useInitScriptElement(connectId, methodNameArr);

  useEffect(() => {
    if (window?.carrotquest?.onReady && !isReady) {
      const onReady = window?.carrotquest?.onReady;
      onReady(() => {
        setIsReady(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.carrotquest?.onReady, isReady]);

  useEffect(() => {
    if (isReloadWindow && !isAuth) {
      window.location.reload();
    }
  }, [isReloadWindow, isAuth]);

  const logout = () => {
    const carrotquestCookieNameArr = document.cookie
      .split('; ')
      .map((i) => i.split('='))
      .filter((i) => i[0].includes('carrotquest'))
      .map((i) => i[0]);

    if (carrotquestCookieNameArr?.length) {
      const expireDate = new Date();
      expireDate.setSeconds(expireDate.getSeconds() + 1);

      carrotquestCookieNameArr.forEach((i) => {
        setCookie(
          i,
          '',
          JSON.parse(process.env.REACT_APP_CARROT_LOCALHOST || 'false')
            ? { 'max-age': -1 }
            : {
                domain: '.selfwork.ru',
                path: '/',
                expires: expireDate.toUTCString(),
              },
        );
      });
    }

    const carrotquestLocalStorageNameArr = Object.keys(localStorage).filter((i) =>
      i.includes('carrotquest'),
    );

    if (carrotquestLocalStorageNameArr?.length)
      carrotquestLocalStorageNameArr.forEach((i) => {
        if (window && window.localStorage) {
          window.localStorage.removeItem(i);
        }
      });

    setIsReloadWindow(true);
  };

  if (window?.carrotquest)
    return methodNameArr.reduce((acc, curr) => ({ ...acc, [curr]: window.carrotquest[curr] }), {
      scriptEl,
      isReady,
      logout,
    });
  return { isReady, scriptEl, logout };
};

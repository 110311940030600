import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clearAction, STATES_LOGIN, useStateLoginSelector } from '../../models/login';
import { AuthConfirmContainer, LoginContainer } from './constainers';

export const LoginWidget = () => {
  const stateLogin = useStateLoginSelector();
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      if (stateLogin === STATES_LOGIN.AUTH) dispatch(clearAction());
    },
    [dispatch, stateLogin],
  );
  return (
    <>
      {stateLogin === STATES_LOGIN.LOGIN && <LoginContainer />}
      {stateLogin === STATES_LOGIN.LOGIN_SMS_CONFIRM && (
        <AuthConfirmContainer
          onComeBack={() => {
            dispatch(clearAction());
          }}
        />
      )}
      {stateLogin === STATES_LOGIN.AUTH && <Redirect to="/lk" />}
    </>
  );
};

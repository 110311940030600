import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { WEIGHT } from 'tools/constants';
import { PTClassNames, PTChildrenElement } from 'tools/types/propTypes';

import { ParagraphText } from 'ui/molecules';

export const ButtonLight = ({ children, onClick, width, borderRadius, disabled, className }) => {
  const onCurrentClick = useCallback(
    (event) => {
      event.preventDefault();
      !disabled && onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <ButtonStyled
      onClick={(e) => {
        onCurrentClick(e);
      }}
      type="button"
      borderRadius={borderRadius}
      width={width}
      disabled={disabled}
      className={className}
    >
      <ParagraphText weight={WEIGHT['circe-bold']} size="16px" disabled={disabled}>
        {children}
      </ParagraphText>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--dark-slate-blue);
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: background-color 0.6s, color 0.6s, border 0.6s;
  transition-timing-function: cubic-bezier(0.08, 0.07, 0.24, 1.26);
  cursor: pointer;
  width: 100%;
  & p {
    display: block;
    padding: 13px 0;
    margin: auto;
    border-radius: 8px;
    color: var(--dark-slate-blue);
    transition: all 0.6s cubic-bezier(0.08, 0.07, 0.24, 1.26);
  }

  &:hover {
    border: 1px solid #ffffff00;
    background: var(--dusk-blue);

    & p {
      color: var(--white);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        cursor: unset;
        border: 1px solid #b0b5c4;
        background: none;
        color: #b0b5c4;
        & p {
          color: #b0b5c4;
        }
      }
    `}
`;

ButtonLight.propTypes = {
  children: PTChildrenElement.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  className: PTClassNames,
};

ButtonLight.defaultProps = {
  borderRadius: '',
  onClick: (f) => f,
  disabled: false,
  className: '',
};

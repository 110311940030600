import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from '../Header/Header';
import Content from '../Content/Content';
import Footer from 'modules/authLegasy/components/Footer/Footer';
import Loader from 'modules/authLegasy/components/Loader/Loader';

import { getMoneyTransferInfo, resetMoneyTransfer } from 'modules/money-transfer/actions';

import './MoneyTransfer.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

const MoneyTransfer = ({
  dispatch,
  user,
  getMoneyTransferInfoFetching,
  getMoneyTransferInfoSuccess,
}) => {
  const [redirectOnLk, setRedirectOnLk] = React.useState(false);

  React.useEffect(() => {
    dispatch(getMoneyTransferInfo());

    return () => dispatch(resetMoneyTransfer());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  return createPortal(
    <div className="money-transfer">
      <div className="money-transfer__wrapper">
        <Header onClose={() => setRedirectOnLk(true)} />

        <Switch>
          <Route path={['/lk/money-transfer', '/lk/money-transfer/:paymentMethod']} exact>
            {getMoneyTransferInfoFetching && <Loader className="money-transfer__loader" inverted />}
            {getMoneyTransferInfoSuccess && <Content />}
          </Route>
        </Switch>
      </div>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <Footer />
    </div>,
    document.body,
  );
};

MoneyTransfer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMoneyTransferInfoFetching: PropTypes.bool.isRequired,
  getMoneyTransferInfoSuccess: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  getMoneyTransferInfoFetching: state.moneyTransfer.getMoneyTransferInfo.fetching,
  getMoneyTransferInfoSuccess: state.moneyTransfer.getMoneyTransferInfo.success,
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoneyTransfer);

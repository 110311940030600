import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import styles from './Overlay.module.scss';

const Overlay = ({ onClose, onSuccess, title, icon, text: Text, nav: Nav }) => {
  const ref = React.useRef();

  React.useEffect(() => {
    function onClickOut(event) {
      const { target } = event;

      if (!ref.current.contains(target)) {
        onCloseClick();
      }
    }

    document.addEventListener('click', onClickOut);
    return () => document.removeEventListener('click', onClickOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCloseClick() {
    if (onClose) {
      onClose();
    }
  }

  function onButtonClick() {
    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.overlayBg}></div>
      <div className={styles.overlayContainer}>
        <div ref={ref} className={styles.overlayWrapper}>
          <div className={styles.overlayMobileHeader}>
            <Header onClose={onCloseClick} />
          </div>
          <button className={styles.overlayCloseButton} onClick={onCloseClick}>
            <IconClose className={styles.overlayCloseIcon} />
          </button>
          <div className={styles.overlayTitle}>{title}</div>
          <div className={styles.overlayIcon}>
            <img src={icon} alt="" />
          </div>
          <div className={styles.overlayText}>
            <Text />
          </div>
          <div className={styles.overlayNav}>
            <Nav onClose={onCloseClick} onSuccess={onButtonClick} />
          </div>
        </div>
        <div className={styles.overlayMobileFooter}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.elementType.isRequired,
  nav: PropTypes.elementType.isRequired,
};

export default Overlay;

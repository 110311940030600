import React from 'react';
import { ReactComponent as Logo } from 'static/assets/logo-white.svg';
import profileBg from 'static/assets/profile-bg.png';

import './CardHeaderSz.scss';

const CardHeaderSz = () => (
  <div className="money-transfer-card-header-sz">
    <Logo className="money-transfer-card-header-sz__logo" />
    <img
      className="money-transfer-card-header-sz__bg"
      src={profileBg}
      alt="Фирменный бэкгранунд Самозаняты.рф"
    />
  </div>
);

export default CardHeaderSz;

/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

import { CircleLoader } from '../../atoms';
import { ParagraphText } from '../../molecules';

export const ButtonNormal = ({
  Icon,
  children,
  onClick,
  width,
  borderRadius,
  disabled,
  routed,
  url,
  className,
  isLoading,
}) => {
  const onCurrentClick = useCallback(
    (event) => {
      event.preventDefault();
      !disabled && onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <ButtonStyled
      onClick={(e) => onCurrentClick(e)}
      type="button"
      borderRadius={borderRadius}
      width={width}
      disabled={disabled}
      className={className}
    >
      {isLoading ? (
        <CircleLoaderStyled />
      ) : routed && url ? (
        <NavLink to={url}>
          {Icon}
          <ParagraphText
            weight={WEIGHT['circe-bold']}
            size="16px"
            color={COLOR['pale-grey']}
            disabled={disabled}
          >
            {children}
          </ParagraphText>
        </NavLink>
      ) : (
        <>
          {Icon}
          <ParagraphText
            weight={WEIGHT['circe-bold']}
            size="16px"
            color={COLOR['pale-grey']}
            disabled={disabled}
          >
            {children}
          </ParagraphText>
        </>
      )}
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  width: ${({ width }) => width};
  border-radius: 8px;
  background: var(--dark-slate-blue);
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover {
    background: #284195;
  }
  &,
  * {
    text-decoration: none;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: unset;
    `}
  & p {
    display: block;
    padding: 13px 0;
    width: 100%;
    margin: auto;
    border-radius: 8px;
  }
`;

const CircleLoaderStyled = styled(CircleLoader)`
  width: 16px;
  height: 16px;
  margin: 17px 0;
`;

ButtonNormal.propTypes = {
  Icon: PropTypes.element,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  className: PTClassNames,
  routed: PropTypes.bool,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
};

ButtonNormal.defaultProps = {
  Icon: null,
  width: '100%',
  borderRadius: '',
  onClick: (f) => f,
  className: '',
  disabled: false,
  routed: false,
  url: null,
  isLoading: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

// Styles
import './DatePickerCustomInput.scss';

const DatePickerCustomInput = React.forwardRef((props, ref) => {
  const { onClick, onChange, onFocus, onBlur, value, placeholderText } = props;

  return (
    <div className="date-picker-custom-input">
      <InputMask
        ref={ref}
        className="date-picker-custom-input__itself"
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        mask="99.99.9999"
        placeholder={placeholderText}
        type="tel"
        // maskChar=""
        autoComplete="false"
        value={value}
        data-cy="datepicker-custom-input"
        data-testid="datepicker-custom-input"
      />
    </div>
  );
});

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  placeholderText: PropTypes.string,
};

DatePickerCustomInput.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  value: null,
  placeholderText: '__/__/____',
};

export default DatePickerCustomInput;

/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTChildrenElement, PTClassNames } from 'tools/types/propTypes';

import { ParagraphText } from '../../molecules';

export const ButtonPlate = ({ children, onClick, width, borderRadius, disabled, className }) => {
  const onCurrentClick = useCallback(
    (event) => {
      event.preventDefault();
      !disabled && onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <ButtonStyled
      onClick={(e) => {
        onCurrentClick(e);
      }}
      type="button"
      borderRadius={borderRadius}
      width={width}
      disabled={disabled}
      className={className}
    >
      <ParagraphTextStyled color={COLOR['pale-grey']} width={WEIGHT['circe-regular']} size="14px">
        {children}
      </ParagraphTextStyled>
    </ButtonStyled>
  );
};

ButtonPlate.propTypes = {
  children: PTChildrenElement.isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  className: PTClassNames,
};

ButtonPlate.defaultProps = {
  width: '100%',
  borderRadius: '',
  onClick: (f) => f,
  className: '',
  disabled: false,
};

const ButtonStyled = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  display: flex;
  place-items: center;
  margin: 0;
  padding: 13px 0;
  width: ${({ width }) => width};
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  text-decoration: none;
  background: var(--pale-grey);
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: unset;
    `}
  &,
  * {
    color: var(--grey-blue);
  }
`;

const ParagraphTextStyled = styled(ParagraphText)`
  display: flex;
  place-items: center;
  width: 100%;
  margin: auto;
  border-radius: 8px;
`;

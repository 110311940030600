import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Components
import QuarterNavigation from 'modules/shared/components/QuarterNavigation/QuarterNavigation';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Actions
import { getTaxes } from 'modules/profileLegasy/actions';

// Misc
import { MONTHSRU } from 'constants/index';
import formatNumber from 'helpers/formatNumber';

// Icons
import { ReactComponent as IconPercent } from 'static/assets/icon-percent.svg';

// Styles
import './Taxes.scss';

const Taxes = ({ className, dispatch, taxes, startCurrentTime, lastOperationsDate }) => {
  const getQuarter = (month) => Math.ceil(month / 3);

  const [currentTime, setCurrentTime] = useState(new Date(startCurrentTime));
  const [currentMonth, setCurrentMonth] = useState(currentTime.getMonth() + 1);
  const currentQuarter = getQuarter(currentMonth);

  const [yearForQuarterNav, setYearForQuarterNav] = useState(currentTime.getFullYear());
  const [quarter, setQuarter] = useState(currentQuarter);

  const [isLoading, setIsLoading] = useState(false);
  const [isFailToLoad, setIsFailToLoad] = useState(false);

  const noTaxes = false;

  /**
   * Берем инфу по тому, когда были совершены последние операции
   * и сравниваем месяц и год с текущими
   * если не совпадают с теущими, значит строим фильтр от тех,
   * что пришли с севера (на запрос о дате последних операций)
   * */
  useEffect(() => {
    if (
      lastOperationsDate &&
      currentTime &&
      lastOperationsDate.slice(0, 4) !== currentTime.getFullYear()
    ) {
      setYearForQuarterNav(+lastOperationsDate.slice(0, 4));
    }

    if (
      lastOperationsDate &&
      currentTime &&
      lastOperationsDate.slice(5, 7) !== currentTime.getMonth() + 1
    ) {
      setCurrentMonth(+lastOperationsDate.slice(5, 7));
      setQuarter(getQuarter(+lastOperationsDate.slice(5, 7)));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [lastOperationsDate]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем  startCurrentTime с сервера, если оно не равно нулю
   * записываем его в currentTime для построения фильтров, если же оно равно 0
   * берем инфу о currentTime с браузера
   * */
  useEffect(() => {
    if (startCurrentTime !== 0) {
      setCurrentTime(new Date(startCurrentTime));
    } else {
      setCurrentTime(new Date());
    }
  }, [startCurrentTime]);

  /** Клик на предыдущий квартал */
  const handlePrevQuarterClick = () => {
    if (quarter > 1) {
      setQuarter(quarter - 1);
    } else {
      setQuarter(4);
      setYearForQuarterNav(yearForQuarterNav - 1);
    }
  };

  /** Клик на следующий квартал */
  const handleNextQuarterClick = () => {
    if (quarter < 4) {
      setQuarter(quarter + 1);
    } else {
      setQuarter(1);
      setYearForQuarterNav(yearForQuarterNav + 1);
    }
  };

  /** Делаем запрос на налоги юзера */
  const getTaxesFunction = () => {
    setIsLoading(true);

    dispatch(getTaxes(12, yearForQuarterNav))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsFailToLoad(true);
      });
  };

  const getStartByQuarter = () => (quarter - 1) * 3;

  useEffect(() => {
    getTaxesFunction();
    /* eslint-disable */
  }, [yearForQuarterNav]);
  /* eslint-enable */

  /** Парсим данные по последним операциям с сервера в формат даты */
  const lastOperationsDateInDateFormat = new Date(Date.parse(lastOperationsDate));

  return (
    <div className={cx('taxes', { [className]: className })} data-testid="taxes">
      <p className="taxes__title">Уплата налогов</p>

      {/* НАВИГАЦИЯ ПО КВАРТАЛАМ ВМЕСТО ХЭДЕРА ТАБЛИЦЫ ПРИ ЭКРАНЕ < 800 */}
      <div className="taxes__year-nav">
        <QuarterNavigation
          handlePrevQuarterClick={handlePrevQuarterClick}
          handleNextQuarterClick={handleNextQuarterClick}
          isLoading={isLoading}
          year={yearForQuarterNav}
          quarter={quarter}
          isNextButtonDisabled={
            yearForQuarterNav === lastOperationsDateInDateFormat.getFullYear() &&
            quarter === getQuarter(lastOperationsDateInDateFormat.getMonth() + 1)
          }
        />
      </div>
      {/* /НАВИГАЦИЯ ПО КВАРТАЛАМ ВМЕСТО ХЭДЕРА ТАБЛИЦЫ ПРИ ЭКРАНЕ < 800 */}

      {/* КОНТЕНТ ТАБЛИЦЫ ПРИ ЭКРАНЕ < 800 */}
      {!isFailToLoad && !noTaxes && (
        <div className="taxes__content">
          <div className="taxes__list">
            {!isLoading &&
              taxes &&
              Array.isArray(taxes) &&
              taxes.slice(getStartByQuarter(), getStartByQuarter() + 3).map((item) => (
                <div className="taxes__item" key={item.month + item.year}>
                  <div className="taxes__item-title-wrap">
                    <div className="taxes__item-title-container">
                      <IconPercent className="taxes__item-icon" />
                      <p className="taxes__item-title">{MONTHSRU[item.month - 1]}</p>
                    </div>
                  </div>

                  <p className="taxes__item-sum">{formatNumber(item.tax / 100)} ₽</p>
                </div>
              ))}
          </div>

          {isLoading && (
            <div className="taxes__loading">
              <CircleLoader className="taxes__loading-loader" />
            </div>
          )}
        </div>
      )}
      {/* /КОНТЕНТ ТАБЛИЦЫ ПРИ ЭКРАНЕ < 800 */}

      {/* ЕСЛИ НЕТ НАЛОГОВ */}
      {noTaxes && !isFailToLoad && (
        <div className="taxes__message">
          Здесь будет отображаться статистика уплаты налогов по месяцам
        </div>
      )}
      {/* /ЕСЛИ НЕТ НАЛОГОВ */}

      {/* ЕСЛИ НЕ УДАЛОСЬ ЗАГРУЗИТЬ ДАННЫЕ */}
      {!noTaxes && isFailToLoad && (
        <div className="taxes__message">
          Произошла какая-то ошибка при обращении к ФНС. Не удалось загрузить данные. Попробуйте
          позже, а если ошибка не пройдет
          {'   '}
          <button className="taxes__message-link js-help" type="button">
            напишите нам
          </button>
          .
        </div>
      )}
      {/* /ЕСЛИ НЕ УДАЛОСЬ ЗАГРУЗИТЬ ДАННЫЕ */}
    </div>
  );
};

Taxes.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.number,
      years: PropTypes.number,
      tax: PropTypes.number,
    }),
  ).isRequired,
  startCurrentTime: PropTypes.number.isRequired,
  lastOperationsDate: PropTypes.string,
};

Taxes.defaultProps = {
  className: null,
  lastOperationsDate: null,
};

const mapStateToProps = (state) => ({
  taxes: state.profile.taxes,
  startCurrentTime: state.auth.startCurrentTime,
  lastOperationsDate: state.profile.lastOperationsDate,
});

export default connect(mapStateToProps)(Taxes);

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import './PaymentWaysItem.scss';

const TURNED_ON = 'TURNED_ON';
const DISABLED = 'DISABLED';
const TURNED_OFF = 'TURNED_OFF';

const PaymentWaysItem = ({ status, title, desc, className, isPaymentWaysLoading }) => {
  return (
    <div
      className={cx('payment-ways-item', {
        [className]: className,
        'payment-ways-item_disabled': status === DISABLED,
      })}
      data-testid="payment-ways-item"
    >
      {isPaymentWaysLoading ? (
        <div className="payment-ways-item__loader-wrap">
          <CircleLoader width={20} height={20} />
        </div>
      ) : (
        <div
          className={cx('payment-ways-item__status', {
            'payment-ways-item__status_turned-on': status === TURNED_ON,
            'payment-ways-item__status_disabled': status === DISABLED,
          })}
          data-testid="payment-ways-item-status"
        >
          {status === TURNED_ON && 'Подключено'}
          {status === TURNED_OFF && 'Подключить'}
          {status === DISABLED && 'Не доступно'}
        </div>
      )}

      <div className="payment-ways-item__content">
        <div className="payment-ways-item__title">{title}</div>
        <div className="payment-ways-item__desc">{desc}</div>
      </div>

      {/* <button className="payment-ways-item__details" type="button">
        <span className="payment-ways-item__details-span" />
        <span className="payment-ways-item__details-span" />
        <span className="payment-ways-item__details-span" />
      </button> */}
    </div>
  );
};

PaymentWaysItem.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  className: PropTypes.string,
  isPaymentWaysLoading: PropTypes.bool,
};

PaymentWaysItem.defaultProps = {
  className: null,
  isPaymentWaysLoading: false,
};

export default PaymentWaysItem;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import useWindowSize from '@rehooks/window-size';
import { CSSTransition } from 'react-transition-group';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Components
import Header from 'modules/authLegasy/components/Header/Header';
import FooterAppLinks from 'modules/authLegasy/components/FooterAppLinks/FooterAppLinks';

// Actions
import { setMenuOpen, logOut } from 'modules/authLegasy/actions';

// Misc
import formatNumber from 'helpers/formatNumber';
import { YES, PARTLY_YES, AUTHORIZED, REGISTERED } from 'constants/index';

// Icons
import { ReactComponent as IconArrow } from 'static/assets/icon-dropdown-arrow.svg';
import { ReactComponent as IconShield } from 'static/assets/icon-shield.svg';
import { ReactComponent as IconUser } from 'static/assets/icon-user.svg';
import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './Menu.scss';
import { checkPaymentEnabled } from '../../../../tools/utils/payments';
import { checkPaymentOnlyAuthorizedIdentifyEnabled } from '../../../../tools/utils/only-authorized';

const GET_MONEY = 'GET_MONEY';
const DOCUMENTS = 'DOCUMENTS';
const PARTNERS = 'PARTNERS';
const MONEY_TRANSFER = 'MONEY_TRANSFER';
const NOTIFICATIONS = 'NOTIFICATIONS';
const ACCOUNTING = 'ACCOUNTING';
const HISTORY = 'HISTORY';

export const Menu = ({
  user,
  dispatch,
  className,
  userTaxStatus,
  szStatusFromDb,
  isUserLoggedIn,
}) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const authUserMenuTimeOut = [350, 400, 450, 500, 550, 600, 650, 700, 750, 800];
  const nonAuthUserMenuTimeOut = [350, 400, 450, 500, 550, 600, 650, 700, 750, 800];

  /** Флаг, переключаем если кликнули на скопировать ссылку-визитку */
  const copiedUrl = `${window.location.origin}${process.env.PUBLIC_URL}/to/${
    user && user.randomUniqueId
  }`;
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  useEffect(() => {
    setIsRendered(true);
  }, [dispatch]);

  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;

  const handleSetMenuOpenClick = () => {
    dispatch(setMenuOpen(false));
  };

  const handleLogoutClick = () => {
    dispatch(setMenuOpen(false));
    dispatch(logOut()).catch(() => {});
  };

  /** Массив для вывода пунктов меню незалогиненого юезар */
  const nonAuthUserTopMenuNavItems = [
    {
      id: 1,
      title: 'Заказчикам',
      link: 'https://самозанятые.рф/find-selfworkers?utm_source=pro.wallet',
    },
    {
      id: 2,
      title: 'Самозанятым',
      link: 'https://самозанятые.рф/selfworkers?utm_source=pro.wallet',
    },
    {
      id: 3,
      title: 'Кошелек',
      link: 'https://самозанятые.рф/wallet?utm_source=pro.wallet',
    },
    {
      id: 4,
      title: 'Эквайринг',
      link: 'https://самозанятые.рф/acquiring?utm_source=pro.wallet',
    },
    {
      id: 5,
      title: 'Бизнесу',
      link: 'https://самозанятые.рф/business?utm_source=pro.wallet',
    },
    {
      id: 6,
      title: 'Блог',
      link: 'https://самозанятые.рф/blog?utm_source=pro.wallet',
    },
  ];

  /** Массив для вывода пунктов меню залогиненого юезар */
  const authUserTopMenuNavItems = [
    {
      id: GET_MONEY,
      title: 'Прием денег',
      link: '/lk',
      timeOut: authUserMenuTimeOut[0],
      animationIn: !!(isRendered && user),
    },
    {
      id: MONEY_TRANSFER,
      title: 'Переводы',
      link: '/lk/money-transfer',
      timeOut: authUserMenuTimeOut[1],
      animationIn: !!(isRendered && user),
    },
    {
      id: NOTIFICATIONS,
      title: 'Уведомления',
      link: '/lk/notifications',
      timeOut: authUserMenuTimeOut[2],
      animationIn: !!(isRendered && windowSize.innerWidth <= 980 && user),
      testId: 'menu-notifications',
    },
    {
      id: ACCOUNTING,
      title: 'Отчетность',
      link: '/lk/accounting',
      timeOut: authUserMenuTimeOut[3],
      animationIn: !!(isRendered && user),
    },
    {
      id: DOCUMENTS,
      title: 'Документы',
      link: '/lk/profile-settings/documents',
      timeOut: authUserMenuTimeOut[4],
      animationIn: !!(isRendered && user),
    },
    {
      id: PARTNERS,
      title: 'Партнеры',
      link: '/lk/profile-settings/partners',
      timeOut: authUserMenuTimeOut[5],
      animationIn: !!(isRendered && user),
    },
    {
      id: HISTORY,
      title: 'История',
      link: '/lk/history',
      timeOut: authUserMenuTimeOut[6],
      animationIn: !!(isRendered && user),
    },
  ];

  return (
    <div
      className={cx('menu', {
        'menu_non-auth': !user,
        [className]: className,
      })}
      id="menu"
    >
      <Header className="menu__header" isUserLoggedIn={isUserLoggedIn} />

      {/* ВЕРХ МОБИЛЬНОГО МЕНЮ */}
      {user && windowSize.innerWidth <= 980 && (
        <>
          {/* АВАТАР, БАЛЛАНС */}
          <Link
            to="/lk/profile-settings"
            className="menu__user js-have-to-handle"
            onClick={handleSetMenuOpenClick}
            data-testid="menu-link-to-profile"
          >
            <div className="menu__user-main">
              <div className="menu__user-avatar-wrap">
                <img
                  src={user.avatar ? avatarUrl + user.avatar : stdAvatar}
                  alt="Изображение пользователя"
                  className="menu__user-avatar"
                />
              </div>
              <div className="menu__user-info">
                {user.clientAccounts &&
                  Array.isArray(user.clientAccounts) &&
                  user.clientAccounts[0] && (
                    <div className="menu__user-balance">
                      {user.clientAccounts[0].balance !== 0 && (
                        <>
                          <span
                            className="menu__user-balance-rubles"
                            data-testid="menu-balance-rubles"
                          >
                            {formatNumber(Math.floor(user.clientAccounts[0].balance / 100))}
                          </span>
                          ,
                          <span
                            className="menu__user-balance-penny"
                            data-testid="menu-balance-penny"
                          >
                            {user.clientAccounts[0].balance.toString().slice(-2)} {'    '}
                          </span>
                        </>
                      )}
                      {user.clientAccounts[0].balance === 0 && (
                        <>
                          <span className="menu__user-balance-rubles">
                            {user.clientAccounts[0].balance}
                          </span>
                          ,<span className="menu__user-balance-penny">00 </span>
                        </>
                      )}
                      ₽
                    </div>
                  )}

                <div className="menu__user-profile-status">
                  {user.personal && user.group === AUTHORIZED && (
                    <IconShield className="menu__user-profile-status-icon menu__user-profile-status-icon_auth-status" />
                  )}

                  {user.personal && user.group === REGISTERED && (
                    <IconUser className="menu__user-profile-status-icon menu__user-profile-status-icon_test" />
                  )}

                  {user.personal && user.group === REGISTERED && (
                    <p className="menu__user-profile-status-text">Неавторизованная</p>
                  )}

                  {user.personal && user.group === AUTHORIZED && (
                    <p className="menu__user-profile-status-text">Авторизованная</p>
                  )}
                </div>
              </div>
            </div>

            <button className="menu__user-btn" type="button">
              <IconArrow className="menu__user-btn-icon" />
            </button>
          </Link>
          {/* /АВАТАР, БАЛЛАНС */}

          {/* ССЫЛКА НА ВИЗИТКУ */}
          {checkPaymentEnabled() && checkPaymentOnlyAuthorizedIdentifyEnabled(user) && (
            <div className="menu__user-business-link-block" data-testid="menu-link-business-card">
              <CopyToClipboard text={copiedUrl} onCopy={handleClickCopy}>
                <button type="button" className="menu__user-business-link">
                  <div className="menu__user-business-link-inner">
                    <IconCopyContent className="menu__icon-copy" />
                    Скопировать ссылку-визитку
                  </div>
                </button>
              </CopyToClipboard>

              {isCopied && <span className="menu__label-copy">скопировано</span>}

              <p className="menu__user-business-link-descr">
                Любой, у кого эта ссылка, может перевести вам деньги
              </p>
            </div>
          )}
          {/* /ССЫЛКА НА ВИЗИТКУ */}

          {/* ТИП УЧЕТКИ */}
          {((userTaxStatus && userTaxStatus === YES) ||
            (szStatusFromDb && szStatusFromDb === PARTLY_YES)) && (
            <Link
              to="/self-employment-from-lk"
              className="menu__user-status menu__user-status_self-employed js-have-to-handle"
              onClick={handleSetMenuOpenClick}
              data-testid="menu-sz-status"
            >
              Самозанятый
            </Link>
          )}

          {(!userTaxStatus ||
            (userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES))) && (
            <Link
              to="/self-employment-from-lk"
              className="menu__user-status js-have-to-handle"
              title="Получить статус самозанятого"
              onClick={handleSetMenuOpenClick}
              data-testid="menu-sz-status"
            >
              Физ лицо
            </Link>
          )}
          {/* /ТИП УЧЕТКИ */}
        </>
      )}
      {/* /ВЕРХ МОБИЛЬНОГО МЕНЮ */}

      {/* ПУНКТЫ МОБИЛЬНОГО МЕНЮ + мелким шрифтом */}
      <div className="menu__container">
        <div className="menu__content">
          {/* КРУПНЫЕ ПУНКТЫ */}
          <nav className="menu__main-nav">
            {/* ПУНКТЫ НЕ ЗАЛОГИНЕНОГО ЮЗЕРА */}
            {nonAuthUserTopMenuNavItems.map((item) => (
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={!!(isRendered && !user)}
                timeout={nonAuthUserMenuTimeOut[0]}
                unmountOnExit
                key={item.id}
              >
                <div className="menu__main-nav-item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.link}
                    className="menu__main-nav-item-link"
                    onClick={handleSetMenuOpenClick}
                  >
                    {item.title}
                  </a>
                </div>
              </CSSTransition>
            ))}
            {/* /ПУНКТЫ НЕ ЗАЛОГИНЕНОГО ЮЗЕРА */}

            {/* ПУНКТЫ ЗАЛОГИНЕНОГО ЮЗЕРА */}
            {authUserTopMenuNavItems.map((item) => (
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={item.animationIn}
                timeout={item.timeOut}
                unmountOnExit
                key={item.id}
              >
                <div className="menu__main-nav-item">
                  <Link
                    to={item.link}
                    className={cx('menu__main-nav-item-link js-have-to-handle', {
                      'menu__main-nav-item-link_has-notifications':
                        item.id === NOTIFICATIONS && user && user.unreadMessages > 0,
                    })}
                    onClick={handleSetMenuOpenClick}
                    data-testid={item.testId}
                  >
                    {item.title}
                  </Link>
                </div>
              </CSSTransition>
            ))}
            {/* /ПУНКТЫ ЗАЛОГИНЕНОГО ЮЗЕРА */}
          </nav>
          {/* /КРУПНЫЕ ПУНКТЫ */}

          {/* МЕЛКИЕ ПУНКТЫ */}
          <nav className="menu__sub-nav">
            {/* РЕГИСТРАЦИЯ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && !user)}
              timeout={nonAuthUserMenuTimeOut[4]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item animation-from-bottom-to-top">
                <Link
                  to="/register"
                  className="menu__sub-nav-item-link"
                  onClick={handleSetMenuOpenClick}
                >
                  Регистрация
                </Link>
              </div>
            </CSSTransition>
            {/* /РЕГИСТРАЦИЯ */}

            {/* ВХОД */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && !user)}
              timeout={nonAuthUserMenuTimeOut[5]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item animation-from-bottom-to-top">
                <Link
                  to="/login"
                  className="menu__sub-nav-item-link"
                  onClick={handleSetMenuOpenClick}
                >
                  Вход
                </Link>
              </div>
            </CSSTransition>
            {/* /ВХОД */}

            {/* ЧАТ ПОДДЕРЖКИ ДЛЯ НЕЗАРЕГАННОГО ЮЗЕРА */}
            {/* <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && !user)}
              timeout={nonAuthUserMenuTimeOut[5]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item">
                <button type="button" className="menu__sub-nav-item-link js-help">
                  Чат поддержки
                </button>
              </div>
            </CSSTransition> */}
            {/* /ЧАТ ПОДДЕРЖКИ ДЛЯ НЕЗАРЕГАННОГО ЮЗЕРА */}

            {/* ПРОФИЛЬ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && user)}
              timeout={nonAuthUserMenuTimeOut[3]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item">
                <Link
                  to="/lk/profile-settings"
                  className="menu__sub-nav-item-link js-have-to-handle"
                  onClick={handleSetMenuOpenClick}
                >
                  Профиль
                </Link>
              </div>
            </CSSTransition>
            {/* /ПРОФИЛЬ */}

            {/* ВЫЙТИ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && user)}
              timeout={nonAuthUserMenuTimeOut[5]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item">
                <button
                  type="button"
                  className="menu__sub-nav-item-link"
                  onClick={handleLogoutClick}
                >
                  Выйти
                </button>
              </div>
            </CSSTransition>
            {/* /ВЫЙТИ */}

            {/* TODO: PRO-384 */}
            {/* ЧАТ ПОДДЕРЖКИ */}
            {/* <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!!(isRendered && user && windowSize.innerWidth < 700)}
              timeout={nonAuthUserMenuTimeOut[5]}
              unmountOnExit
            >
              <div className="menu__sub-nav-item">
                <button type="button" className="menu__sub-nav-item-link js-help">
                  Чат поддержки
                </button>
              </div>
            </CSSTransition> */}
            {/* /ЧАТ ПОДДЕРЖКИ */}
          </nav>
          {/* /МЕЛКИЕ ПУНКТЫ */}
        </div>
      </div>
      {/* /ПУНКТЫ МОБИЛЬНОГО МЕНЮ + мелким шрифтом */}

      {/* ССЫЛКИ НА АППЫ ДЛЯ МОБИЛЬНОГО МЕНЮ */}
      <div className="menu__container menu__container_app">
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!!isRendered}
          timeout={nonAuthUserMenuTimeOut[5]}
          unmountOnExit
        >
          <div className="menu__container_app-offset">
            <FooterAppLinks />
          </div>
        </CSSTransition>
      </div>
      {/* /ССЫЛКИ НА АППЫ ДЛЯ МОБИЛЬНОГО МЕНЮ */}
    </div>
  );
};

Menu.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    group: PropTypes.string,
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
    randomUniqueId: PropTypes.string,
    personal: PropTypes.shape({}),
    taxStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
    unreadMessages: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  userTaxStatus: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
};

Menu.defaultProps = {
  user: null,
  className: null,
  userTaxStatus: null,
  szStatusFromDb: '',
  isUserLoggedIn: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(Menu);

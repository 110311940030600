/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { useDebounce } from '@react-hook/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as IconCloseSmall } from 'static/assets/icon-close-small.svg';
import { ReactComponent as IconSearch } from 'static/assets/icon-search.svg';

export const SearchInput = ({ text = null, onText, placeholder }) => {
  const [currentValue, setCurrentValue] = useState(null);
  const [debounceValue, setDebounceValue] = useDebounce(null, 500);

  const clearInputText = () => {
    onText('');
    setCurrentValue('');
    setDebounceValue('');
  };

  const onTextChange = (event) => {
    setDebounceValue(event.target.value);
    setCurrentValue(event.target.value);
  };

  useEffect(() => {
    if (debounceValue !== null) onText(debounceValue);
  }, [debounceValue, onText]);

  useEffect(() => {
    setCurrentValue(text === null ? '' : text);
  }, [text]);

  return (
    <InputWraperStyled isEmptyText={!text} isText={!!text}>
      <InputStyled
        type="text"
        onChange={onTextChange}
        value={currentValue}
        placeholder={placeholder}
      />
      {currentValue ? <IconCloseSmall onClick={clearInputText} /> : <IconSearch />}
    </InputWraperStyled>
  );
};

SearchInput.propTypes = {
  text: PropTypes.string.isRequired,
  onText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: '',
};

const InputWraperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-right: 10px;
  border: 1px solid var(--pale-grey);
  border-radius: 8px;
  transition: border-color 0.3s;
  cursor: pointer;
  ${({ isText }) =>
    isText &&
    css`
      border-color: var(--dark-slate-blue);
    `}
  &:hover,
  &:focus-within {
    border-color: var(--dark-slate-blue);
  }
`;

const InputStyled = styled.input`
  @include regular();
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 10px 16px;
  border: 0;
  line-height: 20px;
  font-size: 14px;
  color: var(--dark-slate-blue);
  cursor: pointer;
  &:focus-within {
    cursor: initial;
  }

  &::placeholder {
    color: var(--normal-grey-blue);
  }
  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import formatNumber from 'helpers/formatNumber';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import './TaxesToPayCardBrief.scss';

const TaxesToPayCardBrief = ({ title, isLoading, amount, className }) => {
  return (
    <div
      className={cx('taxes-to-pay-card-brief', {
        [className]: className,
      })}
    >
      <div className="taxes-to-pay-card-brief__text">{title}:</div>

      {isLoading ? (
        <CircleLoader width={15} height={15} />
      ) : (
        <>
          {amount ? (
            <div className="taxes-to-pay-card-brief__sum">
              {formatNumber(Math.floor(amount / 100))},{amount.toString().slice(-2)} {'    '} ₽
            </div>
          ) : (
            <div className="taxes-to-pay-card-brief__sum">0,00 ₽</div>
          )}
        </>
      )}
    </div>
  );
};

TaxesToPayCardBrief.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  amount: PropTypes.number,
  className: PropTypes.string,
};

TaxesToPayCardBrief.defaultProps = {
  className: null,
  amount: null,
};

export default TaxesToPayCardBrief;

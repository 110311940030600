import useWindowSize from '@rehooks/window-size';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  EmailShareButton,
  //TODO?: функционал был выключен в рамках задачи SWPS-192
  //TODO?: функционал был выключен в в связи блокировкой на территории РФ
  // FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import { CSSTransition } from 'react-transition-group';

import { deleteCheck, getIncomeCheckById, registerIncome } from 'modules/profileLegasy/actions';
import Check from 'modules/profileLegasy/components/Check/Check';
import HintHowTaxIsCalculated from 'modules/profileLegasy/components/Hint/HintHowTaxIsCalculated';
import HintHowToPayTax from 'modules/profileLegasy/components/Hint/HintHowToPayTax';
import HintWhenToPayTax from 'modules/profileLegasy/components/Hint/HintWhenToPayTax';
import HintWhyRegisterIncome from 'modules/profileLegasy/components/Hint/HintWhyRegisterIncome';
import HintAnnulCheck from 'modules/profileLegasy/components/HintAnnulCheck/HintAnnulCheck';
import IncomeRegistrationCard from 'modules/profileLegasy/components/IncomeRegistrationCard/IncomeRegistrationCard';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import HintsList from 'modules/shared/components/HintsList/HintsList';

import {
  HINT_TIMEOUTS,
  PARTLY_YES,
  PAYMENT_TRANSFER_FAIL as STATUS_FAIL,
  PAYMENT_TRANSFER_LOADING as STATUS_LOADING,
  PAYMENT_TRANSFER_OK as STATUS_OK,
  YES,
  CONSISTENT_ANIMATIONS_DELAY,
} from 'constants/index';
import downloadFile from 'helpers/downloadFile';

import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import { ReactComponent as IconEnvelope } from 'static/assets/icon-envelope.svg';
//TODO?: функционал был выключен в рамках задачи SWPS-192
//TODO?: функционал был выключен в в связи блокировкой на территории РФ
// import { ReactComponent as IconFbShare } from 'static/assets/icon-fb-share.svg';
import { ReactComponent as IconShare } from 'static/assets/icon-share.svg';
import { ReactComponent as IconShareTg } from 'static/assets/icon-share-tg.svg';
import { ReactComponent as IconShareVk } from 'static/assets/icon-share-vk.svg';
import { ReactComponent as IconShareWa } from 'static/assets/icon-share-wa.svg';
import { ReactComponent as IconShareOk } from 'static/assets/icon-share-ok.svg';

import profileBg from 'static/assets/profile-bg.png';

// Styles
import './IncomeRegistration.scss';

const IncomeRegistration = ({ dispatch, incomeCheckData, szStatusFromDb, userTaxStatus }) => {
  const windowSize = useWindowSize();
  const [redirectOnLk, setRedirectOnLk] = useState(false);

  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [currentOpenPopupId, setCurrentOpenPopupId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isIncomeRegistered, setIsIncomeRegistered] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const [annulCheckStatus, setAnnulCheckStatus] = useState(null);
  const [annulCheckError, setAnnulCheckError] = useState(null);

  const [checkData, setCheckData] = useState(incomeCheckData);

  /** Копирование ссылки */
  const [url, setUrl] = useState(incomeCheckData ? incomeCheckData.link : '');
  const [isCopied, setIsCopied] = useState(false);

  /** Открыть попап - зачем регистрировать налог */
  const handleClickWhyRegisterATax = () => {
    toggleDrop();
    setCurrentOpenPopupId(1);
  };

  /** Открыть попап - как расчитывается налог */
  const handleClickHowTaxIsCalculated = () => {
    toggleDrop();
    setCurrentOpenPopupId(2);
  };

  /** Открыть попап - как оплатить налог */
  const handleClickHowToPayTax = () => {
    toggleDrop();
    setCurrentOpenPopupId(3);
  };

  /** Открыть попап - когда платить налоги */
  const handleClickWhenToPayTaxes = () => {
    toggleDrop();
    setCurrentOpenPopupId(4);
  };

  /** Открыть попап - Аннулировать чек */
  const handleClickAnnulBill = () => {
    toggleDrop();
    setCurrentOpenPopupId(5);
  };

  /** Закрытие любого попапа */
  const closeHintHandler = () => {
    closeExplicitly();
    setCurrentOpenPopupId(null);
  };

  /** Создание счета */
  const startRegisterIncome = (data) => {
    setIsLoading(true);
    setIsFailed(false);
    setCheckData(null); // <= Стираем данные по предыдущему чеку

    registerIncome(data)
      .then((res) => {
        if (res && res.data && res.data.dealReceiptId && res.data.operationTime) {
          return dispatch(getIncomeCheckById(res.data.dealReceiptId, res.data.operationTime));
        }
        setIsLoading(false);

        throw new Error('Структура данных с сервера не ок');
      })
      .then((res) => {
        if (res && res.data) {
          setIsIncomeRegistered(true);
          setCheckData(res.data);

          /** Ставим ссылку в стейт для хуки копирования в буфер */
          setUrl(res.data.link);
        }
        throw new Error('Структура данных с сервера не ок');
      })
      .catch(() => {
        setIsLoading(false);
        setIsFailed(true);
      });
  };

  /** Повторить операцию */
  const handleRepeatOperationClick = () => {
    setIsIncomeRegistered(false);
    setIsFailed(false);
  };

  /** Аннулировать чек */
  const handleAnnulButtonClick = (event, reason) => {
    setAnnulCheckError(null);
    event.stopPropagation();

    if (!checkData) {
      return;
    }

    setAnnulCheckStatus(STATUS_LOADING);
    dispatch(deleteCheck(checkData.id, reason))
      .then(() => {
        setAnnulCheckStatus(STATUS_OK);
        setCheckData({
          ...checkData,
          canceled: true,
        });
      })
      .catch((res) => {
        setAnnulCheckStatus(STATUS_FAIL);
        setAnnulCheckError(res ? res.result.message : 'Чек не был аннулирован, попробуйте еще');
      });
  };

  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Отчетность со страницы зарегистрированного дохода, чтобы открыть там нужную вкладку
   * */
  const handleRequestsAccountingLink = () => {
    window.localStorage.setItem('accountingTab', '1');
  };

  /**
   * Устанавливаем нужный этап в Регистрации дохода,
   *  если в localStorage приходит параметр isIncomeRegistered
   *  */
  useEffect(() => {
    const incomeRegistered =
      window && window.localStorage ? window.localStorage.getItem('isIncomeRegistered') : '0';

    if (incomeRegistered && (incomeRegistered === '0' || incomeRegistered === '1')) {
      setIsIncomeRegistered(true);
      if (window && window.localStorage) {
        window.localStorage.removeItem('isIncomeRegistered');
      }
    }
  }, [isIncomeRegistered]);

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Скачиваем картинку чека */
  const downloadImageCheck = (downloadUrl, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', downloadUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        const file = new Blob([xhr.response], { type: 'image/jpeg' });
        downloadFile(file, `${fileName}.jpg`);
      }
    };

    xhr.send();
  };

  /**
   * Проверяекм, сз ли юзер:
   * если у него статус не YES и при этом нет
   * дополнительного статуса szStatusFromDb = PARTLY_YES,
   * то редиректим на личный кабинет
   * */
  useEffect(() => {
    if (
      !userTaxStatus ||
      (userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES))
    ) {
      setRedirectOnLk(true);
    }
  }, [userTaxStatus, szStatusFromDb]);

  const hints = [
    {
      id: 1,
      title: 'Зачем регистрировать доход?',
      titleForPopup: 'Зачем регистрировать доход?',
      action: handleClickWhyRegisterATax,
      openCondition: currentOpenPopupId === 1 && isDropOpen,
      popup: <HintWhyRegisterIncome />,
      dropEl,
    },
    {
      id: 2,
      title: 'Как высчитывается налог?',
      titleForPopup: 'Как высчитывается налог?',
      action: handleClickHowTaxIsCalculated,
      openCondition: currentOpenPopupId === 2 && isDropOpen,
      popup: <HintHowTaxIsCalculated />,
      dropEl,
    },
    {
      id: 3,
      title: 'Как оплатить налог?',
      titleForPopup: 'Как оплатить налог?',
      action: handleClickHowToPayTax,
      openCondition: currentOpenPopupId === 3 && isDropOpen,
      popup: <HintHowToPayTax />,
      dropEl,
    },
    {
      id: 4,
      title: 'Когда нужно оплачивать налог?',
      titleForPopup: 'Когда нужно оплачивать налог?',
      action: handleClickWhenToPayTaxes,
      openCondition: currentOpenPopupId === 4 && isDropOpen,
      popup: <HintWhenToPayTax />,
      dropEl,
    },
  ];

  /** Редирект на ЛК */
  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  return (
    <div className="income-registration">
      {windowSize.innerWidth > 980 && (
        <>
          {!isIncomeRegistered && (
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="income-registration__top">
                <h1 className="income-registration__title">Регистрация дохода</h1>
              </div>
            </CSSTransition>
          )}

          <CSSTransition
            classNames="animation-text"
            in={isRendered}
            timeout={{
              enter: 100,
              exit: 550,
            }}
            unmountOnExit
          >
            <>
              {isIncomeRegistered && (
                <div className="income-registration__top">
                  <h1 className="income-registration__title">Зарегистрирован доход</h1>
                </div>
              )}
            </>
          </CSSTransition>
        </>
      )}

      {windowSize.innerWidth <= 980 && (
        <div className="income-registration__header">
          <div className="income-registration__header-icon-wrap">
            <IconBag className="income-registration__header-icon" />
          </div>

          {!isIncomeRegistered && (
            <h1 className="income-registration__header-title">Регистрация дохода</h1>
          )}
          {isIncomeRegistered && (
            <h1 className="income-registration__header-title">Зарегистрирован доход</h1>
          )}

          <img src={profileBg} alt="Фоновая картинка" className="money-request__header__bg" />
        </div>
      )}

      {/* ФОРМА ДЛЯ РЕГИСТРАЦИИ ДОХОДА */}
      {!isIncomeRegistered && (
        <div className="income-registration__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="income-registration__card-wrap">
              <IncomeRegistrationCard
                className="income-registration__card"
                onSubmit={startRegisterIncome}
                isLoading={isLoading}
                isFailed={isFailed}
                handleRepeatOperationClick={handleRepeatOperationClick}
              />
            </div>
          </CSSTransition>
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="income-registration__useful-links">
              <HintsList hints={hints} closeHintHandler={closeHintHandler} />
            </div>
          </CSSTransition>
        </div>
      )}
      {/* /ФОРМА ДЛЯ РЕГИСТРАЦИИ ДОХОДА */}

      {/* ГОТОВЫЙ ЧЕК */}
      <CSSTransition
        in={isRendered}
        timeout={600}
        unmountOnExit
        classNames="animation-from-bottom-to-top"
      >
        <>
          {isIncomeRegistered && checkData && (
            <div className="income-registration__content">
              <div className="income-registration__bill-wrap">
                {/* НА МОБАЙЛЕ ЭТОТ ТЕКСТ ПЕРЕД ЧЕКОМ  */}
                {windowSize.innerWidth <= 980 && (
                  <div className="income-registration__success-tip">
                    Вы зарегистрировали доход. Теперь можете отправить чек покупателю, а ваш доход
                    уже зарегистрирован в ФНС и автоматически начислен на странице
                    <Link
                      to="/lk/accounting"
                      className="income-registration__success-tip-link"
                      onClick={handleRequestsAccountingLink}
                    >
                      &quot;Отчетность&quot;
                    </Link>
                  </div>
                )}
                {/* /НА МОБАЙЛЕ ЭТОТ ТЕКСТ ПЕРЕД ЧЕКОМ  */}

                {/* ЧЕК */}
                <Check className="income-registration__bill" checkData={checkData} />
                {/* /ЧЕК */}
              </div>

              <div className="income-registration__success">
                {/* ЭТОТ ТЕКСТ НА ДЕСКТОПЕ СПРАВА ОТ ЧЕКА */}
                {windowSize.innerWidth > 980 && (
                  <div className="income-registration__success-tip">
                    Вы зарегистрировали доход. Теперь можете отправить чек покупателю, а ваш доход
                    уже зарегистрирован в ФНС и автоматически начислен на странице
                    <Link
                      to="/lk/accounting"
                      className="income-registration__success-tip-link"
                      onClick={handleRequestsAccountingLink}
                    >
                      &quot;Отчетность&quot;
                    </Link>
                  </div>
                )}
                {/* /ЭТОТ ТЕКСТ НА ДЕСКТОПЕ СПРАВА ОТ ЧЕКА */}

                {/* ПОЛЕЗНЫЕ ССЫЛКИ */}
                <div className="income-registration__useful-links">
                  <p className="income-registration__useful-links-title">Вы можете</p>

                  <div className="income-registration__useful-links-list">
                    {/* РАСШАРИВАНИЕ ЧЕКА РАЗНЫМИ СПОСОБАМИ */}
                    <div className="income-registration__share-block">
                      <span className="income-registration__share-text">Отправить чек:</span>

                      <div className="income-registration__share-list">
                        <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                          <button className="income-registration__share-item" type="button">
                            <IconShare className="income-registration__share-item-icon income-registration__share-item-icon_share" />
                          </button>
                        </CopyToClipboard>

                        <EmailShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconEnvelope className="income-registration__share-item-icon income-registration__share-item-icon_envelope" />
                        </EmailShareButton>

                        <VKShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconShareVk className="income-registration__share-item-icon income-registration__share-item-icon_vk" />
                        </VKShareButton>

                        <TelegramShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconShareTg className="income-registration__share-item-icon income-registration__share-item-icon_tg" />
                        </TelegramShareButton>

                        <OKShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconShareOk className="income-registration__share-item-icon income-registration__share-item-icon_ok" />
                        </OKShareButton>

                        <WhatsappShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconShareWa className="income-registration__share-item-icon income-registration__share-item-icon_wa" />
                        </WhatsappShareButton>

                        {
                          //TODO?: функционал был выключен в рамках задачи SWPS-192
                          //TODO?: функционал был выключен в в связи блокировкой на территории РФ
                        }
                        {/* <FacebookShareButton
                          className="income-registration__share-item"
                          url={checkData.link}
                        >
                          <IconFbShare className="income-registration__share-item-icon income-registration__share-item-icon_fb" />
                        </FacebookShareButton> */}

                        {isCopied && (
                          <p className="income-registration__share-link-copied">скопировано</p>
                        )}
                      </div>
                    </div>
                    {/* /РАСШАРИВАНИЕ ЧЕКА РАЗНЫМИ СПОСОБАМИ */}

                    {/* ССЫЛКА НА ВСЕ ЧЕКИ - В ОТЧЕТНОСТЬ */}
                    <div className="income-registration__useful-links-item-wrap">
                      <Link
                        to="/lk/accounting"
                        className="income-registration__useful-links-item"
                        onClick={handleRequestsAccountingLink}
                      >
                        <span className="income-registration__useful-links-item-text">
                          Посмотреть все чеки
                        </span>
                      </Link>
                    </div>
                    {/* /ССЫЛКА НА ВСЕ ЧЕКИ - В ОТЧЕТНОСТЬ */}

                    {/* КНОПКА - АННУЛИРВОАТЬ ЧЕК */}
                    {checkData && !checkData.canceled && (
                      <div className="income-registration__useful-links-item-wrap">
                        <button
                          type="button"
                          onClick={handleClickAnnulBill}
                          className="income-registration__useful-links-item"
                          disabled={!checkData.checkAvailability}
                        >
                          <span className="income-registration__useful-links-item-text">
                            Аннулировать чек
                          </span>
                        </button>
                      </div>
                    )}
                    {/* /КНОПКА - АННУЛИРВОАТЬ ЧЕК */}

                    {/* КНОПКА - ПОВТОРИТЬ ОПЕРАЦИЮ */}
                    <div className="income-registration__useful-links-item-wrap">
                      <button
                        type="button"
                        onClick={handleRepeatOperationClick}
                        className="income-registration__useful-links-item"
                      >
                        <span className="income-registration__useful-links-item-text">
                          Повторить операцию
                        </span>
                      </button>
                    </div>
                    {/* /КНОПКА - ПОВТОРИТЬ ОПЕРАЦИЮ */}

                    {/* КНОПКА - СОХРАНИТЬ ЧЕК */}
                    <div className="income-registration__useful-links-item-wrap">
                      <button
                        onClick={() => downloadImageCheck(checkData.link, checkData.id)}
                        className="income-registration__useful-links-item"
                        type="button"
                        disabled={!checkData.checkAvailability}
                      >
                        <span className="income-registration__useful-links-item-text">
                          Сохранить чек
                        </span>
                      </button>
                    </div>
                    {/* /КНОПКА - СОХРАНИТЬ ЧЕК */}
                  </div>
                </div>
                {/* /ПОЛЕЗНЫЕ ССЫЛКИ */}
              </div>
            </div>
          )}
        </>
      </CSSTransition>
      {/* /ГОТОВЫЙ ЧЕК */}

      {/* ПОПАП - АННУЛИРОВАТЬ ЧЕК */}
      {checkData && (
        <CSSTransition
          in={isDropOpen && currentOpenPopupId === 5}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
          classNames="hint-auth"
        >
          <HintAuth
            ref={dropEl}
            onCloseBtnClick={closeHintHandler}
            className="income-registration__hint"
          >
            <HintAnnulCheck
              onSubmit={handleAnnulButtonClick}
              status={annulCheckStatus}
              error={annulCheckError}
              key={checkData.id}
              closeExplicitly={closeHintHandler}
              szStatusFromDb={szStatusFromDb}
            />
          </HintAuth>
        </CSSTransition>
      )}
      {/* /ПОПАП - АННУЛИРОВАТЬ ЧЕК */}
    </div>
  );
};

IncomeRegistration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  incomeCheckData: PropTypes.shape({
    link: PropTypes.string,
  }),
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
};

IncomeRegistration.defaultProps = {
  incomeCheckData: null,
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  incomeCheckData: state.profile.incomeCheckData,
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(IncomeRegistration);

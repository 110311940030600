import React from 'react';

const HintWhatIsAcquiring = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      Чтобы принимать платежи на сайте от покупателей, нужно интегрировать магазин к вашему
      кошельку. Сервис эквайринга, позволяет разместить форму для оплаты на сайте и принимать
      платежи. После оплаты, полученные деньги будут автоматически зачисляться на ваш кошелек. А
      покупатели получать чеки.
    </div>
  </div>
);

export default HintWhatIsAcquiring;

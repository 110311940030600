import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Images
import iconCup from 'static/assets/icon-cup.png';
// Icon
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';

// Styles
import './AuthStatusResult.scss';

export const FAIL = 'auth-status__fail';
export const FAIL_ANOTHER_INN = 'auth-status__fail auth-status__fail_another-inn';
export const SUCCESS = 'auth-status__success';
export const WAITING = 'auth-status__waiting';
export const IN_PROGRESS = 'auth-status__waiting';

const AuthStatusResult = (props) => {
  const { title, desc, tipText, className, resetAuthStep, children } = props;
  const windowSize = useWindowSize();

  const CircleProgressBigColor = '#ff2d55';
  const CircleProgressBigValue = 1;

  return (
    <div
      className={cx('auth-status-result', {
        [className]: className,
      })}
    >
      <div className="auth-status-result__top">
        {className === SUCCESS && (
          <img src={iconCup} className="auth-status-result__top-icon" alt="Кубок" />
        )}

        {className === WAITING && (
          <>
            <CircleLoader
              className="auth-status-result__top-loader"
              mainColor="rgba(40, 65, 149, 0.1)"
              spinnerColor="#b0b5c4"
              width={60}
              height={60}
              borderWidth={2}
            />
            <IconLockClosed className="auth-status-result__top-loader-icon" />
          </>
        )}

        {(className === FAIL || className === FAIL_ANOTHER_INN) && (
          <>
            <CircleProgress
              className="auth-status-result__top-fail-progress"
              color={CircleProgressBigColor}
              value={CircleProgressBigValue}
              radius={28}
            />
            <IconLockClosed className="auth-status-result__top-fail-progress-icon" />
          </>
        )}
      </div>

      {title && <h1 className="auth-status-result__title">{title}</h1>}

      {desc && <div className="auth-status-result__desc">{desc}</div>}

      {children}

      {className !== FAIL && className !== FAIL_ANOTHER_INN && (
        <div className="auth-status-result__link-wrap">
          <Link
            to="/lk/profile-settings/account-type"
            className="auth-status-result__link"
            onClick={resetAuthStep}
            data-testid="return-to-profile-settings"
          >
            Перейти в профиль
          </Link>
        </div>
      )}

      {(className === FAIL || className === FAIL_ANOTHER_INN) && (
        <div className="auth-status-result__link-wrap">
          <button
            type="button"
            className="auth-status-result__link"
            onClick={resetAuthStep}
            data-testid="return-to-profile-settings"
          >
            Перейти в профиль
          </button>
        </div>
      )}

      {tipText && windowSize.innerWidth > 700 && (
        <div className="auth-status-result__tip">{tipText}</div>
      )}
    </div>
  );
};

AuthStatusResult.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  desc: PropTypes.string,
  tipText: PropTypes.string,
  resetAuthStep: PropTypes.func,
};

AuthStatusResult.defaultProps = {
  children: null,
  title: null,
  desc: null,
  tipText: null,
  resetAuthStep: () => {},
};

export default AuthStatusResult;

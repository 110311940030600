import React from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '@rehooks/window-size';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { ReactComponent as IconLock } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconLockOpen } from 'static/assets/icon-lock.svg';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index.js';

import './GetSzInnInput.scss';

const FAIL = 'FAIL';
const SUCCESS = 'SUCCESS';
const WAITING = 'WAITING';

const GetSzInnInput = ({
  value,
  onChangeInn,
  className,
  status,
  disabled,
  readOnly,
  isEditButton,
  isLockVisible,
  onEnterInn,
  isValid,
  placeholder,
}) => {
  const windowSize = useWindowSize();

  return (
    <div
      className={cx('get-sz-inn-input', {
        [className]: className,
        'get-sz-inn-input_fail': status === FAIL,
        'get-sz-inn-input_success': status === SUCCESS,
      })}
      data-testid="get-sz-inn-input-wrap"
    >
      <InputMask
        type="tel"
        mask="9999-9999-9999"
        className="get-sz-inn-input__input"
        onChange={onEnterInn}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        maskChar={null}
        data-testid="get-sz-inn-input"
        placeholder={placeholder}
      />
      {isEditButton && (
        <button
          className="get-sz-inn-input__edit"
          type="button"
          onClick={onChangeInn}
          data-testid="get-sz-inn-input-edit"
        >
          <IconEdit className="get-sz-inn-input__edit-icon" />
        </button>
      )}

      {isLockVisible && status === FAIL && <IconLock className="get-sz-inn-input__lock" />}
      {isLockVisible && status === SUCCESS && <IconLockOpen className="get-sz-inn-input__lock" />}

      {status === WAITING && (
        <div className="get-sz-inn-input__loader-wrap">
          {windowSize.innerWidth > 980 ? (
            <>
              <CircleLoader
                className="get-sz-inn-input__loader"
                width={60}
                height={60}
                mainColor="rgba(255, 255, 255, 0.1)"
                spinnerColor="#ffffff"
              />
              <IconLock className="get-sz-inn-input__loader-icon" />
            </>
          ) : (
            <CircleLoader
              className="get-sz-inn-input__small-loader"
              width={15}
              height={15}
              borderWidth={1}
            />
          )}
        </div>
      )}

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isValid}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <IconCheck className="get-sz-inn-input__check" />
      </CSSTransition>
    </div>
  );
};

GetSzInnInput.propTypes = {
  value: PropTypes.string,
  onChangeInn: PropTypes.func,
  onEnterInn: PropTypes.func,
  className: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isEditButton: PropTypes.bool,
  isLockVisible: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
};

GetSzInnInput.defaultProps = {
  status: null,
  value: '',
  onChangeInn: () => {},
  onEnterInn: () => {},
  className: null,
  disabled: false,
  readOnly: false,
  isEditButton: false,
  isLockVisible: true,
  isValid: false,
  placeholder: null,
};

export default GetSzInnInput;

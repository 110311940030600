import cx from 'classnames';
// Misc
import { disableRussianLetters, disableSpaceEnter, validateEmail } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

// Components
import CircleLoader from 'modules/authLegasy/components/CircleLoader/CircleLoader';
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';

import { ReactComponent as IconCheck } from 'static/assets/icon-check-green.svg';
// Icons
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';

// Styles
import './ChangeEmail.scss';

const ChangeEmail = (props) => {
  const {
    className,
    isMenuOpen,
    onSetNewEmail,
    onChangeEmail,
    isLoadingEmailChange,
    isNewEmailOk,
    isNewEmailFailed,
    newEmailErrorMessage,
    email,
  } = props;
  const [newEmail, setNewEmail] = useState('');
  const [isRendered, setIsRendered] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const duration = [
    {
      enter: 100,
      exit: 250,
    },
    {
      enter: 150,
      exit: 200,
    },
    {
      enter: 200,
      exit: 150,
    },
    {
      enter: 250,
      exit: 100,
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    setIsEmailValid(validateEmail(newEmail) && newEmail.length <= 40);
  }, [newEmail]);

  const handleInputChange = (event) => {
    setNewEmail(disableRussianLetters(disableSpaceEnter(event.target.value)));
  };

  // Define props for CircleProgress depend on validity od password
  const circleProgressValueFail = 66;
  const circleProgressValueSuccess = 0;
  const circleProgressColorFail = '#ff2d55';
  const circleProgressColorSuccess = '#00adb5';

  return (
    <div
      className={cx('change-email', {
        [className]: className,
      })}
    >
      <div className="change-email__input-block">
        {/* ИНПУТ */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered}
          timeout={duration[1]}
          unmountOnExit
        >
          <div className="change-email__input-holder change-email__input-holder_email">
            <input
              type="text"
              className="change-email__input"
              placeholder="me@mail.ru"
              onChange={handleInputChange}
              value={newEmail}
            />
          </div>
        </CSSTransition>
        {/* /ИНПУТ */}

        {/* ЛОАДЕР */}
        {isLoadingEmailChange && (
          <div className="change-email__input-loader-wrap change-email__input-loader-wrap_loading">
            <CircleLoader className="change-email__input-loader" />
            <IconLockClosed className="change-email__input-loader-icon change-email__input-loader-icon_loading" />
          </div>
        )}
        {/* /ЛОАДЕР */}

        {/* ПРОГРЕСС БАР ПРИ УДАЧЕ */}
        {isNewEmailOk && !isLoadingEmailChange && !isNewEmailFailed && (
          <div className="change-email__input-loader-wrap">
            <CircleProgress
              color={circleProgressColorSuccess}
              value={circleProgressValueSuccess}
              className="change-email__input-loader"
              radius={28}
            />
            <IconCheck className="change-email__input-loader-icon change-email__input-loader-icon_check" />
          </div>
        )}
        {/* /ПРОГРЕСС БАР ПРИ УДАЧЕ */}

        {/* ПРОГРЕСС БАР ПРИ НЕУДАЧЕ */}
        {isNewEmailFailed && !isLoadingEmailChange && !isNewEmailOk && (
          <div className="change-email__input-loader-wrap change-email__input-loader-wrap_fail">
            <CircleProgress
              color={circleProgressColorFail}
              value={circleProgressValueFail}
              className="change-email__input-loader"
              radius={28}
            />
            <IconLockClosed className="change-email__input-loader-icon change-email__input-loader-icon_fail" />
          </div>
        )}
        {/* /ПРОГРЕСС БАР ПРИ НЕУДАЧЕ */}
      </div>

      {/* СООБЩЕНИЕ ПРИ НЕУДАЧЕ */}
      {isNewEmailFailed && !isLoadingEmailChange && !isLoadingEmailChange && !isNewEmailOk && (
        <div className="change-email__tip change-email__tip_error">{newEmailErrorMessage}</div>
      )}
      {/* СООБЩЕНИЕ ПРИ НЕУДАЧЕ */}

      <div className="change-email__btn-wrap">
        {/* ТЕКСТ ДЛЯ КНОПКИ, ЕЛСИ МЫ МЕНЯЕМ E-MAIL */}
        {email !== '' && (
          <button
            className={cx('change-email__btn', {
              'change-email__btn_disabled': !isEmailValid,
            })}
            type="button"
            onClick={() => {
              onChangeEmail(newEmail);
            }}
            disabled={!isEmailValid}
          >
            Сменить
          </button>
        )}
        {/* /ТЕКСТ ДЛЯ КНОПКИ, ЕЛСИ МЫ МЕНЯЕМ E-MAIL */}

        {/* ТЕКСТ ДЛЯ КНОПКИ, ЕЛСИ МЫ УСТАНАВЛИВАЕМ E-MAIL */}
        {email === '' && (
          <button
            className={cx('change-email__btn', {
              'change-email__btn_disabled': !isEmailValid,
            })}
            type="button"
            onClick={() => onSetNewEmail(newEmail)}
            disabled={!isEmailValid}
          >
            Установить
          </button>
        )}
        {/* /ТЕКСТ ДЛЯ КНОПКИ, ЕЛСИ МЫ УСТАНАВЛИВАЕМ E-MAIL */}
      </div>
    </div>
  );
};

ChangeEmail.propTypes = {
  className: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
  onSetNewEmail: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  isLoadingEmailChange: PropTypes.bool.isRequired,
  isNewEmailOk: PropTypes.bool.isRequired,
  isNewEmailFailed: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  newEmailErrorMessage: PropTypes.string,
};

ChangeEmail.defaultProps = {
  className: null,
  newEmailErrorMessage: null,
};

export default ChangeEmail;

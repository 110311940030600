import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ACCOUNTS_TYPES, AccountTypeDetailsWidget } from 'modules/profile';

import {
  GetIdentificationOfLetterTemplate,
  GetIdentificationOfSelfWorkTemplate,
  GetIdentificationOfSvyaznoyTemplate,
} from './templates';

export const IdentificationAccountDetailsTemplate = () => {
  const match = useRouteMatch();
  const { path, url } = match;
  return (
    <>
      <Switch>
        <Route path={`${path}/get-authorization-of-selfwork/`}>
          <GetIdentificationOfSelfWorkTemplate />
        </Route>
        <Route path={`${path}/get-authorization-of-svyaznoy/`}>
          <GetIdentificationOfSvyaznoyTemplate />
        </Route>
        <Route path={`${path}/get-authorization-of-letter/`}>
          <GetIdentificationOfLetterTemplate />
        </Route>
        <Route exact path={path}>
          <AccountTypeDetailsWidget
            filterGroup={ACCOUNTS_TYPES.IDENTIFIED}
            urlToGetIdentificationOfSelfWorkPage={`${url}/get-authorization-of-selfwork`}
            urlToGetIdentificationOfSvyaznoyPage={`${url}/get-authorization-of-svyaznoy`}
            urlToGetIdentificationOfLetterPage={`${url}/get-authorization-of-letter`}
          />
        </Route>
      </Switch>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconPasswordClosed } from 'static/assets/icon-password-closed.svg';
import { ReactComponent as IconPasswordOpen } from 'static/assets/icon-password-open.svg';

import './ShowHidePasswordButton.scss';

const ShowHidePasswordButton = ({ isPasswordVisible, changePasswordVisibility, className }) => {
  return (
    <button
      className={cx('password-visibility-button', {
        [className]: className,
      })}
      type="button"
      onClick={changePasswordVisibility}
      data-testid="password-visibility-button"
    >
      {isPasswordVisible ? (
        <IconPasswordOpen className="password-visibility-button__icon password-visibility-button__icon_opened" />
      ) : (
        <IconPasswordClosed className="password-visibility-button__icon password-visibility-button__icon_closed" />
      )}
    </button>
  );
};

ShowHidePasswordButton.propTypes = {
  className: PropTypes.string,
  isPasswordVisible: PropTypes.bool.isRequired,
  changePasswordVisibility: PropTypes.func.isRequired,
};

ShowHidePasswordButton.defaultProps = {
  className: null,
};

export default ShowHidePasswordButton;

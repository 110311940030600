import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { normalizePhone } from 'helpers';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { checkInnWithRequestInFns, getUser, setHintOpen, setINN } from 'modules/authLegasy/actions';
import HintHowToKnowINN from 'modules/authLegasy/components/Hint/HintHowToKnowINN';
import SESteps from 'modules/profileLegasy/components/SESteps/SESteps';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import GetSzInnInput from 'modules/shared/components/GetSzInnInput/GetSzInnInput';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import {
  ALIEN_INN,
  BLUE_BORDER,
  CONSISTENT_ANIMATIONS_DELAY,
  ERROR,
  NO,
  NO_PERMISSION,
  NOT_FOUND,
  NOT_MATCH,
  NOT_READY,
  NOT_READY_PERMISSION_REQUESTED,
  YES,
} from 'constants/index';
import checkValidInn from 'helpers/checkValidInn';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import phoneImg from 'static/assets/inn-phone.png';
import profileBg from 'static/assets/profile-bg.png';

export const SEFromLKEnterINN = ({
  onNextStepButtonClick,
  className,
  dispatch,
  INN,
  onSuccessSERegistration,
  onFailedSERegistration,
  onAlreadyRegistered,
  onServerError,
  onNoSz,
  onNoRights,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  /** Валидация ИНН */
  useEffect(() => {
    setIsValid(false);

    if (INN && INN.length === 12 && checkValidInn(INN)) {
      setIsValid(true);
    }
  }, [INN]);

  const handleSubmitInn = () => {
    setIsLoading(true);
    dispatch(checkInnWithRequestInFns(INN))
      .then((data) => {
        /** Проверяем, если овтет с сервера NOT_READY или  NOT_READY_PERMISSION_REQUESTED отправляем
         * повторный запрос*/
        if (
          data &&
          data.data &&
          (data.data.status === NOT_READY || data.data.status === NOT_READY_PERMISSION_REQUESTED)
        ) {
          onNextStepButtonClick();
        }

        if (data && data.data && data.data.status === NOT_FOUND) {
          onFailedSERegistration();
        }

        if (data && data.data && data.data.status === NOT_MATCH) {
          onFailedSERegistration();
        }

        if (data && data.data && data.data.status === NO_PERMISSION) {
          onNoRights();
        }

        if (data && data.data && data.data.status === ALIEN_INN) {
          onAlreadyRegistered();
        }

        if (data && data.data && data.data.status === NO) {
          onNoSz();
        }

        if (data && data.data && data.data.status === ERROR) {
          onServerError();
        }

        if (data && data.data && data.data.status === YES) {
          dispatch(getUser()).catch(() => {});
          onSuccessSERegistration();
        }

        setIsLoading(false);
      })
      .catch((result) => {
        if (result && result.data && result.data === 'inn.change-denied') {
          onFailedSERegistration();
        } else {
          onServerError();
        }

        setIsLoading(false);
      });
  };

  const handleFieldChange = ({ target }) => {
    const normalInn = normalizePhone(target.value);
    dispatch(setINN(normalInn));
  };

  const hints = [
    {
      id: 1,
      title: 'Как узнать свой ИНН?',
      titleForPopup: 'Как узнать свой ИНН?',
      action: toggleDrop,
      openCondition: isDropOpen,
      popup: <HintHowToKnowINN />,
      dropEl,
    },
  ];

  return (
    <div
      className={cx('se-from-lk', {
        [className]: className,
      })}
      data-testid="se-from-lk-enter-inn"
    >
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="se-from-lk__header">
          <div className="se-from-lk__header-container container container_small">
            <div className="se-from-lk__header-content">
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <SESteps currentStep={1} totalSteps={3} className="se-from-lk__header-step" />
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <h1 className="se-from-lk__header-title">Подтвердить статус</h1>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-from-lk__header-desc">
                  Предоставьте нам права в приложении &laquo;Мой&nbsp;налог&raquo; или на сайте ФНС
                  для полноценной работы с нашим сервисом
                </div>
              </CSSTransition>

              <div className="se-from-lk__form">
                <CSSTransition
                  classNames="animation-from-bottom-to-top"
                  in={isRendered && windowSize.innerWidth > 980}
                  timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                  unmountOnExit
                >
                  <GetSzInnInput
                    value={INN}
                    className="se-from-lk__form-input-holder"
                    isEditButton={false}
                    placeholder="Введите ИНН"
                    onEnterInn={handleFieldChange}
                    isLockVisible={false}
                    isValid={isValid}
                  />
                </CSSTransition>

                <CSSTransition
                  classNames="animation-from-bottom-to-top"
                  in={isRendered && windowSize.innerWidth > 980}
                  timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                  unmountOnExit
                >
                  <button
                    type="button"
                    className="se-from-lk__next-btn"
                    onClick={handleSubmitInn}
                    disabled={!isValid || isLoading}
                    aria-label="Отправить инн на проверку"
                  >
                    {isLoading ? (
                      <CircleLoader />
                    ) : (
                      <IconArrow className="se-from-lk__next-btn-icon" />
                    )}
                  </button>
                </CSSTransition>
              </div>
            </div>
          </div>

          <img src={profileBg} alt="Profile bg" className="se-from-lk__header-bg" />

          {windowSize.innerWidth > 980 && (
            <img src={phoneImg} alt="Phone image" className="se-from-lk__phone" />
          )}

          <Link to="/lk" className="se-from-lk__header-close">
            {windowSize.innerWidth > 980 && <IconClose className="se-from-lk__header-close-icon" />}
            {windowSize.innerWidth <= 980 && (
              <IconArrow className="se-from-lk__header-close-icon" />
            )}
          </Link>

          {windowSize.innerWidth <= 980 && (
            <SESteps currentStep={1} totalSteps={3} className="se-from-lk__header-step" />
          )}
        </div>
      </CSSTransition>

      <div className="se-from-lk__content container container_small">
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <HintsList
            hints={hints}
            closeHintHandler={closeExplicitly}
            className="se-from-lk__hint-block"
            title="Вам будет полезно"
          />
        </CSSTransition>

        <div className="se-from-lk__form">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="se-from-lk__header-desc">
              Предоставьте нам права в приложении &laquo;Мой&nbsp;налог&raquo; или на сайте ФНС для
              полноценной работы с нашим сервисом
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <GetSzInnInput
              value={INN}
              className="se-from-lk__form-input-holder"
              isEditButton={false}
              placeholder="Введите ИНН"
              onEnterInn={handleFieldChange}
              isLockVisible={false}
              isValid={isValid}
            />
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={windowSize.innerWidth <= 980 && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
            unmountOnExit
          >
            <StandardButton
              title="Отправить на проверку"
              isLink={false}
              bgType={BLUE_BORDER}
              disabled={!isValid || isLoading}
              onClick={handleSubmitInn}
              isLoading={isLoading}
            />
          </CSSTransition>
        </div>

        {windowSize.innerWidth <= 980 && (
          <HintsList
            hints={hints}
            closeHintHandler={closeExplicitly}
            className="se-from-lk__hint-block"
            title="Вам будет полезно"
          />
        )}
      </div>
    </div>
  );
};

SEFromLKEnterINN.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  INN: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSuccessSERegistration: PropTypes.func.isRequired,
  onFailedSERegistration: PropTypes.func.isRequired,
  onAlreadyRegistered: PropTypes.func.isRequired,
  onServerError: PropTypes.func.isRequired,
  onNoSz: PropTypes.func.isRequired,
  onNoRights: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  INN: state.auth.INN,
});

export default connect(mapStateToProps)(SEFromLKEnterINN);

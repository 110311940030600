import cx from 'classnames';
import { disableRussianLetters, disableSpaceEnter, normalizePhone, validateEmail } from 'helpers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import PaymentBox from 'modules/shared/components/PaymentBox/PaymentBox';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';

// Styles
import './SaveMoneyPreviewForm.scss';
import { Redirect, useLocation } from 'react-router-dom';
import { getSaveMoneyTransactionOperationStatus } from '../../actions';
import StandardButton from '../../../shared/components/StandardButton/StandardButton';
import SBPButton from '../../../shared/components/SBPButton/SBPButton';
import { WindowUI } from '../../../../ui';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

const hideSBP = false; //;process.env.REACT_APP_IS_PROD_BUILD === 'Y';

const SaveMoneyPreviewForm = ({
  sumHint,
  sum,
  maxLimitSum,
  user,
  moneyboxId,
  needFio,
  needPhone,
  needEmail,
  isPreview,
  isMoneyCollectingFinished,
  showedIframe,
  resetPay,
  isNotAvailable,
}) => {
  const urlRef = useRef(new URL(window.location.href));
  const location = useLocation();
  const inputFio = useRef(null);
  const inputEmail = useRef(null);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeSBPLoading, setIsIframeSBPLoading] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showIframeSBP, setShowIframeSBP] = useState(false);
  const [paymentSum, setPaymentSum] = useState('');
  const [commentary, setCommentary] = useState('');
  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState(null);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);

  const [isOpenPopupIsNotAvailable, setOpenPopupIsNotAvailable] = useState(false);

  const amount = paymentSum || (sum > 0 && sum / 100);

  const updateTimeout = useRef(null);
  const updateOperationStatus = useCallback(
    (operationId) => {
      if (!operationId) {
        clearTimeout(updateTimeout.current);
        return;
      }

      getSaveMoneyTransactionOperationStatus(operationId)
        .then((res) => {
          if (res && res.data) {
            if (res.data.state === STATUS_WAIT) {
              updateTimeout.current = setTimeout(() => updateOperationStatus(operationId), 1000);
            } else if (res.data.state === STATUS_ERROR) {
              setRedirectToErrorPage(`/pay-it/moneybox/${operationId}/error/${res.data.uuid}`);
            } else {
              setRedirectToSuccessPage(`/pay-it/moneybox/${operationId}/success/${res.data.uuid}`);
            }

            if (res.data.transactionStateMessage) {
              window.sessionStorage.setItem(
                'transactionStateMessage',
                res.data.transactionStateMessage,
              );
            }
          }
        })
        .catch(() => {
          const uuid = location.pathname.split('/').reverse()[0];
          setRedirectToErrorPage(`/pay-it/moneybox/${operationId}/error${uuid ? '/' + uuid : ''}`);
        });
    },
    [location.pathname],
  );

  useEffect(() => {
    const stateListener = (e) => {
      if (!process.env.REACT_APP_PCI_DSS_IFRAME.includes(e.origin)) return;
      if (e.data.operationId) {
        clearTimeout(updateTimeout?.current);
        updateOperationStatus(e.data.operationId);
      } else if (e.data.linkUrl) {
        window.open(e.data.linkUrl, e.data.target || '_blank');
      }
    };
    if (showIframeSBP) {
      window.addEventListener('message', stateListener);
    }
    return () => {
      window.removeEventListener('message', stateListener);
      clearTimeout(updateTimeout?.current);
    };
  }, [showIframeSBP, updateOperationStatus]);

  useEffect(() => {
    showedIframe(showIframe || showIframeSBP);
  }, [showIframe, showIframeSBP, showedIframe]);

  const getIframeUrl = (sbp) => {
    let url = `${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/${
      sbp ? 'pay-it-money-box-sbp' : 'pay-it-money-box'
    }?payItUID=${moneyboxId}&account=${user ? user.login : 'anonymous'}&amount=${amount * 100}`;

    if (commentary) {
      url += `&comment=${commentary}`;
    }

    if (fio) {
      url += `&fio=${fio}`;
    }

    if (phone) {
      url += `&phone=${phone}`;
    }

    if (email) {
      url += `&email=${email}`;
    }

    return url;
  };

  const continueButtonClick = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    window.sessionStorage.setItem('moneyBoxTransferAmount', amount);
    setStep(2);
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const continueButtonClickSBP = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    window.sessionStorage.setItem('moneyBoxTransferAmount', amount);
    setStep(2);
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  useEffect(() => {
    if (resetPay) {
      setStep(0);
      setShowIframe(false);
      setIsIframeLoading(false);
      setShowIframeSBP(false);
      setIsIframeSBPLoading(false);
    }
  }, [resetPay]);

  useEffect(() => {
    if (urlRef.current) {
      const localAmount = urlRef.current.searchParams.get('amount');
      const localComment = urlRef.current.searchParams.get('comment');
      const localFio = urlRef.current.searchParams.get('fio');
      const localPhone = urlRef.current.searchParams.get('phone');
      const localEmail = urlRef.current.searchParams.get('email');

      if (localComment) {
        setCommentary(localComment);
      }

      if (localAmount) {
        setPaymentSum((+localAmount / 100).toFixed(2));
      }

      if (localFio) {
        setFio(localFio);
      }

      if (localPhone) {
        setPhone(localPhone);
      }

      if (localEmail) {
        setEmail(localEmail);
      }
    }
  }, []);

  /** Показываем preloader для iframe, затем iframe */
  const onPayByCard = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setStep(2);
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const onPayBySBP = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setStep(2);
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  const handleEmailFieldChange = (event) => {
    setEmail(disableRussianLetters(disableSpaceEnter(event.target.value)));
  };

  const handleFieldChange = (event) => {
    setPhone(normalizePhone(event.target.value));
  };

  if (redirectToErrorPage) {
    return <Redirect to={redirectToErrorPage} />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  return (
    <div className="save-money-preview-form">
      {isMoneyCollectingFinished && !isPreview && (
        <p className="save-money-preview-form__block-title">Сбор средств закончился</p>
      )}

      {!isMoneyCollectingFinished && isOpenPopupIsNotAvailable && isNotAvailable && (
        <WindowUI.WindowUniversalError
          header={'Ошибка оплаты'}
          text={'Оплата невозможна из-за превышения лимитов у получателя.'}
          buttonText={'Хорошо'}
          onClose={() => {
            setOpenPopupIsNotAvailable(false);
          }}
        />
      )}

      {step === 0 && (
        <>
          <div className="save-money-preview-form__block">
            <div
              className={cx(
                'save-money-preview-form__input-holder save-money-preview-form__input-holder_sum',
                {
                  'save-money-preview-form__input-holder_focused': amount,
                },
              )}
            >
              <NumberFormat
                className="save-money-preview-form__input"
                value={!isPreview ? amount || '' : sum || ''}
                placeholder={!isPreview ? `${sumHint / 100} ₽` : ''}
                thousandSeparator=" "
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                isNumericString
                allowedDecimalSeparators={[',', '.']}
                type="tel"
                suffix=" ₽"
                onValueChange={(values) => {
                  const { floatValue, value } = values;
                  const minSum = 1;
                  const maxSum = maxLimitSum / 100;

                  if (floatValue < minSum) {
                    setPaymentSum(minSum);
                  } else if (floatValue > maxSum) {
                    setPaymentSum(maxSum);
                  } else if (value === '') {
                    setPaymentSum('');
                  } else {
                    setPaymentSum(value);
                  }
                }}
                readOnly={sum}
                isAllowed={(values) => {
                  const { value } = values;

                  if (value.charAt(0) === '0') {
                    return value.charAt(1) === '.';
                  }

                  return true;
                }}
                disabled={isMoneyCollectingFinished}
              />
              <p className="save-money-preview-form__label">Сумма</p>
              {amount && !isPreview && (
                <IconCheck className="save-money-preview-form__input-check" />
              )}
            </div>
          </div>
          <div className="save-money-preview-form__block">
            <div
              className={cx('save-money-preview-form__input-holder', {
                'save-money-preview-form__input-holder_focused': commentary !== '',
              })}
            >
              <textarea
                cols={30}
                rows={10}
                className="save-money-preview-form__textarea"
                onChange={({ target }) => setCommentary(target.value)}
                value={commentary}
                disabled={isMoneyCollectingFinished}
              />
              <p className="save-money-preview-form__label">Комментарий</p>
            </div>
          </div>
          <div className="save-money-preview-form__button-wrap">
            {needFio || needPhone || needEmail || isPreview ? (
              <>
                {isPreview && !(needFio || needPhone || needEmail) ? (
                  <>
                    <div className="save-money-preview-form__button-wrap-preview">
                      <StandardButton
                        title="Оплатить картой"
                        isLink={false}
                        disabled
                        className="save-money-preview-form__button"
                      />
                      <SBPButton className="save-money-preview-form__button" disablePayButton />
                    </div>
                  </>
                ) : (
                  <button
                    className="save-money-preview-form__button"
                    type="button"
                    onClick={() => setStep(1)}
                    disabled={!moneyboxId || (!amount && !paymentSum) || isMoneyCollectingFinished}
                  >
                    Продолжить
                  </button>
                )}
              </>
            ) : (
              <PaymentBox
                amount={Number(amount) || 0}
                isDisabled={
                  !moneyboxId ||
                  (!amount && !paymentSum) ||
                  isMoneyCollectingFinished ||
                  (needFio && !fio) ||
                  (needEmail && (!email || !validateEmail(email))) ||
                  (needPhone && (!phone || (phone && normalizePhone(phone).length !== 11)))
                }
                onPayByCard={continueButtonClick}
                onPayBySBP={continueButtonClickSBP}
                disableApplePayButton
                disableSamsungPayButton
                isOneColumn
                hideSBP={hideSBP}
                hideCard
              />
            )}
          </div>
        </>
      )}

      {step === 1 && (
        <>
          {needFio && (
            <div className="save-money-preview-form__block">
              <div
                className={cx('save-money-preview-form__input-holder', {
                  'save-money-preview-form__input-holder_focused': fio,
                })}
              >
                <p className="save-money-preview-form__label">ФИО</p>
                <input
                  type="text"
                  maxLength={50}
                  className="save-money-preview-form__input"
                  onChange={(event) => setFio(event.target.value)}
                  value={fio}
                  ref={inputFio}
                  onFocus={() =>
                    setTimeout(
                      () => inputFio.current.setAttribute('placeholder', 'Иванов Иван Иванович'),
                      125,
                    )
                  }
                  onBlur={() => inputFio.current.removeAttribute('placeholder')}
                />
                {fio && <IconCheck className="save-money-preview-form__input-check" />}
              </div>
            </div>
          )}
          {needPhone && (
            <div className="save-money-preview-form__block">
              <div
                className={cx('save-money-preview-form__input-holder', {
                  'save-money-preview-form__input-holder_focused': phone,
                })}
              >
                <p className="save-money-preview-form__label">Ваш номер телефона</p>

                <InputMask
                  type="text"
                  className="save-money-preview-form__input"
                  mask="+7\ (999) 999-99-99"
                  maskChar={0}
                  value={phone}
                  onChange={(event) => handleFieldChange(event)}
                />
                {phone && normalizePhone(phone).length === 11 && (
                  <IconCheck className="save-money-preview-form__input-check" />
                )}
              </div>
            </div>
          )}
          {needEmail && (
            <div className="save-money-preview-form__block">
              <div
                className={cx('save-money-preview-form__input-holder', {
                  'save-money-preview-form__input-holder_focused': email,
                })}
              >
                <p className="save-money-preview-form__label">Электронная почта</p>
                <input
                  type="text"
                  className="save-money-preview-form__input"
                  onChange={(event) => handleEmailFieldChange(event)}
                  value={email}
                  ref={inputEmail}
                  onFocus={() =>
                    setTimeout(
                      () =>
                        inputEmail.current.setAttribute('placeholder', 'Selfworker@selfwork.ru'),
                      125,
                    )
                  }
                  onBlur={() => inputEmail.current.removeAttribute('placeholder')}
                />
                {email && validateEmail(email) && (
                  <IconCheck className="save-money-preview-form__input-check" />
                )}
              </div>
            </div>
          )}
          <div className="save-money-preview-form__button-wrap">
            {isPreview ? (
              <div className="save-money-preview-form__button-wrap-preview">
                <StandardButton
                  title="Оплатить картой"
                  isLink={false}
                  disabled
                  className="save-money-preview-form__button"
                />
                <SBPButton className="save-money-preview-form__button" disablePayButton />
              </div>
            ) : (
              <PaymentBox
                amount={Number(amount) || 0}
                isDisabled={
                  !moneyboxId ||
                  (!amount && !paymentSum) ||
                  isMoneyCollectingFinished ||
                  (needFio && !fio) ||
                  (needEmail && (!email || !validateEmail(email))) ||
                  (needPhone && (!phone || (phone && normalizePhone(phone).length !== 11)))
                }
                onPayByCard={onPayByCard}
                onPayBySBP={onPayBySBP}
                disableApplePayButton
                disableSamsungPayButton
                isOneColumn
                hideSBP={hideSBP}
                hideCard
              />
            )}
          </div>
        </>
      )}

      {step === 2 && (
        <>
          {showIframe && (
            <div className="save-money-preview-form__iframe-wrap">
              {isIframeLoading && (
                <div className="save-money-preview-form__iframe-loader">
                  <CircleLoader />
                </div>
              )}
              <iframe
                title="SaveMoneyPreviewForm"
                className="save-money-preview-form__iframe"
                src={getIframeUrl(false)}
                frameBorder="0"
                onLoad={() => {
                  setIsIframeLoading(false);
                }}
              />
            </div>
          )}
          {showIframeSBP && (
            <div className="save-money-preview-form__iframe-wrap-sbp">
              {isIframeSBPLoading && (
                <div className="save-money-preview-form__iframe-loader">
                  <CircleLoader />
                </div>
              )}
              <iframe
                title="SaveMoneyPreviewForm"
                className="save-money-preview-form__iframe"
                src={getIframeUrl(true)}
                frameBorder="0"
                onLoad={() => {
                  setIsIframeSBPLoading(false);
                }}
              />
            </div>
          )}
        </>
      )}

      <div className="save-money-preview-form__privacy-policy">
        <span>Совершая платеж, вы соглашаетесь с </span>
        <a
          href="https://самозанятые.рф/legal/walletoffer.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="save-money-preview-form__privacy-policy-link"
        >
          публичной офертой
        </a>
        ,{' '}
        <a
          href="https://самозанятые.рф/legal/walletterms.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="save-money-preview-form__privacy-policy-link"
        >
          пользовательским соглашением
        </a>
        <span> и </span>
        <a
          href={`${process.env.REACT_APP_URL}privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
          className="save-money-preview-form__privacy-policy-link"
        >
          политикой конфиденциальности
        </a>
      </div>

      <div className="save-money-preview-form__payments-type">
        <IconVisa className="save-money-preview-form__payments-type-item save-money-preview-form__payments-type-item_visa" />
        <IconMasterCard className="save-money-preview-form__payments-type-item save-money-preview-form__payments-type-item_msc" />
        <IconMir className="save-money-preview-form__payments-type-item save-money-preview-form__payments-type-item_mir" />
        <IconPci className="save-money-preview-form__payments-type-item save-money-preview-form__payments-type-item_pci" />
      </div>
    </div>
  );
};

SaveMoneyPreviewForm.propTypes = {
  moneyboxId: PropTypes.string,
  sum: PropTypes.number,
  sumHint: PropTypes.number,
  maxLimitSum: PropTypes.number,
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  needFio: PropTypes.bool,
  needPhone: PropTypes.bool,
  needEmail: PropTypes.bool,
  isPreview: PropTypes.bool,
  isMoneyCollectingFinished: PropTypes.bool,
  showedIframe: PropTypes.func,
};

SaveMoneyPreviewForm.defaultProps = {
  moneyboxId: null,
  sum: null,
  maxLimitSum: null,
  sumHint: null,
  user: null,
  needFio: false,
  needPhone: false,
  needEmail: false,
  isPreview: false,
  isMoneyCollectingFinished: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SaveMoneyPreviewForm);

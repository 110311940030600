import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Overlay from '../Overlay';
import iconEmojiSad from 'static/assets/icon-emoji-sad.png';

import { hidePopupAddPartner, hidePopupAddPartnerFail } from '../../actions';

import styles from './PopupAddPartnerFail.module.scss';
import { useHistory } from 'react-router-dom';

const PopupAddPartnerFail = ({ dispatch }) => {
  const history = useHistory();
  const text =
    'Что-то пошло не так, попробуйте повторить попытку позже или напишите в тех. поддержку.';

  function onClose() {
    dispatch(hidePopupAddPartner());
    dispatch(hidePopupAddPartnerFail());
  }

  function onCloseSuccessEdit() {
    dispatch(hidePopupAddPartner());
    dispatch(hidePopupAddPartnerFail());
    history.replace('/lk/profile-settings/partners');
  }

  return createPortal(
    <Overlay
      title="Ой! Ошибка!"
      icon={iconEmojiSad}
      text={() => text}
      nav={(props) => <PopupAddPartnerFailNav {...props} />}
      onClose={onClose}
      onSuccess={onCloseSuccessEdit}
    />,
    document.body,
  );
};

PopupAddPartnerFail.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const PopupAddPartnerFailNav = ({ onSuccess }) => (
  <div className={styles.popupAddPartnerFailNav}>
    <button onClick={onSuccess}>К списку партнеров</button>
  </div>
);

PopupAddPartnerFailNav.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default connect()(PopupAddPartnerFail);

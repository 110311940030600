import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './ManualDropdown.scss';

const ManualDropdown = React.forwardRef((props, ref) => {
  const { activeButton, setStepByNavClick } = props;
  const manualProgressSteps = [
    {
      id: 1,
      title: 'Получение статуса самозанятого',
    },
    {
      id: 2,
      title: 'Регистрация в кошельке',
    },
    {
      id: 3,
      title: 'Подтверждение статуса самозанятого в кошельке',
    },
    {
      id: 4,
      title: 'Сотрудничество с компаниями',
    },
  ];

  return (
    <div className="manual-dropdown" ref={ref}>
      {manualProgressSteps.map(item => (
        <button
          className={cx('manual-dropdown__item', {
            'manual-dropdown__item_active': activeButton === item.id,
            'manual-dropdown__item_passed':
              (activeButton === 2 && item.id === 1) ||
              (activeButton === 3 && (item.id === 1 || item.id === 2)) ||
              (activeButton === 4 && (item.id === 1 || item.id === 2 || item.id === 3)),
          })}
          key={item.id}
          type="button"
          onClick={() => setStepByNavClick(item.id)}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
});

ManualDropdown.propTypes = {
  activeButton: PropTypes.number,
  setStepByNavClick: PropTypes.func.isRequired,
};

ManualDropdown.defaultProps = {
  activeButton: 1,
};

export default ManualDropdown;

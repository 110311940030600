/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ACCOUNTS_TYPES, AccountTypeDetailsWidget } from 'modules/profile';

export const EDOAccountDetailsTemplate = () => {
  const match = useRouteMatch();
  const { path } = match;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <AccountTypeDetailsWidget filterGroup={ACCOUNTS_TYPES.EDO} />
        </Route>
      </Switch>
    </>
  );
};
